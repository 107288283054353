<div [formGroup]="contactHistoryFormGroup">
  <div class="col-12">
    <div>
      <div class="row">
        <div class="col-10 col-md-11">
          <app-tlm-contact-entry
            [contactHistoryFormGroup]="contactHistoryFormGroup"
            [matAppearance]="matAppearance"
            [errorMatcher]="errorMatcher"
            [index]="index"
            [locations]="locations"
          >
          </app-tlm-contact-entry>
        </div>
        <div class="col-1 my-auto">
          <button
            mat-icon-button
            color="warn"
            [id]="'removeContactHistory' + index"
            (click)="removeOnClick(index)"
            [disabled]="!removeActionEnabled()"
          >
            <mat-icon class="fa fa-close fa-lg"></mat-icon>
          </button>
        </div>
      </div>
    </div>
  </div>
</div>

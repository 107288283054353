<div>
  <div class="row">
    <div class="col-12">
      <mat-label class="tlm-header">Basic Information</mat-label>
    </div>
  </div>
  <mat-divider></mat-divider>
  <br />

  <form class="needs-validation" novalidate [formGroup]="basicInfoFormFormGroup">
    <div class="row no-gutters">
      <div class="col-12">
        <div class="row">
          <div class="col-12 col-md-4">
            <mat-form-field [appearance]="matAppearance">
              <mat-label>Profile Type:</mat-label>
              <mat-select
                id="profileType"
                [formControl]="profileTypeFormControl"
                [compareWith]="selectCompareProfileTypes"
                (selectionChange)="onProfileTypeChange(basicInfoFormFormGroup)"
                [errorStateMatcher]="errorMatcher"
              >
                <mat-select-trigger>
                  <mat-icon fontSet="fa" [fontIcon]="displayProfileType().icon"></mat-icon>&nbsp;{{ displayProfileType().display }}
                </mat-select-trigger>
                <mat-option disabled>Select One</mat-option>
                <mat-option *ngFor="let profileType of profileTypes" [value]="profileType" [disabled]="profileType.type === 'boloIncomplete'">
                  <mat-icon fontSet="fa" [fontIcon]="profileType.icon"></mat-icon>
                  {{ profileType.display }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="col-12 col-md-8">
            <mat-form-field [appearance]="matAppearance">
              <mat-label>Rock Security Id</mat-label>
              <input id="rockSecurityId" matInput [formControlName]="formNames.rockSecurityId" [errorStateMatcher]="errorMatcher" />
              <mat-error *ngFor="let item of basicInfoFormFormGroup.get(formNames.rockSecurityId).errors | keyvalue">
                {{ displayFieldError(item.key, item.value) }}
              </mat-error>
            </mat-form-field>
          </div>
        </div>
        <div class="row">
          <div class="col-12 col-md-4 tlm-center">
            <ngx-gallery
              id="profilePhotoGallery"
              #ngxGalleryProfilePhoto
              [options]="ngxGalleryOptionsProfilePhoto"
              [images]="ngxGalleryImagesProfilePhoto"
            ></ngx-gallery>
          </div>
          <div class="col-12 col-md-8">
            <div class="row">
              <div class="col-12">
                <mat-form-field [appearance]="matAppearance">
                  <mat-label>First Name</mat-label>
                  <input id="firstName" matInput [formControlName]="formNames.firstName" [errorStateMatcher]="errorMatcher" />
                  <mat-error *ngFor="let item of basicInfoFormFormGroup.get(formNames.firstName).errors | keyvalue">
                    {{ displayFieldError(item.key, item.value) }}
                  </mat-error>
                </mat-form-field>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <mat-form-field [appearance]="matAppearance">
                  <mat-label>Last Name</mat-label>
                  <input id="lastName" type="text" matInput [formControlName]="formNames.lastName" [errorStateMatcher]="errorMatcher" />
                  <mat-error *ngFor="let item of basicInfoFormFormGroup.get(formNames.lastName).errors | keyvalue">
                    {{ displayFieldError(item.key, item.value) }}
                  </mat-error>
                </mat-form-field>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12 col-md-3">
            <mat-form-field [appearance]="matAppearance">
              <mat-label>Date of Birth</mat-label>
              <input
                id="dateOfBirth"
                matInput
                [min]="minDate"
                [max]="maxDate"
                [matDatepicker]="dobPicker"
                [formControlName]="formNames.dob"
                [errorStateMatcher]="errorMatcher"
              />
              <mat-error *ngIf="basicInfoFormFormGroup.get('Date of Birth').hasError('matDatepickerParse')">
                Please enter a valid date MM/DD/YYYY
              </mat-error>
              <mat-error *ngIf="basicInfoFormFormGroup.get('Date of Birth').hasError('matDatepickerMin')">
                Please enter a date after 1/1/1900
              </mat-error>
              <mat-error *ngIf="basicInfoFormFormGroup.get('Date of Birth').hasError('matDatepickerMax')">
                Please enter a date before today
              </mat-error>
              <mat-datepicker-toggle matSuffix [for]="dobPicker"></mat-datepicker-toggle>
              <mat-datepicker #dobPicker></mat-datepicker>
            </mat-form-field>
          </div>
          <div class="col-12 col-md-3">
            <mat-form-field [appearance]="matAppearance">
              <mat-label>Gender</mat-label>
              <mat-select id="gender" [formControlName]="formNames.gender" [errorStateMatcher]="errorMatcher">
                <mat-option>Select One</mat-option>
                <mat-option *ngFor="let gender of genders" [value]="gender">
                  {{ gender }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="col-12 col-md-6">
            <mat-form-field [appearance]="matAppearance">
              <mat-label>Race</mat-label>
              <mat-select id="race" [formControlName]="formNames.race" [errorStateMatcher]="errorMatcher">
                <mat-option>Select One</mat-option>
                <mat-option *ngFor="let race of races" [value]="race">
                  {{ race }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <app-tlm-edit-threat-aliases
              [aliasesFormArray]="basicInfoFormFormGroup.get(formNames.aliasList)"
              [matAppearance]="matAppearance"
              [errorMatcher]="errorMatcher"
            >
            </app-tlm-edit-threat-aliases>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <app-tlm-edit-threat-emails
              [emailsFormArray]="basicInfoFormFormGroup.get(formNames.emailList)"
              [matAppearance]="matAppearance"
              [errorMatcher]="errorMatcher"
            >
            </app-tlm-edit-threat-emails>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <app-tlm-edit-threat-phones
              [phonesFormArray]="basicInfoFormFormGroup.get(formNames.phoneList)"
              [phoneTypes]="phoneTypes"
              [matAppearance]="matAppearance"
              [errorMatcher]="errorMatcher"
            >
            </app-tlm-edit-threat-phones>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <app-tlm-edit-threat-vehicles
              [vehiclesFormArray]="basicInfoFormFormGroup.get(formNames.vehicleList)"
              [states]="states"
              [matAppearance]="matAppearance"
              [errorMatcher]="errorMatcher"
            >
            </app-tlm-edit-threat-vehicles>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>

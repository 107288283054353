<div [formGroup]="addressFormGroup">
  <div class="col-12">
    <div>
      <div class="row">
        <div class="col-10 col-md-11">
          <div class="row">
            <div class="col-12">
              <mat-form-field [appearance]="matAppearance">
                <mat-label>Street</mat-label>
                <input [id]="'street' + index" matInput [formControlName]="formNames.addressStreet" [errorStateMatcher]="errorMatcher" />
              </mat-form-field>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <mat-form-field [appearance]="matAppearance">
                <mat-label>City</mat-label>
                <input [id]="'city' + index" matInput [formControlName]="formNames.addressCity" [errorStateMatcher]="errorMatcher" />
              </mat-form-field>
            </div>
          </div>
          <div class="row">
            <div class="col-12 col-md-6">
              <mat-form-field [appearance]="matAppearance">
                <mat-label>State</mat-label>
                <mat-select [formControlName]="formNames.addressState" [id]="'state' + index" [errorStateMatcher]="errorMatcher">
                  <mat-option>Select One</mat-option>
                  <mat-option *ngFor="let state of states" [value]="state.name">
                    {{ state.name }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="col-12 col-md-6">
              <mat-form-field [appearance]="matAppearance">
                <mat-label>Zip</mat-label>
                <input [id]="'zip' + index" matInput [formControlName]="formNames.addressZip" [errorStateMatcher]="errorMatcher" />
              </mat-form-field>
            </div>
          </div>
          <div class="row">
            <div class="col-12 col-md-6">
              <mat-form-field [appearance]="matAppearance">
                <mat-label>From Date</mat-label>
                <input
                  [id]="'fromDate' + index"
                  matInput
                  [min]="minDate"
                  [max]="maxDate"
                  [matDatepicker]="fromDatePicker"
                  [formControlName]="formNames.addressFrom"
                  [errorStateMatcher]="errorMatcher"
                />
                <mat-error *ngIf="addressFormGroup.get(formNames.addressFrom).hasError('matDatepickerParse')">
                  Please enter a valid date MM/DD/YYYY
                </mat-error>
                <mat-error *ngIf="addressFormGroup.get(formNames.addressFrom).hasError('matDatepickerMin')">
                  Please enter a date after 1/1/1900
                </mat-error>
                <mat-error *ngIf="addressFormGroup.get(formNames.addressFrom).hasError('matDatepickerMax')">
                  Please enter a date before today
                </mat-error>
                <mat-datepicker-toggle [id]="'fromDatePickerToggle' + index" matSuffix [for]="fromDatePicker"></mat-datepicker-toggle>
                <mat-datepicker #fromDatePicker></mat-datepicker>
              </mat-form-field>
            </div>
            <div class="col-12 col-md-6">
              <mat-form-field [appearance]="matAppearance">
                <mat-label>To Date</mat-label>
                <input
                  [id]="'toDate' + index"
                  matInput
                  [min]="minDate"
                  [max]="maxDate"
                  [matDatepicker]="toDatePicker"
                  [formControlName]="formNames.addressTo"
                  [errorStateMatcher]="errorMatcher"
                />
                <mat-error *ngIf="addressFormGroup.get(formNames.addressTo).hasError('matDatepickerParse')">
                  Please enter a valid date MM/DD/YYYY
                </mat-error>
                <mat-error *ngIf="addressFormGroup.get(formNames.addressTo).hasError('matDatepickerMin')">
                  Please enter a date after 1/1/1900
                </mat-error>
                <mat-error *ngIf="addressFormGroup.get(formNames.addressTo).hasError('matDatepickerMax')">
                  Please enter a date before today
                </mat-error>
                <mat-error *ngIf="addressFormGroup.get(formNames.addressTo).hasError('toDateLessThanFromDate')">
                  {{ addressFormGroup.get('to').errors['toDateLessThanFromDate'] }}
                </mat-error>
                <mat-datepicker-toggle [id]="'toDatePickerToggle' + index" matSuffix [for]="toDatePicker"></mat-datepicker-toggle>
                <mat-datepicker #toDatePicker></mat-datepicker>
              </mat-form-field>
            </div>
          </div>
          <div class="row">
            <div class="col-12" style="margin-top: 5px">
              <mat-checkbox [id]="'currentAddress' + index" [formControlName]="formNames.addressCurrent" (change)="onChangeCurrentAddress()"
                >Current Address</mat-checkbox
              >
            </div>
          </div>
          <mat-error *ngIf="addressFormGroup.hasError('missingMainDataDate')">
            {{ addressFormGroup.errors['missingMainDataDate'] }}
          </mat-error>
          <mat-error *ngIf="addressFormGroup.hasError('missingMainDataCurrent')">
            {{ addressFormGroup.errors['missingMainDataCurrent'] }}
          </mat-error>
        </div>
        <div class="col-1 my-auto">
          <div class="row">
            <div class="col-12">
              <button mat-icon-button color="warn" id="removeAddress" (click)="removeOnClick(index)" [disabled]="!removeActionEnabled()">
                <mat-icon class="fa fa-close fa-lg"></mat-icon>
              </button>
            </div>
          </div>
          <br />
          <div class="row">
            <div class="col-12">
              <button mat-icon-button color="accent" id="moveAddressUp" (click)="moveUpOnClick(index)" [disabled]="!moveUpActionEnabled()">
                <mat-icon class="fa fa-chevron-up"></mat-icon>
              </button>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <button mat-icon-button color="accent" id="moveAddressDown" (click)="moveDownOnClick(index)" [disabled]="!moveDownActionEnabled()">
                <mat-icon class="fa fa-chevron-down"></mat-icon>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-tlm-auth-callback-silent-refresh',
  templateUrl: './auth-callback-silent-refresh.component.html'
})
export class AuthCallbackSilentRefreshComponent implements OnInit {
  ngOnInit() {
    // console.log('silent refresh');
  }
}

import { Component, Input } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { StateModel, EditThreatFormNameModel } from '../../../../../shared';
import * as _ from 'lodash';
import { MyErrorStateMatcher, EditThreatUtilities, editThreatFormNames } from '../../../utilities';

@Component({
  moduleId: module.id,
  selector: 'app-tlm-edit-threat-vehicle',
  templateUrl: './vehicle.component.html',
  styleUrls: ['./vehicle.component.scss']
})
export class EditThreatVehicleComponent {
  @Input() vehicleFormGroup: UntypedFormGroup;
  @Input() index: number;
  @Input() length: number;
  @Input() isLast: boolean;
  @Input() removeItem: (n: number) => void;
  @Input() isPopulated: () => boolean;
  @Input() move: (n1: number, n2: number) => void;
  @Input() states: StateModel[];
  @Input() matAppearance: string;
  @Input() errorMatcher: MyErrorStateMatcher;
  formNames: EditThreatFormNameModel;

  constructor(private editThreatUtils: EditThreatUtilities) {
    this.formNames = editThreatFormNames;
  }

  removeActionEnabled(): boolean {
    return this.editThreatUtils.removeActionEnabled(this.length, this.isPopulated, this.vehicleFormGroup);
  }

  removeOnClick(index: number) {
    if (this.removeActionEnabled()) {
      this.removeItem(index);
    }
  }

  moveUpActionEnabled() {
    return this.editThreatUtils.moveUpActionEnabled(this.index);
  }

  moveUpOnClick(index) {
    if (this.moveUpActionEnabled()) {
      this.move(index, index - 1);
    }
  }

  moveDownActionEnabled() {
    return this.editThreatUtils.moveDownActionEnabled(this.length, this.index);
  }

  moveDownOnClick(index) {
    if (this.moveDownActionEnabled()) {
      this.move(index, index + 1);
    }
  }
}

<div>
  <div class="row">
    <div class="col-12">
      <span class="tlm-header d-none d-lg-inline">Be On The Lookout</span>
      <span class="tlm-header d-inline d-lg-none">BOLO</span>
    </div>
  </div>
  <div class="row">
    <div class="col-12">
      <form class="needs-validation" novalidate [formGroup]="bolosFormFormGroup">
        <div class="row">
          <div class="col-12">
            <app-tlm-edit-threat-bolos
              [matAppearance]="matAppearance"
              [errorMatcher]="errorMatcher"
              [profileTypeFormControl]="profileTypeFormControl"
              [bolosFormArray]="bolosFormFormGroup.get(formNames.boloList)"
            >
            </app-tlm-edit-threat-bolos>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>

<div>
  <div class="row">
    <div class="col-12">
      <span class="tlm-header">Addresses</span>
    </div>
  </div>
  <div class="row">
    <div class="col-12">
      <form class="needs-validation" novalidate [formGroup]="addressesFormFormGroup">
        <div class="row">
          <div class="col-12">
            <app-tlm-edit-threat-addresses
              [matAppearance]="matAppearance"
              [errorMatcher]="errorMatcher"
              [states]="states"
              [addressesFormArray]="addressesFormFormGroup.get(formNames.addressList)"
            >
            </app-tlm-edit-threat-addresses>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>

import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { EditThreatComponent } from '../edit-threat.component';

export interface EditThreatData {
  errors: Array<string>;
}

@Component({
  selector: 'app-tlm-edit-threat-error-modal',
  templateUrl: 'error-modal.component.html',
  styleUrls: ['error-modal.component.scss']
})
export class EditThreatErrorModalComponent {
  constructor(public dialogRef: MatDialogRef<EditThreatComponent>, @Inject(MAT_DIALOG_DATA) public data: EditThreatData) {}
}

<div id="fullIdentificationForm">
  <div class="row">
    <div class="col-12 add-identification-container">
      <button type="button" id="addIdentification" class="btn btn-link tlm-link" (click)="createAndAddItemToListIfPopulated()">
        + Add Identification
      </button>
    </div>
  </div>
  <mat-divider></mat-divider>
  <br />
  <div *ngFor="let identificationFormGroup of identificationsFormArray.controls; let i = index">
    <div class="row">
      <div class="col-12">
        <div>
          <app-tlm-edit-threat-identification
            [identificationFormGroup]="identificationFormGroup"
            [index]="i"
            [length]="identificationsFormArray.controls.length"
            [removeItem]="boundRemoveItem"
            [isPopulated]="boundIsPopulated"
            [move]="boundMove"
            [states]="states"
            [matAppearance]="matAppearance"
            [errorMatcher]="errorMatcher"
            [identificationTypes]="identificationTypes"
          >
          </app-tlm-edit-threat-identification>
          <br />
          <mat-divider></mat-divider>
          <br />
        </div>
      </div>
    </div>
    <br />
  </div>
</div>

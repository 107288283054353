<div id="editThreatErrorModal" class="modal-content">
  <div class="modal-header background">
    <h4 class="modal-title">There are Error(s) in the Form(s)</h4>
  </div>
  <div class="modal-body">
    <div *ngFor="let error of data.errors; let i = index">
      <div [id]="'error' + i" class="tlm-error alert alert-danger">
        {{ error }}
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button mat-button color="primary" [mat-dialog-close]="" cdkFocusInitial>OK</button>
  </div>
</div>

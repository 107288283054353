import { Component, Input } from '@angular/core';
import { MyErrorStateMatcher } from '../../../pages/edit-threat/utilities/edit-threat.error-matcher';
import { UntypedFormControl } from '@angular/forms';

@Component({
  moduleId: module.id,
  selector: 'app-tlm-form-control-text-area',
  templateUrl: './form-control-text-area.component.html'
})
export class FormControlTextAreaComponent {
  @Input() fc: UntypedFormControl;
  @Input() label: string;
  @Input() componentId: string;
  @Input() matAppearance: string;
  @Input() errorMatcher: MyErrorStateMatcher;
  @Input() minRows: number;
  @Input() maxRows: number;
}

import { Component, Input } from '@angular/core';
import { StateModel, EditThreatFormNameModel } from '../../../shared';
import { UntypedFormGroup } from '@angular/forms';
import { MyErrorStateMatcher, editThreatFormNames } from '../utilities';

@Component({
  selector: 'app-tlm-edit-threat-identification-form',
  templateUrl: './identifications-form.component.html'
})
export class EditThreatIdentificationsFormComponent {
  @Input() states: StateModel[];
  @Input() identificationTypes: string[];
  @Input() identificationsFormFormGroup: UntypedFormGroup = new UntypedFormGroup({});
  @Input() matAppearance: string;
  @Input() errorMatcher: MyErrorStateMatcher;
  formNames: EditThreatFormNameModel = editThreatFormNames;
}

<div class="container-fullwidth">
  <app-tlm-layout-header></app-tlm-layout-header>
</div>
<br />
<div class="container">
  <div class="row">
    <dl>
      <dt>App Version:</dt>
      <dd>{{ version }}</dd>
      <dt>Deployed Version:</dt>
      <dd>{{ deployedVersion.version }}</dd>
      <dt>Deployed gitCommitDate:</dt>
      <dd>{{ deployedVersion.gitDate }}</dd>
      <dt>Deployed gitCommit:</dt>
      <dd>
        <a href="https://git.rockfin.com/OldRepublic/ThreadLevelMidnight.UI/commit/{{ deployedVersion.gitCommit }}">{{
          deployedVersion.gitCommit8
        }}</a>
      </dd>
      <dt>Deployed Build:</dt>
      <dd>
        <a href="https://circleci.foc.zone/workflow-run/{{ deployedVersion.buildNumber }}">{{ deployedVersion.buildNumber }}</a>
      </dd>
    </dl>
  </div>
</div>

import { Component, Input } from '@angular/core';
import * as _ from 'lodash';
import * as sjv from 'simple-js-validator';
import { UntypedFormArray, UntypedFormGroup } from '@angular/forms';
import { EditThreatUtilities, MyErrorStateMatcher, EditThreatUtilitiesSocialForm, editThreatFormNames } from '../../utilities';

@Component({
  moduleId: module.id,
  selector: 'app-tlm-edit-threat-social-medias',
  templateUrl: './socialmedias.component.html'
})
export class EditThreatSocialMediasComponent {
  @Input() socialMediasFormArray: UntypedFormArray = new UntypedFormArray([]);
  @Input() socialMediaTypes: string[];
  @Input() matAppearance: string;
  @Input() errorMatcher: MyErrorStateMatcher;
  boundRemoveItem: (n: number) => void;
  boundIsPopulated: () => boolean;

  constructor(private editThreatUtils: EditThreatUtilities, private editThreatUtilitiesSocialForm: EditThreatUtilitiesSocialForm) {
    this.boundRemoveItem = this.removeItemFromList.bind(this);
    this.boundIsPopulated = this.isPopulated.bind(this);
  }

  isPopulated(socialMediaFormGroup: UntypedFormGroup): boolean {
    if (
      socialMediaFormGroup &&
      ((socialMediaFormGroup.get(editThreatFormNames.socialMediaValue) &&
        sjv.isNotEmpty(socialMediaFormGroup.get(editThreatFormNames.socialMediaValue).value)) ||
        (socialMediaFormGroup.get(editThreatFormNames.socialMediaType) &&
          sjv.isNotEmpty(socialMediaFormGroup.get(editThreatFormNames.socialMediaType).value)))
    ) {
      return true;
    }
    return false;
  }

  createAndAddItemToListIfPopulated(): void {
    return this.editThreatUtils.createAndAddItemToListIfPopulated(
      this.socialMediasFormArray,
      this.editThreatUtilitiesSocialForm.createEmptySocialMediaItemFormGroup.bind(this.editThreatUtilitiesSocialForm),
      this.isPopulated,
      'socialMedias'
    );
  }

  removeItemFromList(index: number): void {
    let reset = false;
    if (this.socialMediasFormArray.length === 1) {
      reset = true;
    }
    return this.editThreatUtils.removeItemFromList(
      this.socialMediasFormArray,
      this.editThreatUtilitiesSocialForm.createEmptySocialMediaItemFormGroup.bind(this.editThreatUtilitiesSocialForm),
      index,
      reset
    );
  }
}

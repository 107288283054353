import { Component, Input } from '@angular/core';
import * as _ from 'lodash';
import * as sjv from 'simple-js-validator';
import { UntypedFormArray, UntypedFormGroup } from '@angular/forms';
import { EditThreatUtilities, MyErrorStateMatcher, EditThreatUtilitiesBasicInfoForm, editThreatFormNames } from '../../utilities';

@Component({
  moduleId: module.id,
  selector: 'app-tlm-edit-threat-phones',
  templateUrl: './phones.component.html'
})
export class EditThreatPhonesComponent {
  @Input() phonesFormArray: UntypedFormArray = new UntypedFormArray([]);
  @Input() phoneTypes: string[];
  @Input() matAppearance: string;
  @Input() errorMatcher: MyErrorStateMatcher;
  boundRemoveItem: (n: number) => void;
  boundIsPopulated: () => boolean;

  constructor(private editThreatUtils: EditThreatUtilities, private editThreatUtilsBasicInfoForm: EditThreatUtilitiesBasicInfoForm) {
    this.boundRemoveItem = this.removeItemFromList.bind(this);
    this.boundIsPopulated = this.isPopulated.bind(this);
  }

  isPopulated(phoneFG: UntypedFormGroup): boolean {
    if (
      phoneFG &&
      ((phoneFG.get(editThreatFormNames.phoneNumber) && sjv.isNotEmpty(phoneFG.get(editThreatFormNames.phoneNumber).value)) ||
        (phoneFG.get(editThreatFormNames.phoneType) && sjv.isNotEmpty(phoneFG.get(editThreatFormNames.phoneType).value)))
    ) {
      return true;
    }
    return false;
  }

  createAndAddItemToListIfPopulated(): void {
    return this.editThreatUtils.createAndAddItemToListIfPopulated(
      this.phonesFormArray,
      this.editThreatUtilsBasicInfoForm.createEmptyPhoneItemFormGroup.bind(this.editThreatUtilsBasicInfoForm),
      this.isPopulated,
      'phones'
    );
  }

  removeItemFromList(index: number): void {
    let reset = false;
    if (this.phonesFormArray.length === 1) {
      reset = true;
    }
    return this.editThreatUtils.removeItemFromList(
      this.phonesFormArray,
      this.editThreatUtilsBasicInfoForm.createEmptyPhoneItemFormGroup.bind(this.editThreatUtilsBasicInfoForm),
      index,
      reset
    );
  }
}

import { Injectable, Inject } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { IAuthService } from '../../../auth';
import { NgxConfigureService } from 'ngx-configure';

@Injectable()
export class ImageUtilitiesService {
  baseUrl: string;

  constructor(private configService: NgxConfigureService, private http: HttpClient, @Inject('TlmAuthService') private authService: IAuthService) {
    this.baseUrl = this.configService.config.tlmApi.imageServices + '/services/images';
  }

  process(image: string): Observable<any> {
    const headers = this.authService.buildAuthHeaders();
    return this.http.get(this.baseUrl + '/process/' + image, { headers });
  }

  signedGetUrl(key): Observable<any> {
    const headers = this.authService.buildAuthHeaders({
      // eslint-disable-next-line @typescript-eslint/naming-convention
      Accept: 'application/json'
    });
    const url = this.baseUrl + '/signed?type=get&key=' + key;
    return this.http.post<any>(url, {}, { headers });
  }

  signedPutUrl(key, extension, contentType): Observable<any> {
    const headers = this.authService.buildAuthHeaders({
      // eslint-disable-next-line @typescript-eslint/naming-convention
      Accept: 'application/json'
    });
    const url = this.baseUrl + '/signed?type=put&key=' + key + '&extension=' + extension + '&content_type=' + contentType;
    return this.http.post<any>(url, {}, { headers });
  }

  uploadImageBlobToSignedPutUrl(url: string, contentType: string, blob: Blob): Observable<any> {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const headers = new HttpHeaders({ 'Content-Type': contentType });
    return this.http.put(url, blob, { headers, reportProgress: true });
  }

  getFromSignedGetUrl(url: string): Observable<any> {
    return this.http.get(url);
  }

  determineExtension(fileName: string) {
    const dotIndex = fileName.lastIndexOf('.');
    let extension = '';

    if (dotIndex > -1) {
      const sliceIndex = fileName.length - dotIndex - 1;
      extension = fileName.slice(-1 * sliceIndex);

      if (extension === 'jpeg') {
        extension = 'jpg';
      }
    }

    return extension;
  }

  determineContentType(extension: string) {
    let contentType: string;

    switch (extension.toLowerCase()) {
      case 'json':
        contentType = 'application/json';
        break;
      case 'bmp':
        contentType = 'image/bmp';
        break;
      case 'gif':
        contentType = 'image/gif';
        break;
      case 'jpeg':
      case 'jpg':
        contentType = 'image/jpeg';
        break;
      case 'png':
        contentType = 'image/png';
        break;
      default:
        contentType = 'unknown';
    }
    return contentType;
  }

  generateKey(tlmId: string, extension: string) {
    return `${tlmId}/${Math.floor(+new Date() / 1000)}.${extension}`;
  }
}

import { Injectable, Inject } from '@angular/core';
import { Observable, of } from 'rxjs';
import { ISocialMediaTypeApiService } from './social-media-type-api-service.interface';
import { ILogger } from '../logger';

@Injectable()
export class SocialMediaTypeApiService implements ISocialMediaTypeApiService {
  data: string[];

  constructor(@Inject('LoggerInjected') private logger: ILogger) {
    this.data = ['Facebook', 'Instagram', 'LinkedIn', 'Snapchat', 'Twitter', 'Website', 'YouTube', 'Other'];
  }

  list(): Observable<string[]> {
    this.logger.debug('SocialMediaTypeApiMockService.List', this.data);
    return of(this.data);
  }
}

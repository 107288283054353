import { Injectable, Inject } from '@angular/core';
import { Observable, of } from 'rxjs';
import * as _ from 'lodash';
import { TlmModel, TlmModelFactory } from '../../../shared';
import { ITlmApiService } from './tlm-api.interface';
import { ILogger } from '../logger';
import * as shortid from 'shortid';
const tlms = require('./mock/tlms.json');

@Injectable()
export class TlmApiMockService implements ITlmApiService {
  data: TlmModel[] = new Array<TlmModel>();

  constructor(@Inject('LoggerInjected') private logger: ILogger) {
    this.data = tlms.map((item) => new TlmModelFactory().create(item));
  }

  create(item: TlmModel): Observable<TlmModel> {
    item.tlmId = shortid.generate();
    this.data.push(item);
    this.logger.debug('TlmApiMockService.Create', item);
    return of(item);
  }

  delete(id: string): Observable<TlmModel> {
    const found = _.remove(this.data, (item) => item.tlmId === id);
    this.logger.debug('TlmApiMockService.Delete', found);
    return of(found[0]);
  }

  get(id: string): Observable<TlmModel> {
    const found = _.find(this.data, { tlmId: id });
    this.logger.debug('TlmApiMockService.Get', found);
    return of(found);
  }

  list(searchTerm: string): Observable<TlmModel[]> {
    this.logger.debug('TlmApiMockService.List', this.data);
    return of(this.data);
  }

  boloList(): Observable<TlmModel[]> {
    this.logger.debug('TlmApiMockService.BoloList', this.data);
    return of(this.data);
  }

  update(item: TlmModel): Observable<TlmModel> {
    const index = _.findIndex(this.data, { tlmId: item.tlmId });
    this.data[index] = item;
    this.logger.debug('TlmApiMockService.Update', item);
    return of(item);
  }

  load(items: TlmModel[]) {
    this.data = items;
  }
}

<div>
  <div class="row">
    <div class="col-12">
      <span class="tlm-section-title">Aliases</span>
      <button type="button" id="addAlias" class="btn btn-link tlm-link pull-right" (click)="createAndAddItemToListIfPopulated()">+ Add Alias</button>
    </div>
  </div>
  <div *ngFor="let aliasFormGroup of aliasesFormArray.controls; let i = index">
    <app-tlm-edit-threat-alias
      [aliasFormGroup]="aliasFormGroup"
      [index]="i"
      [length]="aliasesFormArray.controls.length"
      [removeItem]="boundRemoveItem"
      [isPopulated]="boundIsPopulated"
      [matAppearance]="matAppearance"
      [errorMatcher]="errorMatcher"
    >
    </app-tlm-edit-threat-alias>
  </div>
</div>

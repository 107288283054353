export class PhoneModel {
  orderId: number;
  value: string;
  type: string;

  constructor(orderId?: number, value?: string, type?: string) {
    this.orderId = orderId;
    this.value = value;
    this.type = type;
  }
}

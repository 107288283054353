import { Component, Inject } from '@angular/core';
import { IAuthService } from '../../auth';

@Component({
  selector: 'app-tlm-version',
  templateUrl: './logout.component.html'
})
export class LogoutComponent {
  constructor(@Inject('TlmAuthService') private authService: IAuthService) {
    this.authService.logout();
  }
}

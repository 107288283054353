import { StateModel } from './state.model';

export class AddressModel {
  orderId: number;
  street: string;
  city: string;
  state: StateModel;
  zip: string;
  from: any;
  to: any;
  currentAddress: boolean;

  // eslint-disable-next-line max-len
  constructor(
    orderId?: number,
    street?: string,
    city?: string,
    state?: StateModel,
    zip?: string,
    from?: string,
    to?: string,
    currentAddress?: boolean
  ) {
    this.orderId = orderId;
    this.street = street;
    this.city = city;
    this.state = state;
    this.zip = zip;
    this.from = from;
    this.to = to;
    this.currentAddress = currentAddress;
  }
}

import { Injectable } from '@angular/core';
import { UntypedFormGroup, UntypedFormArray, UntypedFormBuilder, UntypedFormControl } from '@angular/forms';
import * as _ from 'lodash';
import * as sjv from 'simple-js-validator';
import { TlmModel, ContactHistoryModel } from '../../../shared';
import { EditThreatUtilities } from './edit-threat-utilities.service';
import { editThreatFormNames } from './edit-threat-form-names';

@Injectable()
export class EditThreatUtilitiesContactHistoryForm {
  constructor(private fb: UntypedFormBuilder, private editThreatUtils: EditThreatUtilities) {}

  createEmptyItemFormGroup(profileTypeFC: UntypedFormControl): UntypedFormGroup {
    return this.fb.group(
      {
        [editThreatFormNames.contactDate]: [null, this.dateCannotBeGreaterThanToday.bind(this)],
        [editThreatFormNames.contactHour]: [],
        [editThreatFormNames.contactMinute]: [],
        [editThreatFormNames.contactMeridiem]: [],
        [editThreatFormNames.contactLocation]: [null, this.locationMustBeLocationObject.bind(this)],
        [editThreatFormNames.contactNote]: []
      },
      {
        validator: [
          this.dateRequiredIfBoloOrContactFieldsPopulated.bind(this, profileTypeFC),
          this.hourRequiredIfBoloOrContactFieldsPopulated.bind(this, profileTypeFC),
          this.minutesRequiredIfBoloOrContactFieldsPopulated.bind(this, profileTypeFC),
          this.daytimeRequiredIfBoloOrContactFieldsPopulated.bind(this, profileTypeFC),
          this.locationRequiredIfBoloOrContactFieldsPopulated.bind(this, profileTypeFC)
        ]
      }
    );
  }

  initializeForm(): UntypedFormGroup {
    return new UntypedFormGroup({
      [editThreatFormNames.contactList]: new UntypedFormArray([])
    });
  }

  mapFromTlm(tlm: TlmModel, profileTypeFC: UntypedFormControl): UntypedFormGroup {
    const contactHistoryFA = new UntypedFormArray([]);

    if (tlm.contactHistory && tlm.contactHistory.length > 0) {
      tlm.contactHistory.forEach((contactHistory) => {
        contactHistoryFA.push(
          this.fb.group(
            {
              // ...contactHistory,
              [editThreatFormNames.contactDate]: [contactHistory.date, this.dateCannotBeGreaterThanToday.bind(this)],
              [editThreatFormNames.contactHour]: [contactHistory.hour],
              [editThreatFormNames.contactMinute]: [contactHistory.minutes],
              [editThreatFormNames.contactMeridiem]: [contactHistory.dayTime],
              [editThreatFormNames.contactLocation]: [contactHistory.location, this.locationMustBeLocationObject.bind(this)],
              // TODO: Clean up bad test data so above line doesn't cause errors
              // [editThreatFormNames.contactLocation]: [contactHistory.location],
              [editThreatFormNames.contactNote]: [contactHistory.notes]
            },
            {
              validator: [
                this.dateRequiredIfBoloOrContactFieldsPopulated.bind(this, profileTypeFC),
                this.hourRequiredIfBoloOrContactFieldsPopulated.bind(this, profileTypeFC),
                this.minutesRequiredIfBoloOrContactFieldsPopulated.bind(this, profileTypeFC),
                this.daytimeRequiredIfBoloOrContactFieldsPopulated.bind(this, profileTypeFC),
                this.locationRequiredIfBoloOrContactFieldsPopulated.bind(this, profileTypeFC)
              ]
            }
          )
        );
      });
    } else {
      contactHistoryFA.push(this.createEmptyItemFormGroup(profileTypeFC));
    }

    return new UntypedFormGroup({
      [editThreatFormNames.contactList]: contactHistoryFA
    });
  }

  mapToTlmContactEntry(fg: UntypedFormGroup, order: number): ContactHistoryModel {
    let mapped: ContactHistoryModel = null;
    const location = this.editThreatUtils.getValueOrSetAsUndefined(fg.get(editThreatFormNames.contactLocation));
    const date = this.editThreatUtils.getValueOrSetAsUndefined(fg.get(editThreatFormNames.contactDate));
    const notes = this.editThreatUtils.getValueOrSetAsUndefined(fg.get(editThreatFormNames.contactNote));
    const hour = this.editThreatUtils.getValueOrSetAsUndefined(fg.get(editThreatFormNames.contactHour));
    const minutes = this.editThreatUtils.getValueOrSetAsUndefined(fg.get(editThreatFormNames.contactMinute));
    const dayTimes = this.editThreatUtils.getValueOrSetAsUndefined(fg.get(editThreatFormNames.contactMeridiem));

    if (
      sjv.isNotEmpty(location) ||
      sjv.isNotEmpty(date) ||
      sjv.isNotEmpty(notes) ||
      sjv.isNotEmpty(hour) ||
      sjv.isNotEmpty(minutes) ||
      sjv.isNotEmpty(dayTimes)
    ) {
      mapped = new ContactHistoryModel(order, location, date, notes, hour, minutes, dayTimes);
    }

    return mapped;
  }

  mapToTlm(fg: UntypedFormGroup): ContactHistoryModel[] {
    const mapped = new Array<ContactHistoryModel>();
    let order = 0;
    const contactHistoryFA = fg.get(editThreatFormNames.contactList) as UntypedFormArray;

    contactHistoryFA.controls.forEach((contactHistoryFG) => {
      const entry = this.mapToTlmContactEntry(contactHistoryFG as UntypedFormGroup, order);
      if (entry) {
        mapped.push(entry);
        order++;
      }
    });
    return mapped;
  }

  fieldRequiredIfBoloOrContactFieldsPopulated(profileTypeFC: UntypedFormControl, fg: UntypedFormGroup, requiredFC: UntypedFormControl, errorCode: string, errMsg: string) {
    const dateFC = fg.get(editThreatFormNames.contactDate) as UntypedFormControl;
    const hourFC = fg.get(editThreatFormNames.contactHour) as UntypedFormControl;
    const minuteFC = fg.get(editThreatFormNames.contactMinute) as UntypedFormControl;
    const meridiemFC = fg.get(editThreatFormNames.contactMeridiem) as UntypedFormControl;
    const notesFC = fg.get(editThreatFormNames.contactNote) as UntypedFormControl;
    const locationFC = fg.get(editThreatFormNames.contactLocation) as UntypedFormControl;

    // scenario 1: if bolo and required not touched/submitted; then dont display any error (wait until touched/submitted)
    // scenario 2: if bolo and required touched/submitted and required empty; then display error
    // scenario 3: if bolo and required touched/submitted and required populated; then no error to display
    // scenario 4: if not bolo and contact fields not touched/submitted; then no error to display
    // scenario 5: if not bolo and contact fields touched/submitted and contact fields empty (thus required empty); then no error to display
    // scenario 6: if not bolo and contact fields touched/submitted and contact fields populated and required empty; then display error
    // scenario 7: if not bolo and contact fields touched/submitted and contact fields populated and required populated; then no error to display

    if (_.get(profileTypeFC, 'value.type', '') === 'bolo') {
      if (requiredFC.touched && sjv.isEmpty(requiredFC.value)) {
        // scenario 2: if bolo and date touched/submitted and date empty; then display error
        this.editThreatUtils.addErrorToFormControl(requiredFC, errorCode, errMsg);
        return { [errorCode]: errMsg };
      } else {
        // scenario 1: if bolo and date not touched/submitted; then dont display any error (wait until touched/submitted)
        // scenario 3: if bolo and date touched/submitted and date populated; then no error to display
        this.editThreatUtils.removeErrorFromFormControl(requiredFC, errorCode);
        return null;
      }
    } else {
      if (
        (dateFC.touched || hourFC.touched || minuteFC.touched || meridiemFC.touched || notesFC.touched || locationFC.touched) &&
        (sjv.isNotEmpty(dateFC.value) ||
          sjv.isNotEmpty(hourFC.value) ||
          sjv.isNotEmpty(minuteFC.value) ||
          sjv.isNotEmpty(meridiemFC.value) ||
          sjv.isNotEmpty(notesFC.value) ||
          sjv.isNotEmpty(locationFC.value)) &&
        sjv.isEmpty(requiredFC.value)
      ) {
        // scenario 6: if not bolo and contact fields touched/submitted and contact fields populated and required empty; then display error
        this.editThreatUtils.addErrorToFormControl(requiredFC, errorCode, errMsg);
        return { [errorCode]: errMsg };
      } else {
        // scenario 4: if not bolo and contact fields not touched/submitted; then no error to display
        // scenario 5: if not bolo and contact fields touched/submitted and contact fields empty (thus required empty); then no error to display
        // scenario 7: if not bolo and contact fields touched/submitted and contact fields populated and required populated; then no error to display
        this.editThreatUtils.removeErrorFromFormControl(requiredFC, errorCode);
        return null;
      }
    }
  }

  dateRequiredIfBoloOrContactFieldsPopulated(profileTypeFC: UntypedFormControl, fg: UntypedFormGroup) {
    const dateFC = fg.get(editThreatFormNames.contactDate) as UntypedFormControl;
    const errorCode = 'missingDateField';
    const errMsg = 'Contact: [Date] required';
    return this.fieldRequiredIfBoloOrContactFieldsPopulated(profileTypeFC, fg, dateFC, errorCode, errMsg);
  }

  hourRequiredIfBoloOrContactFieldsPopulated(profileTypeFC: UntypedFormControl, fg: UntypedFormGroup) {
    const dateFC = fg.get(editThreatFormNames.contactHour) as UntypedFormControl;
    const errorCode = 'missingHourField';
    const errMsg = 'Contact: [Hour] required';
    return this.fieldRequiredIfBoloOrContactFieldsPopulated(profileTypeFC, fg, dateFC, errorCode, errMsg);
  }

  minutesRequiredIfBoloOrContactFieldsPopulated(profileTypeFC: UntypedFormControl, fg: UntypedFormGroup) {
    const minutesFC = fg.get(editThreatFormNames.contactMinute) as UntypedFormControl;
    const errorCode = 'missingMinuteField';
    const errMsg = 'Contact: [Minutes] required';
    return this.fieldRequiredIfBoloOrContactFieldsPopulated(profileTypeFC, fg, minutesFC, errorCode, errMsg);
  }

  daytimeRequiredIfBoloOrContactFieldsPopulated(profileTypeFC: UntypedFormControl, fg: UntypedFormGroup) {
    const dayTimesFC = fg.get(editThreatFormNames.contactMeridiem) as UntypedFormControl;
    const errorCode = 'missingDayTimeField';
    const errMsg = 'Contact: [Day Time] required';
    return this.fieldRequiredIfBoloOrContactFieldsPopulated(profileTypeFC, fg, dayTimesFC, errorCode, errMsg);
  }

  locationRequiredIfBoloOrContactFieldsPopulated(profileTypeFC: UntypedFormControl, fg: UntypedFormGroup) {
    const locationFC = fg.get(editThreatFormNames.contactLocation) as UntypedFormControl;
    const errorCode = 'missingLocationField';
    const errMsg = 'Contact: [Location] required';
    return this.fieldRequiredIfBoloOrContactFieldsPopulated(profileTypeFC, fg, locationFC, errorCode, errMsg);
  }

  dateCannotBeGreaterThanToday(dateFC: UntypedFormControl) {
    const errorCode = 'dateGreaterThanToday';
    const errMsg = 'Contact: [Date] cannot be greater than Today';
    if (sjv.isNotEmpty(dateFC.value) && new Date(dateFC.value) > new Date(Date.now())) {
      this.editThreatUtils.addErrorToFormControl(dateFC, errorCode, errMsg);
      return { [errorCode]: errMsg };
    } else {
      this.editThreatUtils.removeErrorFromFormControl(dateFC, errorCode);
      return null;
    }
  }

  locationMustBeLocationObject(locationFC: UntypedFormControl) {
    const errorCode = 'locationMustBeSelected';
    const errMsg = 'Contact: [Location] must be selected from drop down';
    if (locationFC.value && !locationFC.value.locationId) {
      this.editThreatUtils.addErrorToFormControl(locationFC, errorCode, errMsg);
      return { [errorCode]: errMsg };
    } else {
      this.editThreatUtils.removeErrorFromFormControl(locationFC, errorCode);
      return null;
    }
  }
}

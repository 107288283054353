import { Component, Input } from '@angular/core';
import { UntypedFormArray, UntypedFormGroup } from '@angular/forms';
import * as sjv from 'simple-js-validator';
import { StateModel } from '../../../../shared';
import { EditThreatUtilities, MyErrorStateMatcher, EditThreatUtilitiesAddressesForm, editThreatFormNames } from '../../utilities';

@Component({
  moduleId: module.id,
  selector: 'app-tlm-edit-threat-addresses',
  templateUrl: './addresses.component.html',
  styleUrls: ['./addresses.component.scss']
})
export class EditThreatAddressesComponent {
  @Input() states: StateModel[];
  @Input() addressesFormArray: UntypedFormArray = new UntypedFormArray([]);
  @Input() matAppearance: string;
  @Input() errorMatcher: MyErrorStateMatcher;
  boundRemoveItem: (n: number) => void;
  boundIsPopulated: () => boolean;
  boundMove: (n1: number, n2: number) => void;

  constructor(private editThreatUtils: EditThreatUtilities, private editThreatUtilsAddressForm: EditThreatUtilitiesAddressesForm) {
    this.boundRemoveItem = this.removeItemFromList.bind(this);
    this.boundIsPopulated = this.isPopulated.bind(this);
    this.boundMove = this.move.bind(this);
  }

  isPopulated(addressFG: UntypedFormGroup): boolean {
    if (
      addressFG &&
      ((addressFG.get(editThreatFormNames.addressStreet) && sjv.isNotEmpty(addressFG.get(editThreatFormNames.addressStreet).value)) ||
        (addressFG.get(editThreatFormNames.addressCity) && sjv.isNotEmpty(addressFG.get(editThreatFormNames.addressCity).value)) ||
        (addressFG.get(editThreatFormNames.addressState) && sjv.isNotEmpty(addressFG.get(editThreatFormNames.addressState).value)) ||
        (addressFG.get(editThreatFormNames.addressZip) && sjv.isNotEmpty(addressFG.get(editThreatFormNames.addressZip).value)))
    ) {
      return true;
    }
    return false;
  }

  createAndAddItemToListIfPopulated(): void {
    return this.editThreatUtils.createAndAddItemToListIfPopulated(
      this.addressesFormArray,
      this.editThreatUtilsAddressForm.createEmptyItemFormGroup.bind(this.editThreatUtilsAddressForm),
      this.isPopulated,
      'addresses'
    );
  }

  removeItemFromList(index: number): void {
    let reset = false;
    if (this.addressesFormArray.length === 1) {
      reset = true;
    }
    return this.editThreatUtils.removeItemFromList(
      this.addressesFormArray,
      this.editThreatUtilsAddressForm.createEmptyItemFormGroup.bind(this.editThreatUtilsAddressForm),
      index,
      reset
    );
  }

  move(oldIndex, newIndex) {
    return this.editThreatUtils.moveItemInList(this.addressesFormArray, oldIndex, newIndex);
  }
}

import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { DeviceDetectorService } from 'ngx-device-detector';

@Injectable()
export class MediaService {
  private renderAsMobileStatus = new BehaviorSubject<boolean>(false);

  constructor(private deviceService: DeviceDetectorService) {
    // TODO, listen for resize event so this is observable instead of just one time
    // https://stackoverflow.com/questions/35527456/angular-window-resize-event
    // this.mediaService.subscribe(event => {

    const isMobile = this.deviceService.isMobile();

    this.renderAsMobileStatus.next(isMobile);
    // });
  }

  getRenderAsMobileStatus(): Observable<boolean> {
    return this.renderAsMobileStatus.asObservable();
  }
}

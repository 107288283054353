import { ModuleWithProviders, NgModule } from '@angular/core';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { RouterModule } from '@angular/router';
import { NgxGalleryModule } from 'ngx-gallery-9';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { NgxSpinnerModule } from 'ngx-spinner';
import { BoloGuard } from '../../auth';
import { CoreModule } from '../../core';
import { SharedModule } from '../../shared';
import { BoloProfileComponent } from './bolo-profile/bolo-profile.component';
import { BoloComponent } from './bolo.component';

const routing: ModuleWithProviders<unknown> = RouterModule.forChild([
  {
    path: 'bolo',
    component: BoloComponent,
    canActivate: [BoloGuard]
  }
]);

@NgModule({
  declarations: [BoloComponent, BoloProfileComponent],
  imports: [
    routing,
    SharedModule,
    CoreModule,
    InfiniteScrollModule,
    NgxSpinnerModule,
    NgxGalleryModule,
    MatSidenavModule,
    MatFormFieldModule,
    MatInputModule,
    MatCardModule,
    MatDividerModule,
    MatExpansionModule,
    MatTabsModule,
    MatButtonModule,
    MatSelectModule,
    MatDatepickerModule,
    MatAutocompleteModule,
    MatToolbarModule,
    MatListModule,
    MatTableModule
  ],
  providers: []
})
export class BoloModule {}

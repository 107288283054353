import { ModuleWithProviders, NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { SharedModule } from '../../shared';

import { LogoutComponent } from './logout.component';

const routing: ModuleWithProviders<unknown> = RouterModule.forChild([
  {
    path: 'logout',
    component: LogoutComponent
  }
]);

@NgModule({
  declarations: [LogoutComponent],
  imports: [routing, SharedModule]
})
export class LogoutModule {}

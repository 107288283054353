import { ModuleWithProviders, NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';
import { NgxSpinnerModule } from 'ngx-spinner';
import { SearchGuard } from '../../auth';
import { CoreModule } from '../../core';
import { SharedModule } from '../../shared';
import { HomeComponent } from './home.component';

const routing: ModuleWithProviders<unknown> = RouterModule.forChild([
  {
    path: 'home',
    component: HomeComponent,
    canActivate: [SearchGuard]
  }
]);

@NgModule({
  declarations: [HomeComponent],
  imports: [
    routing,
    MatDatepickerModule,
    MatInputModule,
    MatFormFieldModule,
    MatIconModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatTableModule,
    MatSortModule,
    MatTooltipModule,
    SharedModule,
    CoreModule,
    NgxSpinnerModule
  ]
})
export class HomeModule {}

<button class="button-cancel" matTooltip="Cancel" mat-dialog-close mat-button color="warn" id="cancel">
  <mat-icon class="fa fa-close fa-lg" ></mat-icon>
</button>
<div class="tlm-add-profile-modal-header">
  <span>Choose Profile Type</span>
</div>
<br />
 <mat-dialog-content>
  <button class="btn btn-block tlm-profile-type-bolo-outline-button" (click)="onBoloSelected()">
    <span class="fa fa-binoculars fa-3x add-profile-option-icon"></span>
    <span class="add-profile-option-text">BOLO</span>
  </button>
  <mat-divider class="mat-divider"></mat-divider>
  <button class="btn btn-block tlm-profile-type-threat-outline-button" (click)="onThreatSelected()">
    <span class="fa fa-user-secret fa-3x add-profile-option-icon"></span>
    <span class="add-profile-option-text">Threat</span>
  </button>
</mat-dialog-content>

<div [formGroup]="identificationFormGroup">
  <div class="col-12">
    <div>
      <div class="row">
        <div class="col-10 col-md-11">
          <div class="row">
            <div class="col-12 col-md-6">
              <mat-form-field [appearance]="matAppearance">
                <mat-label>Id Type</mat-label>
                <mat-select
                  [id]="'idType' + index"
                  [formControlName]="formNames.identificationType"
                  (selectionChange)="onChangeIdentificationType()"
                  [errorStateMatcher]="errorMatcher"
                >
                  <mat-option>Select One</mat-option>
                  <mat-option *ngFor="let identificationType of identificationTypes" [value]="identificationType">
                    {{ identificationType }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="col-12 col-md-6">
              <mat-form-field [appearance]="matAppearance">
                <mat-label>State</mat-label>
                <mat-select
                  [id]="'idState' + index"
                  [formControlName]="formNames.identificationState"
                  [id]="'state' + index"
                  [errorStateMatcher]="errorMatcher"
                >
                  <mat-option>Select One</mat-option>
                  <mat-option *ngFor="let state of states" [value]="state.name">
                    {{ state.name }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>
          <div class="row">
            <div class="col-12 col-md-6">
              <mat-form-field [appearance]="matAppearance">
                <mat-label>Id Number</mat-label>
                <input [id]="'idNumber' + index" matInput [formControlName]="formNames.identificationNumber" [errorStateMatcher]="errorMatcher" />
              </mat-form-field>
            </div>
            <div class="col-12 col-md-6">
              <mat-form-field [appearance]="matAppearance">
                <mat-label>Expiration Date</mat-label>
                <input
                  [id]="'expirationDate' + index"
                  matInput
                  [min]="minDate"
                  [matDatepicker]="expirationDatePicker"
                  [formControlName]="formNames.identificationExpirationDate"
                  [errorStateMatcher]="errorMatcher"
                />
                <mat-error *ngIf="identificationFormGroup.get(formNames.identificationExpirationDate).hasError('matDatepickerParse')">
                  Please enter a valid date MM/DD/YYYY
                </mat-error>
                <mat-error *ngIf="identificationFormGroup.get(formNames.identificationExpirationDate).hasError('matDatepickerMin')">
                  Please enter a date after 1/1/1900
                </mat-error>
                <mat-datepicker-toggle matSuffix [for]="expirationDatePicker"></mat-datepicker-toggle>
                <mat-datepicker #expirationDatePicker></mat-datepicker>
              </mat-form-field>
            </div>
          </div>
        </div>
        <div class="col-1">
          <div class="row">
            <div class="col-12">
              <button mat-icon-button color="warn" id="removeIdentification" (click)="removeOnClick(index)" [disabled]="!removeActionEnabled()">
                <mat-icon class="fa fa-close fa-lg"></mat-icon>
              </button>
            </div>
          </div>
          <br />
          <div class="row">
            <div class="col-12">
              <button mat-icon-button color="accent" id="moveIdentificationUp" (click)="moveUpOnClick(index)" [disabled]="!moveUpActionEnabled()">
                <mat-icon class="fa fa-chevron-up"></mat-icon>
              </button>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <button
                mat-icon-button
                color="accent"
                id="moveIdentificationDown"
                (click)="moveDownOnClick(index)"
                [disabled]="!moveDownActionEnabled()"
              >
                <mat-icon class="fa fa-chevron-down"></mat-icon>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div [formGroup]="boloFormGroup">
  <div class="row">
    <div class="col-12 col-md-6">
      <mat-form-field [appearance]="matAppearance">
        <mat-label>Start Date</mat-label>
        <input
          id="boloStartDate"
          matInput
          [min]="minDate"
          [matDatepicker]="startDatePicker"
          [formControlName]="formNames.boloStartDate"
          [errorStateMatcher]="errorMatcher"
        />
        <mat-error *ngFor="let item of boloFormGroup.get(formNames.boloStartDate).errors | keyvalue">
          {{ displayFieldError(item.key, item.value) }}
        </mat-error>
        <mat-datepicker-toggle id="startDatePickerToggle" matSuffix [for]="startDatePicker"></mat-datepicker-toggle>
        <mat-datepicker #startDatePicker></mat-datepicker>
      </mat-form-field>
    </div>
    <div class="col-12 col-md-6">
      <mat-form-field [appearance]="matAppearance">
        <mat-label>End Date</mat-label>
        <input
          id="boloEndDate"
          matInput
          [min]="minDate"
          [matDatepicker]="endDatePicker"
          [formControlName]="formNames.boloEndDate"
          [errorStateMatcher]="errorMatcher"
        />
        <mat-error *ngFor="let item of boloFormGroup.get(formNames.boloEndDate).errors | keyvalue">
          {{ displayFieldError(item.key, item.value) }}
        </mat-error>
        <mat-datepicker-toggle id="endDatePickerToggle" matSuffix [for]="endDatePicker"></mat-datepicker-toggle>
        <mat-datepicker #endDatePicker></mat-datepicker>
      </mat-form-field>
    </div>
  </div>
  <div class="row">
    <div class="col-12">
      <mat-form-field [appearance]="matAppearance">
        <mat-label>Reason</mat-label>
        <textarea
          id="boloReason"
          matInput
          cdkTextareaAutosize
          #autosize="cdkTextareaAutosize"
          cdkAutosizeMinRows="5"
          cdkAutosizeMaxRows="25"
          [formControlName]="formNames.boloReason"
        ></textarea>
      </mat-form-field>
    </div>
  </div>
</div>

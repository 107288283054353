import { DateTime } from 'luxon';

export class PersonModel {
  firstName: string;
  lastName: string;
  gender: string;
  dateOfBirth: string;
  race: string;

  constructor(firstName?: string, lastName?: string, dateOfBirth?: string, gender?: string, race?: string) {
    this.firstName = firstName;
    this.lastName = lastName;
    this.gender = gender;
    this.dateOfBirth = dateOfBirth;
    this.race = race;
  }

  get fullNameForDisplay(): string {
    let toDisplay = 'Unknown';

    if (this.firstName && this.lastName) {
      toDisplay = this.firstName + ' ' + this.lastName;
    } else if (this.firstName) {
      toDisplay = this.firstName;
    } else if (this.lastName) {
      toDisplay = this.lastName;
    }

    return toDisplay;
  }

  get dateOfBirthForDisplay(): string {
    let toDisplay = 'Unknown';

    if (this.dateOfBirth) {
      const birthDate = DateTime.fromISO(this.dateOfBirth);
      const age = Math.floor(-1 * birthDate.diffNow('years').years);
      toDisplay = `${birthDate.toLocaleString(DateTime.DATE_FULL)} - ${age} years old`;
    }
    
    return toDisplay;
  }

  get genderForDisplay() {
    let toDisplay = 'Unknown';

    if (this.gender) {
      toDisplay = this.gender;
    }

    return toDisplay;
  }

  get raceForDisplay() {
    let toDisplay = 'Unknown';

    if (this.race) {
      toDisplay = this.race;
    }

    return toDisplay;
  }
}

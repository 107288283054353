import { Component, Input } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { MyErrorStateMatcher } from '../../../utilities/edit-threat.error-matcher';
import { EditThreatUtilities } from '../../../utilities';

@Component({
  moduleId: module.id,
  selector: 'app-tlm-edit-threat-bolo',
  templateUrl: './bolo.component.html'
})
// eslint-disable-next-line max-len
export class EditThreatBoloComponent {
  @Input() profileTypeFormControl: UntypedFormControl;
  @Input() boloFormGroup: UntypedFormGroup;
  @Input() index: number;
  @Input() length: number;
  @Input() removeItem: (n: number) => void;
  @Input() isPopulated: () => boolean;
  @Input() move: (n1: number, n2: number) => void;
  @Input() matAppearance: string;
  @Input() errorMatcher: MyErrorStateMatcher;

  maxDate = new Date();
  minDate = new Date(2019, 0, 1);

  constructor(private editThreatUtils: EditThreatUtilities) {}

  removeActionEnabled(): boolean {
    return this.editThreatUtils.removeActionEnabled(this.length, this.isPopulated, this.boloFormGroup);
  }

  removeOnClick(index: number): void {
    if (this.removeActionEnabled()) {
      this.removeItem(index);
    }
  }
}

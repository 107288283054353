<div>
  <div class="row">
    <div class="col-12">
      <span class="tlm-header">Contact History</span>
    </div>
  </div>
  <div class="row">
    <div class="col-12">
      <form class="needs-validation" novalidate [formGroup]="contactHistoryFormFormGroup">
        <div class="row">
          <div class="col-12">
            <app-tlm-edit-threat-contact-history
              [profileTypeFormControl]="profileTypeFormControl"
              [matAppearance]="matAppearance"
              [errorMatcher]="errorMatcher"
              [contactHistoryFormArray]="contactHistoryFormFormGroup.get(formNames.contactList)"
              [locations]="locations"
            >
            </app-tlm-edit-threat-contact-history>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>

import { Component, EventEmitter, ViewChild, ElementRef, Renderer2 } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, FormControl } from '@angular/forms';
import { HomeComponent } from '../home.component';
import { MatDialogRef } from '@angular/material/dialog';
import { DateRangeModel } from './date-range.model';

@Component({
  selector: 'app-tlm-date-range-modal',
  templateUrl: 'date-range-modal.component.html',
  styleUrls: ['./date-range-modal.component.scss']
})
export class DateRangeModalComponent {
  @ViewChild('weekButton') weekButton: ElementRef;
  @ViewChild('monthButton') monthButton: ElementRef;
  @ViewChild('thirtyDayButton') thirtyDayButton: ElementRef;
  @ViewChild('custom') custom: ElementRef;
  @ViewChild('startDateRange') startDateRange: ElementRef;
  @ViewChild('endDateRange') endDateRange: ElementRef;

  dateRangeModelFormGroup: UntypedFormGroup = new UntypedFormGroup({});
  dateRangeModel = new DateRangeModel();
  dateRange = new EventEmitter();
  startOrEndRangeModified = false;
  customRangeSelected = false;

  maxDate: Date;
  minDate: Date;

  constructor(private renderer: Renderer2, public dialogRef: MatDialogRef<HomeComponent>, private fb: UntypedFormBuilder) {
    this.dateRangeModelFormGroup = this.initializeForm();
    this.maxDate = new Date();
    this.minDate = new Date(1900, 0, 1);
  }

  lastXDaysSelected(days) {
    const endDate = new Date();
    this.dateRangeModel.contactHistoryEndDate = new Date(endDate.setHours(23, 59, 59));

    const date = new Date();
    date.setDate(date.getDate() - days);
    date.setHours(0, 0, 0, 0);

    return date;
  }

  lastWeekSelected() {
    this.dateRangeModel.contactHistoryStartDate = this.lastXDaysSelected(7);
  }

  lastThirtySelected() {
    this.dateRangeModel.contactHistoryStartDate = this.lastXDaysSelected(30);
  }

  lastMonthSelected() {
    const firstOfLastMonth = new Date();
    // sets last day of last month
    firstOfLastMonth.setDate(0);
    // then sets first day of last month, both calls are needed
    firstOfLastMonth.setDate(1);
    firstOfLastMonth.setHours(0, 0, 0, 0);

    const lastDayOfLastMonth = new Date();
    lastDayOfLastMonth.setDate(0);

    this.dateRangeModel.contactHistoryStartDate = firstOfLastMonth;
    this.dateRangeModel.contactHistoryEndDate = lastDayOfLastMonth;
  }

  assignStartDate(startDate: any) {
    this.dateRangeModel.contactHistoryStartDate = startDate;
    this.startOrEndRangeModified = true;
  }

  assignEndDate(endDate: any) {
    this.dateRangeModel.contactHistoryEndDate = new Date(endDate.setHours(23, 59, 59));
    this.startOrEndRangeModified = true;
  }

  submitRange() {
    this.dateRange.emit(this.dateRangeModel);
    this.dialogRef.close();
  }

  clearPreSelections() {
    if (!this.startOrEndRangeModified) {
      this.dateRangeModel.contactHistoryStartDate = undefined;
      this.dateRangeModel.contactHistoryEndDate = undefined;
    }
  }

  accentColor(button: string) {
    if (button === 'week') {
      (this.weekButton as any).color = 'warn';
      (this.thirtyDayButton as any).color = 'primary';
      (this.monthButton as any).color = 'primary';
      this.customRangeSelected = false;
    }
    if (button === 'thirty') {
      (this.thirtyDayButton as any).color = 'warn';
      (this.weekButton as any).color = 'primary';
      (this.monthButton as any).color = 'primary';
      this.customRangeSelected = false;
    }
    if (button === 'month') {
      (this.monthButton as any).color = 'warn';
      (this.weekButton as any).color = 'primary';
      (this.thirtyDayButton as any).color = 'primary';
      this.customRangeSelected = false;
    }
    if (button === 'custom') {
      this.customRangeSelected = true;
      (this.weekButton as any).color = 'primary';
      (this.monthButton as any).color = 'primary';
      (this.thirtyDayButton as any).color = 'primary';
    }
  }

  initializeForm(): UntypedFormGroup {
    return this.fb.group(
      {
        contactHistoryStartRange: [],
        contactHistoryEndRange: []
      },
      {}
    );
  }
}

import { Component, Input } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { MyErrorStateMatcher, EditThreatUtilities, editThreatFormNames } from '../../../utilities';
import { EditThreatFormNameModel } from '../../../../../shared';

@Component({
  moduleId: module.id,
  selector: 'app-tlm-edit-threat-video',
  templateUrl: './video.component.html'
})
export class EditThreatVideoComponent {
  @Input() videoFormGroup: UntypedFormGroup;
  @Input() index: number;
  @Input() length: number;
  @Input() isLast: boolean;
  @Input() removeItem: (n) => void;
  @Input() move: (n1, n2) => void;
  @Input() isPopulated: (fg: UntypedFormGroup) => boolean;
  @Input() matAppearance: string;
  @Input() errorMatcher: MyErrorStateMatcher;
  formNames: EditThreatFormNameModel;

  constructor(private editThreatUtils: EditThreatUtilities) {
    this.formNames = editThreatFormNames;
  }

  removeActionEnabled(): boolean {
    return this.editThreatUtils.removeActionEnabled(this.length, this.isPopulated, this.videoFormGroup);
  }

  removeOnClick(index: number) {
    if (this.removeActionEnabled()) {
      this.removeItem(index);
    }
  }

  moveUpActionEnabled() {
    return this.editThreatUtils.moveUpActionEnabled(this.index);
  }

  moveUpOnClick(index) {
    if (this.moveUpActionEnabled()) {
      this.move(index, index - 1);
    }
  }

  moveDownActionEnabled() {
    return this.editThreatUtils.moveDownActionEnabled(this.length, this.index);
  }

  moveDownOnClick(index) {
    if (this.moveDownActionEnabled()) {
      this.move(index, index + 1);
    }
  }
}

import { Injectable } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, UntypedFormArray, UntypedFormControl } from '@angular/forms';
import * as sjv from 'simple-js-validator';
import * as _ from 'lodash';
import { TlmModel } from '../../../shared';
import { EditThreatUtilities } from './edit-threat-utilities.service';
import { editThreatFormNames } from './edit-threat-form-names';
import { GracieModel } from '../../../shared/models/gracie.model';

@Injectable()
export class EditThreatUtilitiesBackgroundInfoForm {
  constructor(private fb: UntypedFormBuilder, private editThreatUtils: EditThreatUtilities) {}

  createEmptyPhysicalDescriptionFormControl(profileTypeFC: UntypedFormControl): UntypedFormControl {
    return new UntypedFormControl(null, this.physicalDescriptionRequiredIfBolo.bind(this, profileTypeFC));
  }

  initializeForm(profileTypeFC): UntypedFormGroup {
    return this.fb.group({
      [editThreatFormNames.backgroundInfo]: [],
      [editThreatFormNames.physicalDescription]: this.createEmptyPhysicalDescriptionFormControl(profileTypeFC),
      [editThreatFormNames.criminalHistory]: [],
      [editThreatFormNames.gracieNumberList]: []
    });
  }

  mapFromTlm(tlm: TlmModel, profileTypeFC: UntypedFormControl): UntypedFormGroup {
    const gracieFA = this.fb.array([]);

    if (tlm && tlm.gracieNumbers && tlm.gracieNumbers.length > 0) {
      tlm.gracieNumbers.forEach((gracie) => {
        gracieFA.push(
          this.fb.group({
            [editThreatFormNames.gracieNumber]: [gracie.value]
          })
        );
      });
    } else {
      gracieFA.push(this.createEmptyGracieNumberFormGroup());
    }

    return this.fb.group({
      [editThreatFormNames.backgroundInfo]: [tlm && tlm.backgroundInfo ? tlm.backgroundInfo : null],
      [editThreatFormNames.physicalDescription]: [
        tlm && tlm.description ? tlm.description : null,
        this.physicalDescriptionRequiredIfBolo.bind(this, profileTypeFC)
      ],
      [editThreatFormNames.criminalHistory]: [tlm && tlm.criminalHistory ? tlm.criminalHistory : null],
      [editThreatFormNames.gracieNumberList]: gracieFA
    });
  }

  mapToTlmBackgroundInfo(fg: UntypedFormGroup): string {
    return this.editThreatUtils.getValueOrSetAsUndefined(fg.get(editThreatFormNames.backgroundInfo));
  }

  mapToTlmDescription(fg: UntypedFormGroup): string {
    return this.editThreatUtils.getValueOrSetAsUndefined(fg.get(editThreatFormNames.physicalDescription));
  }

  mapToTlmCriminalHistory(fg: UntypedFormGroup): string {
    return this.editThreatUtils.getValueOrSetAsUndefined(fg.get(editThreatFormNames.criminalHistory));
  }

  mapToTlmGracieNumbers(fg: UntypedFormGroup): GracieModel[] {
    const mapped = new Array<GracieModel>();
    let order = 0;
    const fa = fg.get(editThreatFormNames.gracieNumberList) as UntypedFormArray;

    fa.controls.forEach((itemFG) => {
      const gracieNumber = this.editThreatUtils.getValueOrSetAsUndefined(itemFG.get(editThreatFormNames.gracieNumber));

      if (sjv.isNotEmpty(gracieNumber)) {
        mapped.push(new GracieModel(order, gracieNumber));
        order++;
      }
    });
    return mapped;
  }

  createEmptyGracieNumberFormGroup(): UntypedFormGroup {
    return this.fb.group({
      [editThreatFormNames.gracieNumber]: [null]
    });
  }

  fieldRequiredIfBolo(profileTypeFC: UntypedFormControl, requiredFC: UntypedFormControl, errorCode: string, errMsg: string) {
    // scenario 1: if bolo and required not touched/submitted; then dont display any error (wait until touched/submitted)
    // scenario 2: if bolo and required touched/submitted and required empty; then display error
    // scenario 3: if bolo and required touched/submitted and required populated; then no error to display
    // scenario 4: if not bolo; then no error to display

    if (_.get(profileTypeFC, 'value.type', '') === 'bolo') {
      if (requiredFC.touched && sjv.isEmpty(requiredFC.value)) {
        // scenario 2: if bolo and touched/submitted and empty; then display error
        this.editThreatUtils.addErrorToFormControl(requiredFC, errorCode, errMsg);
        return { [errorCode]: errMsg };
      } else {
        // scenario 1: if bolo and not touched/submitted; then dont display any error (wait until touched/submitted)
        // scenario 3: if bolo and touched/submitted and populated; then no error to display
        this.editThreatUtils.removeErrorFromFormControl(requiredFC, errorCode);
        return null;
      }
    } else {
      // scenario 4: if not bolo; then no error to display
      this.editThreatUtils.removeErrorFromFormControl(requiredFC, errorCode);
      return null;
    }
  }

  physicalDescriptionRequiredIfBolo(profileTypeFC: UntypedFormControl, physicalDescriptionFC: UntypedFormControl) {
    const errorCode = 'missingPhysicalDescription';
    const errMsg = 'Background Info: [Physical Description] required';
    return this.fieldRequiredIfBolo(profileTypeFC, physicalDescriptionFC, errorCode, errMsg);
  }
}

import { Component, Input } from '@angular/core';
import { StateModel, EditThreatFormNameModel } from '../../../shared';
import { UntypedFormGroup } from '@angular/forms';
import { MyErrorStateMatcher, editThreatFormNames } from '../utilities';

@Component({
  selector: 'app-tlm-edit-threat-addresses-form',
  templateUrl: './addresses-form.component.html'
})
export class EditThreatAddressesFormComponent {
  @Input() states: StateModel[];
  @Input() addressesFormFormGroup: UntypedFormGroup = new UntypedFormGroup({});
  @Input() matAppearance: string;
  @Input() errorMatcher: MyErrorStateMatcher;
  formNames: EditThreatFormNameModel = editThreatFormNames;
}

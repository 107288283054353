import * as _ from 'lodash';
import { DateTime } from 'luxon';
import { AddressModel } from './address.model';
import { AliasModel } from './alias.model';
import { BoloModel } from './bolo.model';
import { ContactHistoryModel } from './contact-history.model';
import { EmailModel } from './email.model';
import { ErrorModel } from './error.model';
import { GracieModel } from './gracie.model';
import { IdentificationModel } from './identification.model';
import { InteractionModel } from './interaction.model';
import { PersonModel } from './person.model';
import { PhoneModel } from './phone.model';
import { PhotoModel } from './photo.model';
import { SocialMediaModel } from './socialmedia.model';
import { VehicleModel } from './vehicle.model';
import { VideoModel } from './video.model';

const missingValueMessage = 'Unknown';
export class TlmModel {
  tlmId: string;
  tlmStatus: string;
  profileType: string;
  addresses: Array<AddressModel>;
  aliases: Array<AliasModel>;
  emails: Array<EmailModel>;
  identifications: Array<IdentificationModel>;
  person: PersonModel;
  bolos: Array<BoloModel>;
  phones: Array<PhoneModel>;
  photos: Array<PhotoModel>;
  videos: Array<VideoModel>;
  vehicles: Array<VehicleModel>;
  backgroundInfo: string;
  description: string;
  criminalHistory: string;
  gracieNumbers: Array<GracieModel>;
  contactHistory: Array<ContactHistoryModel>;
  socialMedias: Array<SocialMediaModel>;
  lastContact: ContactHistoryModel;
  created: InteractionModel;
  updated: InteractionModel;
  rockSecurityId: string;

  constructor() {
    this.addresses = [];
    this.aliases = [];
    this.emails = [];
    this.identifications = [];
    this.person = new PersonModel();
    this.bolos = [];
    this.phones = [];
    this.photos = [];
    this.videos = [];
    this.vehicles = [];
    this.backgroundInfo = null;
    this.description = null;
    this.criminalHistory = null;
    this.gracieNumbers = [];
    this.contactHistory = [];
    this.socialMedias = [];
    this.created = new InteractionModel();
    this.updated = new InteractionModel();
    this.rockSecurityId = null;
    this.profileType = null;
  }

  fullNameOrAliasPopulated(): boolean {
    let anyAliasPopulated = false;

    this.aliases.forEach((alias) => {
      if (alias.isPopulated()) {
        anyAliasPopulated = true;
      }
    });

    const fullNamePopulated =
      this.person.firstName && this.person.firstName.trim().length > 0 && this.person.lastName && this.person.lastName.trim().length > 0;

    if (!fullNamePopulated && !anyAliasPopulated) {
      return false;
    }

    return true;
  }

  getContactDateForDisplay(thisContact: ContactHistoryModel): string {
    return thisContact.date ? DateTime.fromISO(thisContact.date).toLocaleString(DateTime.DATE_FULL) : missingValueMessage;
  }

  getContactTimeForDisplay(thisContact: ContactHistoryModel): string {
    let strMin;
    let strHour;
    let strDay;

    if (thisContact.dayTime) {
      strDay = thisContact.dayTime;
    } else {
      strDay = 'AM';
    }

    if (thisContact.hour) {
      strHour = thisContact.hour.toString();
    } else {
      strHour = '12';
      strDay = 'AM';
    }

    if (thisContact.minutes) {
      strMin = thisContact.minutes.toString();
      if (strMin.length === 1) {
        strMin = '0' + strMin;
      }
    } else {
      strMin = '00';
    }

    return strHour + ':' + strMin + ' ' + strDay;
  }

  sortBolos(boloList: BoloModel[]): BoloModel[] {
    if (!boloList || boloList.length === 0) {
      return boloList;
    }
    return _.sortBy(boloList, ['startDate']).reverse();
  }

  getFirstSortedBolo(): BoloModel {
    return this.getSortedBolos()[0];
  }

  getSortedBolos(): BoloModel[] {
    return this.sortBolos(this.bolos);
  }

  get identification(): IdentificationModel {
    let identification = new IdentificationModel(0);

    if (this.identifications && this.identifications.length > 0) {
      identification = this.identifications[0];
    }
    return identification;
  }

  get nameForDisplay(): string {
    let toDisplay = 'He Who Must Not Be Named';

    if (this.person.firstName && this.person.lastName) {
      toDisplay = this.person.firstName + ' ' + this.person.lastName;
    } else if (this.aliases && this.aliases.length > 0 && this.aliases[0].value) {
      toDisplay = this.aliases[0].value;
    } else if (this.rockSecurityId) {
      toDisplay = this.rockSecurityId;
    }

    return toDisplay;
  }

  get aliasesForDisplay() {
    let toDisplay = 'None';

    if (this.aliases && this.aliases.length > 0) {
      toDisplay = '';

      for (const alias of this.aliases) {
        toDisplay = toDisplay + alias.value + ', ';
      }

      toDisplay = toDisplay.slice(0, -2);
    }

    return toDisplay;
  }

  get emailsForDisplay() {
    let toDisplay = 'None';

    if (this.emails && this.emails.length > 0) {
      toDisplay = '';

      for (const email of this.emails) {
        toDisplay = toDisplay + email.value + ', ';
      }

      toDisplay = toDisplay.slice(0, -2);
    }

    return toDisplay;
  }

  get graciesForDisplay() {
    let toDisplay = 'None';

    if (this.gracieNumbers && this.gracieNumbers.length > 0) {
      toDisplay = '';

      for (const gracieNumber of this.gracieNumbers) {
        toDisplay = toDisplay + gracieNumber.value + ', ';
      }

      toDisplay = toDisplay.slice(0, -2);
    }

    return toDisplay;
  }

  get phonesForDisplay() {
    let toDisplay = 'None';

    if (this.phones && this.phones.length > 0) {
      toDisplay = '';

      for (const phone of this.phones) {
        toDisplay = toDisplay + phone.value + ', ';
      }

      toDisplay = toDisplay.slice(0, -2);
    }

    return toDisplay;
  }

  get errors(): ErrorModel[] {
    let errors = [];

    errors = errors.concat(this.readErrors(this.phones));
    errors = errors.concat(this.readErrors(this.emails));

    if (!this.fullNameOrAliasPopulated()) {
      errors.push(new ErrorModel('Error: Either First and Last Name or Alias is required'));
    }

    return errors;
  }

  private readErrors(items): ErrorModel[] {
    let errors = [];
    items.forEach((item, index) => {
      if (item.isInvalid()) {
        errors = errors.concat(item.errors);
      }
    });
    return errors;
  }

  get isActiveBolo() {
    const firstBolo = this.getFirstSortedBolo();
    return firstBolo && firstBolo.isActive;
  }

  get profileStatus() {
    let profileStatus = 'boloInactive';
    const profileType = _.get(this, 'profileType', '') || '';

    if (profileType === 'threat') {
      profileStatus = 'threat';
    } else if (profileType.indexOf('bolo') > -1 && this.bolos.length > 0 && this.isActiveBolo) {
      profileStatus = 'boloActive';
    }

    return profileStatus;
  }
}

<div [formGroup]="gracieNumberFormGroup">
  <div class="row">
    <div class="col-5 col-md-6">
      <mat-form-field [appearance]="matAppearance">
        <mat-label>Gracie Number</mat-label>
        <input [id]="'gracieNumber' + index" matInput [formControlName]="formNames.gracieNumber" [errorStateMatcher]="errorMatcher" />
      </mat-form-field>
    </div>
    <div class="col-1 my-auto">
      <button mat-icon-button color="warn" [id]="'removeGracieNumber' + index" (click)="removeOnClick(index)" [disabled]="!removeActionEnabled()">
        <mat-icon class="fa fa-close fa-lg"></mat-icon>
      </button>
    </div>
  </div>
</div>

import { Component, Input } from '@angular/core';
import { MyErrorStateMatcher } from '../../../pages/edit-threat/utilities/edit-threat.error-matcher';
import { UntypedFormControl } from '@angular/forms';

@Component({
  moduleId: module.id,
  selector: 'app-tlm-form-control-rich-text-editor',
  templateUrl: './form-control-rich-text-editor.component.html'
})
export class FormControlRichTextEditorComponent {
  @Input() fc: UntypedFormControl;
  @Input() label: string;
  @Input() componentId: string;

  richTextTitlebar: any;

  constructor() {
    // eslint-disable-next-line max-len
    this.richTextTitlebar = {
      toolbar: [
        ['bold', 'italic', 'underline'],
        [{ list: 'ordered' }, { list: 'bullet' }],
        [{ size: ['small', false, 'large', 'huge'] }],
        ['link'],
        [{ color: [] }, { background: [] }]
      ]
    };
  }

  getToolbar(): any {
    return this.richTextTitlebar;
  }
}

import { Component, Input } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { MyErrorStateMatcher, editThreatFormNames } from '../utilities';
import { EditThreatFormNameModel } from '../../../shared';

@Component({
  selector: 'app-tlm-edit-threat-bolos-form',
  templateUrl: './bolos-form.component.html'
})
export class EditThreatBolosFormComponent {
  @Input() profileTypeFormControl: UntypedFormControl;
  @Input() bolosFormFormGroup: UntypedFormGroup;
  @Input() matAppearance: string;
  @Input() errorMatcher: MyErrorStateMatcher;
  formNames: EditThreatFormNameModel = editThreatFormNames;
}

import { Component, Inject, ViewChild, Input } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, UntypedFormControl, ValidatorFn, ValidationErrors } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatStepper } from '@angular/material/stepper';
import * as sjv from 'simple-js-validator';
import { HeaderComponent } from '../header.component';
import { ContactLocationModel, PhotoModel } from '../../../../shared';
import {
  MyErrorStateMatcher,
  editThreatFormNames,
  EditThreatUtilitiesBolosForm,
  EditThreatUtilitiesContactHistoryForm,
  EditThreatUtilitiesBasicInfoForm,
  EditThreatUtilitiesBackgroundInfoForm
} from '../../../../pages/edit-threat/utilities';

@Component({
  selector: 'app-tlm-add-bolo-modal',
  templateUrl: 'add-bolo-modal.component.html',
  styleUrls: ['add-bolo-modal.component.scss']
})
export class AddBoloModalComponent {
  @ViewChild('addImage') addImage;
  @Input() errorMatcher: MyErrorStateMatcher;

  contactForm: UntypedFormGroup;
  infoForm: UntypedFormGroup;
  photoForm: UntypedFormGroup;
  boloForm: UntypedFormGroup;
  formNames: any;

  profileTypeFormControl: UntypedFormControl;
  locations: ContactLocationModel[];
  hasAdminAccess: boolean;

  photo: PhotoModel;
  imageUrl: any;

  errors: any;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: UntypedFormBuilder,
    public dialogRef: MatDialogRef<HeaderComponent>,
    private editThreatUtilsBasicInfo: EditThreatUtilitiesBasicInfoForm,
    private editThreatUtilsBackgroundInfo: EditThreatUtilitiesBackgroundInfoForm,
    private editThreatUtilsBolo: EditThreatUtilitiesBolosForm,
    private editThreatUtilsContact: EditThreatUtilitiesContactHistoryForm
  ) {
    this.locations = data.locations;
    this.hasAdminAccess = data.hasAdminAccess;
    this.profileTypeFormControl = new UntypedFormControl(data.profileType);
    this.imageUrl = null;
    this.errors = null;
    this.formNames = editThreatFormNames;

    this.contactForm = this.editThreatUtilsContact.createEmptyItemFormGroup(this.profileTypeFormControl);

    this.infoForm = this.formBuilder.group({
      [this.formNames.rockSecurityId]: this.editThreatUtilsBasicInfo.createEmptyRockSecurityFormControl(this.profileTypeFormControl),
      [this.formNames.physicalDescription]: this.editThreatUtilsBackgroundInfo.createEmptyPhysicalDescriptionFormControl(this.profileTypeFormControl),
      [this.formNames.firstName]: [],
      [this.formNames.lastName]: [],
      [this.formNames.alias]: []
    });

    this.photoForm = this.formBuilder.group({
      [this.formNames.photo]: [null, this.validatePhoto]
    });

    this.boloForm = this.editThreatUtilsBolo.createEmptyItemFormGroup(this.profileTypeFormControl);
  }

  finalizeAndCloseModal() {
    // add photo to inputs object
    // 0 index because there is only one file
    if (sjv.isNotEmpty(this.addImage.nativeElement.files)) {
      this.photoForm.get('Photo').setValue(this.addImage.nativeElement.files[0]);
    }

    this.dialogRef.close({
      profileType: this.profileTypeFormControl.value,
      contactForm: this.contactForm,
      infoForm: this.infoForm,
      photoForm: this.photoForm,
      boloForm: this.boloForm
    });
  }

  onAddPhoto(event) {
    this.addImage.nativeElement.click();
    event.preventDefault();
  }

  onInputPhoto(files, photoFC) {
    if (files.length === 0) {
      return;
    }
    const reader = new FileReader();
    reader.readAsDataURL(files[0]);
    reader.onload = (_event) => {
      this.imageUrl = reader.result;
      photoFC.setValue(this.addImage.nativeElement.files[0]);
    };
  }

  onRemovePhoto(event) {
    this.imageUrl = null;
    this.photoForm.reset();
    event.preventDefault();
  }

  validatePhoto: ValidatorFn = (input: UntypedFormControl): ValidationErrors | null => {
    const errorCode = 'photoRequired';
    const errMsg = 'Photo: [Photo] is required';

    // bolo is assumed since this is add bolo form
    // scenario 1: if non-admin and required not touched/submitted; then dont display any error (wait until touched/submitted) (return null)
    // scenario 2: if non-admin and required touched/submitted and required empty; then display error (return error object)
    // scenario 3: if non-admin and required touched/submitted and required populated; then no error to display (return null)
    // scenario 4: if admin; then no error to display (return null)

    if (!this.hasAdminAccess && input.touched && sjv.isEmpty(input.value)) {
      // scenario 2: if bolo and non-admin and required touched/submitted and required empty; then display error (return error object)
      return { [errorCode]: errMsg };
    } else {
      // scenario 1: if non-admin and required not touched/submitted; then dont display any error (wait until touched/submitted) (return null)
      // scenario 3: if non-admin and required touched/submitted and required populated; then no error to display (return null)
      // scenario 4: if admin; then no error to display (return null)
      return null;
    }
  };

  validateForm(fg) {
    fg.markAllAsTouched(); // marked all form controls as touched
    fg.updateValueAndValidity(); // force validation for form group
    Object.keys(fg.controls).forEach((field) => {
      fg.get(field).markAsDirty(); // without marking dirty, ui was not showing errors
      fg.get(field).updateValueAndValidity(); // force validation for form control(s)
    });
  }

  onClickNext(stepper: MatStepper, form: UntypedFormGroup) {
    this.validateForm(form);

    if (form.valid) {
      stepper.next();
    }
  }

  onClickSubmit(form: UntypedFormGroup) {
    this.validateForm(form);

    if (form.valid) {
      this.finalizeAndCloseModal();
    }
  }

  displayFieldError(key, value): string {
    const display = value;
    return display;
  }
}

<mat-form-field [appearance]="matAppearance">
  <mat-label>{{ label }}</mat-label>
  <textarea
    [id]="componentId"
    [formControl]="fc"
    [errorStateMatcher]="errorMatcher"
    matInput
    cdkTextareaAutosize
    #autosize="cdkTextareaAutosize"
    [cdkAutosizeMinRows]="minRows"
    [cdkAutosizeMaxRows]="maxRows"
    trim="blur"
  ></textarea>
  <mat-error *ngFor="let item of fc.errors | keyvalue">
    {{ item.value }}
  </mat-error>
</mat-form-field>

<div>
  <div class="row">
    <div class="col-12">
      <span class="tlm-section-title">Phones</span>
      <button type="button" id="addPhone" class="btn btn-link tlm-link pull-right" (click)="createAndAddItemToListIfPopulated()">+ Add Phone</button>
    </div>
  </div>
  <div *ngFor="let phoneFormGroup of phonesFormArray.controls; let i = index; let isLastRow = last">
    <div class="row">
      <div class="col-12">
        <app-tlm-edit-threat-phone
          [phoneFormGroup]="phoneFormGroup"
          [index]="i"
          [isLast]="isLastRow"
          [length]="phonesFormArray.controls.length"
          [removeItem]="boundRemoveItem"
          [isPopulated]="boundIsPopulated"
          [phoneTypes]="phoneTypes"
          [matAppearance]="matAppearance"
          [errorMatcher]="errorMatcher"
        >
        </app-tlm-edit-threat-phone>
      </div>
    </div>
  </div>
</div>

import { Component, OnInit, ViewChild, Input, OnChanges, Inject, Output, EventEmitter } from '@angular/core';
import { PhotoModel, EditThreatFormNameModel } from '../../../shared';
import { ImageService, ILogger, IImageService } from '../../../core';
import { NgxGalleryOptions, NgxGalleryImage, NgxGalleryAnimation, NgxGalleryComponent } from 'ngx-gallery-9';
import * as _ from 'lodash';
import { ActivatedRoute } from '@angular/router';
import { UntypedFormGroup, UntypedFormArray, UntypedFormControl } from '@angular/forms';
import { editThreatFormNames } from '../utilities';

@Component({
  selector: 'app-tlm-edit-threat-photos',
  templateUrl: './photos.component.html'
})
export class EditThreatPhotosComponent implements OnInit, OnChanges {
  @ViewChild('ngxGallery') ngxGallery: NgxGalleryComponent;
  @ViewChild('addImage') addImage;

  @Input() photosFormGroup: UntypedFormGroup;
  tlmId: string;
  ngxGalleryOptions: NgxGalleryOptions[];
  ngxGalleryImages: NgxGalleryImage[];
  formNames: EditThreatFormNameModel = editThreatFormNames;

  constructor(
    @Inject('LoggerInjected') private logger: ILogger,
    @Inject('ImageServiceInjected') private imageService: IImageService,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.route.params.subscribe((params) => {
      this.tlmId = params['tlmId'];
    });

    this.ngxGalleryOptions = [
      {
        width: '800px',
        height: '600px',
        thumbnailsColumns: 4,
        thumbnailsRemainingCount: true,
        imageAnimation: NgxGalleryAnimation.Slide,
        imageSize: 'contain',
        thumbnailSize: 'contain'
      },
      // max-width 800
      {
        breakpoint: 800,
        width: '100%',
        height: '600px',
        imagePercent: 80,
        thumbnailsPercent: 20,
        thumbnailsMargin: 20,
        thumbnailMargin: 20,
        imageSize: 'contain',
        thumbnailSize: 'contain'
      },
      // max-width 400
      {
        breakpoint: 400,
        preview: false,
        imageSize: 'contain',
        thumbnailSize: 'contain'
      }
    ];

    this.ngxGalleryImages = [];
  }

  async ngOnChanges() {
    const photosFA = this.photosFormGroup.get(editThreatFormNames.photoList) as UntypedFormArray;

    if (!photosFA || !photosFA.controls) {
      return;
    }

    if (photosFA.controls.length > 0) {
      const promises = photosFA.controls.map((photoFG) => {
        const photoKey = photoFG.get(editThreatFormNames.photo).value;
        return this.imageService.getImage(photoKey);
      });

      let results;

      try {
        results = await Promise.all(promises);
      } catch (err) {
        this.logger.errorWithPopup('There was an issue processing the image(s).  Please try again.', err.error.message);
      }

      results.forEach((data) => {
        this.ngxGalleryImages.push(data);
      });
    }
  }

  onAdd() {
    this.addImage.nativeElement.click();
  }

  async onAddImage(): Promise<any> {
    // https://malcoded.com/posts/angular-file-upload-component-with-express
    const files: { [key: string]: File } = this.addImage.nativeElement.files;
    for (const key in files) {
      if (!isNaN(parseInt(key, 10))) {
        return this.imageService
          .uploadImage(this.tlmId, files[key])
          .then((data) => {
            this.addImageToLists(data);
            this.logger.warnWithPopup('Warning', 'The picture has been added.  Please save profile to complete the process.');
          })
          .catch((err) => {
            this.logger.errorWithPopup('There was an issue processing the image.  Please try again.', err.error.message);
          });
      }
    }
  }

  addImageToLists(data): void {
    this.ngxGalleryImages.push({
      small: data.base64Image,
      medium: data.base64Image,
      big: data.base64Image
    });

    const photosFA = this.photosFormGroup.get(editThreatFormNames.photoList) as UntypedFormArray;
    photosFA.controls.push(
      new UntypedFormGroup({
        [editThreatFormNames.photo]: new UntypedFormControl(data.key)
      })
    );
  }

  removeImageFromLists(imageIndex): void {
    this.ngxGalleryImages.splice(imageIndex, 1);
    const photosFA = this.photosFormGroup.get(editThreatFormNames.photoList) as UntypedFormArray;
    photosFA.controls.splice(imageIndex, 1);
  }

  favImageInLists(imageIndex): void {
    // move selected item to the beginning of array

    const photosFA = this.photosFormGroup.get(editThreatFormNames.photoList) as UntypedFormArray;
    const favPhoto = photosFA.controls[imageIndex];
    const favImage = this.ngxGalleryImages[imageIndex];

    this.removeImageFromLists(imageIndex);

    this.ngxGalleryImages.unshift(favImage);
    photosFA.controls.unshift(favPhoto);
  }

  onFavorite(): void {
    this.favImageInLists(this.ngxGallery.selectedIndex);
    this.logger.warnWithPopup('Warning', 'The picture has been marked as primary.  Please save profile to complete the process.');
  }

  onRemove(): void {
    this.removeImageFromLists(this.ngxGallery.selectedIndex);
    this.ngxGallery.selectedIndex = this.ngxGallery.selectedIndex === 0 ? 0 : this.ngxGallery.selectedIndex - 1;
    this.logger.warnWithPopup('Warning', 'The picture has been marked to be removed.  Please save profile to complete the removal.');
  }
}

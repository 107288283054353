import { Injectable } from '@angular/core';
import { UntypedFormGroup, UntypedFormArray, UntypedFormBuilder } from '@angular/forms';
import * as _ from 'lodash';
import * as sjv from 'simple-js-validator';
import { TlmModel, IdentificationModel, StateModel } from '../../../shared';
import { EditThreatUtilities } from './edit-threat-utilities.service';
import { editThreatFormNames } from './edit-threat-form-names';

@Injectable()
export class EditThreatUtilitiesIdentificationsForm {
  constructor(private fb: UntypedFormBuilder, private editThreatUtils: EditThreatUtilities) {}

  createEmptyItemFormGroup(): UntypedFormGroup {
    return this.fb.group({
      [editThreatFormNames.identificationState]: [],
      [editThreatFormNames.identificationNumber]: [],
      [editThreatFormNames.identificationExpirationDate]: [],
      [editThreatFormNames.identificationType]: []
    });
  }

  initializeForm(): UntypedFormGroup {
    return new UntypedFormGroup({
      [editThreatFormNames.identificationList]: new UntypedFormArray([])
    });
  }

  mapFromTlm(tlm: TlmModel, states: StateModel[]): UntypedFormGroup {
    const identificationsFA = new UntypedFormArray([]);

    if (tlm.identifications && tlm.identifications.length > 0) {
      tlm.identifications.forEach((identification) => {
        const stateDisplayName = this.editThreatUtils.convertStateCodeToDisplayName(identification.state ? identification.state.code : null, states);

        identificationsFA.push(
          this.fb.group({
            [editThreatFormNames.identificationExpirationDate]: [identification.expirationDate],
            [editThreatFormNames.identificationNumber]: [identification.value],
            [editThreatFormNames.identificationType]: [identification.type],
            [editThreatFormNames.identificationState]: [stateDisplayName]
          })
        );
      });
    } else {
      identificationsFA.push(this.createEmptyItemFormGroup());
    }

    return new UntypedFormGroup({
      [editThreatFormNames.identificationList]: identificationsFA
    });
  }

  mapToTlm(fg: UntypedFormGroup, states: StateModel[]): IdentificationModel[] {
    const mapped = new Array<IdentificationModel>();
    let order = 0;
    const identificationsFA = fg.get(editThreatFormNames.identificationList) as UntypedFormArray;

    identificationsFA.controls.forEach((itemFG) => {
      const idType = this.editThreatUtils.getValueOrSetAsUndefined(itemFG.get(editThreatFormNames.identificationType));
      const state = this.editThreatUtils.getValueOrSetAsUndefined(itemFG.get(editThreatFormNames.identificationState));
      const idNumber = this.editThreatUtils.getValueOrSetAsUndefined(itemFG.get(editThreatFormNames.identificationNumber));
      const expirationDate = this.editThreatUtils.getValueOrSetAsUndefined(itemFG.get(editThreatFormNames.identificationExpirationDate));

      if (sjv.isNotEmpty(idType) || sjv.isNotEmpty(state) || sjv.isNotEmpty(idNumber) || sjv.isNotEmpty(expirationDate)) {
        const stateCode = this.editThreatUtils.convertStateNameToCode(state, states);

        mapped.push(new IdentificationModel(order, new StateModel(state, stateCode), idNumber, expirationDate, idType));
        order++;
      }
    });

    return mapped;
  }
}

import { Component, OnInit, Inject, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AddThreatModalComponent } from './add-threat-modal/add-threat-modal.component';
import { AddBoloModalComponent } from './add-bolo-modal/add-bolo-modal.component';
import { AddProfileSelectionModalComponent } from './add-profile-selection-modal/add-profile-selection-modal.component';
import { Router } from '@angular/router';
import { ITlmApiService, IContactLocationService, IImageService, ProfileTypeApiService } from '../../services';
import { TlmModel, PhotoModel, ProfileTypeModel } from '../../../shared';
import * as sjv from 'simple-js-validator';
import { ToastrService } from 'ngx-toastr';
import { IAuthService } from '../../../auth';
import { Title } from '@angular/platform-browser';
import * as shortid from 'shortid';
import {
  EditThreatUtilitiesBolosForm,
  EditThreatUtilitiesContactHistoryForm,
  EditThreatUtilitiesBasicInfoForm,
  EditThreatUtilitiesBackgroundInfoForm,
  editThreatFormNames
} from '../../../pages/edit-threat/utilities';
import { UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'app-tlm-layout-header',
  templateUrl: './header.component.html'
})
export class HeaderComponent implements OnInit {
  @Input() profileType: string;

  hasBoloAccess: boolean;
  hasCreateAccess: boolean;
  hasEditAccess: boolean;
  hasSearchAccess: boolean;
  hasAdminAccess: boolean;
  isEditPage: boolean;
  locations: any;
  profileTypeBolo: ProfileTypeModel;
  profileTypeThreat: ProfileTypeModel;
  formNames: any;

  constructor(
    private toastr: ToastrService,
    public route: Router,
    private titleService: Title,
    private dialog: MatDialog,
    @Inject('TlmApiServiceInjected') private tlmApiService: ITlmApiService,
    @Inject('TlmAuthService') private authService: IAuthService,
    @Inject('ContactLocationServiceInjected')
    private contactLocationService: IContactLocationService,
    @Inject('ImageServiceInjected') private imageService: IImageService,
    private profileTypeApiService: ProfileTypeApiService,
    private editThreatUtilsBasicInfo: EditThreatUtilitiesBasicInfoForm,
    private editThreatUtilsBolo: EditThreatUtilitiesBolosForm,
    private editThreatUtilsContact: EditThreatUtilitiesContactHistoryForm,
    private editThreatUtilsBackgroundInfo: EditThreatUtilitiesBackgroundInfoForm
  ) { }

  ngOnInit() {
    this.checkBoloAccess();
    this.checkCreateAccess();
    this.checkSearchAccess();
    this.checkEditAccess();
    this.checkEditPage();
    this.checkAdminAccess();

    this.formNames = editThreatFormNames;

    this.profileTypeApiService.list().subscribe((data) => {
      this.profileTypeBolo = data.find((pt) => pt.type === 'bolo');
      this.profileTypeThreat = data.find((pt) => pt.type === 'threat');
    });

    // Location Service for Add BOLO Modal
    this.contactLocationService.list().subscribe((data) => (this.locations = data));
  }

  addProfile() {
    const dialogRef = this.dialog.open(AddProfileSelectionModalComponent, {
      autoFocus: false,
      panelClass: 'add-profile-selection-modal-panel',
      position: { top: '5%' }  
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (sjv.isEmpty(result)) {
        return;
      } else {
        if (result.profileTypeSelected === 'bolo') {
          this.addBolo();
        } else if (result.profileTypeSelected === 'threat') {
          this.addThreat();
        }
      }
    });
  }

  addBolo() {
    const dialogRef = this.dialog.open(AddBoloModalComponent, {
      data: {
        profileType: this.profileTypeBolo,
        locations: this.locations,
        hasAdminAccess: this.hasAdminAccess
      },
      autoFocus: false,
      panelClass: 'add-bolo-modal-panel',
      position: { top: '5%' },
      width: '900px'
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (sjv.isEmpty(result)) {
        return;
      } else {
        this.save(result);
      }
    });
  }

  addThreat() {
    const dialogRef = this.dialog.open(AddThreatModalComponent, {
      data: {
        profileType: this.profileTypeThreat
      },
      autoFocus: false,
      panelClass: 'add-threat-modal-panel',
      position: { top: '5%' },
      width: '800px'
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (sjv.isEmpty(result)) {
        return;
      } else {
        this.save(result);
      }
    });
  }

  // TODO: move to utils
  async mapPhotoForm(tlm: TlmModel, fg: UntypedFormGroup): Promise<PhotoModel[]> {
    const photos: PhotoModel[] = [];
    try {
      const photo = fg.get('Photo').value;

      if (sjv.isNotEmpty(photo)) {
        
          const data = await this.imageService.uploadImage(tlm.tlmId, photo);
          photos.push(new PhotoModel(0, data.key));        
      }
    }
    catch (err) {
      this.toastr.error('There was an issue processing the image. Please try again');
      console.error('Error processing image: ', err);
    }
    return photos;
  }

  validateForm(fg) {
    fg.markAllAsTouched(); // marked all form controls as touched
    fg.updateValueAndValidity(); // force validation for form group
    Object.keys(fg.controls).forEach((field) => {
      fg.get(field).markAsDirty(); // without marking dirty, ui was not showing errors
      fg.get(field).updateValueAndValidity(); // force validation for form control(s)
    });
  }

  async save(addProfile: any) {
    // show starting to save
    const savingToast = this.toastr.info('Saving...', null, { timeOut: 0 });
    try {
      const tlm = new TlmModel();
      tlm.tlmId = shortid.generate();
      tlm.tlmStatus = 'pre-published';
      tlm.profileType = addProfile.profileType.type;

      if (addProfile.profileType.type === 'threat') {       
          // assume all the validation has been performed via the form submit already

          const aliasList = this.editThreatUtilsBasicInfo.createAliasFormGroupFromAliasFormControl(addProfile.addThreatForm.get(this.formNames.alias));
          tlm.person = this.editThreatUtilsBasicInfo.mapToTlmPerson(addProfile.addThreatForm);
          tlm.aliases = this.editThreatUtilsBasicInfo.mapToTlmAliases(aliasList);

          this.createTlm(tlm);        
      } else if (addProfile.profileType.type === 'bolo') {
        
          // assume all the validation has been performed via the form submit already

          const aliasList = this.editThreatUtilsBasicInfo.createAliasFormGroupFromAliasFormControl(addProfile.infoForm.get(this.formNames.alias));
          tlm.person = this.editThreatUtilsBasicInfo.mapToTlmPerson(addProfile.infoForm);
          tlm.aliases = this.editThreatUtilsBasicInfo.mapToTlmAliases(aliasList);
          tlm.rockSecurityId = this.editThreatUtilsBasicInfo.mapToTlmRockSecurityId(addProfile.infoForm);
          tlm.description = this.editThreatUtilsBackgroundInfo.mapToTlmDescription(addProfile.infoForm);
          tlm.photos = await this.mapPhotoForm(tlm, addProfile.photoForm);
          tlm.bolos = [this.editThreatUtilsBolo.mapToTlmBoloEntry(addProfile.boloForm)];
          tlm.contactHistory = [this.editThreatUtilsContact.mapToTlmContactEntry(addProfile.contactForm, 0)];

          this.createTlm(tlm);            
      } else {
        this.toastr.error('There was an issue processing your submission. Please try again.', null, { timeOut: 3000 });
        console.error('Invalid Form Submit: unknown profile type');
      }
    }
    catch (err) {
      console.error('Failed to save profile', err);
    }
    this.toastr.clear(savingToast.toastId);
  }

  createTlm(tlm: TlmModel) {
    this.tlmApiService.create(tlm).subscribe(
      (data) => {
        console.log(data);
        this.toastr.success('Assigned id: ' + data.tlmId, 'TLM Added Successfully');
        setTimeout(() => {
          this.route.navigate(['/profile/' + data.tlmId + '/edit/basic']);
        }, 2500);
      },
      (error) => {
        console.error(error);
        this.toastr.error('Error: ' + error, 'TLM NOT Added');
      }
    );
  }

  checkAdminAccess() {
    this.hasAdminAccess = this.authService.hasAdminAccess();
  }

  checkBoloAccess() {
    this.hasBoloAccess = this.authService.hasBoloAccess();
  }

  checkCreateAccess() {
    this.hasCreateAccess = this.authService.hasCreateAccess();
  }

  checkSearchAccess() {
    this.hasSearchAccess = this.authService.hasSearchAccess();
  }

  checkEditAccess() {
    this.hasEditAccess = this.authService.hasEditAccess();
  }

  checkEditPage() {
    this.isEditPage = this.titleService.getTitle() === 'TLM - Profile';
  }

  routeTo(route: string) {
    this.route.navigate([route]);
  }
}

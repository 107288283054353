export class EmailModel {
  orderId: number;
  value: string;

  constructor(orderId?: number, value?: string) {
    this.orderId = orderId;
    this.value = value;
  }

  get displayName(): string {
    return this.value ? this.value : 'None';
  }
}

import { Injectable } from '@angular/core';

@Injectable()
export class FileService {
  constructor() {}

  appendFormData(name: string, value: string | Blob, fileName: string): FormData {
    const formData = new FormData();
    formData.append(name, value, fileName);
    return formData;
  }

  readAsDataURLFileReader(blob: Blob): Promise<string | ArrayBuffer> {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();

      fileReader.onloadend = (e) => resolve(fileReader.result);

      fileReader.readAsDataURL(blob);
    });
  }
}

<div>
  <div class="row">
    <div class="col-12">
      <span class="tlm-section-title">Vehicles</span>
      <button type="button" id="addVehicle" class="btn btn-link tlm-link pull-right" (click)="createAndAddItemToListIfPopulated()">
        + Add Vehicle
      </button>
    </div>
  </div>
  <div *ngFor="let vehicleFormGroup of vehiclesFormArray.controls; let i = index; let isLastRow = last">
    <div>
      <app-tlm-edit-threat-vehicle
        [vehicleFormGroup]="vehicleFormGroup"
        [index]="i"
        [isLast]="isLastRow"
        [length]="vehiclesFormArray.controls.length"
        [removeItem]="boundRemoveItem"
        [isPopulated]="boundIsPopulated"
        [move]="boundMove"
        [states]="states"
        [matAppearance]="matAppearance"
        [errorMatcher]="errorMatcher"
      >
      </app-tlm-edit-threat-vehicle>
      <br />
      <mat-divider></mat-divider>
      <br />
      <br />
    </div>
  </div>
</div>

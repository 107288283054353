import { Injectable, Inject } from '@angular/core';
import { Observable } from 'rxjs';
import { ContactLocationModel } from '../../../shared';
import { Resolve } from '@angular/router';
import { IContactLocationService } from './contact-location-service.interface';

@Injectable()
export class ContactLocationServiceResolver implements Resolve<ContactLocationModel[]> {
  constructor(
    @Inject('ContactLocationServiceInjected')
    private service: IContactLocationService
  ) {}

  resolve(): Observable<ContactLocationModel[]> | Promise<ContactLocationModel[]> | ContactLocationModel[] {
    return this.service.list();
  }
}

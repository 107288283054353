import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Observable, throwError, of } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { ServiceUtilities } from '../../core';

@Component({
  selector: 'app-tlm-version',
  templateUrl: './version.component.html'
})
export class VersionComponent implements OnInit {
  public version: string = environment.version;

  public deployedVersion = {
    version: 'set during build',
    gitCommit8: 'set during build',
    gitCommit: 'set during build',
    gitDate: 'set during build',
    buildNumber: 'set during build'
  };

  constructor(private http: HttpClient, private serviceUtilities: ServiceUtilities) {}

  public ngOnInit() {
    this.get().subscribe((data) => {
      this.deployedVersion = data;
    });
  }

  get(): Observable<any> {
    return this.http.get('/assets/deployedVersion.json').pipe(
      map((response) => response),
      catchError(this.serviceUtilities.handleError)
    );
  }
}

<div [formGroup]="contactHistoryFormGroup">
  <div class="row">
    <div class="col-12 col-lg-3">
      <mat-form-field [appearance]="matAppearance">
        <mat-label>Date</mat-label>
        <input
          [id]="'contactDate' + index"
          [min]="minDate"
          [max]="maxDate"
          matInput
          [matDatepicker]="dateDatePicker"
          [formControlName]="formNames.contactDate"
          [errorStateMatcher]="errorMatcher"
        />
        <mat-error *ngFor="let item of contactHistoryFormGroup.get(formNames.contactDate).errors | keyvalue">
          {{ displayFieldError(item.key, item.value) }}
        </mat-error>
        <mat-datepicker-toggle matSuffix [for]="dateDatePicker" [id]="'dateDatePicker' + index"></mat-datepicker-toggle>
        <mat-datepicker #dateDatePicker></mat-datepicker>
      </mat-form-field>
    </div>
    <div class="col-12 col-lg-3">
      <mat-form-field [appearance]="matAppearance">
        <mat-label>Hour</mat-label>
        <mat-select [id]="'contactHour' + index" [formControlName]="formNames.contactHour" [errorStateMatcher]="errorMatcher">
          <mat-option *ngFor="let hour of hours; let i = index" [value]="hour">
            <span [id]="'contactHourOption' + i">{{ hour }}</span>
          </mat-option>
        </mat-select>
        <mat-error *ngFor="let item of contactHistoryFormGroup.get(formNames.contactHour).errors | keyvalue">
          {{ displayFieldError(item.key, item.value) }}
        </mat-error>
      </mat-form-field>
    </div>
    <div class="col-12 col-lg-3">
      <mat-form-field [appearance]="matAppearance">
        <mat-label>Minute</mat-label>
        <mat-select [id]="'contactMinutes' + index" [formControlName]="formNames.contactMinute" [errorStateMatcher]="errorMatcher">
          <mat-option *ngFor="let minute of minutes; let i = index" [value]="minute">
            <span [id]="'contactMinutesOption' + i">{{ minute }}</span>
          </mat-option>
        </mat-select>
        <mat-error *ngFor="let item of contactHistoryFormGroup.get(formNames.contactMinute).errors | keyvalue">
          {{ displayFieldError(item.key, item.value) }}
        </mat-error>
      </mat-form-field>
    </div>
    <div class="col-12 col-lg-3">
      <mat-form-field [appearance]="matAppearance">
        <mat-label>DayTime</mat-label>
        <mat-select [id]="'contactDaytimes' + index" [formControlName]="formNames.contactMeridiem" [errorStateMatcher]="errorMatcher">
          <mat-option *ngFor="let daytime of dayTimes; let i = index" [value]="daytime">
            <span [id]="'contactDaytimesOption' + i">{{ daytime }}</span>
          </mat-option>
        </mat-select>
        <mat-error *ngFor="let item of contactHistoryFormGroup.get(formNames.contactMeridiem).errors | keyvalue">
          {{ displayFieldError(item.key, item.value) }}
        </mat-error>
      </mat-form-field>
    </div>
  </div>
  <div class="row">
    <div class="col-12">
      <mat-form-field [appearance]="matAppearance">
        <mat-label>Location</mat-label>
        <input
          [id]="'contactLocation' + index"
          type="text"
          matInput
          placeholder="Location"
          [matAutocomplete]="auto"
          [formControlName]="formNames.contactLocation"
          [errorStateMatcher]="errorMatcher"
        />
        <mat-autocomplete #auto="matAutocomplete" [displayWith]="locationDisplayFn">
          <mat-option *ngFor="let location of filteredLocations | async" [value]="location" [matTooltip]="location.address">
            <span>{{ location.title }}</span> :
            <small>{{ location.address }}</small>
          </mat-option>
        </mat-autocomplete>
        <mat-error *ngFor="let item of contactHistoryFormGroup.get(formNames.contactLocation).errors | keyvalue">
          {{ displayFieldError(item.key, item.value) }}
        </mat-error>
      </mat-form-field>
    </div>
  </div>
  <div class="row">
    <div class="col-12">
      <mat-form-field [appearance]="matAppearance">
        <mat-label>Notes</mat-label>
        <textarea
          matInput
          cdkTextareaAutosize
          #autosize="cdkTextareaAutosize"
          cdkAutosizeMinRows="5"
          cdkAutosizeMaxRows="25"
          [id]="'contactNotes' + index"
          [formControlName]="formNames.contactNote"
        ></textarea>
      </mat-form-field>
    </div>
  </div>
</div>

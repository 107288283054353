<form class="needs-validation" novalidate [formGroup]="dateRangeModelFormGroup">
  <div>
    <h3 class="modal-title">Contact History</h3>
    <hr />
    <div class="text-left">
      <button
        (click)="[accentColor('week'), lastWeekSelected()]"
        #weekButton
        queryParamsHandling="merge"
        mat-button
        color="primary"
        style="width: 100%"
        id="weekButton"
        class="text-left"
      >
        Last Week
      </button>
      <button
        (click)="[accentColor('thirty'), lastThirtySelected()]"
        #thirtyDayButton
        queryParamsHandling="merge"
        mat-button
        color="primary"
        style="width: 100%"
        id="thirtyButton"
        class="text-left"
      >
        Last 30 Days
      </button>
      <button
        (click)="[accentColor('month'), lastMonthSelected()]"
        #monthButton
        queryParamsHandling="merge"
        mat-button
        color="primary"
        style="width: 100%"
        id="lastMonthButton"
        class="text-left"
      >
        Last Month
      </button>

      <mat-expansion-panel (click)="[accentColor('custom'), clearPreSelections()]">
        <mat-expansion-panel-header>
          <mat-panel-title
            #custom
            [ngClass]="{ 'custom-range-not-selected': customRangeSelected === false, 'custom-range-selected': customRangeSelected === true }"
            class="text-left"
          >
            Custom
          </mat-panel-title>
        </mat-expansion-panel-header>
        <div class="container">
          <div class="row">
            <div class="col-lg-6 col-md-12">
              <mat-form-field>
                <mat-label>Start Date</mat-label>
                <input
                  #startDateRange
                  (dateChange)="assignStartDate($event.target.value)"
                  formControlName="contactHistoryStartRange"
                  matInput
                  [min]="minDate"
                  [max]="maxDate"
                  [matDatepicker]="StartDatePicker"
                />
                <mat-error *ngIf="dateRangeModelFormGroup.get('contactHistoryStartRange').hasError('matDatepickerParse')">
                  Please enter a valid date MM/DD/YYYY
                </mat-error>
                <mat-error *ngIf="dateRangeModelFormGroup.get('contactHistoryStartRange').hasError('matDatepickerMin')">
                  Please enter a date after 1/1/1900
                </mat-error>
                <mat-error *ngIf="dateRangeModelFormGroup.get('contactHistoryStartRange').hasError('matDatepickerMax')">
                  Please enter a date before today
                </mat-error>
                <mat-datepicker-toggle matSuffix [for]="StartDatePicker"></mat-datepicker-toggle>
                <mat-datepicker #StartDatePicker></mat-datepicker>
              </mat-form-field>
            </div>
            <div class="col-lg-6 col-md-12">
              <mat-form-field>
                <mat-label>End Date</mat-label>
                <input
                  #endDateRange
                  (dateChange)="assignEndDate($event.target.value)"
                  formControlName="contactHistoryEndRange"
                  matInput
                  [min]="minDate"
                  [max]="maxDate"
                  [matDatepicker]="EndDatePicker"
                />
                <mat-error *ngIf="dateRangeModelFormGroup.get('contactHistoryEndRange').hasError('matDatepickerParse')">
                  Please enter a valid date MM/DD/YYYY
                </mat-error>
                <mat-error *ngIf="dateRangeModelFormGroup.get('contactHistoryEndRange').hasError('matDatepickerMin')">
                  Please enter a date after 1/1/1900
                </mat-error>
                <mat-error *ngIf="dateRangeModelFormGroup.get('contactHistoryEndRange').hasError('matDatepickerMax')">
                  Please enter a date before today
                </mat-error>
                <mat-datepicker-toggle matSuffix [for]="EndDatePicker"></mat-datepicker-toggle>
                <mat-datepicker #EndDatePicker></mat-datepicker>
              </mat-form-field>
            </div>
          </div>
        </div>
      </mat-expansion-panel>
      <br />
      <button (click)="submitRange()" id="submitRange" queryParamsHandling="merge" class="text-left, modal-submit-button">Submit</button>
    </div>
  </div>
</form>

import { StateModel } from './state.model';

export class IdentificationModel {
  orderId: number;
  state: StateModel;
  value: string;
  expirationDate: string;
  type: string;

  constructor(orderId?: number, state?: StateModel, value?: string, expirationDate?: string, type?: string) {
    this.orderId = orderId;
    this.state = state;
    this.value = value;
    this.expirationDate = expirationDate;
    this.type = type;
  }
}

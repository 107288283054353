import { Component, Input } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { MyErrorStateMatcher, EditThreatUtilities, editThreatFormNames } from '../../../utilities';
import { EditThreatFormNameModel } from '../../../../../shared';

@Component({
  moduleId: module.id,
  selector: 'app-tlm-edit-threat-social-media',
  templateUrl: './socialmedia.component.html'
})
export class EditThreatSocialMediaComponent {
  @Input() socialMediaFormGroup: UntypedFormGroup;
  @Input() index: number;
  @Input() length: number;
  @Input() isLast: boolean;
  @Input() removeItem: (n: number) => void;
  @Input() isPopulated: () => boolean;
  @Input() socialMediaTypes: string[];
  @Input() matAppearance: string;
  @Input() errorMatcher: MyErrorStateMatcher;
  formNames: EditThreatFormNameModel;

  constructor(private editThreatUtils: EditThreatUtilities) {
    this.formNames = editThreatFormNames;
  }

  removeActionEnabled(): boolean {
    return this.editThreatUtils.removeActionEnabled(this.length, this.isPopulated, this.socialMediaFormGroup);
  }

  removeOnClick(index: number) {
    if (this.removeActionEnabled()) {
      this.removeItem(index);
    }
  }
}

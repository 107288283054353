import { Injectable } from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import { ILogger } from './logger.interface';
import { ToastrService } from 'ngx-toastr';

@Injectable()
export class Logger implements ILogger {
  constructor(private logger: NGXLogger, private toastrService: ToastrService) {}

  trace(message: any, ...additional: any[]): void {
    this.logger.trace(message, additional);
  }

  debug(message: any, ...additional: any[]): void {
    this.logger.debug(message, additional);
  }

  info(message: any, ...additional: any[]): void {
    this.logger.info(message, additional);
  }

  infoWithPopup(popupTitle: string, message: any, ...additional: any[]): void {
    this.info(message, additional);
    this.toastrService.info(message, popupTitle);
  }

  warn(message: any, ...additional: any[]): void {
    this.logger.warn(message, additional);
  }

  warnWithPopup(popupTitle: string, message: any, ...additional: any[]): void {
    this.warn(message, additional);
    const msg = this.extractMessage(message);
    this.toastrService.warning(msg, popupTitle);
  }

  error(message: any, ...additional: any[]): void {
    this.logger.error(message, additional);
  }

  errorWithPopup(popupTitle: string, message: any, ...additional: any[]): void {
    this.error(message, additional);
    const msg = this.extractMessage(message);
    this.toastrService.error(msg, popupTitle);
  }

  extractMessage(obj) {
    let msg: string;

    if (typeof obj === 'object') {
      if (obj.message) {
        msg = obj.message;
      } else {
        msg = JSON.stringify(obj);
      }
    } else {
      msg = obj;
    }

    return msg;
  }
}

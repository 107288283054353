<div>
  <div class="row">
    <div class="col-12">
      <span class="tlm-header">Identifications</span>
    </div>
  </div>
  <div class="row">
    <div class="col-12">
      <form class="needs-validation" novalidate [formGroup]="identificationsFormFormGroup">
        <div class="row">
          <div class="col-12">
            <app-tlm-edit-threat-identifications
              [matAppearance]="matAppearance"
              [errorMatcher]="errorMatcher"
              [states]="states"
              [identificationTypes]="identificationTypes"
              [identificationsFormArray]="identificationsFormFormGroup.get(formNames.identificationList)"
            >
            </app-tlm-edit-threat-identifications>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>

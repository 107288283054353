<div [formGroup]="boloFormGroup">
  <div class="col-12">
    <div>
      <div class="row">
        <div class="col-10 col-md-11">
          <app-tlm-bolo-entry [boloFormGroup]="boloFormGroup" [matAppearance]="matAppearance" [errorMatcher]="errorMatcher"></app-tlm-bolo-entry>
        </div>
        <div class="col-1 my-auto">
          <div class="row">
            <div class="col-12">
              <button mat-icon-button color="warn" id="removeBolo" (click)="removeOnClick(index)" [disabled]="!removeActionEnabled()">
                <mat-icon class="fa fa-close fa-lg"></mat-icon>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

import { Injectable } from '@angular/core';
import { UntypedFormGroup, UntypedFormArray, UntypedFormBuilder, UntypedFormControl } from '@angular/forms';
import * as _ from 'lodash';
import * as sjv from 'simple-js-validator';
import { TlmModel, AddressModel, StateModel } from '../../../shared';
import { EditThreatUtilities } from './edit-threat-utilities.service';
import { editThreatFormNames } from './edit-threat-form-names';

@Injectable()
export class EditThreatUtilitiesAddressesForm {
  constructor(private fb: UntypedFormBuilder, private editThreatUtils: EditThreatUtilities) {}

  createEmptyItemFormGroup(): UntypedFormGroup {
    return this.fb.group(
      {
        [editThreatFormNames.addressStreet]: [],
        [editThreatFormNames.addressCity]: [],
        [editThreatFormNames.addressState]: [],
        [editThreatFormNames.addressZip]: [],
        [editThreatFormNames.addressFrom]: [],
        [editThreatFormNames.addressTo]: [],
        [editThreatFormNames.addressCurrent]: []
      },
      { validator: this.addressFormValidation.bind(this) }
    );
  }

  initializeForm(): UntypedFormGroup {
    return new UntypedFormGroup({
      [editThreatFormNames.addressList]: new UntypedFormArray([])
    });
  }

  mapFromTlm(tlm: TlmModel, states: StateModel[]): UntypedFormGroup {
    const addressesFA = new UntypedFormArray([]);

    if (tlm.addresses && tlm.addresses.length > 0) {
      tlm.addresses.forEach((address) => {
        const stateDisplayName = this.editThreatUtils.convertStateCodeToDisplayName(address.state ? address.state.code : null, states);

        addressesFA.push(
          this.fb.group(
            {
              [editThreatFormNames.addressStreet]: [address.street],
              [editThreatFormNames.addressCity]: [address.city],
              [editThreatFormNames.addressState]: [stateDisplayName],
              [editThreatFormNames.addressZip]: [address.zip],
              [editThreatFormNames.addressFrom]: [address.from],
              [editThreatFormNames.addressTo]: [address.to],
              [editThreatFormNames.addressCurrent]: [address.currentAddress]
            },
            { validator: this.addressFormValidation.bind(this) }
          )
        );
      });
    } else {
      addressesFA.push(this.createEmptyItemFormGroup());
    }

    return new UntypedFormGroup({
      [editThreatFormNames.addressList]: addressesFA
    });
  }

  mapToTlm(fg: UntypedFormGroup, states: StateModel[]): AddressModel[] {
    const mapped = new Array<AddressModel>();
    let order = 0;
    const addressesFA = fg.get(editThreatFormNames.addressList) as UntypedFormArray;

    addressesFA.controls.forEach((addressFG) => {
      const street = this.editThreatUtils.getValueOrSetAsUndefined(addressFG.get(editThreatFormNames.addressStreet));
      const city = this.editThreatUtils.getValueOrSetAsUndefined(addressFG.get(editThreatFormNames.addressCity));
      const state = this.editThreatUtils.getValueOrSetAsUndefined(addressFG.get(editThreatFormNames.addressState));
      const zip = this.editThreatUtils.getValueOrSetAsUndefined(addressFG.get(editThreatFormNames.addressZip));
      const from = this.editThreatUtils.getValueOrSetAsUndefined(addressFG.get(editThreatFormNames.addressFrom));
      const to = this.editThreatUtils.getValueOrSetAsUndefined(addressFG.get(editThreatFormNames.addressTo));
      const currentAddress = this.editThreatUtils.getValueOrSetAsUndefined(addressFG.get(editThreatFormNames.addressCurrent));

      if (
        sjv.isNotEmpty(street) ||
        sjv.isNotEmpty(city) ||
        sjv.isNotEmpty(state) ||
        sjv.isNotEmpty(zip) ||
        sjv.isNotEmpty(from) ||
        sjv.isNotEmpty(to) ||
        sjv.isNotEmpty(currentAddress)
      ) {
        const stateCode = this.editThreatUtils.convertStateNameToCode(state, states);

        mapped.push(new AddressModel(order, street, city, new StateModel(state, stateCode), zip, from, to, currentAddress));
        order++;
      }
    });

    return mapped;
  }

  addressFormValidation(group: UntypedFormGroup) {
    const errors = {};

    const streetFC = group.get(editThreatFormNames.addressStreet) as UntypedFormControl;
    const cityFC = group.get(editThreatFormNames.addressCity) as UntypedFormControl;
    const zipFC = group.get(editThreatFormNames.addressZip) as UntypedFormControl;
    const stateFC = group.get(editThreatFormNames.addressState) as UntypedFormControl;
    const fromDateFC = group.get(editThreatFormNames.addressFrom) as UntypedFormControl;
    const toDateFC = group.get(editThreatFormNames.addressTo) as UntypedFormControl;
    const currentFC = group.get(editThreatFormNames.addressCurrent) as UntypedFormControl;

    const hasMainData =
      sjv.isNotEmpty(streetFC.value) || sjv.isNotEmpty(cityFC.value) || sjv.isNotEmpty(stateFC.value) || sjv.isNotEmpty(zipFC.value);

    const allPristine =
      streetFC.pristine && cityFC.pristine && stateFC.pristine && zipFC.pristine && fromDateFC.pristine && toDateFC.pristine && currentFC.pristine;

    if (allPristine) {
      return null;
    }

    // check if dates are valid
    if (sjv.isNotEmpty(fromDateFC.value) && sjv.isNotEmpty(toDateFC.value) && new Date(fromDateFC.value) > new Date(toDateFC.value)) {
      const errMsg = 'Addresses: [To Date] should be greater than or equal to [From Date]';
      this.editThreatUtils.addErrorToFormControl(toDateFC, 'toDateLessThanFromDate', errMsg);
      errors['toDateLessThanFromDate'] = errMsg;
    } else {
      this.editThreatUtils.removeErrorFromFormControl(toDateFC, 'toDateLessThanFromDate');
    }

    if (!hasMainData && (sjv.isNotEmpty(fromDateFC.value) || sjv.isNotEmpty(toDateFC.value))) {
      const errMsg = 'Addresses: At least one address field required if a date field is populated';
      this.editThreatUtils.addErrorToFormControl(streetFC, 'missingMainDataDate', errMsg);
      this.editThreatUtils.addErrorToFormControl(cityFC, 'missingMainDataDate', errMsg);
      this.editThreatUtils.addErrorToFormControl(zipFC, 'missingMainDataDate', errMsg);
      this.editThreatUtils.addErrorToFormControl(stateFC, 'missingMainDataDate', errMsg);
      errors['missingMainDataDate'] = errMsg;
    } else {
      this.editThreatUtils.removeErrorFromFormControl(streetFC, 'missingMainDataDate');
      this.editThreatUtils.removeErrorFromFormControl(cityFC, 'missingMainDataDate');
      this.editThreatUtils.removeErrorFromFormControl(zipFC, 'missingMainDataDate');
      this.editThreatUtils.removeErrorFromFormControl(stateFC, 'missingMainDataDate');
    }

    if (!hasMainData && currentFC.value) {
      const errMsg = 'Addresses: At least one address field required if [Current Address] is checked';
      this.editThreatUtils.addErrorToFormControl(streetFC, 'missingMainDataCurrent', errMsg);
      this.editThreatUtils.addErrorToFormControl(cityFC, 'missingMainDataCurrent', errMsg);
      this.editThreatUtils.addErrorToFormControl(zipFC, 'missingMainDataCurrent', errMsg);
      this.editThreatUtils.addErrorToFormControl(stateFC, 'missingMainDataCurrent', errMsg);
      errors['missingMainDataCurrent'] = errMsg;
    } else {
      this.editThreatUtils.removeErrorFromFormControl(streetFC, 'missingMainDataCurrent');
      this.editThreatUtils.removeErrorFromFormControl(cityFC, 'missingMainDataCurrent');
      this.editThreatUtils.removeErrorFromFormControl(zipFC, 'missingMainDataCurrent');
      this.editThreatUtils.removeErrorFromFormControl(stateFC, 'missingMainDataCurrent');
    }

    if (sjv.isEmpty(errors)) {
      return null;
    } else {
      return errors;
    }
  }
}

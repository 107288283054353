import { ModuleWithProviders, NgModule } from '@angular/core';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';
import { NgxGalleryModule } from 'ngx-gallery-9';
import { NgxSpinnerModule } from 'ngx-spinner';
import { ReadGuard } from '../../auth';
import { CoreModule } from '../../core';
import { SharedModule } from '../../shared';
import { ThreatProfileComponent } from './threat-profile.component';
import { ThreatProfileResolver } from './threat-profile.resolver';

const routing: ModuleWithProviders<unknown> = RouterModule.forChild([
  {
    path: 'profile/:tlmId',
    component: ThreatProfileComponent,
    canActivate: [ReadGuard],
    resolve: { resolved: ThreatProfileResolver }
  }
]);

@NgModule({
  declarations: [ThreatProfileComponent],
  imports: [
    routing,
    SharedModule,
    CoreModule,
    NgxSpinnerModule,
    NgxGalleryModule,
    MatSidenavModule,
    MatFormFieldModule,
    MatInputModule,
    MatCardModule,
    MatDividerModule,
    MatExpansionModule,
    MatTabsModule,
    MatButtonModule,
    MatSelectModule,
    MatDatepickerModule,
    MatAutocompleteModule,
    MatToolbarModule,
    MatListModule,
    MatTableModule,
    MatTooltipModule
  ],
  providers: [ThreatProfileResolver]
})
export class ThreatProfileModule {}

import { Injectable, Inject } from '@angular/core';
import { Observable, of } from 'rxjs';
import { IProfileTypeApiService } from './profile-type-api.interface';
import { ILogger } from '../logger';
import { ProfileTypeModel } from '../../../shared';

@Injectable()
export class ProfileTypeApiService implements IProfileTypeApiService {
  data: ProfileTypeModel[];

  constructor(@Inject('LoggerInjected') private logger: ILogger) {
    this.data = [
      {
        display: 'BOLO Incomplete',
        type: 'boloIncomplete',
        class: 'tlm-profile-type-bolo-incomplete',
        classForegroundOnly: 'tlm-profile-type-bolo-incomplete-foreground',
        classBackgroundOnly: 'tlm-profile-type-bolo-incomplete-background',
        icon: 'fa-binoculars'
      },
      {
        display: 'BOLO',
        type: 'bolo',
        class: 'tlm-profile-type-bolo',
        classForegroundOnly: 'tlm-profile-type-bolo-foreground',
        classBackgroundOnly: 'tlm-profile-type-bolo-background',
        icon: 'fa-binoculars'
      },
      {
        display: 'Threat',
        type: 'threat',
        class: 'tlm-profile-type-threat',
        classForegroundOnly: 'tlm-profile-type-threat-foreground',
        classBackgroundOnly: 'tlm-profile-type-threat-background',
        icon: 'fa-user-secret'
      }
    ];
  }

  list(): Observable<ProfileTypeModel[]> {
    this.logger.debug('ProfileTypeApiService.List', this.data);
    return of(this.data);
  }
}

import { Injectable } from '@angular/core';
import { NGXLoggerMock } from 'ngx-logger/testing';
import { ILogger } from './logger.interface';

@Injectable()
export class LoggerMock implements ILogger {
  public logger: NGXLoggerMock;

  constructor() {
    this.logger = new NGXLoggerMock();
  }

  trace(message: any, ...additional: any[]): void {
    this.logger.trace(message, additional);
  }

  debug(message: any, ...additional: any[]): void {
    this.logger.debug(message, additional);
  }

  info(message: any, ...additional: any[]): void {
    this.logger.info(message, additional);
  }

  infoWithPopup(popupTitle: string, message: any, ...additional: any[]): void {
    this.info(message, additional);
  }

  warn(message: any, ...additional: any[]): void {
    this.logger.warn(message, additional);
  }

  warnWithPopup(popupTitle: string, message: any, ...additional: any[]): void {
    this.warn(message, additional);
  }

  error(message: any, ...additional: any[]): void {
    this.logger.error(message, additional);
  }

  errorWithPopup(popupTitle: string, message: any, ...additional: any[]): void {
    this.error(message, additional);
  }
}

import { Injectable, Inject } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { IAuthService } from './auth-service.interface';

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(@Inject('TlmAuthService') private authService: IAuthService) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    this.authService.setCacheRedirect(state.url);
    if (this.authService.isLoggedIn()) {
      return true;
    } else {
      this.authService.login(state.url);
      return false;
    }
  }
}

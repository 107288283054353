import { ModuleWithProviders, NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { SharedModule } from '../../shared';
import { CoreModule } from '../../core';

import { IncorrectAccessComponent } from './incorrect-access.component';

const routing: ModuleWithProviders<unknown> = RouterModule.forChild([
  {
    path: 'incorrect-access',
    component: IncorrectAccessComponent
  }
]);

@NgModule({
  declarations: [IncorrectAccessComponent],
  imports: [routing, SharedModule, CoreModule]
})
export class IncorrectAccessModule {}

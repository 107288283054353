import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { Subscription } from 'rxjs';
import { AddPersonService } from '../../services';
import { ConfigAddPersonSubMenu } from './add-person-sub-menu.config';

import * as _ from 'lodash';

@Component({
  selector: 'app-tlm-add-person-sub-menu',
  templateUrl: './add-person-sub-menu.component.html'
})
export class AddPersonSubMenuComponent implements OnInit, OnDestroy {
  @Input() ulClass: string;

  menuList = [];
  menu$: Subscription;
  activeMenu: string;

  constructor(private addPersonService: AddPersonService) {
    _.forOwn(ConfigAddPersonSubMenu, (value, key) => {
      value['key'] = key;
      this.menuList.push(value);
    });
  }

  ngOnDestroy() {
    this.menu$.unsubscribe();
  }

  ngOnInit(): void {
    this.menu$ = this.addPersonService.getActiveMenu().subscribe((menu) => {
      this.activeMenu = menu;
    });
  }
}

import { Injectable } from '@angular/core';
import { Observable, Subject, BehaviorSubject } from 'rxjs';
import { SubMenuConfig } from '../../components/add-person-sub-menu';

@Injectable()
export class AddPersonService {
  private savingStatus = new Subject<boolean>();
  private cancelStatus = new Subject<boolean>();
  private activeMenu = new BehaviorSubject<string>('not set');
  private menuCollapsed = new BehaviorSubject<boolean>(true);

  constructor() {
    this.setMenuCollapsed(true);
  }

  setSavingStatusToSaving() {
    this.savingStatus.next(true);
  }

  setSavingStatusToSaved() {
    this.savingStatus.next(false);
  }

  getSavingStatus(): Observable<boolean> {
    return this.savingStatus.asObservable();
  }

  setCancelStatusToCanceling() {
    this.cancelStatus.next(true);
  }

  setCancelStatusToCanceled() {
    this.cancelStatus.next(false);
  }

  getCancelStatus(): Observable<boolean> {
    return this.cancelStatus.asObservable();
  }

  setActiveMenu(menu: SubMenuConfig) {
    this.activeMenu.next(menu.name);
    this.setMenuCollapsed(true);
  }

  getActiveMenu(): Observable<string> {
    return this.activeMenu.asObservable();
  }

  setMenuCollapsed(collapsed: boolean) {
    this.menuCollapsed.next(collapsed);
  }

  getMenuCollapsed(): Observable<boolean> {
    return this.menuCollapsed.asObservable();
  }
}

<div id="fullAddressForm">
  <div class="row">
    <div class="col-12 add-address-container">
      <button type="button" id="addAddress" class="btn btn-link tlm-link" (click)="createAndAddItemToListIfPopulated()">+ Add Address</button>
    </div>
  </div>
  <mat-divider></mat-divider>
  <br />
  <div>
    <div *ngFor="let addressFormGroup of addressesFormArray.controls; let i = index">
      <div class="row">
        <div class="col-12">
          <app-tlm-edit-threat-address
            [addressFormGroup]="addressFormGroup"
            [index]="i"
            [length]="addressesFormArray.controls.length"
            [removeItem]="boundRemoveItem"
            [isPopulated]="boundIsPopulated"
            [move]="boundMove"
            [matAppearance]="matAppearance"
            [errorMatcher]="errorMatcher"
            [states]="states"
          >
          </app-tlm-edit-threat-address>
          <br />
          <mat-divider></mat-divider>
          <br />
        </div>
      </div>
    </div>
  </div>
</div>

<button class="button-cancel" matTooltip="Cancel" mat-dialog-close mat-button color="warn" id="cancel">
  <mat-icon class="fa fa-close fa-lg"></mat-icon>
</button>
<form id="addThreatModal" [formGroup]="addThreatForm">
  <div class="tlm-add-profile-modal-header">
    <span>Add Threat</span>
  </div>
  <br />
  <mat-dialog-content>
    <div class="row">
      <div class="col-12">
        <app-tlm-form-control-input-no-error
          [fc]="addThreatForm.get(formNames.firstName)"
          label="First Name"
          componentId="firstName"
          matAppearance="outline"
        ></app-tlm-form-control-input-no-error>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <app-tlm-form-control-input-no-error
          [fc]="addThreatForm.get(formNames.lastName)"
          label="Last Name"
          componentId="lastName"
          matAppearance="outline"
        ></app-tlm-form-control-input-no-error>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <app-tlm-form-control-input-no-error
          [fc]="addThreatForm.get(formNames.alias)"
          label="Alias"
          componentId="alias"
          matAppearance="outline"
        ></app-tlm-form-control-input-no-error>
      </div>
    </div>
    <div>
      <mat-error *ngFor="let item of addThreatForm.errors | keyvalue">
        {{ item.value }}
      </mat-error>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions style="justify-content: flex-end">
    <button
      id="saveButton"
      mat-raised-button
      color="primary"
      style="float: right"
      type="button"
      (click)="onClickSubmit(addThreatForm)"
      [disabled]="addThreatForm.dirty && addThreatForm.invalid"
    >
      Submit
    </button>
  </mat-dialog-actions>
  <br />
</form>

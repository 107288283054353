import { Component, Input } from '@angular/core';
import { UntypedFormArray, UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import * as sjv from 'simple-js-validator';
import { EditThreatUtilities, MyErrorStateMatcher, EditThreatUtilitiesContactHistoryForm, editThreatFormNames } from '../../utilities';
import { ContactLocationModel } from '../../../../shared';

@Component({
  moduleId: module.id,
  selector: 'app-tlm-edit-threat-contact-history',
  templateUrl: './contact-history.component.html',
  styleUrls: ['./contact-history.component.scss']
})
export class EditThreatContactHistoryComponent {
  @Input() profileTypeFormControl: UntypedFormControl;
  @Input() contactHistoryFormArray: UntypedFormArray = new UntypedFormArray([]);
  @Input() matAppearance: string;
  @Input() errorMatcher: MyErrorStateMatcher;
  @Input() locations: ContactLocationModel[];
  boundRemoveItem: (n: number) => void;
  boundIsPopulated: () => boolean;

  constructor(private editThreatUtils: EditThreatUtilities, private editThreatUtilsContact: EditThreatUtilitiesContactHistoryForm) {
    this.boundRemoveItem = this.removeItemFromList.bind(this);
    this.boundIsPopulated = this.isPopulated.bind(this, editThreatFormNames);
  }

  isPopulated(formNames: any, fg: UntypedFormGroup): boolean {
    const dateFC = fg.get(formNames.contactDate) as UntypedFormControl;
    const hourFC = fg.get(formNames.contactHour) as UntypedFormControl;
    const minuteFC = fg.get(formNames.contactMinute) as UntypedFormControl;
    const meridiemFC = fg.get(formNames.contactMeridiem) as UntypedFormControl;
    const notesFC = fg.get(formNames.contactNote) as UntypedFormControl;
    const locationFC = fg.get(formNames.contactLocation) as UntypedFormControl;

    if (
      (dateFC && sjv.isNotEmpty(dateFC.value)) ||
      (hourFC && sjv.isNotEmpty(hourFC.value)) ||
      (minuteFC && sjv.isNotEmpty(minuteFC.value)) ||
      (meridiemFC && sjv.isNotEmpty(meridiemFC.value)) ||
      (notesFC && sjv.isNotEmpty(notesFC.value)) ||
      (locationFC && sjv.isNotEmpty(locationFC.value))
    ) {
      return true;
    }
    return false;
  }

  createAndAddItemToListIfPopulated(): void {
    return this.editThreatUtils.createAndAddItemToListIfPopulated(
      this.contactHistoryFormArray,
      this.editThreatUtilsContact.createEmptyItemFormGroup.bind(this.editThreatUtilsContact, this.profileTypeFormControl),
      this.isPopulated.bind(this, editThreatFormNames),
      'contact history'
    );
  }

  removeItemFromList(index: number): void {
    let reset = false;
    if (this.contactHistoryFormArray.length === 1) {
      reset = true;
    }
    return this.editThreatUtils.removeItemFromList(
      this.contactHistoryFormArray,
      this.editThreatUtilsContact.createEmptyItemFormGroup.bind(this.editThreatUtilsContact, this.profileTypeFormControl),
      index,
      reset
    );
  }
}

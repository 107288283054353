import { DateTime } from 'luxon';
import { ContactLocationModel } from './contact-location.model';

export class ContactHistoryModel {
  orderID: number;
  location: ContactLocationModel;
  date: string;
  notes: string;
  hour: number;
  minutes: number;
  dayTime: string;

  constructor(orderID?: number, location?: ContactLocationModel, date?: string, notes?: string, hour?: number, minutes?: number, dayTime?: string) {
    this.orderID = orderID;
    this.location = location;
    this.date = date;
    this.notes = notes ? notes : 'No Notes';
    this.hour = hour;
    this.minutes = minutes;
    this.dayTime = dayTime;
  }

  get displayDate(): string {
    return DateTime.fromISO(this.date).toLocaleString(DateTime.DATETIME_SHORT);
  }
}

import { HttpClient } from '@angular/common/http';
import { Observable, throwError, of } from 'rxjs';
import { map, catchError, tap } from 'rxjs/operators';
import { NgxConfigureService } from 'ngx-configure';
import * as _ from 'lodash';
import { Injectable, Inject } from '@angular/core';
import { TlmModel, TlmModelFactory } from '../../../shared';
import { IAuthService } from '../../../auth';
import { ITlmSearchService } from './tlm-search-service.interface';
import { ILogger } from '../logger';
import { SearchModel } from '../../../shared/models/search.model';

@Injectable()
export class TlmSearchService implements ITlmSearchService {
  config: any;
  apiPath: string;
  urlPath: string;

  constructor(
    @Inject('LoggerInjected') private logger: ILogger,
    private configService: NgxConfigureService,
    private http: HttpClient,
    @Inject('TlmAuthService') private authService: IAuthService
  ) {
    this.config = this.configService.config;
    this.apiPath = this.config.tlmSearchService.url;
    this.urlPath = 'services/search/sync';
  }

  search(searchModel: SearchModel): Observable<TlmModel[]> {
    const headers = this.authService.buildAuthHeaders();
    const searchBody = this.createQuery(searchModel);

    return this.http.post(this.apiPath + this.urlPath, searchBody, { headers }).pipe(
      map((response) =>
        _.map(response['hits']['hits'], (item) => {
          const tlm = new TlmModelFactory().create(item._source);
          tlm['score'] = item._score;
          tlm['highlight'] = item.highlight;
          return tlm;
        })
      ),
      tap((data) => this.logger.debug('TlmUISearchService.Search', data)),
      catchError((err) => {
        this.logger.error(err);
        return throwError(err);
      })
    );
  }

  createQuery(searchModel: SearchModel) {
    let searchBody;
    const searchTerm = searchModel.searchTerm;

    if (this.isEmptySearch(searchModel)) {
      /* eslint-disable @typescript-eslint/naming-convention */
      searchBody = {
        body: {
          highlight: {
            fields: {
              '*': {}
            },
            post_tags: [''],
            pre_tags: ['']
          },
          size: 100,
          query: {
            multi_match: {
              fields: ['person.firstName', 'person.lastName', 'aliases.value', 'emails.value', 'phones.value', 'rockSecurityId'],
              type: 'best_fields',
              query: searchTerm
            }
          }
        },
        index: 'threat'
      };
    } else if (searchTerm === '*') {
      searchBody = {
        body: {
          size: 100,
          query: {
            match_all: {}
          }
        },
        index: 'threat'
      };
    } else {
      searchBody = {
        body: {
          highlight: {
            fields: {
              '*': {}
            },
            post_tags: [''],
            pre_tags: ['']
          },
          size: 100,
          query: {
            bool: {
              should: [],
              must: [],
              minimum_should_match: 1
            }
          }
        },
        index: 'threat'
      };

      // Search Bar
      if (searchTerm) {
        const genericSearchBarMatches = [
          { match: { 'person.firstName': searchTerm } },
          { match: { 'person.lastName': searchTerm } },
          { match: { 'aliases.value': searchTerm } },
          { match: { 'emails.value': searchTerm } },
          { match: { 'phones.value': searchTerm } },
          { match_phrase_prefix: { rockSecurityId: searchTerm } }
        ];

        const genericSearchBarPrefixes = [
          { prefix: { 'person.firstName': searchTerm } },
          { prefix: { 'person.lastName': searchTerm } },
          { prefix: { 'aliases.value': searchTerm } },
          { prefix: { 'emails.value': searchTerm } },
          { prefix: { 'phones.value': searchTerm } },
          { prefix: { rockSecurityId: searchTerm } }
        ];

        genericSearchBarMatches.forEach((search) => {
          searchBody.body.query.bool.should.push(search);
        });

        if (!searchModel.contactHistoryStartDate && !searchModel.contactHistoryEndDate && !searchModel.gracieNumber) {
          genericSearchBarPrefixes.forEach((prefix) => {
            searchBody.body.query.bool.should.push(prefix);
          });
          searchBody.body.query.bool.minimum_should_match = 1;
        }
      }

      // Contact History Location Filter Search
      if (searchModel.location) {
        const locationMatchPhrasePrefixSearch = [
          // {'match_phrase_prefix' : { 'contactHistory.location.title': searchModel.location }},
          {
            match_phrase_prefix: {
              'contactHistory.location.address': searchModel.location
            }
          }
        ];

        locationMatchPhrasePrefixSearch.forEach((search) => {
          searchBody.body.query.bool.must.push(search);
        });

        searchBody.body.query.bool.minimum_should_match = 0;
      }

      // Contact History Date Range Filter Search
      if (searchModel.contactHistoryStartDate) {
        if (!searchModel.contactHistoryEndDate) {
          searchModel.contactHistoryEndDate = searchModel.contactHistoryStartDate;
        }

        searchModel.contactHistoryStartDate = new Date(searchModel.contactHistoryStartDate);

        const dateSearch = {
          range: {
            'contactHistory.date': {
              gte: searchModel.contactHistoryStartDate.toISOString(),
              lte: searchModel.contactHistoryEndDate.toISOString()
            }
          }
        };
        searchBody.body.query.bool.minimum_should_match = 0;
        searchBody.body.query.bool.must.push(dateSearch);
      }

      // Gracie Number Filter Search
      if (searchModel.gracieNumber) {
        const gracieMatchPhrasePrefixSearch = {
          match_phrase_prefix: {
            'gracieNumbers.value': searchModel.gracieNumber
          }
        };

        searchBody.body.query.bool.must.push(gracieMatchPhrasePrefixSearch);
        searchBody.body.query.bool.minimum_should_match = 0;
      }
    }

    return searchBody;

    /* eslint-enable @typescript-eslint/naming-convention */
  }

  isEmptySearch(searchModel: SearchModel) {
    if (
      searchModel.searchTerm === '' &&
      (searchModel.gracieNumber === undefined || searchModel.gracieNumber === '') &&
      (searchModel.location === undefined || searchModel.location === '') &&
      !searchModel.contactHistoryStartDate &&
      !searchModel.contactHistoryEndDate
    ) {
      return true;
    }
    return false;
  }
}

import { Injectable, Inject } from '@angular/core';

import { Resolve } from '@angular/router';
import { Observable, forkJoin } from 'rxjs';
import {
  GenderApiService,
  RaceApiService,
  StateApiService,
  IdentificationTypeApiService,
  PhoneTypeApiService,
  IContactLocationService,
  SocialMediaTypeApiService
} from '../../core';
import { map, tap } from 'rxjs/operators';
import * as _ from 'lodash';

@Injectable()
export class EditThreatResolver implements Resolve<Observable<Record<string, unknown>>> {
  constructor(
    private phoneTypeApiService: PhoneTypeApiService,
    private socialMediaTypeApiService: SocialMediaTypeApiService,
    private raceApiService: RaceApiService,
    private genderApiService: GenderApiService,
    private stateApiService: StateApiService,
    private identificationsTypeApiService: IdentificationTypeApiService,
    @Inject('ContactLocationServiceInjected')
    private contactLocationService: IContactLocationService
  ) {}

  resolve() {
    return forkJoin([
      this.genderApiService.list(),
      this.raceApiService.list(),
      this.phoneTypeApiService.list(),
      this.stateApiService.list(),
      this.identificationsTypeApiService.list(),
      this.contactLocationService.list(),
      this.socialMediaTypeApiService.list()
    ]).pipe(
      map((results) => ({
        genders: results[0],
        races: results[1],
        phoneTypes: results[2],
        states: results[3],
        identificationTypes: results[4],
        locations: results[5],
        socialMediaTypes: results[6]
      }))
    );
  }
}

<div class="container" style="color: white">
  <!-- <strong>Status: {{ firstName ? 'logged in' : 'logged out' }} </strong>
  <h1 *ngIf="!firstName">Welcome!</h1>
  <h1 *ngIf="firstName">Welcome, {{firstName}} {{lastName}}!</h1> -->

  <div class="panel panel-default">
    <div class="panel-body">
      <p>Login with Authorization Server</p>
      <button class="btn btn-default" style="color: white; background-color: white" (click)="login()">Login</button>
      <button class="btn btn-default" style="color: white; background-color: white" (click)="logout()">Logout</button>
    </div>
  </div>
  <!--
  <div class="panel panel-default">
    <div class="panel-body">
      <p>
        <strong>access_token_expiration:</strong> {{access_token_expiration}}
      </p>
      <p>
        <strong>id_token_expiration:</strong> {{id_token_expiration}}
      </p>
    </div>
  </div>

  <div class="panel panel-default">
    <div class="panel-body">
      <p>
        <strong>access_token:</strong> {{access_token}}
      </p>
      <p>
        <strong>id_token:</strong> {{id_token}}
      </p>
      <div *ngIf="userProfile">
        <strong>user profile:</strong>
        <pre style="color: white">{{userProfile | json}}</pre>
      </div>

    </div>
  </div>

  <button class="btn btn-default" (click)="testSilentRefresh()" style="color: white">Test silent refresh</button> -->
</div>

import { Injectable, Inject } from '@angular/core';
import { UntypedFormGroup, UntypedFormArray, AbstractControl, UntypedFormControl } from '@angular/forms';
import * as _ from 'lodash';
import * as sjv from 'simple-js-validator';
import { ToastrService } from 'ngx-toastr';
import { ILogger } from '../../../core';
import { StateModel } from '../../../shared';

@Injectable()
export class EditThreatUtilities {
  constructor(@Inject('LoggerInjected') private logger: ILogger, private toastr: ToastrService) {}

  getValueOrSetAsUndefined(ctrl: AbstractControl) {
    if (ctrl && sjv.isNotEmpty(ctrl.value)) {
      return ctrl.value;
    } else {
      return undefined;
    }
  }

  removeActionEnabled(length, isPopulated, formGroup): boolean {
    return length > 1 || isPopulated(formGroup);
  }

  moveUpActionEnabled(index): boolean {
    if (index > 0) {
      return true;
    } else {
      return false;
    }
  }

  moveDownActionEnabled(length, index): boolean {
    if (length <= 1 || index >= length - 1) {
      return false;
    } else {
      return true;
    }
  }

  allArePopulated(fa: UntypedFormArray, isPopulated: (fg: UntypedFormGroup) => boolean): boolean {
    for (const fg of fa.controls) {
      if (!isPopulated(fg as UntypedFormGroup)) {
        return false;
      }
    }

    return true;
  }

  createAndAddItemToListIfPopulated(
    fa: UntypedFormArray,
    createEmptyItem: () => UntypedFormControl,
    isPopulated: (fg: UntypedFormGroup) => boolean,
    listName: string
  ): void {
    if (this.allArePopulated(fa, isPopulated)) {
      fa.insert(0, createEmptyItem());
    } else {
      this.toastr.warning('All ' + listName + ' must be populated before adding a new one.');
    }
  }

  removeItemFromListWithAddtlValidation(fa: UntypedFormArray, index: number, reset: boolean = false): void {
    if (index === 0 && fa.controls.length === 1) {
      if (reset) {
        fa.reset();
      } else {
        const fg = fa.controls[0] as UntypedFormGroup;
        _.forOwn(fg.controls, (ctrl, key) => {
          this.logger.debug('Setting ' + key + ' to', null);
          ctrl.markAsDirty();
          ctrl.setValue(null);
        });
      }
    } else {
      fa.controls.splice(index, 1);
    }
  }

  removeItemFromList(fa: UntypedFormArray, createEmptyItem: () => UntypedFormControl, index: number, reset: boolean = false): void {
    if (index === 0 && fa.controls.length === 1) {
      if (reset) {
        fa.reset();
      } else {
        fa.controls[0] = createEmptyItem();
      }
    } else {
      fa.controls.splice(index, 1);
    }
  }

  moveItemInList(fa: UntypedFormArray, oldIndex, newIndex): void {
    const array = fa.controls;

    while (oldIndex < 0) {
      oldIndex += array.length;
    }
    while (newIndex < 0) {
      newIndex += array.length;
    }
    if (newIndex >= array.length) {
      let k = newIndex - array.length;
      while (k-- + 1) {
        array.push(undefined);
      }
    }
    array.splice(newIndex, 0, array.splice(oldIndex, 1)[0]);
  }

  updateControl(ctrl, value) {
    ctrl.setValue(value);
    setTimeout(() => {
      ctrl.markAsDirty();
    }); // use setTimeout to handle ExpressionChangedAfterItHasBeenCheckedError
  }

  convertStateNameToCode(stateName: string, states: StateModel[]): string {
    let code;
    if (sjv.isNotEmpty(stateName)) {
      const found = _.find(states, { name: stateName });

      if (found) {
        code = found.code;
      }
    }

    return code;
  }

  convertStateCodeToDisplayName(code: string, states: StateModel[]): string {
    let stateName;
    if (sjv.isNotEmpty(code)) {
      const found = _.find(states, { code });

      if (found) {
        stateName = found.name;
      }
    }

    return stateName;
  }

  addErrorToFormControl(fc: UntypedFormControl, errKey: string, errValue: any) {
    const errs = fc.errors || {};
    errs[errKey] = errValue;
    fc.setErrors(errs);
    fc.markAsTouched();
    fc.markAsDirty(); // errors werent displaying unless marked as dirty
  }

  removeErrorFromFormControl(fc: UntypedFormControl, errKey: string) {
    if (fc && fc.hasError(errKey)) {
      delete fc.errors[errKey];
      fc.updateValueAndValidity();
    }
  }
}

<nav id="genericHeader" class="navbar navbar-dark background justify-content-between">
 
    <span id="genericHeaderBrand" class="navbar-brand">
      <span class="d-none d-lg-inline">Thread Level Midnight</span>
      <span class="d-inline d-lg-none">TLM</span>
    </span>
    <ul class="nav navbar-nav navbar-right">
        
      <form class="d-flex row-cols-xl-auto">
        <div *ngIf="hasAdminAccess">
          <button class="d-none d-lg-inline" type="button" mat-button id="adminButton" color="accent" (click)="routeTo('/admin')">
            <span class="fa fa-user"></span>
            <span> Admin</span>
          </button>
          <button class="d-inline d-lg-none" type="button" mat-icon-button id="adminButtonIconOnly" color="accent"
            (click)="routeTo('/admin')">
            <span class="fa fa-user"></span>
          </button>
        </div>
        <div *ngIf="hasSearchAccess">
          <button class="d-none d-lg-inline" type="button" mat-button id="searchButton" color="accent" (click)="routeTo('/')">
            <span class="fa fa-search"></span>
            <span> Search</span>
          </button>
          <button class="d-inline d-lg-none" type="button" mat-icon-button id="searchButtonIconOnly" color="accent"
            (click)="routeTo('/')">
            <span class="fa fa-search"></span>
          </button>
        </div>
        <div *ngIf="hasBoloAccess">
          <button class="d-none d-lg-inline" type="button" mat-button id="boloButton" color="accent" (click)="routeTo('/bolo')">
            <span class="fa fa-binoculars"></span>
            <span> BOLO</span>
          </button>
          <button class="d-inline d-lg-none" type="button" mat-icon-button id="boloButtonIconOnly" color="accent"
            (click)="routeTo('/bolo')">
            <span class="fa fa-binoculars"></span>
          </button>
        </div>
        <div *ngIf="hasCreateAccess && !isEditPage">
          <button class="d-none d-lg-inline" type="button" mat-button id="addProfile" color="accent" (click)="addProfile()">
            <span class="fa fa-plus"></span>
            <span> Add Profile</span>
          </button>
          <button class="d-inline d-lg-none" type="button" mat-icon-button id="addProfileIconOnly" color="accent"
            (click)="addProfile()">
            <span class="fa fa-plus"></span>
          </button>
        </div>
        <div *ngIf="hasEditAccess && isEditPage">
          <button class="d-none d-lg-inline" type="button" mat-button routerLink="edit/basic" id="edit" color="accent">
            <span class="fa fa-edit"></span>
            <span>{{ profileType === 'threat' ? ' Edit Threat Profile' : ' Edit Bolo
              Profile' }}</span>
          </button>
          <button class="d-inline d-lg-none" type="button" mat-icon-button routerLink="edit/basic" id="editIconOnly" color="accent">
            <span class="fa fa-edit"></span>
          </button>
        </div>
      </form>
    </ul> 
</nav>
import { DateTime } from 'luxon';
const missingValueMessage = 'Unknown';
export class BoloModel {
  startDate: string;
  endDate: string;
  reason: string;

  constructor(startDate?: string, endDate?: string, reason?: string) {
    this.startDate = startDate;
    this.endDate = endDate;
    this.reason = reason;
  }

  get startDateForDisplay() {
    return this.getDateForDisplay(this.startDate);
  }

  get endDateForDisplay() {
    return this.getDateForDisplay(this.endDate);
  }

  get reasonForDisplay() {
    return this.reason || missingValueMessage;
  }

  private getDateForDisplay(date?: string) {
    const dateWithoutTime = date?.split('T')[0];
    return dateWithoutTime ? DateTime.fromFormat(dateWithoutTime, 'yyyy-MM-dd').toLocaleString(DateTime.DATE_FULL) : missingValueMessage;
  }

  get isActive() {
    const today = DateTime.now().startOf('day');
    return !!(this.startDate && this.endDate && DateTime.fromFormat(this.startDate, 'yyyy-MM-dd') <= today && DateTime.fromFormat(this.endDate, 'yyyy-MM-dd') >= today);
  }
}

import { Injectable, Inject } from '@angular/core';

import { Resolve } from '@angular/router';
import { Observable, of, forkJoin } from 'rxjs';
import {
  GenderApiService,
  RaceApiService,
  StateApiService,
  IdentificationTypeApiService,
  PhoneTypeApiService,
  IContactLocationService
} from '../../core';
import { map } from 'rxjs/operators';

@Injectable()
export class ThreatProfileResolver implements Resolve<Observable<Record<string, unknown>>> {
  constructor(
    private phoneTypeApiService: PhoneTypeApiService,
    private raceApiService: RaceApiService,
    private genderApiService: GenderApiService,
    private stateApiService: StateApiService,
    private identificationsTypeApiService: IdentificationTypeApiService,
    @Inject('ContactLocationServiceInjected')
    private contactLocationService: IContactLocationService
  ) {}

  resolve() {
    return forkJoin([
      this.genderApiService.list(),
      this.raceApiService.list(),
      this.phoneTypeApiService.list(),
      this.stateApiService.list(),
      this.identificationsTypeApiService.list(),
      this.contactLocationService.list()
    ]).pipe(
      map((results) => ({
        genders: results[0],
        races: results[1],
        phoneTypes: results[2],
        states: results[3],
        identificationTypes: results[4],
        locations: results[5]
      }))
    );
  }
}

<div class="row" [formGroup]="emailFormGroup">
  <div class="col-10 col-md-8">
    <mat-form-field [appearance]="matAppearance">
      <mat-label>Email</mat-label>
      <input [id]="'email' + index" matInput [formControlName]="formNames.email" [errorStateMatcher]="errorMatcher" />
      <mat-error *ngIf="emailFormGroup.get(formNames.email).hasError('pattern')"> Email address is not valid </mat-error>
    </mat-form-field>
  </div>
  <div class="col-1 my-auto">
    <button mat-icon-button color="warn" [id]="'removeEmail' + index" (click)="removeOnClick(index)" [disabled]="!removeActionEnabled()">
      <mat-icon class="fa fa-close fa-lg"></mat-icon>
    </button>
  </div>
</div>

import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { NgxConfigureService } from 'ngx-configure';

import { IAuthService } from './auth-service.interface';
import { OAuthService, AuthConfig } from 'angular-oauth2-oidc';
import { filter } from 'rxjs/operators';
import { HttpHeaders } from '@angular/common/http';

@Injectable()
export class Auth0AuthService implements IAuthService {
  config: any;
  userProfile: any;

  constructor(private configService: NgxConfigureService, private oauthService: OAuthService, private router: Router) {
    this.config = this.configService.config;

    const authConfig: AuthConfig = {
      issuer: this.config.auth.issuer,
      redirectUri: window.location.origin + '/auth-callback',
      // silentRefreshRedirectUri: window.location.origin + '/auth-callback-silent-refresh',
      clientId: this.config.auth.clientId,
      scope: this.config.auth.scope,
      responseType: 'code',
      showDebugInformation: true,
      customQueryParams: {
        audience: this.config.auth.audience
      }
    };

    this.oauthService.configure(authConfig);
    this.oauthService.setStorage(localStorage);
    this.oauthService.loadDiscoveryDocumentAndLogin();

    // Optional
    this.oauthService.setupAutomaticSilentRefresh();

    this.oauthService.events.subscribe((e) => {
      // eslint-disable-next-line no-console
      // console.log('oauth/oidc event', e);
    });

    this.oauthService.events.pipe(filter((e) => e.type === 'session_terminated')).subscribe((e) => {
      // eslint-disable-next-line no-console
      // console.log('Your session has been terminated!');
    });

    this.oauthService.events.pipe(filter((e) => e.type === 'token_received')).subscribe((e) => {
      // console.log('token_receive handler -> loading user profile');
      this.oauthService.loadUserProfile();
    });

    this.oauthService.events.pipe(filter((e) => e.type === 'user_profile_loaded')).subscribe((e) => {
      // console.log('user_profile_loaded handler -> navigating to cached redirect');
      this.processUserProfile();
      this.navigateToCachedRedirect();
    });
  }

  public login(redirect?: string) {
    this.setCacheRedirect(redirect);
    this.oauthService.initCodeFlow();
  }

  public logout() {
    this.oauthService.logOut();
    this.delCacheCommonId();
    this.delCacheHasAdminAccess();
    this.delCacheHasBoloAccess();
    this.delCacheHasCreateAccess();
    this.delCacheHasEditAccess();
    this.delCacheHasReadAccess();
    this.delCacheHasSearchAccess();
    this.delCacheRedirect();
    this.oauthService.initCodeFlow();
  }

  public delCacheRedirect() {
    localStorage.removeItem('authRedirect');
  }

  public getCacheRedirect(): string {
    return localStorage.getItem('authRedirect');
  }

  public setCacheRedirect(redirect: string) {
    localStorage.setItem('authRedirect', redirect);
  }

  public delCacheHasAdminAccess() {
    localStorage.removeItem('authHasAdminAccess');
  }

  public getCacheHasAdminAccess(): string {
    return localStorage.getItem('authHasAdminAccess');
  }

  public setCacheHasAdminAccess(access: boolean) {
    return localStorage.setItem('authHasAdminAccess', access.toString());
  }

  public delCacheHasReadAccess() {
    localStorage.removeItem('authHasReadAccess');
  }

  public getCacheHasReadAccess(): string {
    return localStorage.getItem('authHasReadAccess');
  }

  public setCacheHasReadAccess(access: boolean) {
    return localStorage.setItem('authHasReadAccess', access.toString());
  }

  public delCacheHasCreateAccess() {
    localStorage.removeItem('authHasCreateAccess');
  }

  public getCacheHasCreateAccess(): string {
    return localStorage.getItem('authHasCreateAccess');
  }

  public setCacheHasCreateAccess(access: boolean) {
    return localStorage.setItem('authHasCreateAccess', access.toString());
  }

  public delCacheHasEditAccess() {
    localStorage.removeItem('authHasEditAccess');
  }

  public getCacheHasEditAccess(): string {
    return localStorage.getItem('authHasEditAccess');
  }

  public setCacheHasEditAccess(access: boolean) {
    return localStorage.setItem('authHasEditAccess', access.toString());
  }

  public delCacheHasBoloAccess() {
    localStorage.removeItem('authHasBoloAccess');
  }

  public getCacheHasBoloAccess(): string {
    return localStorage.getItem('authHasBoloAccess');
  }

  public setCacheHasBoloAccess(access: boolean) {
    return localStorage.setItem('authHasBoloAccess', access.toString());
  }

  public delCacheHasSearchAccess() {
    localStorage.removeItem('authHasSearchAccess');
  }

  public getCacheHasSearchAccess(): string {
    return localStorage.getItem('authHasSearchAccess');
  }

  public setCacheHasSearchAccess(access: boolean) {
    return localStorage.setItem('authHasSearchAccess', access.toString());
  }

  public getCacheCommonId(): string {
    return localStorage.getItem('commonId');
  }

  public setCacheCommonId(commonId) {
    return localStorage.setItem('commonId', commonId);
  }

  public delCacheCommonId() {
    localStorage.removeItem('commonId');
  }

  public loadUserProfile(): Promise<any> {
    return this.oauthService.loadUserProfile();
  }

  public getIdentityClaims(): any {
    return this.oauthService.getIdentityClaims();
  }

  public getRequestAccessToken() {
    return this.oauthService.requestAccessToken;
  }

  public getIdToken(): string {
    return this.oauthService.getIdToken();
  }

  public getAccessToken(): string {
    return this.oauthService.getAccessToken();
  }

  public getIdTokenExpiration(): number {
    return this.oauthService.getIdTokenExpiration();
  }

  public getAccessTokenExpiration(): number {
    return this.oauthService.getAccessTokenExpiration();
  }

  public testSilentRefresh() {
    this.oauthService.oidc = true;

    this.oauthService
      .silentRefresh()
      .then((info) => console.log('refresh ok', info))
      .catch((err) => console.error('refresh error', err));
  }

  public setRequestAccessToken(value: boolean) {
    this.oauthService.requestAccessToken = value;
    localStorage.setItem('requestAccessToken', '' + value);
  }

  public isLoggedIn(): boolean {
    return this.oauthService.hasValidIdToken();
  }

  public hasAdminAccess(): boolean {
    return this.getCacheHasAdminAccess() === 'true';
  }

  public hasReadAccess(): boolean {
    return this.getCacheHasReadAccess() === 'true';
  }

  public hasCreateAccess(): boolean {
    return this.getCacheHasCreateAccess() === 'true';
  }

  public hasEditAccess(): boolean {
    return this.getCacheHasEditAccess() === 'true';
  }

  public hasBoloAccess(): boolean {
    return this.getCacheHasBoloAccess() === 'true';
  }

  public hasSearchAccess(): boolean {
    return this.getCacheHasSearchAccess() === 'true';
  }

  public buildAuthHeaders(addtlHttpHeaders?: any): HttpHeaders {
    return new HttpHeaders(addtlHttpHeaders).set('Authorization', 'Bearer ' + this.oauthService.getAccessToken());
  }

  private navigateToCachedRedirect() {
    const redirect = this.getCacheRedirect();
    this.router.navigate([redirect || '/home']);
  }

  private processUserProfile() {
    // Check if the user has admin role
    const claims = this.oauthService.getIdentityClaims();
    const roles = claims['https://ql.custom.openid.com/groups'] || [];

    const commonId = claims['https://ql.custom.openid.com/common_id'] || -999;

    const hasAdminAccess = this.hasRole(roles, this.config.auth.adminAccessRoles);
    let hasCreateAccess = this.hasRole(roles, this.config.auth.createAccessRoles);
    let hasEditAccess = this.hasRole(roles, this.config.auth.editAccessRoles);
    let hasReadAccess = this.hasRole(roles, this.config.auth.readAccessRoles);
    let hasBoloAccess = this.hasRole(roles, this.config.auth.boloAccessRoles);
    let hasSearchAccess = this.hasRole(roles, this.config.auth.searchAccessRoles);

    if (hasAdminAccess) {
      // if the user has admin access, then they have create, edit, and read access
      hasCreateAccess = true;
      hasEditAccess = true;
      hasReadAccess = true;
      hasBoloAccess = true;
      hasSearchAccess = true;
    }

    this.setCacheHasAdminAccess(hasAdminAccess);
    this.setCacheHasReadAccess(hasReadAccess);
    this.setCacheHasCreateAccess(hasCreateAccess);
    this.setCacheHasEditAccess(hasEditAccess);
    this.setCacheHasBoloAccess(hasBoloAccess);
    this.setCacheHasSearchAccess(hasSearchAccess);
    this.setCacheCommonId(commonId);
  }

  private hasRole(roleListToSearchIn, roleListToFind): boolean {
    let hasRole = false;

    for (const role of roleListToFind) {
      for (const usersRoles of roleListToSearchIn) {
        if (usersRoles.includes(role)) {
          hasRole = true;
          break;
        }
      }
    }

    return hasRole;
  }
}

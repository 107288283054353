import { Component, OnInit, OnDestroy, ViewChild, Inject } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { TlmModel } from '../../shared';
import { Subscription } from 'rxjs';
import * as _ from 'lodash';
import { NgxGalleryComponent } from 'ngx-gallery-9';
import { ITlmApiService } from '../../core';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-tlm-bolo',
  templateUrl: './bolo.component.html',
  styleUrls: ['bolo.component.scss']
})
export class BoloComponent implements OnInit, OnDestroy {
  @ViewChild('ngxGalleryProfilePhoto')
  ngxGalleryProfilePhoto: NgxGalleryComponent;

  tlms$: Subscription;
  tlms: Array<TlmModel>;

  boloRows = [];

  infiniteScrollDistance = 1;
  infiniteScrollThrottle = 500;
  currentPage: number;
  pageSize: number;
  allBolosLoaded: boolean;
  isLoading: boolean;

  defaultProfilePhoto = 'assets/personPlaceholder.100.png';

  constructor(private titleService: Title, @Inject('TlmApiServiceInjected') private tlmApiService: ITlmApiService, private toastr: ToastrService) {}

  ngOnInit(): void {
    this.titleService.setTitle('TLM - Bolo');
    this.pageSize = 12;
    this.currentPage = 0;
    this.allBolosLoaded = false;

    this.tlms$ = this.tlmApiService.boloList().subscribe((data) => {
      this.tlms = _.sortBy(data, (item) => item.bolos[0].startDate).reverse();

      this.toastr.info('Scroll to the bottom to load more.', null, {
        closeButton: true,
        disableTimeOut: true,
        positionClass: 'toast-bottom-left'
      });

      this.isLoading = true;
      this.showMoreBolos();
      this.isLoading = false;
    });
  }

  ngOnDestroy() {
    if (this.tlms$) {
      this.tlms$.unsubscribe();
    }
    this.toastr.clear();
  }

  onScroll() {
    if (!this.allBolosLoaded) {
      this.isLoading = true;
      this.showMoreBolos();
      this.isLoading = false;
    }
  }

  backToTop() {
    window.scroll({
      top: 0,
      behavior: 'smooth'
    });
  }

  populateBoloRows(boloItem: any) {
    let lastRow;

    if (this.boloRows.length === 0) {
      this.boloRows.push({ first: boloItem });
    } else {
      lastRow = this.boloRows[this.boloRows.length - 1];

      if (!lastRow.first) {
        this.boloRows.push({ first: boloItem });
      } else if (!lastRow.second) {
        lastRow.second = boloItem;
      } else {
        this.boloRows.push({ first: boloItem });
      }
    }
  }

  showMoreBolos() {
    for (let i = this.currentPage * this.pageSize; i < this.tlms.length && i < (this.currentPage + 1) * this.pageSize; i++) {
      const boloItem = {
        tlm: this.tlms[i],
        photo: [
          {
            small: this.defaultProfilePhoto,
            medium: this.defaultProfilePhoto,
            big: this.defaultProfilePhoto
          }
        ]
      };
      this.populateBoloRows(boloItem);
      if (i >= this.tlms.length - 1) {
        this.allBolosLoaded = true;
        this.toastr.clear();
      }
    }
    if (this.allBolosLoaded === false) {
      this.currentPage++;
    }
  }
}

import { HttpClient } from '@angular/common/http';
import { Observable, throwError, of } from 'rxjs';
import { map, catchError, tap } from 'rxjs/operators';
import { NgxConfigureService } from 'ngx-configure';
import * as _ from 'lodash';
import { Injectable, Inject } from '@angular/core';
import { TlmModel, TlmModelFactory } from '../../../shared';
import { IAuthService } from '../../../auth';
import { ITlmApiService } from './tlm-api.interface';
import { ILogger } from '../logger';
import { DateTime } from 'luxon';

@Injectable()
export class TlmApiService implements ITlmApiService {
  config: any;
  apiPath: string;
  urlPath: string;

  constructor(
    @Inject('LoggerInjected') private logger: ILogger,
    private configService: NgxConfigureService,
    private http: HttpClient,
    @Inject('TlmAuthService') private authService: IAuthService
  ) {
    this.config = this.configService.config;
    this.apiPath = this.config.tlmApi.url + '/api';
    this.urlPath = 'tlms';
  }

  create(item: TlmModel): Observable<TlmModel> {
    const headers = this.authService.buildAuthHeaders();
    return this.http
      .post<TlmModel>(this.apiPath + '/' + this.urlPath, item, { headers })
      .pipe(
        map((response) => new TlmModelFactory().create(response)),
        tap((data) => this.logger.debug('TlmApiService.Create', data)),
        catchError((err) => {
          this.logger.error(err);
          return throwError(err);
        })
      );
  }

  delete(id: string): Observable<TlmModel> {
    const headers = this.authService.buildAuthHeaders();
    return this.http
      .delete<TlmModel>(this.apiPath + '/' + this.urlPath + '/' + id, {
        headers
      })
      .pipe(
        map((response) => new TlmModelFactory().create(response)),
        tap((data) => this.logger.debug('TlmApiService.Delete', data)),
        catchError((err) => {
          this.logger.error(err);
          return throwError(err);
        })
      );
  }

  get(id: string): Observable<TlmModel> {
    const headers = this.authService.buildAuthHeaders();
    return this.http
      .get<TlmModel>(this.apiPath + '/' + this.urlPath + '/' + id, { headers })
      .pipe(
        map((response) => new TlmModelFactory().create(response)),
        tap((data) => {
          this.logger.debug('TlmApiService.Get', data);
        }),
        catchError((err) => {
          this.logger.error(err);
          return throwError(err);
        })
      );
  }

  list(searchTerm: string): Observable<TlmModel[]> {
    const headers = this.authService.buildAuthHeaders();
    return this.http
      .get<TlmModel[]>(this.apiPath + '/' + this.urlPath + '?searchTerm=' + encodeURIComponent(searchTerm), { headers })
      .pipe(
        map((response) => _.map(response, (item) => new TlmModelFactory().create(item))),
        tap((data) => this.logger.debug('TlmApiService.List', data)),
        catchError((err) => {
          this.logger.error(err);
          return throwError(err);
        })
      );
  }

  boloList(): Observable<TlmModel[]> {
    const headers = this.authService.buildAuthHeaders();
    const today = DateTime.now().toFormat('yyyy-MM-dd');
    return this.http
      .get<TlmModel[]>(this.apiPath + '/bolos/' + today, { headers })
      .pipe(
        map((response) => _.map(response, (item) => new TlmModelFactory().create(item))),
        tap((data) => this.logger.debug('TlmApiService.BoloList', data)),
        catchError((err) => {
          this.logger.error(err);
          return throwError(err);
        })
      );
  }

  update(item: TlmModel): Observable<TlmModel> {
    const headers = this.authService.buildAuthHeaders();
    return this.http
      .put<TlmModel>(this.apiPath + '/' + this.urlPath, item, { headers })
      .pipe(
        map((response) => new TlmModelFactory().create(response)),
        tap((data) => this.logger.debug('TlmApiService.Update', data)),
        // catchError(this.handleError<any>('tlmApiUpdate', {}))
        catchError((err) => {
          this.logger.error(err);
          return throwError(err);
        })
      );
  }
}

import { NgxConfigureOptions } from 'ngx-configure';
import { Injectable } from '@angular/core';

/* eslint-disable @typescript-eslint/naming-convention */
@Injectable()
export class ConfigOptions extends NgxConfigureOptions {
  ConfigurationURL = 'assets/config.json';
  AppVersion = require('../../../../package.json').version;
  BustCache = true;
}
/* eslint-enable @typescript-eslint/naming-convention */

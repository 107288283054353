import { Component, Input } from '@angular/core';
import * as _ from 'lodash';
import * as sjv from 'simple-js-validator';
import { UntypedFormArray, UntypedFormGroup } from '@angular/forms';
import { EditThreatUtilities, editThreatFormNames, MyErrorStateMatcher, EditThreatUtilitiesVideosForm } from '../../utilities';

@Component({
  moduleId: module.id,
  selector: 'app-tlm-edit-threat-videos',
  templateUrl: './videos.component.html'
})
export class EditThreatVideosComponent {
  @Input() videoFormArray: UntypedFormArray = new UntypedFormArray([]);
  @Input() matAppearance: string;
  @Input() errorMatcher: MyErrorStateMatcher;
  boundRemoveItem: () => void;
  boundIsPopulated: () => boolean;
  boundMove: () => void;

  constructor(private editThreatUtils: EditThreatUtilities, private editThreatUtilitiesVideosForm: EditThreatUtilitiesVideosForm) {
    this.boundRemoveItem = this.removeItemFromList.bind(this);
    this.boundIsPopulated = this.isPopulated.bind(this);
    this.boundMove = this.move.bind(this);
  }

  isPopulated(videoFormGroup: UntypedFormGroup): boolean {
    if (
      videoFormGroup &&
      ((videoFormGroup.get(editThreatFormNames.videoTitle) && sjv.isNotEmpty(videoFormGroup.get(editThreatFormNames.videoTitle).value)) ||
        (videoFormGroup.get(editThreatFormNames.videoLink) && sjv.isNotEmpty(videoFormGroup.get(editThreatFormNames.videoLink).value)))
    ) {
      return true;
    }
    return false;
  }

  createAndAddItemToListIfPopulated(): void {
    return this.editThreatUtils.createAndAddItemToListIfPopulated(
      this.videoFormArray,
      this.editThreatUtilitiesVideosForm.createEmptyVideoItemFormGroup.bind(this.editThreatUtilitiesVideosForm),
      this.isPopulated,
      'videos'
    );
  }

  removeItemFromList(index: number): void {
    let reset = false;
    if (this.videoFormArray.length === 1) {
      reset = true;
    }
    return this.editThreatUtils.removeItemFromList(
      this.videoFormArray,
      this.editThreatUtilitiesVideosForm.createEmptyVideoItemFormGroup.bind(this.editThreatUtilitiesVideosForm),
      index,
      reset
    );
  }

  move(oldIndex, newIndex) {
    return this.editThreatUtils.moveItemInList(this.videoFormArray, oldIndex, newIndex);
  }
}

import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import * as _ from 'lodash';
import { NgxConfigureService } from 'ngx-configure';
import { Observable, throwError } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { IAuthService } from '../../../auth';
import { ContactLocationModel, ContactLocationModelFactory } from '../../../shared';
import { ILogger } from '../logger';
import { IContactLocationService } from './contact-location-service.interface';

@Injectable()
export class ContactLocationService implements IContactLocationService {
  config: any;
  apiPath: string;
  urlPath: string;

  constructor(
    @Inject('LoggerInjected') private logger: ILogger,
    private configService: NgxConfigureService,
    private http: HttpClient,
    @Inject('TlmAuthService') private authService: IAuthService
  ) {
    this.config = this.configService.config;
    this.apiPath = this.config.tlmApi.url + '/api';
    this.urlPath = 'locations';
  }

  list(): Observable<ContactLocationModel[]> {
    const headers = this.authService.buildAuthHeaders();
    return this.http
      .get<ContactLocationModel[]>(this.apiPath + '/' + this.urlPath, {
        headers
      })
      .pipe(
        map((response) => _.map(response, (item) => new ContactLocationModelFactory().create(item))),
        map((data) => _.orderBy(data, ['title', 'address'])),
        tap((data) => this.logger.debug('ContactLocation List', data)),
        catchError((err) => {
          this.logger.error(err);
          return throwError(err);
        })
      );
  }
}

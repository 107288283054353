<nav id="addPersonHeader" class="navbar navbar-expand-lg navbar-dark background justify-content-between">
  <a id="addPersonHeaderBrand" class="navbar-brand" routerLink="/">Edit Profile</a>
  <ul class="nav navbar-nav navbar-right">
    <form class="form-inline">
      <button mat-button color="accent" id="addPersonHeaderSave" (click)="save()"><span class="fa fa-save"></span> Save</button>
      <button mat-button color="warn" id="addPersonHeaderCancel" (click)="cancel()"><span class="fa fa-close"></span> Cancel</button>
    </form>
  </ul>
</nav>
<nav id="editPersonMobileNavMenu" *ngIf="renderAsMobile" class="navbar navbar-expand-lg navbar-light bg-light">
  <a class="navbar-brand" href="#">{{ activeMenu }}</a>
  <button type="button" class="navbar-toggler" type="button" (click)="collapse()">
    <span class="navbar-toggler-icon"></span>
  </button>
  <div class="collapse navbar-collapse" [ngbCollapse]="isNavbarCollapsed">
    <app-tlm-add-person-sub-menu ulClass="navbar-nav mr-auto mt-2 mt-lg-0"></app-tlm-add-person-sub-menu>
  </div>
</nav>

<div>
  <div class="row">
    <div class="col-12">
      <span class="tlm-header">Background Information</span>
    </div>
  </div>
  <mat-divider></mat-divider>
  <br />
  <div class="row">
    <div class="col-12">
      <form class="needs-validation" novalidate [formGroup]="backgroundInfoFormFormGroup">
        <div class="row">
          <div class="col-12">
            <app-tlm-form-control-rich-text-editor
              [fc]="backgroundInfoFormFormGroup.get(formNames.physicalDescription)"
              label="Physical Description"
              componentId="physicalDescription"
            ></app-tlm-form-control-rich-text-editor>
          </div>
        </div>
        <br />
        <div class="row">
          <div class="col-12">
            <label for="backgroundInfo" class="tlm-label">Background</label>
            <div class="input-group row">
              <quill-editor
                id="backgroundInfo"
                class="rich-text-editor col-12"
                [formControl]="backgroundInfoFormFormGroup.get(formNames.backgroundInfo)"
                [modules]="getToolbar()"
              >
              </quill-editor>
            </div>
          </div>
        </div>
        <br />
        <br />
        <br />
        <div class="row">
          <div class="col-12">
            <label for="criminalHistory" class="tlm-label">Criminal History</label>
            <div class="input-group row">
              <quill-editor
                id="criminalHistory"
                class="rich-text-editor col-12"
                [formControl]="backgroundInfoFormFormGroup.get(formNames.criminalHistory)"
                [modules]="getToolbar()"
              >
              </quill-editor>
            </div>
          </div>
        </div>
        <br />
        <br />
        <br />
        <div class="row">
          <div class="col-12">
            <app-tlm-edit-threat-gracie-numbers
              [gracieNumbersFormArray]="backgroundInfoFormFormGroup.get(formNames.gracieNumberList)"
              [matAppearance]="matAppearance"
              [errorMatcher]="errorMatcher"
            >
            </app-tlm-edit-threat-gracie-numbers>
          </div>
        </div>
        <br />
        <br />
      </form>
    </div>
  </div>
</div>

import { EditThreatFormNameModel } from '../../../shared';

export const editThreatFormNames: EditThreatFormNameModel = {
  // Address Form
  addressList: 'Address List',
  addressStreet: 'Address Street',
  addressCity: 'Address City',
  addressState: 'Address State',
  addressZip: 'Address Zip',
  addressFrom: 'Address From',
  addressTo: 'Address To',
  addressCurrent: 'Address Current',

  // Background Info Form
  backgroundInfo: 'Background Info',
  physicalDescription: 'Physical Description',
  criminalHistory: 'Criminal History',
  gracieNumberList: 'Gracie Numbers',
  gracieNumber: 'gracieNumber',

  // Basic Info Form
  profilePicture: 'Profile Picture',
  rockSecurityId: 'Rock Security Id',
  firstName: 'First Name',
  lastName: 'Last Name',
  dob: 'Date of Birth',
  gender: 'Gender',
  race: 'Race',
  aliasList: 'Aliases List',
  alias: 'Alias',
  emailList: 'Email List',
  email: 'Email',
  phoneList: 'Phone List',
  phoneNumber: 'Phone Number',
  phoneType: 'Phone Type',
  vehicleList: 'Vehicle List',
  vehicleYear: 'Vehicle Year',
  vehicleMakeModel: 'Vehicle Make Model',
  vehicleLicensePlate: 'Vehicle License Plate',
  vehicleColor: 'Vehicle Color',
  vehicleState: 'Vehicle State',

  // BOLO Form
  boloList: 'BOLO List',
  boloStartDate: 'BOLO Start Date',
  boloEndDate: 'BOLO End Date',
  boloReason: 'BOLO Reason',

  // Contact History Form
  contactList: 'Contact History List',
  contactDate: 'Contact Date',
  contactHour: 'Contact Hour',
  contactMinute: 'Contact Minute',
  contactMeridiem: 'Contact Meridiem',
  contactLocation: 'Contact Location',
  contactNote: 'Contact Note',

  // Photo Form
  photoList: 'Photo List',
  photo: 'Photo',

  // Identification Form
  identificationList: 'Identification List',
  identificationType: 'Identification Type',
  identificationState: 'Identification State',
  identificationNumber: 'Identification Number',
  identificationExpirationDate: 'Identification Expiration Date',

  // Social Media Form
  socialMediaList: 'Social Media List',
  socialMediaValue: 'Social Media Value',
  socialMediaType: 'Social Media Type',

  // Video Form
  videoList: 'Video List',
  videoTitle: 'Video Title',
  videoLink: 'Video Link',
  videoModifiedDate: 'Modified Date'
};

export class VideoModel {
  orderId: number;
  title: string;
  link: string;
  modifiedDate: string;

  constructor(orderId?: number, title?: string, link?: string, modifiedDate?: string) {
    this.orderId = orderId;
    this.title = title;
    this.link = link;
    this.modifiedDate = modifiedDate;
  }
}

import { Component, Input } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { MyErrorStateMatcher, editThreatFormNames } from '../utilities';
import { SocialMediaModel, EditThreatFormNameModel } from '../../../shared';

@Component({
  selector: 'app-tlm-edit-threat-social',
  templateUrl: './social.component.html'
})
export class EditThreatSocialFormComponent {
  @Input() socialMediaFormFormGroup: UntypedFormGroup = new UntypedFormGroup({});
  @Input() matAppearance: string;
  @Input() errorMatcher: MyErrorStateMatcher;
  @Input() socialMedias: SocialMediaModel[];
  @Input() socialMediaTypes: string[];
  formNames: EditThreatFormNameModel = editThreatFormNames;
}

import { Component, Input } from '@angular/core';
import { UntypedFormArray, UntypedFormGroup } from '@angular/forms';
import * as sjv from 'simple-js-validator';
import { EditThreatUtilities } from '../../utilities';
import { MyErrorStateMatcher, EditThreatUtilitiesBasicInfoForm, editThreatFormNames } from '../../utilities';

@Component({
  moduleId: module.id,
  selector: 'app-tlm-edit-threat-aliases',
  templateUrl: './aliases.component.html'
})
export class EditThreatAliasesComponent {
  @Input() aliasesFormArray: UntypedFormArray = new UntypedFormArray([]);
  @Input() matAppearance: string;
  @Input() errorMatcher: MyErrorStateMatcher;
  boundRemoveItem: (n: number) => void;
  boundIsPopulated: () => boolean;

  constructor(private editThreatUtils: EditThreatUtilities, private editThreatUtilsBasicInfoForm: EditThreatUtilitiesBasicInfoForm) {
    this.boundRemoveItem = this.removeItemFromList.bind(this);
    this.boundIsPopulated = this.isPopulated.bind(this);
  }

  isPopulated(aliasFG: UntypedFormGroup): boolean {
    return aliasFG && aliasFG.get(editThreatFormNames.alias) && sjv.isNotEmpty(aliasFG.get(editThreatFormNames.alias).value);
  }

  createAndAddItemToListIfPopulated(): void {
    return this.editThreatUtils.createAndAddItemToListIfPopulated(
      this.aliasesFormArray,
      this.editThreatUtilsBasicInfoForm.createEmptyAliasItemFormGroup.bind(this.editThreatUtilsBasicInfoForm),
      this.isPopulated,
      'aliases'
    );
  }

  removeItemFromList(index: number): void {
    let reset = false;
    if (this.aliasesFormArray.length === 1) {
      reset = true;
    }
    return this.editThreatUtils.removeItemFromListWithAddtlValidation(this.aliasesFormArray, index, reset);
  }
}

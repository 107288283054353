import { Component, Input } from '@angular/core';
import { MyErrorStateMatcher } from '../../../pages/edit-threat/utilities/edit-threat.error-matcher';
import { UntypedFormControl } from '@angular/forms';

@Component({
  moduleId: module.id,
  selector: 'app-tlm-form-control-input-error-func',
  templateUrl: './form-control-input-error-func.component.html'
})
export class FormControlInputErrorFuncComponent {
  @Input() fc: UntypedFormControl;
  @Input() label: string;
  @Input() componentId: string;
  @Input() matAppearance: string;
  @Input() errorMatcher: MyErrorStateMatcher;
  @Input() displayFieldError: (a1: any, a2: any) => string;
}

import { Component, Input, OnChanges } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { MyErrorStateMatcher } from '../../../pages/edit-threat/utilities/edit-threat.error-matcher';
import { ContactLocationModel } from '../../../shared';
import { Observable } from 'rxjs';
import { startWith, map } from 'rxjs/operators';
import { editThreatFormNames } from '../../../pages/edit-threat/utilities';

@Component({
  moduleId: module.id,
  selector: 'app-tlm-contact-entry',
  templateUrl: './contact-entry.component.html',
  styleUrls: ['./contact-entry.component.scss']
})
export class ContactEntryComponent implements OnChanges {
  @Input() contactHistoryFormGroup: UntypedFormGroup;
  @Input() matAppearance: string;
  @Input() errorMatcher: MyErrorStateMatcher;
  @Input() index: number;
  @Input() locations: ContactLocationModel[];

  minDate = new Date(1970, 0, 1);
  maxDate = new Date();
  filteredLocations: Observable<ContactLocationModel[]>;
  formNames: any;

  hours: Array<number>;
  minutes: Array<number>;
  dayTimes: Array<string>;

  constructor() {
    this.formNames = editThreatFormNames;
    this.hours = [];
    this.minutes = [];
    this.dayTimes = ['AM', 'PM'];

    for (let i = 1; i < 13; i++) {
      this.hours.push(i);
    }

    for (let i = 0; i < 60; i++) {
      this.minutes.push(i);
    }
  }

  ngOnChanges(): void {
    this.filteredLocations = this.contactHistoryFormGroup.get(this.formNames.contactLocation).valueChanges.pipe(
      startWith<string | ContactLocationModel>(''),
      map((value) => (typeof value === 'string' ? value : value?.locationForDisplay)),
      map((name) => (name ? this.filter(name) : this.locations.slice()))
    );
  }

  locationDisplayFn(location?: ContactLocationModel): string | undefined {
    return location ? location.locationForDisplay : undefined;
  }

  filter(value: string): ContactLocationModel[] {
    const filterValue = value.toLowerCase();
    return this.locations.filter((item) => item.locationForDisplay.toLowerCase().includes(filterValue));
  }

  displayFieldError(key, value): string {
    let display = value;
    if (key === 'matDatepickerParse') {
      display = 'Please enter a valid date MM/DD/YYYY';
    } else if (key === 'matDatepickerMin') {
      display = 'Please enter a date after 1/1/1970';
    }
    return display;
  }
}

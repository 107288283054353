<div [formGroup]="aliasFormGroup">
  <div class="row">
    <div class="col-10 col-md-8">
      <mat-form-field [appearance]="matAppearance">
        <mat-label>Alias</mat-label>
        <input [id]="'alias' + index" matInput placeholder="Alias" [formControlName]="formNames.alias" [errorStateMatcher]="errorMatcher" />
        <mat-error *ngFor="let item of aliasFormGroup.get(formNames.alias).errors | keyvalue">
          {{ item.value }}
        </mat-error>
      </mat-form-field>
    </div>
    <div class="col-1 my-auto">
      <button mat-icon-button color="warn" [id]="'removeAlias' + index" (click)="removeOnClick(index)" [disabled]="!removeActionEnabled()">
        <mat-icon class="fa fa-close fa-lg"></mat-icon>
      </button>
    </div>
  </div>
</div>

export class AliasModel {
  orderId: number;
  value: string;

  constructor(orderId?: number, value?: string) {
    this.orderId = orderId;
    this.value = value;
  }

  isPopulated(): boolean {
    return this.value && this.value.trim().length > 0;
  }
}

<div>
  <div class="row">
    <div class="col-12" style="text-align: right; margin-top: -35px">
      <button type="button" id="addSocialMedia" class="btn btn-link tlm-link pull-right" (click)="createAndAddItemToListIfPopulated()">
        + Add Social Media
      </button>
    </div>
  </div>
  <mat-divider></mat-divider>
  <br />
  <div *ngFor="let socialMediaFormGroup of socialMediasFormArray.controls; let i = index; let isLastRow = last">
    <div class="row">
      <div class="col-12">
        <app-tlm-edit-threat-social-media
          [socialMediaFormGroup]="socialMediaFormGroup"
          [index]="i"
          [isLast]="isLastRow"
          [length]="socialMediasFormArray.controls.length"
          [removeItem]="boundRemoveItem"
          [isPopulated]="boundIsPopulated"
          [socialMediaTypes]="socialMediaTypes"
          [matAppearance]="matAppearance"
          [errorMatcher]="errorMatcher"
        >
        </app-tlm-edit-threat-social-media>
      </div>
    </div>
  </div>
</div>

<div class="container-fullwidth">
  <app-tlm-layout-header></app-tlm-layout-header>
</div>
<br />

<div
  infiniteScroll
  [infiniteScrollDistance]="infiniteScrollDistance"
  [infiniteScrollThrottle]="infiniteScrollThrottle"
  (scrolled)="onScroll()"
  class="container"
>
  <h1 class="bolo-header tlm-header" id="boloHeader">Be On The Lookout</h1>
  <div *ngFor="let item of boloRows; let i = index">
    <div class="row">
      <div *ngIf="item.first" class="col-lg-6 col-md-10">
        <app-tlm-bolo-profile [tlm]="item.first.tlm" [photo]="item.first.photo" [rowIndex]="i" [colIndex]="0"></app-tlm-bolo-profile>
      </div>
      <div class="d-inline d-lg-none col-md-2"></div>
      <br />
      <div *ngIf="item.second" class="col-lg-6 col-md-10">
        <app-tlm-bolo-profile [tlm]="item.second.tlm" [photo]="item.second.photo" [rowIndex]="i" [colIndex]="1"></app-tlm-bolo-profile>
      </div>
    </div>
    <br />
  </div>
  <div class="loading-infinite-scroll" *ngIf="isLoading">
    <div class="row justify-content-around loading-animation">
      <div class="col-auto loader-ellips">
        <span class="loader-ellips__dot"></span>
        <span class="loader-ellips__dot"></span>
        <span class="loader-ellips__dot"></span>
        <span class="loader-ellips__dot"></span>
      </div>
    </div>
  </div>
  <div class="all-content-loaded" *ngIf="allBolosLoaded">
    <div class="row justify-content-around">
      <div class="col-auto">
        <mat-divider style="background-color: rgba(103, 58, 183, 0.24)"></mat-divider>
        <button mat-button color="primary" (click)="backToTop()">BACK TO TOP</button>
      </div>
    </div>
  </div>
  <br />
</div>

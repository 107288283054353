<div>
  <div class="row">
    <div class="col-12">
      <span class="tlm-section-title">Gracie Numbers</span>
      <button type="button" id="addGracie" class="btn btn-link tlm-link pull-right" (click)="createAndAddItemToListIfPopulated()">
        + Add Gracie Number
      </button>
    </div>
  </div>
  <div id="gracieNumbers" *ngFor="let gracieNumberFormGroup of gracieNumbersFormArray.controls; let i = index; let isLastRow = last">
    <div class="row">
      <div class="col-12">
        <app-tlm-edit-threat-gracie-number
          [gracieNumberFormGroup]="gracieNumberFormGroup"
          [index]="i"
          [isLast]="isLastRow"
          [length]="gracieNumbersFormArray.controls.length"
          [removeItem]="boundRemoveItem"
          [isPopulated]="boundIsPopulated"
          [matAppearance]="matAppearance"
          [errorMatcher]="errorMatcher"
        >
        </app-tlm-edit-threat-gracie-number>
      </div>
    </div>
  </div>
</div>

import { Component, OnInit, OnDestroy, Inject, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MediaService, ITlmApiService, ILogger, IImageService } from '../../core';
import {
  TlmModel,
  VehicleModel,
  IdentificationModel,
  AddressModel,
  StateModel,
  ContactHistoryModel,
  TlmModelFactory,
  PhotoModel,
  BoloModel,
  BaseUtilities,
  SocialMediaModel,
  VideoModel
} from '../../shared';
import { Subscription, throwError, Observable, of } from 'rxjs';
import { Title } from '@angular/platform-browser';
import { catchError } from 'rxjs/operators';
import { NgxSpinnerService } from 'ngx-spinner';
import { DataSource } from '@angular/cdk/collections';
import * as sjv from 'simple-js-validator';
import * as _ from 'lodash';
import { DateTime } from 'luxon';
import { NgxGalleryOptions, NgxGalleryImage, NgxGalleryComponent, NgxGalleryOrder } from 'ngx-gallery-9';

export class VehiclesDataSource extends DataSource<any> {
  constructor(private vehicles: Observable<VehicleModel[]>) {
    super();
  }
  connect(): Observable<VehicleModel[]> {
    return this.vehicles;
  }
  disconnect() {}
}

export class IdentificationsDataSource extends DataSource<any> {
  constructor(private identifications: Observable<IdentificationModel[]>) {
    super();
  }
  connect(): Observable<IdentificationModel[]> {
    return this.identifications;
  }
  disconnect() {}
}

export class AddressesDataSource extends DataSource<any> {
  constructor(private addresses: Observable<AddressModel[]>) {
    super();
  }
  connect(): Observable<AddressModel[]> {
    return this.addresses;
  }
  disconnect() {}
}

export class ContactHistoryDataSource extends DataSource<any> {
  constructor(private contactHistory: Observable<ContactHistoryModel[]>) {
    super();
  }
  connect(): Observable<ContactHistoryModel[]> {
    return this.contactHistory;
  }
  disconnect() {}
}

@Component({
  selector: 'app-tlm-threat-profile',
  templateUrl: './threat-profile.component.html',
  styleUrls: ['./threat-profile.component.scss']
})
export class ThreatProfileComponent implements OnInit, OnDestroy {
  @ViewChild('ngxGalleryProfilePhoto')
  ngxGalleryProfilePhoto: NgxGalleryComponent;
  @ViewChild('ngxGalleryAddtlPhotos')
  ngxGalleryAddtlPhotos: NgxGalleryComponent;

  vehiclesDataSource: VehiclesDataSource;
  identificationsDataSource: IdentificationsDataSource;
  addressesDataSource: AddressesDataSource;
  vehicleDisplayedColumns = ['year', 'color', 'makeModel', 'license'];
  identificationDisplayedColumns = ['idType', 'idNumber', 'expirationDate', 'state'];
  addressDisplayedColumns = ['street', 'city', 'state', 'zip', 'from', 'to', 'currentAddress'];

  param$: Subscription;

  tlm$: Subscription;
  tlm: TlmModel;
  phoneTypes: string[];
  genders: string[];
  races: string[];
  states: StateModel[];
  identificationTypes: string[];
  socialMedias: any;

  defaultProfilePhoto = 'assets/personPlaceholder.100.png';

  renderAsMobile$: Subscription;
  renderAsMobile: boolean;

  isActive: any;

  ngxGalleryOptionsProfilePhoto: NgxGalleryOptions[];
  ngxGalleryImagesProfilePhoto: NgxGalleryImage[];

  ngxGalleryOptionsAddtlPhotos: NgxGalleryOptions[];
  ngxGalleryImagesAddtlPhotos: NgxGalleryImage[];

  constructor(
    @Inject('LoggerInjected') private logger: ILogger,
    private titleService: Title,
    private activatedRoute: ActivatedRoute,
    private spinner: NgxSpinnerService,
    private mediaService: MediaService,
    private utils: BaseUtilities,
    @Inject('TlmApiServiceInjected') private tlmApiService: ITlmApiService,
    @Inject('ImageServiceInjected') private imageService: IImageService
  ) {
    this.isActive = {};

    this.ngxGalleryOptionsProfilePhoto = [
      {
        width: '100%',
        height: '300px',
        thumbnails: false,
        imageSize: 'contain',
        imageArrows: false,
        thumbnailsArrows: false
      }
    ];

    this.ngxGalleryOptionsAddtlPhotos = [
      {
        width: '100%',
        height: '300px',
        image: false,
        imageSize: 'contain',
        thumbnailSize: 'contain',
        thumbnailsColumns: 2,
        thumbnailsRows: 2,
        thumbnailsOrder: NgxGalleryOrder.Row
      }
    ];

    this.ngxGalleryImagesProfilePhoto = [];
    this.ngxGalleryImagesAddtlPhotos = [];
  }

  onInitLoadDataFromSnapshot() {
    this.genders = this.activatedRoute.snapshot.data.resolved.genders;
    this.races = this.activatedRoute.snapshot.data.resolved.races;
    this.phoneTypes = this.activatedRoute.snapshot.data.resolved.phoneTypes;
    this.states = this.activatedRoute.snapshot.data.resolved.states;
    this.identificationTypes = this.activatedRoute.snapshot.data.resolved.identificationTypes;
  }

  onInitSetTitle(): void {
    this.titleService.setTitle('TLM - Profile');
  }

  onInitStartSpinner(): void {
    this.spinner.show();
  }

  onInitDetermineMobileStatus(): void {
    this.renderAsMobile$ = this.mediaService.getRenderAsMobileStatus().subscribe((event) => {
      this.renderAsMobile = event;
    });
  }

  async processPhotosToNgxGalleries(photos: PhotoModel[]): Promise<any> {
    // console.log('processPhotosToNgxGalleries');

    const ngxGalleries = {
      profile: {},
      addtl: []
    };

    if (photos.length > 0) {
      // create promise list that will get thumbnails from photo list
      const promises = photos.map((photo) => this.imageService.getImage(photo.key));

      return Promise.all(promises)
        .then((result) => {
          ngxGalleries.profile = result[0];
          ngxGalleries.addtl = result;

          return ngxGalleries;
        })
        .catch((err) => {
          this.logger.errorWithPopup('There was an issue processing the image(s).  Please try again.', err.error.message);
          throw new Error(err.error.message);
        });
    } else {
      ngxGalleries.profile = {
        small: this.defaultProfilePhoto,
        medium: this.defaultProfilePhoto,
        big: this.defaultProfilePhoto
      };

      return Promise.resolve(ngxGalleries);
    }
  }

  async processTlmPhotosAndNgxGalleries(): Promise<void> {
    this.tlm.photos = _.sortBy(this.tlm.photos, ['orderId']);

    const ngxGalleries = await this.processPhotosToNgxGalleries(this.tlm.photos);

    this.ngxGalleryImagesProfilePhoto.push(ngxGalleries.profile);
    this.ngxGalleryImagesAddtlPhotos.push(...ngxGalleries.addtl);
  }

  async processTlmData(data): Promise<void> {
    this.tlm = new TlmModelFactory().create(data);

    await this.processTlmPhotosAndNgxGalleries();

    this.vehiclesDataSource = new VehiclesDataSource(of(this.tlm.vehicles));
    this.identificationsDataSource = new IdentificationsDataSource(of(this.tlm.identifications));
    this.addressesDataSource = new AddressesDataSource(of(this.tlm.addresses));

    this.socialMedias = this.getSocialMedias();
    this.spinner.hide();
  }

  onInitLoadDataBasedOnRouteParams() {
    this.param$ = this.activatedRoute.params.subscribe((params) => {
      this.tlm$ = this.tlmApiService
        .get(params['tlmId'])
        .pipe(
          catchError((err) => {
            this.logger.errorWithPopup('Error Getting TLM Data', err);
            return throwError(err);
          })
        )
        .subscribe((data) => {
          this.processTlmData(data);
        });
    });
  }

  ngOnInit(): void {
    this.onInitLoadDataFromSnapshot();
    this.onInitSetTitle();
    this.onInitStartSpinner();
    this.onInitDetermineMobileStatus();
    this.onInitLoadDataBasedOnRouteParams();
  }

  ngOnDestroy(): void {
    this.param$.unsubscribe();

    if (this.tlm$) {
      this.tlm$.unsubscribe();
    }

    this.renderAsMobile$.unsubscribe();
  }

  mouseEnter(id: string) {
    this.isActive[id] = true;
  }

  mouseLeave(id: string) {
    this.isActive[id] = false;
  }

  parseISOString(isoDate: string) {
    if (sjv.isEmpty(isoDate)) {
      return '';
    }

    const dateArray = isoDate.toString().substring(0, 10).split(/\D+/);
    const newDate = dateArray[1] + '-' + dateArray[2] + '-' + dateArray[0];
    return newDate;
  }

  displayStateCode(isoCode?: string) {
    return isoCode ? isoCode.toString().substring(3) : 'N/A';
  }

  addPhotoToImageList(imageList, key, url) {
    imageList.push({
      small: url,
      medium: key,
      big: key
    });
  }

  currentAddresses(): AddressModel[] {
    return this.tlm ? this.sortAddresses(this.tlm.addresses.filter((item) => item.currentAddress)) : [];
  }

  nonCurrentAddresses(): AddressModel[] {
    return this.tlm ? this.sortAddresses(this.tlm.addresses.filter((item) => !item.currentAddress)) : [];
  }

  sortAddresses(addresses: AddressModel[]): AddressModel[] {
    if (!addresses || addresses.length === 0) {
      return addresses;
    }

    return _.sortBy(addresses, ['from', 'to']).reverse();
  }

  getAddressDisplay(address: AddressModel) {
    let displayTo = 'unknown';
    let displayFrom = 'unknown';

    const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

    if (address.from) {
      const date = new Date(address.from);
      displayFrom = monthNames[date.getMonth()] + ' ' + date.getDate() + ', ' + date.getFullYear();
    }

    if (address.to) {
      const date = new Date(address.to);
      displayTo = monthNames[date.getMonth()] + ' ' + date.getDate() + ', ' + date.getFullYear();
    }

    if (address.currentAddress) {
      displayTo = 'present';
    }

    const city = sjv.isNotEmpty(address.city) ? address.city + ', ' : '';
    const state = sjv.isNotEmpty(address.state) && sjv.isNotEmpty(address.state.code) ? address.state.code + ' ' : '';
    const zip = sjv.isNotEmpty(address.zip) ? address.zip : '';

    return {
      firstLine: sjv.isNotEmpty(address.street) ? address.street : 'Street address unknown',
      secondLine: (city + state + zip).trim(),
      thirdLine: 'From <strong>' + displayFrom + '</strong> to <strong>' + displayTo + '</strong>'
    };
  }

  sortHistory(history: ContactHistoryModel[]): ContactHistoryModel[] {
    if (!history || history.length === 0) {
      return history;
    }
    return _.sortBy(history, ['date']).reverse();
  }

  getContactHistory(): ContactHistoryModel[] {
    return this.tlm ? this.sortHistory(this.tlm.contactHistory) : [];
  }

  getRestOfContactHistory(): ContactHistoryModel[] {
    return this.getContactHistory().slice(1, this.getContactHistory().length);
  }

  getFirstContact(): ContactHistoryModel {
    return this.getContactHistory()[0];
  }

  getFirstSortedBolo(): BoloModel {
    return this.tlm.getSortedBolos()[0];
  }

  getBolosWithoutFirstBolo(): BoloModel[] {
    return this.tlm.getSortedBolos().slice(1, this.tlm.getSortedBolos().length);
  }

  formatDateFromString(date?: string) {
    if(!date) {
      return 'Unknown';
    }
    if(!Date.parse(date)) {
      return 'Invalid date';
    }
    const dateWithoutTime = date.split('T')[0];
    return DateTime.fromFormat(dateWithoutTime, 'yyyy-MM-dd').toLocaleString(DateTime.DATE_MED);
  }

  getSocialMedias() {
    const socialMedias = this.tlm.socialMedias;
    if (sjv.isEmpty(socialMedias)) {
      return null;
    }

    return this.organizeSocialMediaForDisplay(socialMedias);
  }

  organizeSocialMediaForDisplay(socialMedias: SocialMediaModel[]) {
    const formatted = this.reformatSocialMedia(socialMedias);
    const ordered = this.orderSocialMediaByPopularity(formatted);

    return ordered;
  }

  reformatSocialMedia(inputArray) {
    // Sort Array alphabetically by type then by value

    if (sjv.isEmpty(inputArray) || sjv.isEmpty(inputArray[0].type)) {
      return null;
    }

    inputArray.sort((a, b) => {
      if (a.type.toLowerCase() < b.type.toLowerCase()) {
        return -1;
      }
      if (a.type.toLowerCase() > b.type.toLowerCase()) {
        return 1;
      }
      if (a.value.toLowerCase() < b.value.toLowerCase()) {
        return -1;
      }
      if (a.value.toLowerCase() > b.value.toLowerCase()) {
        return 1;
      }
      return 0;
    });

    const result = [
      {
        type: inputArray[0].type,
        values: [this.buildSocialMediaObject(inputArray[0])]
      }
    ];

    while (inputArray.length > 1) {
      if (inputArray[1].type === inputArray[0].type) {
        result[result.length - 1].values.push(this.buildSocialMediaObject(inputArray[1]));
      } else if (inputArray[1].type !== inputArray[0].type) {
        result.push({
          type: inputArray[1].type,
          values: [this.buildSocialMediaObject(inputArray[1])]
        });
      }
      inputArray = inputArray.slice(1);
    }
    return result;
  }

  buildSocialMediaObject(item) {
    return { link: item.value, isUrl: this.utils.isValidUrl(item.value) };
  }

  // Sorts input based on custom sortOrder, non-matches fall to end of sort
  orderSocialMediaByPopularity(socialMedias) {
    const ordering = {};
    const sortOrder = ['Facebook', 'Instagram', 'Twitter', 'Snapchat', 'LinkedIn', 'YouTube', 'Website', 'Other'];
    for (let i = 0; i < sortOrder.length; i++) {
      ordering[sortOrder[i]] = i;
    }

    return socialMedias.sort((a, b) => ordering[a.type] - ordering[b.type]);
  }

  getSocialMediaIcon(type: string) {
    const styling = ' tlm-fa-15x icon-color-' + type.toLowerCase();
    switch (type.toLowerCase()) {
      case 'facebook':
        return 'fa fa-facebook-square' + styling;
      case 'instagram':
        return 'fa fa-instagram' + styling;
      case 'twitter':
        return 'fa fa-twitter-square' + styling;
      case 'snapchat':
        return 'fa fa-snapchat-square' + styling;
      case 'linkedin':
        return 'fa fa-linkedin-square' + styling;
      case 'youtube':
        return 'fa fa-youtube-square' + styling;
      default:
        return 'fa fa-globe tlm-fa-15x icon-color-globe';
    }
  }

  displayThreatProfile() {
    return _.get(this.tlm, 'profileStatus', false) === 'threat' ? true : false;
  }

  displayActiveBoloProfile() {
    return _.get(this.tlm, 'profileStatus', false) === 'boloActive' ? true : false;
  }

  displayInactiveBoloProfile() {
    return _.get(this.tlm, 'profileStatus', false) === 'boloInactive' ? true : false;
  }

  sortVideos(videoList: VideoModel[]): VideoModel[] {
    if (!videoList || videoList.length === 0) {
      return videoList;
    }
    return _.sortBy(videoList, ['orderId']);
  }

  getFirstSortedVideo(): VideoModel {
    return this.getSortedVideos()[0];
  }

  getVideosWithoutFirstVideo(): VideoModel[] {
    return this.getSortedVideos().slice(1, this.getSortedVideos().length);
  }

  getSortedVideos(): VideoModel[] {
    return this.sortVideos(this.tlm.videos);
  }
}

export class ContactLocationModel {
  title: string;
  address: string;
  groupId: string;
  locationId: string;

  constructor(locationId?: string, title?: string, address?: string, groupId?: string) {
    this.locationId = locationId;
    this.title = title;
    this.address = address;
    this.groupId = groupId;
  }

  get locationForDisplay(): string {
    let toDisplay = 'Unknown';

    if (this.title && this.address) {
      toDisplay = this.title + ':' + this.address;
    }

    return toDisplay;
  }
}

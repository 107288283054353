import { Component, Input, Inject, ViewChild, OnChanges } from '@angular/core';
import * as _ from 'lodash';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { IImageService } from '../../../core';
import { TlmModel, StateModel, ProfileTypeModel, EditThreatFormNameModel } from '../../../shared';
import { NgxGalleryOptions, NgxGalleryImage, NgxGalleryComponent } from 'ngx-gallery-9';
import { MyErrorStateMatcher } from '../utilities/edit-threat.error-matcher';
import { editThreatFormNames } from '../utilities';

@Component({
  selector: 'app-tlm-edit-threat-basic-info',
  templateUrl: './basic-info.component.html',
  styleUrls: ['./basic-info.component.scss']
})
export class EditThreatBasicInfoComponent implements OnChanges {
  @ViewChild('ngxGalleryProfilePhoto')
  ngxGalleryProfilePhoto: NgxGalleryComponent;

  @Input() profileTypeFormControl: UntypedFormControl;
  @Input() tlm: TlmModel;
  @Input() genders: string[];
  @Input() races: string[];
  @Input() phoneTypes: string[];
  @Input() states: StateModel[];
  @Input() profileTypes: ProfileTypeModel[];
  @Input() basicInfoFormFormGroup: UntypedFormGroup = new UntypedFormGroup({});
  @Input() matAppearance: string;
  @Input() errorMatcher: MyErrorStateMatcher;
  formNames: EditThreatFormNameModel;

  maxDate: Date;
  minDate: Date;

  ngxGalleryOptionsProfilePhoto: NgxGalleryOptions[];
  ngxGalleryImagesProfilePhoto: NgxGalleryImage[];

  defaultProfilePhoto = 'assets/personPlaceholder.100.png';

  constructor(@Inject('ImageServiceInjected') private imageService: IImageService) {
    this.formNames = editThreatFormNames;

    this.maxDate = new Date();
    this.minDate = new Date(1900, 0, 1);

    this.ngxGalleryOptionsProfilePhoto = [
      {
        width: '150px',
        height: '150px',
        thumbnails: false,
        imageSize: 'contain',
        imageArrows: false,
        thumbnailsArrows: false
      }
    ];
    this.ngxGalleryImagesProfilePhoto = [];
  }

  ngOnChanges() {
    this.ngxGalleryImagesProfilePhoto = []; // reset the photo to empty

    if (
      this.basicInfoFormFormGroup &&
      this.basicInfoFormFormGroup.get(editThreatFormNames.profilePicture) &&
      this.basicInfoFormFormGroup.get(editThreatFormNames.profilePicture).value
    ) {
      this.imageService.getImage(this.basicInfoFormFormGroup.get(editThreatFormNames.profilePicture).value).then((getImageResponse) => {
        this.ngxGalleryImagesProfilePhoto.push(getImageResponse);
      });
    } else {
      this.ngxGalleryImagesProfilePhoto.push({
        small: this.defaultProfilePhoto,
        medium: this.defaultProfilePhoto,
        big: this.defaultProfilePhoto
      });
    }
  }

  displayProfileType(): ProfileTypeModel {
    let forDisplay: ProfileTypeModel = {
      type: 'Unknown',
      display: 'Unknown',
      icon: '',
      class: '',
      classBackgroundOnly: '',
      classForegroundOnly: ''
    };

    if (this.profileTypeFormControl && this.profileTypeFormControl.value) {
      const profileType = this.profileTypeFormControl.value;
      const found = _.find(this.profileTypes, { type: profileType.type });
      if (found) {
        forDisplay = found;
      }
    }
    return forDisplay;
  }

  selectCompareProfileTypes(pt1: ProfileTypeModel, pt2: ProfileTypeModel): boolean {
    return pt1.type === pt2.type;
  }

  displayFieldError(key, value): string {
    const display = value;
    return display;
  }

  onProfileTypeChange(fg) {
    fg.markAllAsTouched(); // marked all form controls as touched
    fg.updateValueAndValidity(); // force validation for form group
    Object.keys(fg.controls).forEach((field) => {
      fg.get(field).markAsDirty(); // without marking dirty, ui was not showing errors
      fg.get(field).updateValueAndValidity(); // force validation for form control(s)
    });
  }
}

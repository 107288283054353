import { Component, Input } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { MyErrorStateMatcher, editThreatFormNames } from '../utilities';
import { ContactLocationModel, EditThreatFormNameModel } from '../../../shared';

@Component({
  selector: 'app-tlm-edit-threat-contact-history-form',
  templateUrl: './contact-history-form.component.html'
})
export class EditThreatContactHistoryFormComponent {
  @Input() profileTypeFormControl: UntypedFormControl;
  @Input() contactHistoryFormFormGroup: UntypedFormGroup = new UntypedFormGroup({});
  @Input() matAppearance: string;
  @Input() errorMatcher: MyErrorStateMatcher;
  @Input() locations: ContactLocationModel[];
  formNames: EditThreatFormNameModel = editThreatFormNames;
}

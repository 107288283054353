<div [formGroup]="videoFormGroup">
  <div class="row">
    <div class="col-5 col-md-5">
      <mat-form-field [appearance]="matAppearance">
        <mat-label>Video Title</mat-label>
        <input [id]="'videoTitle' + index" matInput [formControlName]="formNames.videoTitle" [errorStateMatcher]="errorMatcher" trim="blur" />
        <mat-error *ngFor="let item of videoFormGroup.get(formNames.videoTitle).errors | keyvalue">
          {{ item.value }}
        </mat-error>
      </mat-form-field>
    </div>
    <div class="col-5 col-md-5">
      <mat-form-field [appearance]="matAppearance">
        <mat-label>Video Link</mat-label>
        <input [id]="'videoLink' + index" matInput [formControlName]="formNames.videoLink" [errorStateMatcher]="errorMatcher" trim="blur" />
        <mat-error *ngFor="let item of videoFormGroup.get(formNames.videoLink).errors | keyvalue">
          {{ item.value }}
        </mat-error>
      </mat-form-field>
    </div>
    <div class="col-1 my-auto">
      <div class="row">
        <div class="col-12">
          <button mat-icon-button color="accent" id="moveVideoUp" (click)="moveUpOnClick(index)" [disabled]="!moveUpActionEnabled()">
            <mat-icon class="fa fa-chevron-up"></mat-icon>
          </button>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <button mat-icon-button color="accent" id="moveVideoDown" (click)="moveDownOnClick(index)" [disabled]="!moveDownActionEnabled()">
            <mat-icon class="fa fa-chevron-down"></mat-icon>
          </button>
        </div>
      </div>
    </div>
    <div class="col-1 my-auto">
      <button mat-icon-button color="warn" [id]="'removeVideo' + index" (click)="removeOnClick(index)" [disabled]="!removeActionEnabled()">
        <mat-icon class="fa fa-close fa-lg"></mat-icon>
      </button>
    </div>
  </div>
</div>

import { Injectable, Inject } from '@angular/core';
import { ILogger } from '../logger';
import { IImageService } from './image.interface';

@Injectable()
export class ImageMockService implements IImageService {
  data = new Array<any>();

  constructor(@Inject('LoggerInjected') private logger: ILogger) {}

  uploadImage(tlmId: string, imageFile: any): Promise<any> {
    return new Promise((resolve, reject) => {
      const item = { tlmId, imageFile };
      this.data.push(item);
      return resolve(item);
    });
  }

  getImage(key: string): Promise<any> {
    return new Promise((resolve, reject) =>
      resolve({
        small: 'assets/personPlaceholder.100.png',
        medium: 'assets/personPlaceholder.200.png',
        big: 'assets/personPlaceholder.800.png'
      })
    );
  }
}

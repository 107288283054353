export class EditThreatFormNameModel {
  // Address Form
  addressList: string;
  addressStreet: string;
  addressCity: string;
  addressState: string;
  addressZip: string;
  addressFrom: string;
  addressTo: string;
  addressCurrent: string;

  // Background Info Form
  backgroundInfo: string;
  physicalDescription: string;
  criminalHistory: string;
  gracieNumberList: string;
  gracieNumber: string;

  // Basic Info Form
  profilePicture: string;
  rockSecurityId: string;
  firstName: string;
  lastName: string;
  dob: string;
  gender: string;
  race: string;
  aliasList: string;
  alias: string;
  emailList: string;
  email: string;
  phoneList: string;
  phoneNumber: string;
  phoneType: string;
  vehicleList: string;
  vehicleYear: string;
  vehicleMakeModel: string;
  vehicleLicensePlate: string;
  vehicleColor: string;
  vehicleState: string;

  // BOLO Form
  boloList: string;
  boloStartDate: string;
  boloEndDate: string;
  boloReason: string;

  // Contact History Form
  contactList: string;
  contactDate: string;
  contactHour: string;
  contactMinute: string;
  contactMeridiem: string;
  contactLocation: string;
  contactNote: string;

  // Photo Form
  photoList: string;
  photo: string;

  // Identification Form
  identificationList: string;
  identificationType: string;
  identificationState: string;
  identificationNumber: string;
  identificationExpirationDate: string;

  // Social Media Form
  socialMediaList: string;
  socialMediaValue: string;
  socialMediaType: string;

  // Video Form
  videoList: string;
  videoTitle: string;
  videoLink: string;
  videoModifiedDate: string;
}

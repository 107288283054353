import { animate, state, style, transition, trigger } from '@angular/animations';
import { formatDate } from '@angular/common';
import { AfterViewInit, Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ErrorStateMatcher } from '@angular/material/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import * as _ from 'lodash';
import { DateTime } from 'luxon';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subject, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { IAuthService } from '../../auth';
import { ITlmSearchService } from '../../core';
import { MediaService } from '../../core/services/media-service/media-service';
import { ProfileTypeModel, TlmModel, TlmModelFactory } from '../../shared';
import { SearchModel } from '../../shared/models/search.model';
import { MyErrorStateMatcher } from '../edit-threat/utilities/edit-threat.error-matcher';
import { DateRangeModalComponent } from './date-range-modal/date-range-modal.component';

@Component({
  selector: 'app-tlm-home',
  templateUrl: './home.component.html',
  styleUrls: ['home.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0', display: 'none' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)'))
    ])
  ]
})
export class HomeComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild(MatSort) sort: MatSort;

  tlmsDataSource = new MatTableDataSource();
  mobileTlmDataSource = [];

  columnsToDisplay = ['tlmId', 'profileStatus', 'displayName', 'matches', 'contactDate'];
  expandedElement;

  errorMatcher: ErrorStateMatcher;

  tlms$: Subscription;
  tlms: Array<TlmModel>;
  noData = true;
  firstSearch = true;
  dataToggle = true;

  showTips = false;

  dateRange = 'Select Date Range';

  searchObject = new SearchModel();
  isSearchBar = true;

  searchTerm$ = new Subject<string>();
  locationSearchTerm$ = new Subject<string>();
  gracieSearchTerm$ = new Subject<string>();

  renderAsMobile$: Subscription;
  renderAsMobile: boolean;

  profileTypes: ProfileTypeModel[];
  debug = false;

  constructor(
    private titleService: Title,
    @Inject('TlmSearchServiceInjected')
    private tlmSearchService: ITlmSearchService,
    private router: Router,
    private spinner: NgxSpinnerService,
    private dialog: MatDialog,
    private mediaService: MediaService,
    private route: ActivatedRoute,
    @Inject('TlmAuthService') private authService: IAuthService
  ) {
    this.errorMatcher = new MyErrorStateMatcher();
  }

  ngAfterViewInit() {
    this.tlmsDataSource.sort = this.sort;
  }

  ngOnInit(): void {
    this.titleService.setTitle('TLM - Search');
    this.isSearchBar = true;

    this.profileTypes = this.route.snapshot.data.profileTypes;

    this.renderAsMobile$ = this.mediaService.getRenderAsMobileStatus().subscribe((event) => {
      this.renderAsMobile = event;
    });

    if (this.firstSearch) {
      this.spinner.show();
    }

    this.searchTerm$
      .pipe(debounceTime(75))
      .pipe(distinctUntilChanged())
      .subscribe((searchTerm) => {
        this.tlmsDataSource.data = [];

        const searchModel = this.searchObject;

        if (this.isSearchBar === true) {
          searchModel.searchTerm = searchTerm;
        }

        if (searchModel.contactHistoryStartDate !== undefined && searchModel.contactHistoryEndDate === undefined) {
          searchModel.contactHistoryEndDate = new Date();
        }

        this.tlms$ = this.tlmSearchService.search(searchModel).subscribe((data) => {
          this.noData = false;

          this.mobileTlmDataSource = [];
          data = this.separateContactHistories(data);

          // data clean up
          this.tlmsDataSource.data = data.map((item) => {
            const tlm = new TlmModelFactory().create(item);
            const contactHistorySorted = _.sortBy(tlm.contactHistory, ['date']).reverse();
            const newItem = this.mapTlmToSearchObject(tlm, contactHistorySorted);
            const matched = this.mapHighlightsToSearchMatches(tlm['highlight'], contactHistorySorted);
            newItem['matches'] = matched.matches;
            newItem['matchColor'] = matched.matchColor;
            newItem['matchFontWeight'] = matched.matchFontWeight;

            this.mobileTlmDataSource.push(newItem);
            return newItem;
          });

          if (this.tlmsDataSource.data.length === 0) {
            this.noData = true;
            this.tlmsDataSource.data = [
              {
                displayName: 'Searching for "' + searchModel.searchTerm + '" returned 0 results'
              }
            ];
          }

          this.tlmsDataSource.sortingDataAccessor = (dataToSort, sortHeaderId) => dataToSort[sortHeaderId].toLocaleLowerCase();

          if (this.firstSearch) {
            this.firstSearch = false;
            this.spinner.hide();
          }
        });
      });

    // default show all the things
    this.searchTerm$.next('');

    this.route.queryParams.subscribe((params) => {
      this.debug = false;
      if (params.debug && this.authService.hasAdminAccess()) {
        this.debug = params.debug === 'true';
      }
    });
  }

  ngOnDestroy() {
    if (this.tlms$) {
      this.tlms$.unsubscribe();
    }

    this.renderAsMobile$.unsubscribe();
  }

  mapTlmToSearchObject(tlm, contactHistorySorted) {
    const searchObj = {};

    searchObj['hideDate'] = !contactHistorySorted[0];
    searchObj['score'] = tlm['score'];
    searchObj['tlmId'] = tlm.tlmId;
    searchObj['displayName'] = tlm.nameForDisplay;
    searchObj['firstName'] = tlm.person.firstName;
    searchObj['lastName'] = tlm.person.lastName;
    searchObj['aliases'] = tlm.aliasesForDisplay;
    searchObj['emails'] = tlm.emailsForDisplay;
    searchObj['phones'] = tlm.phonesForDisplay;
    searchObj['createDate'] = tlm.created.dateTime;
    searchObj['createDisplayDate'] = DateTime.fromJSDate(new Date(tlm.created.dateTime)).toLocaleString(DateTime.DATETIME_FULL);
    searchObj['contactDate'] = contactHistorySorted[0] ? contactHistorySorted[0].date : 'No Contact Date';
    searchObj['lastContact'] = tlm.lastContact;
    searchObj['gracieNumber'] = tlm.graciesForDisplay;
    searchObj['profileType'] = tlm.profileType;
    searchObj['profileStatus'] = tlm.profileStatus;
    searchObj['rsid'] = tlm.rockSecurityId;

    if (contactHistorySorted.length === 0) {
      searchObj['location'] = 'Unknown';
    } else {
      searchObj['location'] = '';
      contactHistorySorted.forEach((contactHistory) => {
        if (contactHistory && contactHistory.location && contactHistory.location.locationForDisplay) {
          searchObj['location'] = searchObj['location'] + contactHistory.location.locationForDisplay + ', ';
        }
      });
    }

    return searchObj;
  }

  mapHighlightsToSearchMatches(highlight, contactHistory) {
    const matched = {
      matches: '',
      matchColor: 'red',
      matchFontWeight: 'bold'
    };

    if (highlight) {
      if (highlight['person.firstName']) {
        matched.matches = matched.matches + ' FIRST NAME: ' + highlight['person.firstName'] + '. ';
      }

      if (highlight['person.lastName']) {
        matched.matches = matched.matches + ' LAST NAME: ' + highlight['person.lastName'] + '. ';
      }

      if (highlight['aliases.value']) {
        matched.matches = matched.matches + ' ALIAS: ' + highlight['aliases.value'] + '. ';
      }

      if (highlight['emails.value']) {
        matched.matches = matched.matches + ' EMAIL: ' + highlight['emails.value'] + '. ';
      }

      if (highlight['phones.value']) {
        matched.matches = matched.matches + ' PHONE: ' + highlight['phones.value'] + '. ';
      }

      if (highlight['gracieNumbers.value']) {
        matched.matches = matched.matches + ' GRACIE: ' + highlight['gracieNumbers.value'] + '. ';
      }

      if (highlight['contactHistory.location.address']) {
        matched.matches = matched.matches + ' LOCATION ADDRESS: ' + highlight['contactHistory.location.address'] + '. ';
      }

      if (highlight['contactHistory.location.title']) {
        matched.matches = matched.matches + ' LOCATION TITLE: ' + highlight['contactHistory.location.title'] + '. ';
      }

      if (highlight['rockSecurityId']) {
        matched.matches = matched.matches + '  RS ID: ' + highlight['rockSecurityId'] + '. ';
      }
    }

    if (contactHistory && contactHistory[0] && this.isInSearchDateRange(contactHistory[0].date)) {
      matched.matches =
        matched.matches +
        ' CONTACT DATE: ' +
        formatDate(new Date(contactHistory[0].date), 'MM/dd/yyyy h:mm', 'en-US') +
        ' ' +
        contactHistory[0].dayTime;
    }

    return matched;
  }

  selectedRow(row) {
    this.router.navigate(['/profile/', row.tlmId]);
  }

  assignSearchBar(searchBarValue: any) {
    this.searchObject.searchTerm = searchBarValue;
    this.isSearchBar = true;
  }

  assignLocation(location: any) {
    this.searchObject.location = location;
    this.triggerDistinctSearch();
    this.isSearchBar = false;
  }

  assignGracieNumber(gracieNumber: any) {
    this.searchObject.gracieNumber = gracieNumber;
    this.isSearchBar = false;
  }

  routeToProfile(value) {
    this.router.navigate(['/profile/' + value]);
  }

  openDateRangeModal() {
    const dialogRef = this.dialog.open(DateRangeModalComponent, {
      autoFocus: true
    });

    const sub = dialogRef.componentInstance.dateRange.subscribe((data) => {
      this.searchObject.contactHistoryStartDate = data.contactHistoryStartDate;
      this.searchObject.contactHistoryEndDate = data.contactHistoryEndDate;
      this.dateRange =
        this.searchObject.contactHistoryStartDate.toLocaleDateString() + ' - ' + this.searchObject.contactHistoryEndDate.toLocaleDateString();
    });

    dialogRef.afterClosed().subscribe(() => {
      this.isSearchBar = false;

      this.triggerDistinctSearch();
      sub.unsubscribe();
    });
  }

  clearDateRange() {
    this.searchObject.contactHistoryStartDate = undefined;
    this.searchObject.contactHistoryEndDate = undefined;
    this.dateRange = 'Select Date Range';
    this.isSearchBar = false;
    this.searchTerm$.next('clearedDate');
  }

  separateContactHistories(data: TlmModel[]) {
    const parsedData: TlmModel[] = new Array<TlmModel>();

    for (const tlm of data) {
      // assigns most recent date to last contact
      if (tlm.contactHistory.length > 0) {
        const orderedDates = _.sortBy(tlm.contactHistory, 'date');
        tlm.lastContact = orderedDates[orderedDates.length - 1];
      }

      if (
        !tlm.contactHistory ||
        tlm.contactHistory.length <= 1 ||
        !this.searchObject.contactHistoryStartDate ||
        !this.searchObject.contactHistoryEndDate
      ) {
        parsedData.push(tlm);
      } else {
        for (const contactHistory of tlm.contactHistory) {
          const duplicateTlm = new TlmModel();
          Object.assign(duplicateTlm, tlm);
          duplicateTlm.contactHistory = [contactHistory];

          // only create rows for contact histories in our search range
          if (
            this.isInSearchDateRange(duplicateTlm.contactHistory[0].date) &&
            this.locationCheck(duplicateTlm.contactHistory[0].location.locationForDisplay)
          ) {
            parsedData.push(duplicateTlm);
          }
        }
      }
    }
    return parsedData;
  }

  isInSearchDateRange(date: any) {
    if (this.searchObject.contactHistoryStartDate && this.searchObject.contactHistoryEndDate) {
      if (typeof date === 'string') {
        date = new Date(date);
      }

      if (date >= this.searchObject.contactHistoryStartDate && date <= this.searchObject.contactHistoryEndDate) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  locationCheck(location) {
    // if searching location, it must be a substring of the passed location
    if (this.searchObject.location) {
      if (location.toLocaleLowerCase().indexOf(this.searchObject.location.toLocaleLowerCase()) > -1) {
        return true;
      } else {
        return false;
      }
    } else {
      return true;
    }
  }

  formatLocationForDisplay(location: string) {
    if (location.length > 30) {
      location = location.substring(0, 27) + '...';
    }
    return location;
  }

  triggerDistinctSearch() {
    // date toggle is used to send a different value between entries to the search subscription to signify a distinct change
    if (this.dataToggle === true) {
      this.searchTerm$.next('toggleTrue');
      this.dataToggle = false;
    } else {
      this.searchTerm$.next('toggleFalse');
      this.dataToggle = true;
    }
  }
}

<div class="container-fullwidth">
  <app-tlm-layout-header-edit-person></app-tlm-layout-header-edit-person>
</div>
<br />
<div class="container" *ngIf="tlm.tlmId">
  <div class="row">
    <div class="col-3" *ngIf="!renderAsMobile">
      <div id="editThreatNavMenu" class="tlm-sub-menu">
        <app-tlm-add-person-sub-menu ulClass="nav flex-column"></app-tlm-add-person-sub-menu>
      </div>
    </div>
    <div class="col-md-9 col-sm-12">
      <form novalidate [formGroup]="tlmFormGroup">
        <app-tlm-edit-threat-basic-info
          id="addPersonBasicInfoForm"
          [hidden]="hideSubForm('addPersonBasicInfoForm')"
          [matAppearance]="matAppearance"
          [errorMatcher]="errorMatcher"
          [profileTypeFormControl]="profileTypeFormControl"
          [tlm]="tlm"
          [genders]="genders"
          [races]="races"
          [phoneTypes]="phoneTypes"
          [profileTypes]="profileTypes"
          [states]="states"
          [basicInfoFormFormGroup]="basicInfoFormFormGroup"
        >
        </app-tlm-edit-threat-basic-info>
        <app-tlm-edit-threat-identification-form
          id="addPersonIdentificationsForm"
          [hidden]="hideSubForm('addPersonIdentificationsForm')"
          [matAppearance]="matAppearance"
          [errorMatcher]="errorMatcher"
          [states]="states"
          [identificationTypes]="identificationTypes"
          [identificationsFormFormGroup]="identificationsFormFormGroup"
        >
        </app-tlm-edit-threat-identification-form>
        <app-tlm-edit-threat-background-info
          id="addPersonBackgroundForm"
          [hidden]="hideSubForm('addPersonBackgroundForm')"
          [matAppearance]="matAppearance"
          [errorMatcher]="errorMatcher"
          [backgroundInfoFormFormGroup]="backgroundInfoFormFormGroup"
        >
        </app-tlm-edit-threat-background-info>
        <app-tlm-edit-threat-addresses-form
          id="addPersonAddressForm"
          [hidden]="hideSubForm('addPersonAddressForm')"
          [matAppearance]="matAppearance"
          [errorMatcher]="errorMatcher"
          [states]="states"
          [addressesFormFormGroup]="addressesFormFormGroup"
        >
        </app-tlm-edit-threat-addresses-form>
        <app-tlm-edit-threat-social
          id="addPersonSocialForm"
          [socialMediaTypes]="socialMediaTypes"
          [matAppearance]="matAppearance"
          [errorMatcher]="errorMatcher"
          [socialMediaFormFormGroup]="socialMediaFormFormGroup"
          [hidden]="hideSubForm('addPersonSocialForm')"
        >
        </app-tlm-edit-threat-social>
        <app-tlm-edit-threat-photos id="addPersonPhotosForm" [hidden]="hideSubForm('addPersonPhotosForm')" [photosFormGroup]="photosFormGroup">
        </app-tlm-edit-threat-photos>
        <app-tlm-edit-threat-videos-form
          id="addPersonVideosForm"
          [matAppearance]="matAppearance"
          [errorMatcher]="errorMatcher"
          [videosFormFormGroup]="videosFormFormGroup"
          [hidden]="hideSubForm('addPersonVideosForm')"
        >
        </app-tlm-edit-threat-videos-form>
        <app-tlm-edit-threat-contact-history-form
          id="addPersonContactHistoryForm"
          [locations]="locations"
          [hidden]="hideSubForm('addPersonContactHistoryForm')"
          [matAppearance]="matAppearance"
          [errorMatcher]="errorMatcher"
          [profileTypeFormControl]="profileTypeFormControl"
          [contactHistoryFormFormGroup]="contactHistoryFormFormGroup"
        >
        </app-tlm-edit-threat-contact-history-form>
        <app-tlm-edit-threat-bolos-form
          id="addPersonBoloForm"
          [hidden]="hideSubForm('addPersonBoloForm')"
          [matAppearance]="matAppearance"
          [errorMatcher]="errorMatcher"
          [profileTypeFormControl]="profileTypeFormControl"
          [bolosFormFormGroup]="bolosFormFormGroup"
        >
        </app-tlm-edit-threat-bolos-form>
      </form>
    </div>
  </div>
</div>

import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { HeaderComponent } from '../header.component';
import { UntypedFormGroup, UntypedFormBuilder, UntypedFormControl } from '@angular/forms';
import { EditThreatUtilitiesBasicInfoForm, editThreatFormNames } from '../../../../pages/edit-threat/utilities';

@Component({
  selector: 'app-tlm-add-threat-modal',
  templateUrl: 'add-threat-modal.component.html',
  styleUrls: ['add-threat-modal.component.scss']
})
export class AddThreatModalComponent {
  addThreatForm: UntypedFormGroup;
  profileTypeFormControl: UntypedFormControl;
  formNames: any;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: UntypedFormBuilder,
    public dialogRef: MatDialogRef<HeaderComponent>,
    private editThreatUtilsBasicInfo: EditThreatUtilitiesBasicInfoForm
  ) {
    this.profileTypeFormControl = new UntypedFormControl(data.profileType);
    this.formNames = editThreatFormNames;

    const firstNameFC = new UntypedFormControl();
    const lastNameFC = new UntypedFormControl();
    const aliasFC = new UntypedFormControl();

    this.addThreatForm = this.formBuilder.group(
      {
        [editThreatFormNames.firstName]: firstNameFC,
        [editThreatFormNames.lastName]: lastNameFC,
        [editThreatFormNames.alias]: aliasFC
      },
      {
        validator: this.editThreatUtilsBasicInfo.nameOrAliasRequiredIfThreat.bind(this, this.profileTypeFormControl, firstNameFC, lastNameFC, aliasFC)
      }
    );
  }

  validateForm(fg) {
    fg.markAllAsTouched(); // marked all form controls as touched
    fg.updateValueAndValidity(); // force validation for form group
    Object.keys(fg.controls).forEach((field) => {
      fg.get(field).markAsDirty(); // without marking dirty, ui was not showing errors
      fg.get(field).updateValueAndValidity(); // force validation for form control(s)
    });
  }

  onClickSubmit(form: UntypedFormGroup) {
    this.validateForm(form);

    if (form.valid) {
      this.dialogRef.close({
        profileType: this.profileTypeFormControl.value,
        addThreatForm: this.addThreatForm
      });
    }
  }
}

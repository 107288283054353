import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { HeaderComponent } from '../header.component';

@Component({
  selector: 'app-tlm-add-profile-selection-modal',
  templateUrl: 'add-profile-selection-modal.component.html',
  styleUrls: ['add-profile-selection-modal.component.scss']
})
export class AddProfileSelectionModalComponent {
  constructor(public dialogRef: MatDialogRef<HeaderComponent>) {}

  closeDialog(profileTypeSelected: string) {
    this.dialogRef.close({
      profileTypeSelected
    });
  }

  onThreatSelected() {
    this.closeDialog('threat');
  }

  onBoloSelected() {
    this.closeDialog('bolo');
  }
}

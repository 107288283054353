<div>
  <div class="row">
    <div class="col-12">
      <span class="tlm-header">Photos</span>
    </div>
  </div>
  <mat-divider></mat-divider>
  <br />
  <div>
    <form class="needs-validation" novalidate [formGroup]="photosFormGroup">
      <div class="row">
        <div class="col-12">
          <span class="tlm-sub-header align-bottom">Photos</span>
          <mat-button-toggle-group name="fontStyle" class="pull-right">
            <button mat-button color="primary" (click)="onFavorite()"><span class="fa fa-star"></span> Primary</button>
            <button mat-button color="warn" (click)="onRemove()"><span class="fa fa-minus"></span> Remove</button>
            <button mat-button color="accent" (click)="onAdd()"><span class="fa fa-plus"></span> Add</button>
          </mat-button-toggle-group>
          <input
            type="file"
            #addImage
            style="display: none"
            (change)="onAddImage()"
            multiple
            accept="image/jpeg, image/png, image/tiff, image/bmp, image/gif"
          />
          <div *ngIf="photosFormGroup.errors && ngxGalleryImages.length === 0">
            <mat-error *ngFor="let item of photosFormGroup.errors | keyvalue">
              {{ item.value }}
            </mat-error>
          </div>
        </div>
      </div>
      <br />
      <div class="row">
        <div class="col-12">
          <div *ngIf="ngxGalleryImages.length > 0">
            <ngx-gallery id="photoGallery" #ngxGallery [options]="ngxGalleryOptions" [images]="ngxGalleryImages"></ngx-gallery>
          </div>
          <div *ngIf="ngxGalleryImages.length === 0">
            <span id="photoGalleryIsEmpty">Use "Add" to add photos</span>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>

import { Injectable } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, UntypedFormArray, UntypedFormBuilder, Validators } from '@angular/forms';
import * as _ from 'lodash';
import * as sjv from 'simple-js-validator';
import { TlmModel, AliasModel, PhoneModel, VehicleModel, EmailModel, PersonModel, StateModel } from '../../../shared';
import { EditThreatUtilities } from './edit-threat-utilities.service';
import { editThreatFormNames } from './edit-threat-form-names';

@Injectable()
export class EditThreatUtilitiesBasicInfoForm {
  constructor(private fb: UntypedFormBuilder, private editThreatUtils: EditThreatUtilities) {}

  createEmptyAliasItemFormGroup(): UntypedFormGroup {
    return this.fb.group({
      [editThreatFormNames.alias]: []
    });
  }

  createEmptyEmailItemFormGroup(): UntypedFormGroup {
    return this.fb.group({
      [editThreatFormNames.email]: [null, Validators.pattern('^.+@.+\\..+')]
    });
  }

  createEmptyPhoneItemFormGroup(): UntypedFormGroup {
    return this.fb.group(
      {
        [editThreatFormNames.phoneNumber]: [null, this.phoneNumberValid],
        [editThreatFormNames.phoneType]: []
      },
      {
        validator: this.phoneBothRequiredIfPopulated.bind(this)
      }
    );
  }

  createEmptyVehicleItemFormGroup(): UntypedFormGroup {
    return this.fb.group({
      [editThreatFormNames.vehicleYear]: [],
      [editThreatFormNames.vehicleMakeModel]: [],
      [editThreatFormNames.vehicleLicensePlate]: [],
      [editThreatFormNames.vehicleColor]: [],
      [editThreatFormNames.vehicleState]: []
    });
  }

  createEmptyRockSecurityFormControl(profileTypeFC: UntypedFormControl): UntypedFormControl {
    return new UntypedFormControl(null, this.rockSecurityIdRequiredIfBolo.bind(this, profileTypeFC));
  }

  initializeForm(profileTypeFC: UntypedFormControl): UntypedFormGroup {
    return this.fb.group(
      {
        [editThreatFormNames.profilePicture]: [],
        [editThreatFormNames.firstName]: [],
        [editThreatFormNames.lastName]: [],
        [editThreatFormNames.rockSecurityId]: this.createEmptyRockSecurityFormControl(profileTypeFC),
        [editThreatFormNames.dob]: [],
        [editThreatFormNames.gender]: [],
        [editThreatFormNames.race]: [],
        [editThreatFormNames.aliasList]: this.fb.array([]),
        [editThreatFormNames.emailList]: this.fb.array([]),
        [editThreatFormNames.phoneList]: this.fb.array([]),
        [editThreatFormNames.vehicleList]: this.fb.array([])
      },
      {
        validator: [
          this.nameOrAliasListRequiredIfThreat.bind(this, profileTypeFC),
          this.rsIdNameOrAliasRequiredIfBoloIncomplete.bind(this, profileTypeFC)
        ]
      }
    );
  }

  createAliasFormGroupFromAliasFormControl(aliasFC: UntypedFormControl): UntypedFormGroup {
    const fg = new UntypedFormGroup({
      [editThreatFormNames.aliasList]: new UntypedFormArray([new UntypedFormGroup({ [editThreatFormNames.alias]: aliasFC })])
    });
    return fg;
  }

  mapFromTlm(tlm: TlmModel, states: StateModel[], profileTypeFC: UntypedFormControl): UntypedFormGroup {
    const aliasesFA = this.fb.array([]);
    const emailsFA = this.fb.array([]);
    const phonesFA = this.fb.array([]);
    const vehiclesFA = this.fb.array([]);

    if (tlm && tlm.aliases && tlm.aliases.length > 0) {
      tlm.aliases.forEach((alias) => {
        aliasesFA.push(
          this.fb.group({
            [editThreatFormNames.alias]: [alias.value]
          })
        );
      });
    } else {
      aliasesFA.push(this.createEmptyAliasItemFormGroup());
    }

    if (tlm && tlm.emails && tlm.emails.length > 0) {
      tlm.emails.forEach((email) => {
        emailsFA.push(
          this.fb.group({
            [editThreatFormNames.email]: [email.value, Validators.pattern('^.+@.+\\..+')]
          })
        );
      });
    } else {
      emailsFA.push(this.createEmptyEmailItemFormGroup());
    }

    if (tlm && tlm.vehicles && tlm.vehicles.length > 0) {
      tlm.vehicles.forEach((vehicle) => {
        const stateDisplayName = this.editThreatUtils.convertStateCodeToDisplayName(vehicle.state ? vehicle.state.code : null, states);
        vehiclesFA.push(
          this.fb.group({
            [editThreatFormNames.vehicleYear]: [vehicle.year],
            [editThreatFormNames.vehicleMakeModel]: [vehicle.makeModel],
            [editThreatFormNames.vehicleLicensePlate]: [vehicle.licensePlate],
            [editThreatFormNames.vehicleColor]: [vehicle.color],
            [editThreatFormNames.vehicleState]: [stateDisplayName]
          })
        );
      });
    } else {
      vehiclesFA.push(this.createEmptyVehicleItemFormGroup());
    }

    if (tlm && tlm.phones && tlm.phones.length > 0) {
      tlm.phones.forEach((phone) => {
        phonesFA.push(
          this.fb.group(
            {
              [editThreatFormNames.phoneNumber]: [phone.value, this.phoneNumberValid],
              [editThreatFormNames.phoneType]: [phone.type]
            },
            {
              validator: this.phoneBothRequiredIfPopulated.bind(this)
            }
          )
        );
      });
    } else {
      phonesFA.push(this.createEmptyPhoneItemFormGroup());
    }

    return this.fb.group(
      {
        [editThreatFormNames.profilePicture]: [tlm && tlm.photos && tlm.photos.length > 0 ? tlm.photos[0].key : undefined],
        [editThreatFormNames.firstName]: [tlm && tlm.person && tlm.person.firstName ? tlm.person.firstName : undefined],
        [editThreatFormNames.lastName]: [tlm && tlm.person && tlm.person.lastName ? tlm.person.lastName : undefined],
        [editThreatFormNames.rockSecurityId]: [
          tlm && tlm.rockSecurityId ? tlm.rockSecurityId : undefined,
          this.rockSecurityIdRequiredIfBolo.bind(this, profileTypeFC)
        ],
        [editThreatFormNames.dob]: [tlm && tlm.person && tlm.person.dateOfBirth ? tlm.person.dateOfBirth : undefined],
        [editThreatFormNames.gender]: [tlm && tlm.person && tlm.person.gender],
        [editThreatFormNames.race]: [tlm && tlm.person && tlm.person.race],
        [editThreatFormNames.aliasList]: aliasesFA,
        [editThreatFormNames.emailList]: emailsFA,
        [editThreatFormNames.phoneList]: phonesFA,
        [editThreatFormNames.vehicleList]: vehiclesFA
      },
      {
        validator: [
          this.nameOrAliasListRequiredIfThreat.bind(this, profileTypeFC),
          this.rsIdNameOrAliasRequiredIfBoloIncomplete.bind(this, profileTypeFC)
        ]
      }
    );
  }

  mapToTlmRockSecurityId(fg: UntypedFormGroup): string {
    return this.editThreatUtils.getValueOrSetAsUndefined(fg.get(editThreatFormNames.rockSecurityId));
  }

  mapToTlmPerson(fg: UntypedFormGroup): PersonModel {
    let mapped = new PersonModel();

    const firstName = this.editThreatUtils.getValueOrSetAsUndefined(fg.get(editThreatFormNames.firstName));
    const lastName = this.editThreatUtils.getValueOrSetAsUndefined(fg.get(editThreatFormNames.lastName));
    const dateOfBirth = this.editThreatUtils.getValueOrSetAsUndefined(fg.get(editThreatFormNames.dob));
    const gender = this.editThreatUtils.getValueOrSetAsUndefined(fg.get(editThreatFormNames.gender));
    const race = this.editThreatUtils.getValueOrSetAsUndefined(fg.get(editThreatFormNames.race));

    if (sjv.isNotEmpty(firstName) || sjv.isNotEmpty(lastName) || sjv.isNotEmpty(dateOfBirth) || sjv.isNotEmpty(gender) || sjv.isNotEmpty(race)) {
      mapped = new PersonModel(firstName, lastName, dateOfBirth, gender, race);
    }

    return mapped;
  }

  mapToTlmAliases(fg: UntypedFormGroup): AliasModel[] {
    const mapped = new Array<AliasModel>();
    let order = 0;
    const fa = fg.get(editThreatFormNames.aliasList) as UntypedFormArray;

    fa.controls.forEach((itemFG) => {
      const aliasName = this.editThreatUtils.getValueOrSetAsUndefined(itemFG.get(editThreatFormNames.alias));

      if (sjv.isNotEmpty(aliasName)) {
        mapped.push(new AliasModel(order, aliasName));
        order++;
      }
    });

    return mapped;
  }

  mapToTlmEmails(fg: UntypedFormGroup): EmailModel[] {
    const mapped = new Array<EmailModel>();
    let order = 0;
    const fa = fg.get(editThreatFormNames.emailList) as UntypedFormArray;

    fa.controls.forEach((itemFG) => {
      const emailAddress = this.editThreatUtils.getValueOrSetAsUndefined(itemFG.get(editThreatFormNames.email));

      if (sjv.isNotEmpty(emailAddress)) {
        mapped.push(new EmailModel(order, emailAddress));
        order++;
      }
    });

    return mapped;
  }

  mapToTlmPhones(fg: UntypedFormGroup): PhoneModel[] {
    const mapped = new Array<PhoneModel>();
    let order = 0;
    const fa = fg.get(editThreatFormNames.phoneList) as UntypedFormArray;

    fa.controls.forEach((itemFG) => {
      const phoneNumber = this.editThreatUtils.getValueOrSetAsUndefined(itemFG.get(editThreatFormNames.phoneNumber));
      const phoneType = this.editThreatUtils.getValueOrSetAsUndefined(itemFG.get(editThreatFormNames.phoneType));

      if (sjv.isNotEmpty(phoneNumber) || sjv.isNotEmpty(phoneType)) {
        mapped.push(new PhoneModel(order, phoneNumber, phoneType));
        order++;
      }
    });

    return mapped;
  }

  mapToTlmVehicles(fg: UntypedFormGroup, states: StateModel[]): VehicleModel[] {
    const mapped = new Array<VehicleModel>();
    let order = 0;
    const fa = fg.get(editThreatFormNames.vehicleList) as UntypedFormArray;

    fa.controls.forEach((itemFG) => {
      const year = this.editThreatUtils.getValueOrSetAsUndefined(itemFG.get(editThreatFormNames.vehicleYear));
      const makeModel = this.editThreatUtils.getValueOrSetAsUndefined(itemFG.get(editThreatFormNames.vehicleMakeModel));
      const color = this.editThreatUtils.getValueOrSetAsUndefined(itemFG.get(editThreatFormNames.vehicleColor));
      const licensePlate = this.editThreatUtils.getValueOrSetAsUndefined(itemFG.get(editThreatFormNames.vehicleLicensePlate));
      const state = this.editThreatUtils.getValueOrSetAsUndefined(itemFG.get(editThreatFormNames.vehicleState));

      if (sjv.isNotEmpty(year) || sjv.isNotEmpty(makeModel) || sjv.isNotEmpty(color) || sjv.isNotEmpty(licensePlate) || sjv.isNotEmpty(state)) {
        const stateCode = this.editThreatUtils.convertStateNameToCode(state, states);

        mapped.push(new VehicleModel(order, year, makeModel, color, licensePlate, new StateModel(state, stateCode)));
        order++;
      }
    });

    return mapped;
  }

  fieldRequiredIfBolo(profileTypeFC: UntypedFormControl, requiredFC: UntypedFormControl, errorCode: string, errMsg: string) {
    // scenario 1: if bolo and required not touched/submitted; then dont display any error (wait until touched/submitted)
    // scenario 2: if bolo and required touched/submitted and required empty; then display error
    // scenario 3: if bolo and required touched/submitted and required populated; then no error to display
    // scenario 4: if not bolo; then no error to display

    if (_.get(profileTypeFC, 'value.type', '') === 'bolo') {
      if (requiredFC.touched && sjv.isEmpty(requiredFC.value)) {
        // scenario 2: if bolo and touched/submitted and empty; then display error
        this.editThreatUtils.addErrorToFormControl(requiredFC, errorCode, errMsg);
        return { [errorCode]: errMsg };
      } else {
        // scenario 1: if bolo and not touched/submitted; then dont display any error (wait until touched/submitted)
        // scenario 3: if bolo and touched/submitted and populated; then no error to display
        this.editThreatUtils.removeErrorFromFormControl(requiredFC, errorCode);
        return null;
      }
    } else {
      // scenario 4: if not bolo; then no error to display
      this.editThreatUtils.removeErrorFromFormControl(requiredFC, errorCode);
      return null;
    }
  }

  rockSecurityIdRequiredIfBolo(profileTypeFC: UntypedFormControl, rockSecurityIdFC: UntypedFormControl) {
    const errorCode = 'missingRockSecurityId';
    const errMsg = 'Basic Info: [Rock Security Id] required';
    return this.fieldRequiredIfBolo(profileTypeFC, rockSecurityIdFC, errorCode, errMsg);
  }

  addErrorToForm(formControlList: UntypedFormControl[], errorCode: string, errMsg: string) {
    formControlList.forEach((fc) => {
      this.editThreatUtils.addErrorToFormControl(fc, errorCode, errMsg);
    });
  }

  removeErrorFromForm(formControlList: UntypedFormControl[], errorCode: string) {
    formControlList.forEach((fc) => {
      this.editThreatUtils.removeErrorFromFormControl(fc, errorCode);
    });
  }

  nameOrAliasRequiredIfThreat = (profileTypeFC: UntypedFormControl, firstNameFC: UntypedFormControl, lastNameFC: UntypedFormControl, alias0FC: UntypedFormControl) => {
    const errorCode = 'nameOrAliasRequired';
    const errMsg = 'Basic Info: Either Full Name ([First] and [Last Name]) or [Alias] is required';

    // scenario 1: if threat and name/alias not touched/submitted; then dont display any error (wait until touched/submitted)
    // scenario 2: if threat and name/alias touched/submitted and name/alias empty; then display error
    // scenario 3: if threat and name/alias touched/submitted and name/alias populated; then no error to display
    // scenario 4: if not threat; then no error to display

    const formControlList = [firstNameFC, lastNameFC, alias0FC];

    if (_.get(profileTypeFC, 'value.type', '') === 'threat') {
      if (firstNameFC.untouched && lastNameFC.untouched && alias0FC.untouched) {
        // scenario 1: if threat and name/alias not touched/submitted; then dont display any error (wait until touched/submitted)
        this.removeErrorFromForm(formControlList, errorCode);
        return null;
      } else {
        if ((sjv.isNotEmpty(firstNameFC.value) && sjv.isNotEmpty(lastNameFC.value)) || sjv.isNotEmpty(alias0FC.value)) {
          // scenario 3: if threat and name/alias touched/submitted and name/alias populated; then no error to display
          this.removeErrorFromForm(formControlList, errorCode);
          return null;
        } else {
          // scenario 2: if threat and name/alias touched/submitted and name/alias empty; then display error
          this.addErrorToForm(formControlList, errorCode, errMsg);
          return { [errorCode]: errMsg };
        }
      }
    } else {
      // scenario 4: if not bolo; then no error to display
      this.removeErrorFromForm(formControlList, errorCode);
      return null;
    }
  };

  nameOrAliasListRequiredIfThreat = (profileTypeFC: UntypedFormControl, basicInfoFG: UntypedFormGroup) => {
    const firstNameFC = basicInfoFG.get(editThreatFormNames.firstName) as UntypedFormControl;
    const lastNameFC = basicInfoFG.get(editThreatFormNames.lastName) as UntypedFormControl;
    const aliasesFA = basicInfoFG.get(editThreatFormNames.aliasList) as UntypedFormArray;
    let alias0FC = new UntypedFormControl();
    if (aliasesFA && aliasesFA.controls.length > 0) {
      alias0FC = aliasesFA.controls[0].get(editThreatFormNames.alias) as UntypedFormControl;
    }

    return this.nameOrAliasRequiredIfThreat(profileTypeFC, firstNameFC, lastNameFC, alias0FC);
  };

  rsIdNameOrAliasRequiredIfBoloIncomplete = (profileTypeFC: UntypedFormControl, basicInfoFG: UntypedFormGroup) => {
    const firstNameFC = basicInfoFG.get(editThreatFormNames.firstName) as UntypedFormControl;
    const lastNameFC = basicInfoFG.get(editThreatFormNames.lastName) as UntypedFormControl;
    const rockSecurityIdFC = basicInfoFG.get(editThreatFormNames.rockSecurityId) as UntypedFormControl;
    const aliasesFA = basicInfoFG.get(editThreatFormNames.aliasList) as UntypedFormArray;
    let alias0FC = new UntypedFormControl();
    if (aliasesFA.length > 0) {
      alias0FC = aliasesFA.controls[0].get(editThreatFormNames.alias) as UntypedFormControl;
    }

    const errorCode = 'rsIdNameOrAliasRequired';
    const errMsg = 'Basic Info: Either Full Name ([First] and [Last Name]), [Rock Security Id], or [Alias] is required';

    // scenario 1: if boloIncomplete and rsId/name/alias not touched/submitted; then dont display any error (wait until touched/submitted)
    // scenario 2: if boloIncomplete and rsId/name/alias touched/submitted and rsId/name/alias empty; then display error
    // scenario 3: if boloIncomplete and rsId/name/alias touched/submitted and rsId/name/alias populated; then no error to display
    // scenario 4: if not boloIncomplete; then no error to display

    const formControlList = [rockSecurityIdFC, firstNameFC, lastNameFC, alias0FC];

    if (_.get(profileTypeFC, 'value.type', '') === 'boloIncomplete') {
      if (rockSecurityIdFC.untouched && firstNameFC.untouched && lastNameFC.untouched && alias0FC.untouched) {
        // scenario 1: if boloIncomplete and name/rsId/alias not touched/submitted; then dont display any error (wait until touched/submitted)
        this.removeErrorFromForm(formControlList, errorCode);
        return null;
      } else {
        if (
          sjv.isNotEmpty(rockSecurityIdFC.value) ||
          (sjv.isNotEmpty(firstNameFC.value) && sjv.isNotEmpty(lastNameFC.value)) ||
          sjv.isNotEmpty(alias0FC.value)
        ) {
          // scenario 3: if boloIncomplete and rsId/name/alias touched/submitted and rsId/name/alias populated; then no error to display
          this.removeErrorFromForm(formControlList, errorCode);
          return null;
        } else {
          // scenario 2: if boloIncomplete and rsId/name/alias touched/submitted and rsId/name/alias empty; then display error
          this.addErrorToForm(formControlList, errorCode, errMsg);
          return { [errorCode]: errMsg };
        }
      }
    } else {
      // scenario 4: if not boloIncomplete; then no error to display
      this.removeErrorFromForm(formControlList, errorCode);
      return null;
    }
  };
  phoneBothRequiredIfPopulated = (group: UntypedFormGroup) => {
    const phoneNumberFC = group.get(editThreatFormNames.phoneNumber) as UntypedFormControl;
    const phoneTypeFC = group.get(editThreatFormNames.phoneType) as UntypedFormControl;

    if (phoneNumberFC.untouched && phoneNumberFC.pristine && phoneTypeFC.untouched && phoneTypeFC.pristine) {
      return null;
    }

    if (
      (sjv.isEmpty(phoneNumberFC.value) && sjv.isEmpty(phoneTypeFC.value)) ||
      (sjv.isNotEmpty(phoneNumberFC.value) && sjv.isNotEmpty(phoneTypeFC.value))
    ) {
      this.removeErrorFromForm([phoneNumberFC, phoneTypeFC], 'bothRequiredIfPopulated');
      return null;
    } else {
      const errMsg = 'Both [Phone Number] and [Phone Type] required if either is populated';
      this.addErrorToForm([phoneNumberFC, phoneTypeFC], 'bothRequiredIfPopulated', errMsg);
      return {
        bothRequiredIfPopulated: 'Both phone number and phone type required if either is populated'
      };
    }
  };

  phoneNumberValid = (control: UntypedFormControl) => {
    const phoneNumber = control.value;

    if (control.untouched && control.pristine) {
      return null;
    }

    if (sjv.isEmpty(phoneNumber)) {
      return null;
    } else {
      const isNum = /^\d+$/.test(phoneNumber);
      if (!isNum) {
        return { phoneNumberValid: 'Phone number must contain numbers only' };
      } else {
        if (phoneNumber.length !== 10) {
          return { phoneNumberValid: 'Phone number must be 10 digits' };
        } else {
          return null;
        }
      }
    }
  };
}

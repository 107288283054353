import { StateModel } from './state.model';

export class VehicleModel {
  orderId: number;
  year: string;
  makeModel: string;
  licensePlate: string;
  color: string;
  state: StateModel;

  // eslint-disable-next-line max-len
  constructor(orderId?: number, year?: string, makeModel?: string, color?: string, licensePlate?: string, state?: StateModel) {
    this.orderId = orderId;
    this.year = year;
    this.makeModel = makeModel;
    this.licensePlate = licensePlate;
    this.color = color;
    this.state = state;
  }
}

import {
  TlmModel,
  AddressModel,
  AliasModel,
  EmailModel,
  GracieModel,
  PersonModel,
  PhoneModel,
  PhotoModel,
  VideoModel,
  VehicleModel,
  IdentificationModel,
  BoloModel,
  ContactHistoryModel,
  ContactLocationModel,
  SocialMediaModel
} from '../models';
import * as _ from 'lodash';

export class TlmModelFactory {
  public create(tlm: TlmModel): TlmModel {
    tlm = Object.assign(new TlmModel(), tlm);
    tlm.addresses = _.map(tlm.addresses, (item) => Object.assign(new AddressModel(), item));
    tlm.aliases = _.map(tlm.aliases, (item) => Object.assign(new AliasModel(), item));
    tlm.emails = _.map(tlm.emails, (item) => Object.assign(new EmailModel(), item));
    tlm.identifications = _.map(tlm.identifications, (item) => Object.assign(new IdentificationModel(), item));
    tlm.person = Object.assign(new PersonModel(), tlm.person);
    tlm.phones = _.map(tlm.phones, (item) => Object.assign(new PhoneModel(), item));
    tlm.socialMedias = _.map(tlm.socialMedias, (item) => Object.assign(new SocialMediaModel(), item));
    tlm.videos = _.map(tlm.videos, (item) => Object.assign(new VideoModel(), item));
    tlm.photos = _.map(tlm.photos, (item) => Object.assign(new PhotoModel(), item));
    tlm.vehicles = _.map(tlm.vehicles, (item) => Object.assign(new VehicleModel(), item));
    tlm.bolos = _.map(tlm.bolos, (item) => Object.assign(new BoloModel(), item));
    tlm.contactHistory = _.map(tlm.contactHistory, (item) => {
      item = Object.assign(new ContactHistoryModel(), item);
      item.location = Object.assign(new ContactLocationModel(), item.location);
      return item;
    });
    tlm.gracieNumbers = _.map(tlm.gracieNumbers, (item) => Object.assign(new GracieModel(), item));
    return tlm;
  }
}

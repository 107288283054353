<div>
  <div class="row">
    <div class="col-12" style="text-align: right; margin-top: -35px">
      <button type="button" id="addVideo" class="btn btn-link tlm-link pull-right" (click)="createAndAddItemToListIfPopulated()">
        + Add Video Link
      </button>
    </div>
  </div>
  <mat-divider></mat-divider>
  <br />
  <div *ngFor="let videoFormGroup of videoFormArray.controls; let i = index; let isLastRow = last">
    <div class="row">
      <div class="col-12">
        <app-tlm-edit-threat-video
          [videoFormGroup]="videoFormGroup"
          [index]="i"
          [isLast]="isLastRow"
          [length]="videoFormArray.controls.length"
          [removeItem]="boundRemoveItem"
          [isPopulated]="boundIsPopulated"
          [move]="boundMove"
          [matAppearance]="matAppearance"
          [errorMatcher]="errorMatcher"
        >
        </app-tlm-edit-threat-video>
      </div>
    </div>
  </div>
</div>

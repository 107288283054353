import { Injectable } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, UntypedFormArray, UntypedFormBuilder } from '@angular/forms';
import * as _ from 'lodash';
import * as sjv from 'simple-js-validator';
import { TlmModel, SocialMediaModel } from '../../../shared';
import { EditThreatUtilities } from './edit-threat-utilities.service';
import { editThreatFormNames } from './edit-threat-form-names';

@Injectable()
export class EditThreatUtilitiesSocialForm {
  constructor(private fb: UntypedFormBuilder, private editThreatUtils: EditThreatUtilities) {}

  createEmptySocialMediaItemFormGroup(): UntypedFormGroup {
    return this.fb.group(
      {
        [editThreatFormNames.socialMediaValue]: [],
        [editThreatFormNames.socialMediaType]: []
      },
      {
        validator: this.typeAndValueRequiredIfPopulated.bind(this)
      }
    );
  }

  initializeForm(): UntypedFormGroup {
    return this.fb.group(
      {
        [editThreatFormNames.socialMediaList]: this.fb.array([])
      },
      {
        validator: this.socialMediaRequired.bind(this)
      }
    );
  }

  mapFromTlm(tlm: TlmModel): UntypedFormGroup {
    const socialMediasFA = this.fb.array([]);

    tlm.socialMedias = _.orderBy(tlm.socialMedias, ['type'], ['asc']);

    if (tlm && tlm.socialMedias && tlm.socialMedias.length > 0) {
      tlm.socialMedias.forEach((socialMedia) => {
        socialMediasFA.push(
          this.fb.group({
            [editThreatFormNames.socialMediaValue]: [socialMedia.value],
            [editThreatFormNames.socialMediaType]: [socialMedia.type]
          })
        );
      });
    } else {
      socialMediasFA.push(this.createEmptySocialMediaItemFormGroup());
    }

    return this.fb.group(
      {
        [editThreatFormNames.socialMediaList]: socialMediasFA
      },
      {
        validator: this.socialMediaRequired.bind(this)
      }
    );
  }

  mapToTlmSocialMedias(fg: UntypedFormGroup): SocialMediaModel[] {
    const mapped = new Array<SocialMediaModel>();
    const fa = fg.get(editThreatFormNames.socialMediaList) as UntypedFormArray;

    fa.controls.forEach((itemFG) => {
      const socialMediaValue = this.editThreatUtils.getValueOrSetAsUndefined(itemFG.get(editThreatFormNames.socialMediaValue));
      const socialMediaType = this.editThreatUtils.getValueOrSetAsUndefined(itemFG.get(editThreatFormNames.socialMediaType));

      if (sjv.isNotEmpty(socialMediaType) && sjv.isNotEmpty(socialMediaValue)) {
        mapped.push(new SocialMediaModel(socialMediaValue, socialMediaType));
      }
    });

    return mapped;
  }

  typeAndValueRequiredIfPopulated = (group: UntypedFormGroup) => {
    const socialMediaValue = group.get(editThreatFormNames.socialMediaValue) as UntypedFormControl;
    const socialMediaType = group.get(editThreatFormNames.socialMediaType) as UntypedFormControl;

    if (socialMediaType.pristine && socialMediaValue.pristine) {
      return null;
    }

    if (sjv.isNotEmpty(socialMediaType.value) && sjv.isNotEmpty(socialMediaValue.value)) {
      this.editThreatUtils.removeErrorFromFormControl(socialMediaType, 'bothRequiredIfPopulated');
      this.editThreatUtils.removeErrorFromFormControl(socialMediaValue, 'bothRequiredIfPopulated');
      return null;
    } else {
      const errMsg = 'Social Media: [Type] AND [Handle/Link] are required';
      this.editThreatUtils.addErrorToFormControl(socialMediaType, 'bothRequiredIfPopulated', errMsg);
      this.editThreatUtils.addErrorToFormControl(socialMediaValue, 'bothRequiredIfPopulated', errMsg);
      return { bothRequiredIfPopulated: errMsg };
    }
  };

  socialMediaRequired = (group: UntypedFormGroup) => {
    const socialMediasFA = group.get(editThreatFormNames.socialMediaList) as UntypedFormArray;

    if (socialMediasFA.pristine) {
      return null;
    }

    let anySocialMediaPopulated = false;

    socialMediasFA.controls.forEach((socialMediaFormGroup) => {
      if (
        (socialMediaFormGroup &&
          socialMediaFormGroup.get(editThreatFormNames.socialMediaValue) &&
          sjv.isNotEmpty(socialMediaFormGroup.get(editThreatFormNames.socialMediaValue).value)) ||
        (socialMediaFormGroup &&
          socialMediaFormGroup.get(editThreatFormNames.socialMediaType) &&
          sjv.isNotEmpty(socialMediaFormGroup.get(editThreatFormNames.socialMediaType).value))
      ) {
        anySocialMediaPopulated = true;
      }
    });

    const socialMedia0FC = socialMediasFA.controls[0].get(editThreatFormNames.socialMediaValue) as UntypedFormControl;
    const socialMedia1FC = socialMediasFA.controls[0].get(editThreatFormNames.socialMediaType) as UntypedFormControl;

    if (anySocialMediaPopulated) {
      this.editThreatUtils.removeErrorFromFormControl(socialMedia0FC, 'socialMediaRequired');
      this.editThreatUtils.removeErrorFromFormControl(socialMedia1FC, 'socialMediaRequired');
      return null;
    } else {
      const errMsg = 'Social Media: [Type] AND [Handle/Link] are required';
      this.editThreatUtils.addErrorToFormControl(socialMedia0FC, 'socialMediaRequired', errMsg);
      this.editThreatUtils.addErrorToFormControl(socialMedia1FC, 'socialMediaRequired', errMsg);
      return { socialMediaRequired: errMsg };
    }
  };
}

import { APP_BASE_HREF } from '@angular/common';
import { ModuleWithProviders, NgModule, CUSTOM_ELEMENTS_SCHEMA  } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import {
    ErrorStateMatcher,
    MatNativeDateModule,
    ShowOnDirtyErrorStateMatcher
} from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS, MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
// Angular Core
import { BrowserModule, Title } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { NgxConfigureModule, NgxConfigureOptions } from 'ngx-configure';
import { NgxGalleryModule } from 'ngx-gallery-9';
import { LoggerModule, NgxLoggerLevel } from 'ngx-logger';
import { NgxSpinnerModule } from 'ngx-spinner';
// External
import { ToastrModule } from 'ngx-toastr';
// Internal
import { AppComponent } from './app.component';
import { AuthModule } from './auth';
import { CoreModule, MediaService } from './core';
import { AdminModule } from './pages/admin/admin.module';
import { AuthCallbackModule } from './pages/auth-callback/auth-callback.module';
import { BoloModule } from './pages/bolo/bolo.module';
// Pages
import { EditThreatModule } from './pages/edit-threat/edit-threat.module';
import { DateRangeModalComponent } from './pages/home/date-range-modal/date-range-modal.component';
import { HomeModule } from './pages/home/home.module';
import { IncorrectAccessModule } from './pages/incorrect-access/incorrect-access.module';
import { LogoutModule } from './pages/logout/logout.module';
import { ThreatProfileModule } from './pages/threat-profile/threat-profile.module';
import { VersionModule } from './pages/version/version.module';
import { BaseUtilities, ConfigOptions, SharedModule } from './shared';

// Routing
const rootRouting: ModuleWithProviders<unknown> = RouterModule.forRoot(
  [
    {
      path: '',
      redirectTo: '/home',
      pathMatch: 'full'
    }
  ],
  { useHash: false }
);

@NgModule({
    declarations: [AppComponent, DateRangeModalComponent],
    imports: [
        // Angular Core
        BrowserModule,
        BrowserAnimationsModule,
        MatSidenavModule,
        MatFormFieldModule,
        MatInputModule,
        MatCardModule,
        MatDividerModule,
        MatExpansionModule,
        MatTabsModule,
        MatButtonModule,
        MatSelectModule,
        MatDialogModule,
        MatDatepickerModule,
        MatAutocompleteModule,
        MatToolbarModule,
        MatListModule,
        FormsModule,
        ReactiveFormsModule,
        MatNativeDateModule,
        // External
        ToastrModule.forRoot(),
        NgxConfigureModule.forRoot(),
        NgxSpinnerModule,
        LoggerModule.forRoot({
            level: NgxLoggerLevel.DEBUG,
            serverLogLevel: NgxLoggerLevel.OFF
        }),
        NgxGalleryModule,
        // Internal
        AuthModule.forRoot(),
        CoreModule.forRoot(),
        SharedModule,
        // Routing
        rootRouting,
        // Pages
        EditThreatModule,
        AuthCallbackModule,
        HomeModule,
        LogoutModule,
        ThreatProfileModule,
        VersionModule,
        BoloModule,
        AdminModule,
        IncorrectAccessModule
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
    providers: [
        { provide: APP_BASE_HREF, useValue: '/' },
        { provide: NgxConfigureOptions, useClass: ConfigOptions },
        {
            provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
            useValue: { floatLabel: 'auto' }
        },
        { provide: ErrorStateMatcher, useClass: ShowOnDirtyErrorStateMatcher },
        BaseUtilities,
        Title,
        MediaService
    ],
    bootstrap: [AppComponent]
})
export class AppModule {}

<div [formGroup]="socialMediaFormGroup">
  <div class="row">
    <div class="col-5 col-md-3">
      <mat-form-field [appearance]="matAppearance" style="font-weight: bold">
        <mat-label>Social Media Type</mat-label>
        <mat-select [formControlName]="formNames.socialMediaType" [id]="'socialMediaType' + index" [errorStateMatcher]="errorMatcher" trim="blur">
          <mat-option *ngFor="let socialMediaType of socialMediaTypes" [value]="socialMediaType">
            {{ socialMediaType }}
          </mat-option>
        </mat-select>
        <mat-error *ngFor="let item of socialMediaFormGroup.get(formNames.socialMediaType).errors | keyvalue">
          {{ item.value }}
        </mat-error>
      </mat-form-field>
    </div>
    <div class="col-5 col-md-6">
      <mat-form-field [appearance]="matAppearance">
        <mat-label>Social Media Handle/Link</mat-label>
        <input
          [id]="'socialMediaValue' + index"
          matInput
          [formControlName]="formNames.socialMediaValue"
          [errorStateMatcher]="errorMatcher"
          trim="blur"
        />
        <mat-error *ngFor="let item of socialMediaFormGroup.get(formNames.socialMediaValue).errors | keyvalue">
          {{ item.value }}
        </mat-error>
      </mat-form-field>
    </div>
    <div class="col-1 my-auto">
      <button mat-icon-button color="warn" [id]="'removeSocialMedia' + index" (click)="removeOnClick(index)" [disabled]="!removeActionEnabled()">
        <mat-icon class="fa fa-close fa-lg"></mat-icon>
      </button>
    </div>
  </div>
</div>

<div id="fullContactForm">
  <div class="row">
    <div class="col-12 add-contact-container">
      <button type="button" id="addContact" class="btn btn-link tlm-link" (click)="createAndAddItemToListIfPopulated()">+ Add Contact</button>
    </div>
  </div>
  <mat-divider></mat-divider>
  <br />
  <div *ngFor="let contactHistoryFormGroup of contactHistoryFormArray.controls; let i = index">
    <div class="row">
      <div class="col-12">
        <div>
          <app-tlm-edit-threat-contact
            [contactHistoryFormGroup]="contactHistoryFormGroup"
            [index]="i"
            [length]="contactHistoryFormArray.controls.length"
            [removeItem]="boundRemoveItem"
            [isPopulated]="boundIsPopulated"
            [matAppearance]="matAppearance"
            [errorMatcher]="errorMatcher"
            [locations]="locations"
          >
          </app-tlm-edit-threat-contact>
          <br />
          <mat-divider></mat-divider>
          <br />
        </div>
      </div>
    </div>
    <br />
  </div>
</div>

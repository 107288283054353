export class SearchModel {
  searchTerm: string;
  location: string;
  contactHistoryStartDate: Date;
  contactHistoryEndDate: Date;
  gracieNumber: string;

  constructor(searchTerm?: string, location?: string, contactHistoryStartDate?: Date, contactHistoryEndDate?: Date, gracieNumber?: string) {
    this.searchTerm = searchTerm;
    this.location = location;
    this.contactHistoryStartDate = contactHistoryStartDate;
    this.contactHistoryEndDate = contactHistoryEndDate;
    this.gracieNumber = gracieNumber;
  }
}

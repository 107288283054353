import { Injectable } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, UntypedFormArray, UntypedFormBuilder, Validators } from '@angular/forms';
import * as _ from 'lodash';
import * as sjv from 'simple-js-validator';
import { TlmModel, VideoModel, BaseUtilities } from '../../../shared';
import { EditThreatUtilities } from './edit-threat-utilities.service';
import { editThreatFormNames } from './edit-threat-form-names';

@Injectable()
export class EditThreatUtilitiesVideosForm {
  constructor(private fb: UntypedFormBuilder, private editThreatUtils: EditThreatUtilities, private utils: BaseUtilities) {}

  createEmptyVideoItemFormGroup(): UntypedFormGroup {
    return this.fb.group(
      {
        [editThreatFormNames.videoTitle]: [''],
        [editThreatFormNames.videoLink]: [''],
        [editThreatFormNames.videoModifiedDate]: []
      },
      {
        validator: [this.titleAndLinkRequiredIfPopulated.bind(this), this.linkIsPopulatedAndValid.bind(this)]
      }
    );
  }

  initializeForm(): UntypedFormGroup {
    return this.fb.group(
      {
        [editThreatFormNames.videoList]: this.fb.array([])
      },
      {
        validator: this.videoRequired.bind(this)
      }
    );
  }

  mapFromTlm(tlm: TlmModel): UntypedFormGroup {
    const videosFA = this.fb.array([]);

    tlm.videos = _.orderBy(tlm.videos, ['orderId'], ['asc']);

    if (tlm && tlm.videos && tlm.videos.length > 0) {
      tlm.videos.forEach((video) => {
        videosFA.push(
          this.fb.group({
            [editThreatFormNames.videoTitle]: [video.title],
            [editThreatFormNames.videoLink]: [video.link],
            [editThreatFormNames.videoModifiedDate]: [video.modifiedDate]
          })
        );
      });
    } else {
      videosFA.push(this.createEmptyVideoItemFormGroup());
    }

    return this.fb.group(
      {
        [editThreatFormNames.videoList]: videosFA
      },
      {
        validator: this.videoRequired.bind(this)
      }
    );
  }

  mapToTlmVideos(fg: UntypedFormGroup): VideoModel[] {
    const mapped = new Array<VideoModel>();
    let order = 0;
    const fa = fg.get([editThreatFormNames.videoList]) as UntypedFormArray;

    fa.controls.forEach((itemFG) => {
      const videoTitle = this.editThreatUtils.getValueOrSetAsUndefined(itemFG.get(editThreatFormNames.videoTitle));
      const videoLink = this.editThreatUtils.getValueOrSetAsUndefined(itemFG.get(editThreatFormNames.videoLink));
      const videoModifiedDate = new Date().toISOString();

      if (sjv.isNotEmpty(videoTitle) || sjv.isNotEmpty(videoLink)) {
        mapped.push(new VideoModel(order, videoTitle, videoLink, videoModifiedDate));
        order++;
      }
    });

    return mapped;
  }

  linkIsPopulatedAndValid = (group: UntypedFormGroup) => {
    const videoLink = group.get(editThreatFormNames.videoLink) as UntypedFormControl;

    if (videoLink.pristine) {
      return null;
    }

    if ((videoLink.value === '' && videoLink.dirty) || this.utils.isValidUrl(videoLink.value)) {
      this.editThreatUtils.removeErrorFromFormControl(videoLink, 'linkIsPopulatedAndValid');
      return null;
    } else {
      const errMsg = 'Video: [Link] is not valid';
      this.editThreatUtils.addErrorToFormControl(videoLink, 'linkIsPopulatedAndValid', errMsg);
      return { linkIsPopulatedAndValid: errMsg };
    }
  };

  titleAndLinkRequiredIfPopulated = (group: UntypedFormGroup) => {
    const videoTitle = group.get(editThreatFormNames.videoTitle) as UntypedFormControl;
    const videoLink = group.get(editThreatFormNames.videoLink) as UntypedFormControl;

    // check if both fields are untouched
    if (videoTitle.pristine && videoLink.pristine) {
      return null;
    }

    // check if both fields are empty and dirty. AKA they typed something and backspaced to empty both fields
    if (videoTitle.value === '' && videoTitle.dirty && videoLink.value === '' && videoLink.dirty) {
      this.editThreatUtils.removeErrorFromFormControl(videoTitle, 'bothRequiredIfPopulated');
      this.editThreatUtils.removeErrorFromFormControl(videoLink, 'bothRequiredIfPopulated');
      return null;
    }

    // check if both fields have value. if so, hide error. if not, show error
    if (sjv.isNotEmpty(videoTitle.value) && sjv.isNotEmpty(videoLink.value)) {
      this.editThreatUtils.removeErrorFromFormControl(videoTitle, 'bothRequiredIfPopulated');
      this.editThreatUtils.removeErrorFromFormControl(videoLink, 'bothRequiredIfPopulated');
      return null;
    } else {
      const errMsg = 'Video: [Title] AND [Link] are required';
      this.editThreatUtils.addErrorToFormControl(videoTitle, 'bothRequiredIfPopulated', errMsg);
      this.editThreatUtils.addErrorToFormControl(videoLink, 'bothRequiredIfPopulated', errMsg);
      return { bothRequiredIfPopulated: errMsg };
    }
  };

  videoRequired = (group: UntypedFormGroup) => {
    const videosFA = group.get(editThreatFormNames.videoList) as UntypedFormArray;

    if (videosFA.pristine) {
      return null;
    }

    let anyVideoPopulated = false;

    const video0FC = videosFA.controls[0].get(editThreatFormNames.videoTitle) as UntypedFormControl;
    const video1FC = videosFA.controls[0].get(editThreatFormNames.videoLink) as UntypedFormControl;

    if (video0FC.value === '' && video1FC.value === '') {
      return null;
    } else {
      anyVideoPopulated = true;
    }

    if (anyVideoPopulated) {
      this.editThreatUtils.removeErrorFromFormControl(video0FC, 'videoRequired');
      this.editThreatUtils.removeErrorFromFormControl(video1FC, 'videoRequired');
      return null;
    } else {
      const errMsg = 'Video: [Title] AND [Link] are required';
      this.editThreatUtils.addErrorToFormControl(video0FC, 'videoRequired', errMsg);
      this.editThreatUtils.addErrorToFormControl(video1FC, 'videoRequired', errMsg);
      return { videoRequired: errMsg };
    }
  };
}

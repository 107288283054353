import { Component, OnInit, OnDestroy } from '@angular/core';
import { AddPersonService } from '../../services';
import { Subscription } from 'rxjs';
import { MediaService } from '../../services';

@Component({
  selector: 'app-tlm-layout-header-edit-person',
  templateUrl: './header-edit-person.component.html'
})
export class HeaderEditPersonComponent implements OnInit, OnDestroy {
  activeMenu: string;
  menu$: Subscription;
  isNavbarCollapsed: boolean;
  collapsed$: Subscription;
  renderAsMobile$: Subscription;
  renderAsMobile: boolean;

  constructor(public addPersonService: AddPersonService, private mediaService: MediaService) {}

  ngOnDestroy() {
    this.menu$.unsubscribe();
    this.collapsed$.unsubscribe();
    this.renderAsMobile$.unsubscribe();
  }

  ngOnInit(): void {
    this.menu$ = this.addPersonService.getActiveMenu().subscribe((menu) => {
      this.activeMenu = menu;
    });

    this.collapsed$ = this.addPersonService.getMenuCollapsed().subscribe((collapsed) => {
      this.isNavbarCollapsed = collapsed;
    });

    this.renderAsMobile$ = this.mediaService.getRenderAsMobileStatus().subscribe((event) => {
      this.renderAsMobile = event;
    });
  }

  save() {
    this.addPersonService.setSavingStatusToSaving();
  }

  cancel() {
    this.addPersonService.setCancelStatusToCanceling();
  }

  collapse() {
    this.addPersonService.setMenuCollapsed(!this.isNavbarCollapsed);
  }
}

import { Component, Input, OnChanges } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { StateModel, EditThreatFormNameModel } from '../../../../../shared';
import * as _ from 'lodash';
import { MyErrorStateMatcher, EditThreatUtilities, editThreatFormNames } from '../../../utilities';

@Component({
  moduleId: module.id,
  selector: 'app-tlm-edit-threat-identification',
  templateUrl: './identification.component.html',
  styleUrls: ['./identification.component.scss']
})
export class EditThreatIdentificationComponent implements OnChanges {
  @Input() identificationFormGroup: UntypedFormGroup;
  @Input() index: number;
  @Input() length: number;
  @Input() isLast: boolean;
  @Input() removeItem: (n: number) => void;
  @Input() isPopulated: () => boolean;
  @Input() move: (n1: number, n2: number) => void;
  @Input() states: StateModel[];
  @Input() identificationTypes: string[];
  @Input() matAppearance: string;
  @Input() errorMatcher: MyErrorStateMatcher;
  formNames: EditThreatFormNameModel;

  minDate = new Date(1900, 0, 1);

  constructor(private editThreatUtils: EditThreatUtilities) {
    this.formNames = editThreatFormNames;
  }

  ngOnChanges() {
    this.onChangeIdentificationType();
  }

  removeActionEnabled(): boolean {
    return this.editThreatUtils.removeActionEnabled(this.length, this.isPopulated, this.identificationFormGroup);
  }

  removeOnClick(index: number) {
    if (this.removeActionEnabled()) {
      this.removeItem(index);
    }
  }

  moveUpActionEnabled() {
    return this.editThreatUtils.moveUpActionEnabled(this.index);
  }

  moveUpOnClick(index) {
    if (this.moveUpActionEnabled()) {
      this.move(index, index - 1);
    }
  }

  moveDownActionEnabled() {
    return this.editThreatUtils.moveDownActionEnabled(this.length, this.index);
  }

  moveDownOnClick(index) {
    if (this.moveDownActionEnabled()) {
      this.move(index, index + 1);
    }
  }

  onChangeIdentificationType() {
    if (
      this.identificationFormGroup.get(editThreatFormNames.identificationType) &&
      this.identificationFormGroup.get(editThreatFormNames.identificationType).value === 'Passport'
    ) {
      this.identificationFormGroup.get(editThreatFormNames.identificationState).setValue(null);
      this.identificationFormGroup.get(editThreatFormNames.identificationState).disable();
    } else {
      this.identificationFormGroup.get(editThreatFormNames.identificationState).enable();
    }
  }
}

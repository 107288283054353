import { Component, Input } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { MyErrorStateMatcher } from '../utilities/edit-threat.error-matcher';
import { editThreatFormNames } from '../utilities';
import { EditThreatFormNameModel } from '../../../shared';

@Component({
  selector: 'app-tlm-edit-threat-background-info',
  templateUrl: './background-info.component.html',
  styleUrls: ['./background-info.component.scss']
})
export class EditThreatBackgroundInfoComponent {
  @Input() backgroundInfoFormFormGroup: UntypedFormGroup;
  @Input() matAppearance: string;
  @Input() errorMatcher: MyErrorStateMatcher;
  formNames: EditThreatFormNameModel;

  richTextTitlebar: any;

  constructor() {
    this.formNames = editThreatFormNames;
    // eslint-disable-next-line max-len
    this.richTextTitlebar = {
      toolbar: [
        ['bold', 'italic', 'underline'],
        [{ list: 'ordered' }, { list: 'bullet' }],
        [{ size: ['small', false, 'large', 'huge'] }],
        ['link'],
        [{ color: [] }, { background: [] }]
      ]
    };
  }

  getToolbar(): any {
    return this.richTextTitlebar;
  }
}

<div class="container-fullwidth">
  <app-tlm-layout-header></app-tlm-layout-header>
</div>
<br />
<div *ngIf="displayError" class="container">
  <h4>You are not authorized to access that page!</h4>
  <br />
  <h5>Your access token might have expired.</h5>
  <h5>To get a new token, <a href="/logout">click here</a></h5>
  <br />
  <br />
</div>

import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-tlm-incorrect-access',
  templateUrl: './incorrect-access.component.html'
})
export class IncorrectAccessComponent implements OnInit {
  displayError = false;

  ngOnInit() {
    setTimeout(() => {
      this.displayError = true;
    }, 1000);
  }
}

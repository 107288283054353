import { Component, OnInit, OnDestroy, Inject } from '@angular/core';
import { UntypedFormGroup, UntypedFormArray, UntypedFormControl } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { Subscription, throwError, Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { NgxSpinnerService } from 'ngx-spinner';
import * as sjv from 'simple-js-validator';
import * as _ from 'lodash';

import { AddPersonService, ConfigAddPersonSubMenu, ITlmApiService, ILogger, IContactLocationService } from '../../core';
import { MediaService } from '../../core';
import { TlmModel, StateModel, InteractionModel, ContactLocationModel, ProfileTypeModel } from '../../shared';
import { ErrorStateMatcher } from '@angular/material/core';
import { MatDialog } from '@angular/material/dialog';
import { EditThreatErrorModalComponent } from './error-modal';
import { MyErrorStateMatcher } from './utilities/edit-threat.error-matcher';
import { EditThreatUtilitiesBasicInfoForm } from './utilities/edit-threat-utilities-basic-info-form.service';
import { EditThreatUtilitiesBackgroundInfoForm } from './utilities/edit-threat-utilities-background-info-form.service';
import { EditThreatUtilitiesAddressesForm } from './utilities/edit-threat-utilities-addresses-form.service';
import { EditThreatUtilitiesIdentificationsForm } from './utilities/edit-threat-utilities-identifications-form.service';
import { EditThreatUtilitiesBolosForm } from './utilities/edit-threat-utilities-bolos-form.service';
import { EditThreatUtilitiesPhotosForm } from './utilities/edit-threat-utilities-photos-form.service';
import { EditThreatUtilitiesContactHistoryForm } from './utilities/edit-threat-utilities-contact-history-form.service';
import { EditThreatUtilitiesProfileType } from './utilities/edit-threat-utilities-profile-type.service';
import { EditThreatUtilitiesSocialForm } from './utilities/edit-threat-utilities-social-form.service';
import { EditThreatUtilitiesVideosForm } from './utilities/edit-threat-utilities-videos-form.service';

@Component({
  selector: 'app-tlm-edit-threat',
  templateUrl: './edit-threat.component.html'
})
export class EditThreatComponent implements OnInit, OnDestroy {
  renderAsMobile$: Subscription;
  renderAsMobile: boolean;

  save$: Subscription;
  savingStatus: boolean;

  cancel$: Subscription;
  cancelStatus: boolean;

  menu$: Subscription;
  activeMenu: string;

  param$: Subscription;

  tlm$: Subscription;
  tlm: TlmModel = new TlmModel();

  // locations$: Subscription;
  locations: ContactLocationModel[];

  profileTypesRaw: ProfileTypeModel[];
  profileTypes: ProfileTypeModel[];
  phoneTypes: string[];
  genders: string[];
  races: string[];
  states: StateModel[];
  identificationTypes: string[];
  socialMediaTypes: string[];

  values$: Observable<any>;

  addPersonForm: UntypedFormGroup;

  tlmFormGroup: UntypedFormGroup = new UntypedFormGroup({});

  // sub forms with own menus
  profileTypeFormControl: UntypedFormControl;
  basicInfoFormFormGroup: UntypedFormGroup;
  identificationsFormFormGroup: UntypedFormGroup;
  bolosFormFormGroup: UntypedFormGroup;
  backgroundInfoFormFormGroup: UntypedFormGroup;
  addressesFormFormGroup: UntypedFormGroup = new UntypedFormGroup({});
  employmentFormGroup: UntypedFormGroup = new UntypedFormGroup({});
  socialMediaFormFormGroup: UntypedFormGroup = new UntypedFormGroup({});
  contactHistoryFormFormGroup: UntypedFormGroup = new UntypedFormGroup({});
  photosFormGroup: UntypedFormGroup = new UntypedFormGroup({});
  videosFormFormGroup: UntypedFormGroup = new UntypedFormGroup({});

  errorMatcher: ErrorStateMatcher;

  matAppearance = 'fill';

  constructor(
    @Inject('LoggerInjected') private logger: ILogger,
    private titleService: Title,
    private addPersonService: AddPersonService,
    public route: Router,
    @Inject('TlmApiServiceInjected') private tlmApiService: ITlmApiService,
    @Inject('ContactLocationServiceInjected')
    private contactLocationService: IContactLocationService,
    private mediaService: MediaService,
    private activatedRoute: ActivatedRoute,
    private spinner: NgxSpinnerService,
    private dialog: MatDialog,
    private editThreatUtilsProfileType: EditThreatUtilitiesProfileType,
    private editThreatUtilsBasicInfoForm: EditThreatUtilitiesBasicInfoForm,
    private editThreatUtilsBackgroundInfoForm: EditThreatUtilitiesBackgroundInfoForm,
    private editThreatUtilsAddressesForm: EditThreatUtilitiesAddressesForm,
    private editThreatUtilsIdentificationsForm: EditThreatUtilitiesIdentificationsForm,
    private editThreatUtilitiesBolosForm: EditThreatUtilitiesBolosForm,
    private editThreatUtilitiesContactHistoryForm: EditThreatUtilitiesContactHistoryForm,
    private editThreatUtilitiesSocialForm: EditThreatUtilitiesSocialForm,
    private editThreatUtilitiesPhotosForm: EditThreatUtilitiesPhotosForm,
    private editThreatUtilitiesVideosForm: EditThreatUtilitiesVideosForm
  ) {
    this.profileTypeFormControl = editThreatUtilsProfileType.initializeFormControl();
    this.basicInfoFormFormGroup = editThreatUtilsBasicInfoForm.initializeForm(this.profileTypeFormControl);
    this.identificationsFormFormGroup = editThreatUtilsIdentificationsForm.initializeForm();
    this.bolosFormFormGroup = editThreatUtilitiesBolosForm.initializeForm();
    this.contactHistoryFormFormGroup = editThreatUtilitiesContactHistoryForm.initializeForm();
    this.backgroundInfoFormFormGroup = editThreatUtilsBackgroundInfoForm.initializeForm(this.profileTypeFormControl);
    this.socialMediaFormFormGroup = editThreatUtilitiesSocialForm.initializeForm();
    this.addressesFormFormGroup = editThreatUtilsAddressesForm.initializeForm();
    this.photosFormGroup = editThreatUtilitiesPhotosForm.initializeForm(this.profileTypeFormControl);
    this.videosFormFormGroup = editThreatUtilitiesVideosForm.initializeForm();

    this.errorMatcher = new MyErrorStateMatcher();
  }

  onInitLoadDataFromSnapshot() {
    this.genders = this.activatedRoute.snapshot.data.resolved.genders;
    this.races = this.activatedRoute.snapshot.data.resolved.races;
    this.phoneTypes = this.activatedRoute.snapshot.data.resolved.phoneTypes;
    this.socialMediaTypes = this.activatedRoute.snapshot.data.resolved.socialMediaTypes;
    this.states = this.activatedRoute.snapshot.data.resolved.states;
    this.identificationTypes = this.activatedRoute.snapshot.data.resolved.identificationTypes;
    this.locations = this.activatedRoute.snapshot.data.locations;
    this.profileTypesRaw = this.activatedRoute.snapshot.data.profileTypes;
  }

  onInitSetTitle(): void {
    this.titleService.setTitle('TLM - Edit');
  }

  onInitStartSpinner(): void {
    this.spinner.show();
  }

  onInitDetermineMobileStatus(): void {
    this.renderAsMobile$ = this.mediaService.getRenderAsMobileStatus().subscribe((event) => {
      this.renderAsMobile = event;
    });
  }

  removeProfileTypesForBoloIncomplete() {
    if (_.get(this.tlm, 'profileType', '') !== 'boloIncomplete') {
      const index = this.profileTypes.findIndex((pt) => pt.type === 'boloIncomplete');
      this.profileTypes.splice(index, 1);
    }
  }

  async processTlmData(data): Promise<void> {
    this.tlm = data;
    this.profileTypes = JSON.parse(JSON.stringify(this.profileTypesRaw));
    this.removeProfileTypesForBoloIncomplete();
    this.tlm = this.sortData(this.tlm);
    this.mapTlmToForm(this.tlm);
    this.spinner.hide();
  }

  onInitLoadDataBasedOnRouteParams() {
    this.param$ = this.activatedRoute.params.subscribe((params) => {
      this.tlm$ = this.tlmApiService
        .get(params['tlmId'])
        .pipe(
          catchError((err) => {
            this.logger.errorWithPopup('Error Getting TLM Data', err);
            return throwError(err);
          })
        )
        .subscribe((data) => {
          this.processTlmData(data);
        });
    });
  }

  onInitListenForSave() {
    this.save$ = this.addPersonService.getSavingStatus().subscribe((savingStatus) => {
      if (savingStatus) {
        this.save();
        this.addPersonService.setSavingStatusToSaved();
      }
    });
  }

  onInitListenForCancel() {
    this.cancel$ = this.addPersonService.getCancelStatus().subscribe((cancelStatus) => {
      if (cancelStatus) {
        this.addPersonService.setCancelStatusToCanceled();

        if (this.tlm.tlmId) {
          this.route.navigate(['/profile/' + this.tlm.tlmId]);
        } else {
          this.route.navigate(['/']);
        }
      }
    });
  }

  onInitListenForMenu() {
    this.menu$ = this.addPersonService.getActiveMenu().subscribe((menu) => {
      this.activeMenu = menu;
    });
  }

  ngOnInit(): void {
    this.onInitLoadDataFromSnapshot();
    this.onInitSetTitle();
    this.onInitStartSpinner();
    this.onInitDetermineMobileStatus();
    this.onInitLoadDataBasedOnRouteParams();
    this.onInitListenForSave();
    this.onInitListenForCancel();
    this.onInitListenForMenu();
  }

  ngOnDestroy() {
    this.save$.unsubscribe();
    this.cancel$.unsubscribe();
    this.menu$.unsubscribe();
    this.renderAsMobile$.unsubscribe();
    this.tlm$.unsubscribe();
  }

  mapTlmToForm(tlm: TlmModel) {
    this.profileTypeFormControl = this.editThreatUtilsProfileType.mapFromTlm(tlm, this.profileTypesRaw);
    this.basicInfoFormFormGroup = this.editThreatUtilsBasicInfoForm.mapFromTlm(tlm, this.states, this.profileTypeFormControl);
    this.identificationsFormFormGroup = this.editThreatUtilsIdentificationsForm.mapFromTlm(tlm, this.states);
    this.bolosFormFormGroup = this.editThreatUtilitiesBolosForm.mapFromTlm(tlm, this.profileTypeFormControl);
    this.backgroundInfoFormFormGroup = this.editThreatUtilsBackgroundInfoForm.mapFromTlm(tlm, this.profileTypeFormControl);
    this.addressesFormFormGroup = this.editThreatUtilsAddressesForm.mapFromTlm(tlm, this.states);
    this.contactHistoryFormFormGroup = this.editThreatUtilitiesContactHistoryForm.mapFromTlm(tlm, this.profileTypeFormControl);
    this.socialMediaFormFormGroup = this.editThreatUtilitiesSocialForm.mapFromTlm(tlm);
    this.photosFormGroup = this.editThreatUtilitiesPhotosForm.mapFromTlm(tlm, this.profileTypeFormControl);
    this.videosFormFormGroup = this.editThreatUtilitiesVideosForm.mapFromTlm(tlm);

    /* eslint-disable @typescript-eslint/naming-convention */
    this.tlmFormGroup = new UntypedFormGroup({
      'Profile Type': this.profileTypeFormControl,
      'Basic Information Form': this.basicInfoFormFormGroup,
      'Identifications Form': this.identificationsFormFormGroup,
      'Bolo Form': this.bolosFormFormGroup,
      'Background Info Form': this.backgroundInfoFormFormGroup,
      'Address Form': this.addressesFormFormGroup,
      'Employment History Form': this.employmentFormGroup,
      'Social Media Form': this.socialMediaFormFormGroup,
      'Photos Form': this.photosFormGroup,
      'Videos Form': this.videosFormFormGroup,
      'Contact History Form': this.contactHistoryFormFormGroup
    });
    /* eslint-enable @typescript-eslint/naming-convention */
  }

  mapFormToTlm() {
    const newTlm = new TlmModel();
    newTlm.tlmId = this.tlm.tlmId;
    newTlm.tlmStatus = this.tlm.tlmStatus;

    newTlm.created = new InteractionModel();
    if (this.tlm.created) {
      newTlm.created.by = this.tlm.created.by;
      newTlm.created.dateTime = this.tlm.created.dateTime;
    }

    newTlm.updated = new InteractionModel();
    if (this.tlm.updated) {
      newTlm.updated.by = this.tlm.updated.by;
      newTlm.updated.dateTime = this.tlm.updated.dateTime;
    }

    newTlm.profileType = this.editThreatUtilsProfileType.mapToTlm(this.profileTypeFormControl);

    newTlm.rockSecurityId = this.editThreatUtilsBasicInfoForm.mapToTlmRockSecurityId(this.basicInfoFormFormGroup);
    newTlm.person = this.editThreatUtilsBasicInfoForm.mapToTlmPerson(this.basicInfoFormFormGroup);
    newTlm.aliases = this.editThreatUtilsBasicInfoForm.mapToTlmAliases(this.basicInfoFormFormGroup);
    newTlm.emails = this.editThreatUtilsBasicInfoForm.mapToTlmEmails(this.basicInfoFormFormGroup);
    newTlm.phones = this.editThreatUtilsBasicInfoForm.mapToTlmPhones(this.basicInfoFormFormGroup);
    newTlm.vehicles = this.editThreatUtilsBasicInfoForm.mapToTlmVehicles(this.basicInfoFormFormGroup, this.states);

    newTlm.identifications = this.editThreatUtilsIdentificationsForm.mapToTlm(this.identificationsFormFormGroup, this.states);

    newTlm.bolos = this.editThreatUtilitiesBolosForm.mapToTlm(this.bolosFormFormGroup);
    newTlm.bolos = newTlm.getSortedBolos();

    newTlm.backgroundInfo = this.editThreatUtilsBackgroundInfoForm.mapToTlmBackgroundInfo(this.backgroundInfoFormFormGroup);
    newTlm.description = this.editThreatUtilsBackgroundInfoForm.mapToTlmDescription(this.backgroundInfoFormFormGroup);
    newTlm.criminalHistory = this.editThreatUtilsBackgroundInfoForm.mapToTlmCriminalHistory(this.backgroundInfoFormFormGroup);
    newTlm.gracieNumbers = this.editThreatUtilsBackgroundInfoForm.mapToTlmGracieNumbers(this.backgroundInfoFormFormGroup);

    newTlm.addresses = this.editThreatUtilsAddressesForm.mapToTlm(this.addressesFormFormGroup, this.states);

    newTlm.photos = this.editThreatUtilitiesPhotosForm.mapToTlmPhotos(this.photosFormGroup);

    newTlm.contactHistory = this.editThreatUtilitiesContactHistoryForm.mapToTlm(this.contactHistoryFormFormGroup);

    newTlm.socialMedias = this.editThreatUtilitiesSocialForm.mapToTlmSocialMedias(this.socialMediaFormFormGroup);

    newTlm.videos = this.editThreatUtilitiesVideosForm.mapToTlmVideos(this.videosFormFormGroup);

    return newTlm;
  }

  buildErrors(errors, formItem, label) {
    _.forOwn(formItem.controls, (value, key) => {
      let newLabel = label;
      if (!(key === 'undefined' && formItem.controls[key].constructor.name === UntypedFormArray.name)) {
        newLabel = label + '.' + key;
      }
      this.buildErrors(errors, formItem.controls[key], newLabel);
    });

    formItem.updateValueAndValidity({ onlySelf: true });
    _.forOwn(formItem.errors, (value, key) => {
      if (typeof formItem.errors[key] !== typeof true && typeof formItem.errors[key] === typeof '') {
        // error content has been specified
        errors.push('Error: ' + formItem.errors[key]);
      } else {
        errors.push('Error: ' + label);
      }
    });

    return errors;
  }

  validateForm(fg) {
    fg.markAllAsTouched(); // marked all form controls as touched
    fg.updateValueAndValidity(); // force validation for form group
    Object.keys(fg.controls).forEach((field) => {
      fg.get(field).markAsDirty(); // without marking dirty, ui was not showing errors
      fg.get(field).updateValueAndValidity(); // force validation for form control(s)
    });
  }

  save() {
    if (sjv.isEmpty(this.tlm.tlmId)) {
      this.logger.errorWithPopup('TLM Object Error', new Error('The TLM object is missing the tlmId'));
    } else {
      this.validateForm(this.tlmFormGroup);

      let errors = this.buildErrors([], this.tlmFormGroup, 'Edit Threat');
      errors = _.uniq(errors);

      if (sjv.isNotEmpty(errors)) {
        this.dialog.open(EditThreatErrorModalComponent, {
          data: { errors }
        });
      } else {
        this.spinner.show();

        const tlm = this.mapFormToTlm();

        this.logger.debug('Updating TLM', tlm);
        this.tlmApiService.update(tlm).subscribe(
          (data) => {
            this.spinner.hide();
            this.logger.debug('Updated TLM', data);
            this.route.navigate(['/profile/' + this.tlm.tlmId]);
          },
          (error) => {
            this.spinner.hide();
            this.logger.errorWithPopup('TLM NOT Added', error);
          }
        );
      }
    }
  }

  hideSubForm(subForm: string) {
    const url = this.removeQueryStringParamsFromUrl(this.route.url);
    const subUrl = url.substring(url.lastIndexOf('/') + 1);
    const subMenu = ConfigAddPersonSubMenu[subForm];
    return subUrl !== subMenu.route;
  }

  removeQueryStringParamsFromUrl(url: string): string {
    const qsIndex = url.indexOf('?');

    if (qsIndex > -1) {
      url = url.slice(0, qsIndex);
    }

    return url;
  }

  sortData(tlm) {
    tlm.addresses = _.sortBy(tlm.addresses, ['orderId']);
    tlm.aliases = _.sortBy(tlm.aliases, ['orderId']);
    tlm.emails = _.sortBy(tlm.emails, ['orderId']);
    tlm.phones = _.sortBy(tlm.phones, ['orderId']);
    tlm.vehicles = _.sortBy(tlm.vehicles, ['orderId']);
    tlm.identifications = _.sortBy(tlm.identifications, ['orderId']);
    tlm.photos = _.sortBy(tlm.photos, ['orderId']);
    tlm.contactHistory = _.sortBy(tlm.contactHistory, ['date']).reverse();
    tlm.bolos = _.sortBy(tlm.bolos, ['startDate']).reverse();
    tlm.socialMedias = _.sortBy(tlm.socialMedias, ['type', 'value']);
    tlm.videos = _.sortBy(tlm.videos, ['orderId']);
    return tlm;
  }
}

import { Injectable, Inject } from '@angular/core';
import { Observable, of } from 'rxjs';
import { IPhoneTypeApiService } from './phone-type-api.interface';
import { ILogger } from '../logger';

@Injectable()
export class PhoneTypeApiService implements IPhoneTypeApiService {
  data: string[];

  constructor(@Inject('LoggerInjected') private logger: ILogger) {
    this.data = ['Mobile', 'Work', 'Home', 'Other'];
  }

  list(): Observable<string[]> {
    this.logger.debug('PhoneTypeApiMockService.List', this.data);
    return of(this.data);
  }
}

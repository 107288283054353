import { Injectable } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { TlmModel, ProfileTypeModel } from '../../../shared';
import { EditThreatUtilities } from './edit-threat-utilities.service';

@Injectable()
export class EditThreatUtilitiesProfileType {
  constructor(private editThreatUtils: EditThreatUtilities) {}

  initializeFormControl(): UntypedFormControl {
    return new UntypedFormControl();
  }

  mapFromTlm(tlm: TlmModel, profileTypes: ProfileTypeModel[]): UntypedFormControl {
    let profileType = new ProfileTypeModel();
    const found = profileTypes.find((pt) => pt.type === tlm.profileType);
    if (found) {
      profileType = found;
    }

    return new UntypedFormControl(profileType);
  }

  mapToTlm(fc: UntypedFormControl): string {
    let mapped = null;
    const profileType = this.editThreatUtils.getValueOrSetAsUndefined(fc);
    if (profileType) {
      mapped = profileType.type;
    }
    return mapped;
  }
}

import { ModuleWithProviders, NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { SharedModule } from '../../shared';

import { AuthCallbackComponent } from './auth-callback.component';
import { AuthCallbackSilentRefreshComponent } from './auth-callback-silent-refresh.component';

const routing: ModuleWithProviders<unknown> = RouterModule.forChild([
  {
    path: 'auth-callback',
    component: AuthCallbackComponent
  },
  {
    path: 'auth-callback-silent-refresh',
    component: AuthCallbackSilentRefreshComponent
  }
]);

@NgModule({
  declarations: [AuthCallbackComponent, AuthCallbackSilentRefreshComponent],
  imports: [routing, SharedModule]
})
export class AuthCallbackModule {}

import { Component, Input } from '@angular/core';
import { UntypedFormArray, UntypedFormGroup } from '@angular/forms';
import * as sjv from 'simple-js-validator';
import { EditThreatUtilities, MyErrorStateMatcher, EditThreatUtilitiesBasicInfoForm, editThreatFormNames } from '../../utilities';

@Component({
  moduleId: module.id,
  selector: 'app-tlm-edit-threat-emails',
  templateUrl: './emails.component.html'
})
export class EditThreatEmailsComponent {
  @Input() emailsFormArray: UntypedFormArray = new UntypedFormArray([]);
  @Input() matAppearance: string;
  @Input() errorMatcher: MyErrorStateMatcher;
  boundRemoveItem: (n: number) => void;
  boundIsPopulated: () => boolean;

  constructor(private editThreatUtils: EditThreatUtilities, private editThreatUtilsBasicInfoForm: EditThreatUtilitiesBasicInfoForm) {
    this.boundRemoveItem = this.removeItemFromList.bind(this);
    this.boundIsPopulated = this.isPopulated.bind(this);
  }

  isPopulated(emailFG: UntypedFormGroup): boolean {
    return emailFG && emailFG.get(editThreatFormNames.email) && sjv.isNotEmpty(emailFG.get(editThreatFormNames.email).value);
  }

  createAndAddItemToListIfPopulated(): void {
    return this.editThreatUtils.createAndAddItemToListIfPopulated(
      this.emailsFormArray,
      this.editThreatUtilsBasicInfoForm.createEmptyEmailItemFormGroup.bind(this.editThreatUtilsBasicInfoForm),
      this.isPopulated,
      'emails'
    );
  }

  removeItemFromList(index: number): void {
    let reset = false;
    if (this.emailsFormArray.length === 1) {
      reset = true;
    }
    return this.editThreatUtils.removeItemFromList(
      this.emailsFormArray,
      this.editThreatUtilsBasicInfoForm.createEmptyEmailItemFormGroup.bind(this.editThreatUtilsBasicInfoForm),
      index,
      reset
    );
  }
}

import { ModuleWithProviders, NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { SharedModule } from '../../shared';
import { CoreModule } from '../../core';

import { VersionComponent } from './version.component';

const routing: ModuleWithProviders<unknown> = RouterModule.forChild([
  {
    path: 'version',
    component: VersionComponent
  }
]);

@NgModule({
  declarations: [VersionComponent],
  imports: [routing, SharedModule, CoreModule]
})
export class VersionModule {}

import { Inject, Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { ContactLocationModel, ContactLocationModelFactory } from '../../../shared';
import { ILogger } from '../logger';
import { IContactLocationService } from './contact-location-service.interface';

@Injectable()
export class ContactLocationServiceMock implements IContactLocationService {
  raw = [
    new ContactLocationModel('abc123', 'Building A', '123 Street Somewhere, MI 48100', '89'),
    new ContactLocationModel('def456', 'Building B', '456 Street Foobar, MI 48200', '43'),
    new ContactLocationModel('ghi789', 'Building C', '789 Street Heresville, MI 48300', '22')
  ];

  locations: ContactLocationModel[];

  constructor(@Inject('LoggerInjected') private logger: ILogger) {
    this.locations = this.raw.map((item) => new ContactLocationModelFactory().create(item));
  }

  list(): Observable<ContactLocationModel[]> {
    this.logger.debug('ContactLocationServiceMock.List', this.locations);
    return of(this.locations);
  }
}

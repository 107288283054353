<div class="container-fullwidth">
  <app-tlm-layout-header [profileType]="tlm?.profileType"></app-tlm-layout-header>
</div>
<br />
<div class="container" *ngIf="tlm">
  <div class="row">
    <div class="col-12 tlm-profile-section" id="summarySection" (mouseenter)="mouseEnter('header')" (mouseleave)="mouseLeave('header')"
      [class.mat-elevation-z2]="!isActive.header" [class.mat-elevation-z20]="isActive.header">
      <mat-card id="profileHeader">
        <div class="row tlm-top-buffer">
          <div class="col-md-2 col-sm-12 tlm-center">
            <ngx-gallery id="profilePhotoGallery" #ngxGalleryProfilePhoto [options]="ngxGalleryOptionsProfilePhoto"
              [images]="ngxGalleryImagesProfilePhoto"></ngx-gallery>
          </div>
          <div class="col-md-10 col-sm-12">
            <div class="row tlm-top-buffer">
              <div class="col-md-12 col-sm-12 no-gutters tlm-top-buffer" id="incompleteBolo">
                <mat-toolbar color="primary"
                  *ngIf="!displayThreatProfile() && !displayActiveBoloProfile() && !displayInactiveBoloProfile()">
                  <span id="profileHeaderDisplayName">{{ tlm.nameForDisplay }}</span>
                </mat-toolbar>
                <mat-toolbar class="tlm-profile-status-threat-foreground tlm-view-profile-header"
                  *ngIf="displayThreatProfile()">
                  <span id="profileHeaderDisplayName" class="tlm-view-profile-header-name">{{ tlm.nameForDisplay }}</span>
                  <span class="tlm-mat-spacer"></span>
                  <div class="far-end">
                    <span class="space-reducer d-none d-md-block">Threat</span>
                    <span class="space-reducer d-none d-md-block">Created {{ formatDateFromString(tlm.created.dateTime) }}</span>
                  </div>
                  <span id="threatIcon" class="fa fa-user-secret tlm-fa-25x"></span>
                </mat-toolbar>
                <mat-toolbar class="tlm-profile-status-bolo-active-foreground tlm-view-profile-header"
                  *ngIf="displayActiveBoloProfile()">
                  <span id="profileHeaderDisplayName" class="tlm-view-profile-header-name">{{ tlm.nameForDisplay }}</span>
                  <span class="tlm-mat-spacer"></span>
                  <div class="far-end">
                    <span class="space-reducer d-none d-md-block">Active BOLO</span>
                    <span class="space-reducer d-none d-md-block">Expires {{
                      formatDateFromString(getFirstSortedBolo().endDate) }}</span>
                  </div>
                  <span id="activeBoloIcon" class="fa fa-binoculars tlm-fa-25x"></span>
                </mat-toolbar>
                <mat-toolbar class="tlm-profile-status-bolo-inactive-foreground tlm-view-profile-header"
                  *ngIf="displayInactiveBoloProfile()">
                  <span id="profileHeaderDisplayName" class="tlm-view-profile-header-name">{{ tlm.nameForDisplay }}</span>
                  <span class="tlm-mat-spacer"></span>
                  <div class="far-end">
                    <span class="space-reducer d-none d-md-block">BOLO</span>
                    <span *ngIf="tlm.bolos.length === 0" class="space-reducer d-none d-md-block">Inactive</span>
                    <span *ngIf="tlm.bolos.length > 0" class="space-reducer d-none d-md-block">Expired {{ formatDateFromString(getFirstSortedBolo().endDate) }}</span>
                  </div>
                  <span id="inactiveBoloIcon" class="fa fa-binoculars tlm-fa-25x"></span>
                </mat-toolbar>
              </div>
            </div>
            <br />
            <div class="row">
              <div class="col-md-4 col-sm-12">
                <mat-form-field appearance="outline">
                  <mat-label>Date of Birth</mat-label>
                  <input id="profileHeaderDateOfBirth" matInput [value]="tlm.person.dateOfBirthForDisplay" readonly />
                </mat-form-field>
              </div>
              <div class="col-md-4 col-sm-12">
                <mat-form-field appearance="outline">
                  <mat-label>Gender</mat-label>
                  <input id="profileHeaderGender" matInput [value]="tlm.person.genderForDisplay" readonly />
                </mat-form-field>
              </div>
              <div class="col-md-4 col-sm-12">
                <mat-form-field appearance="outline">
                  <mat-label>Rock Security Id</mat-label>
                  <input id="profileHeaderRockSecurityId" matInput [value]="tlm.rockSecurityId" readonly />
                </mat-form-field>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <mat-form-field appearance="outline">
                  <mat-label>Aliases</mat-label>
                  <input id="profileHeaderAliases" matInput [value]="tlm.aliasesForDisplay" readonly />
                </mat-form-field>
              </div>
            </div>
          </div>
        </div>
      </mat-card>
    </div>
  </div>
  <br />
  <mat-divider></mat-divider>
  <br />
  <div class="row">
    <div class="col-12" id="tabs">
      <br />
      <div class="row" id="detailsSection">
        <div class="col-lg-4 col-md-12" id="leftPaneOfDetails">
          <div class="row" id="addtlPhotosTitle">
            <div class="col-12">
              <h4><span>Photos</span></h4>
            </div>
          </div>
          <div class="row">
            <div class="col-12 tlm-profile-section" id="addtlPhotosSection" (mouseenter)="mouseEnter('addtlPhotos')"
              (mouseleave)="mouseLeave('addtlPhotos')" [class.mat-elevation-z2]="!isActive.addtlPhotos"
              [class.mat-elevation-z20]="isActive.addtlPhotos">
              <mat-card id="addtlPhotos">
                <div class="row">
                  <div class="col-12">
                    <ngx-gallery *ngIf="ngxGalleryImagesAddtlPhotos.length > 0" id="addtlPhotosGallery"
                      #ngxGalleryAddtlPhotos [options]="ngxGalleryOptionsAddtlPhotos"
                      [images]="ngxGalleryImagesAddtlPhotos"></ngx-gallery>
                    <span *ngIf="ngxGalleryImagesAddtlPhotos.length === 0" id="addtlPhotosGalleryNone">No Addtl.
                      Photos</span>
                  </div>
                </div>
              </mat-card>
            </div>
          </div>
          <br />
          <mat-divider></mat-divider>
          <br />
          <div class="row" id="videoTitle">
            <div class="col-12">
              <h4>Videos</h4>
            </div>
          </div>
          <div class="row">
            <div class="col-12 tlm-profile-section" id="videoSection" (mouseenter)="mouseEnter('videos')"
              (mouseleave)="mouseLeave('videos')" [class.mat-elevation-z2]="!isActive.videos"
              [class.mat-elevation-z20]="isActive.videos">
              <mat-card>
                <div class="row" *ngIf="tlm.videos.length === 0">
                  <span>No Videos</span>
                </div>
                <div *ngIf="tlm.videos.length > 0">
                  <div class="row">
                    <div class="col-12">
                      <strong>Most Recent Video</strong>
                      <div class="mat-card-content">
                        <a [href]="getFirstSortedVideo().link" target="_blank" rel="noopener">{{
                          getFirstSortedVideo().title }}</a>
                      </div>
                    </div>
                  </div>
                </div>
              </mat-card>
              <div *ngIf="getVideosWithoutFirstVideo().length > 0">
                <mat-divider></mat-divider>
                <mat-expansion-panel id="additionalVideos" class="mat-elevation-z">
                  <mat-expansion-panel-header>
                    <mat-panel-title>
                      <strong>Additional Videos</strong>
                    </mat-panel-title>
                  </mat-expansion-panel-header>
                  <div *ngFor="let videoItem of getVideosWithoutFirstVideo()">
                    <div class="mat-card-content">
                      <a [href]="videoItem.link" target="_blank">{{ videoItem.title }}</a>
                    </div>
                  </div>
                </mat-expansion-panel>
              </div>
              <mat-divider></mat-divider>
            </div>
          </div>
          <br />
          <mat-divider></mat-divider>
          <br />
          <div class="row" id="addressesTitle">
            <div class="col-12">
              <h4>Addresses</h4>
            </div>
          </div>
          <div class="row">
            <div class="col-12 tlm-profile-section" id="addresses" (mouseenter)="mouseEnter('addresses')"
              (mouseleave)="mouseLeave('addresses')" [class.mat-elevation-z2]="!isActive.addresses"
              [class.mat-elevation-z20]="isActive.addresses">
              <mat-card class="mat-elevation-z">
                <div class="row">
                  <span id="noAddresses"
                    *ngIf="currentAddresses().length === 0 && nonCurrentAddresses().length === 0">No
                    Addresses</span>
                  <div id="currentAddresses" *ngFor="let addressItem of currentAddresses()">
                    <strong>Current Address</strong>
                    <div class="mat-card-content">
                      <div [innerHtml]="getAddressDisplay(addressItem).firstLine"></div>
                      <div [innerHtml]="getAddressDisplay(addressItem).secondLine"></div>
                      <div [innerHtml]="getAddressDisplay(addressItem).thirdLine"></div>
                    </div>
                  </div>
                  <div *ngIf="currentAddresses().length === 0">
                    <div id="additionalAddressesNoCurrent" *ngFor="let addressItem of nonCurrentAddresses()">
                      <div class="mat-card-content">
                        <div [innerHtml]="getAddressDisplay(addressItem).firstLine"></div>
                        <div [innerHtml]="getAddressDisplay(addressItem).secondLine"></div>
                        <div [innerHtml]="getAddressDisplay(addressItem).thirdLine"></div>
                      </div>
                    </div>
                  </div>
                </div>
              </mat-card>
              <mat-divider></mat-divider>
              <mat-expansion-panel id="additionalAddresses" class="mat-elevation-z"
                *ngIf="nonCurrentAddresses().length > 0 && currentAddresses().length > 0">
                <mat-expansion-panel-header>
                  <mat-panel-title>
                    <strong>Additional Addresses</strong>
                  </mat-panel-title>
                </mat-expansion-panel-header>
                <div *ngFor="let addressItem of nonCurrentAddresses()">
                  <div class="mat-card-content">
                    <div [innerHtml]="getAddressDisplay(addressItem).firstLine"></div>
                    <div [innerHtml]="getAddressDisplay(addressItem).secondLine"></div>
                    <div [innerHtml]="getAddressDisplay(addressItem).thirdLine"></div>
                  </div>
                </div>
              </mat-expansion-panel>
            </div>
          </div>
          <br />
          <mat-divider></mat-divider>
          <br />
          <div class="row" id="socialMediaTitle">
            <div class="col-12">
              <h4>Social Media</h4>
            </div>
          </div>
          <div class="row" >
            <div class="col-12" class="tlm-profile-sections" id="socialMedia" class="social-media-card" (mouseenter)="mouseEnter('socialMedia')"
              (mouseleave)="mouseLeave('socialMedia')" [class.mat-elevation-z2]="!isActive.socialMedia"
              [class.mat-elevation-z20]="isActive.socialMedia">
              <mat-card>
                <div class="social-media-item"
                  *ngFor="let socialMedia of socialMedias; first as isFirst; last as isLast; let i = index">
                  <br *ngIf="isFirst" />
                  <div class="row">
                    <div class="col-1">
                      <span class="{{ getSocialMediaIcon(socialMedia.type) }}"></span>
                    </div>
                    <div class="social-media-platform col">
                      <div>{{ socialMedia.type }}</div>
                    </div>
                  </div>
                  <mat-list dense class="row">
                    <mat-list-item class="col social-account-list truncate-contents"
                      *ngFor="let social of socialMedia.values; let j = index">
                      <a [id]="'social-' + i + '-' + j" *ngIf="social.isUrl; else notUrl" class="social-media-value"
                        rel="noopener" href="{{ social.link }}">{{ social.link }}</a>
                      <ng-template #notUrl class="social-media-value"><a [id]="'social-' + i + '-' + j"
                          class="social-media-value">{{ social.link }}</a></ng-template>
                    </mat-list-item>
                  </mat-list>
                  <br />
                  <mat-divider [inset]="true" *ngIf="!isLast"></mat-divider>
                  <br *ngIf="!isLast" />
                </div>
                <div class="row" *ngIf="socialMedias === null">
                  <div class="col">
                    <span>No Social Media</span>
                  </div>
                </div>
              </mat-card>
              <mat-divider></mat-divider>
            </div>
          </div>
          <br />
          <mat-divider></mat-divider>
          <br />
        </div>
        <div class="col-lg-8 col-md-12" id="rightPaneOfDetails">
          <div class="row" id="basicInfoTitle">
            <div class="col-12">
              <h4><span>Basic Information</span></h4>
            </div>
          </div>
          <div class="row">
            <div class="col-12 tlm-profile-section" id="basicInfoSection" (mouseenter)="mouseEnter('basicInfo')"
              (mouseleave)="mouseLeave('basicInfo')" [class.mat-elevation-z2]="!isActive.basicInfo"
              [class.mat-elevation-z20]="isActive.basicInfo">
              <mat-card id="basicInfo">
                <div class="row">
                  <div class="col-md-6 col-sm-12">
                    <mat-form-field appearance="outline">
                      <mat-label>Full Name</mat-label>
                      <input id="fullName" matInput [value]="tlm.person.fullNameForDisplay" readonly />
                    </mat-form-field>
                  </div>
                  <div class="col-md-6 col-sm-12">
                    <mat-form-field appearance="outline" floatLabel="always">
                      <mat-label>Race</mat-label>
                      <input id="race" matInput [value]="tlm.person.raceForDisplay" placeholder="Unknown" readonly />
                    </mat-form-field>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-6 col-sm-12">
                    <div *ngIf="tlm.emails.length === 0">
                      <mat-form-field appearance="outline" floatLabel="always">
                        <mat-label>Emails</mat-label>
                        <input id="emails" matInput placeholder="None" readonly />
                      </mat-form-field>
                    </div>
                    <div *ngIf="tlm.emails.length > 0">
                      <mat-form-field appearance="outline" floatLabel="always">
                        <mat-label>Emails</mat-label>
                        <mat-list>
                          <ng-container *ngFor="let emailItem of tlm.emails; last as lastEmail; index as eIndex">
                            <mat-list-item>
                              <input [id]="'emails' + eIndex" matInput [value]="emailItem.value" readonly />
                              <mat-divider *ngIf="!lastEmail"></mat-divider>
                            </mat-list-item>
                          </ng-container>
                        </mat-list>
                      </mat-form-field>
                    </div>
                  </div>
                  <div class="col-md-6 col-sm-12">
                    <div *ngIf="tlm.phones.length === 0">
                      <mat-form-field appearance="outline" floatLabel="always">
                        <mat-label>Phones</mat-label>
                        <input id="phones" matInput placeholder="None" readonly />
                      </mat-form-field>
                    </div>
                    <div *ngIf="tlm.phones.length > 0">
                      <mat-form-field appearance="outline" floatLabel="always">
                        <mat-label>Phones</mat-label>
                        <mat-list>
                          <ng-container *ngFor="let phoneItem of tlm.phones; last as lastPhone; index as pIndex">
                            <mat-list-item>
                              <input [id]="'phones' + pIndex" matInput [value]="
                                  phoneItem.type +
                                  ': ' +
                                  '(' +
                                  phoneItem.value.substr(0, 3) +
                                  ') ' +
                                  phoneItem.value.substr(3, 3) +
                                  '-' +
                                  phoneItem.value.substr(6)
                                " readonly />
                              <mat-divider *ngIf="!lastPhone"></mat-divider>
                            </mat-list-item>
                          </ng-container>
                        </mat-list>
                      </mat-form-field>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-12">
                    <div *ngIf="tlm.vehicles.length === 0">
                      <mat-form-field appearance="outline" floatLabel="always">
                        <mat-label>Vehicles</mat-label>
                        <input id="vehicles" matInput placeholder="None" readonly />
                      </mat-form-field>
                    </div>
                    <div *ngIf="tlm.vehicles.length > 0">
                      <div *ngIf="!renderAsMobile">
                        <mat-form-field appearance="outline" floatLabel="always">
                          <mat-label>Vehicles</mat-label>
                          <input id="hiddenVehicle" matInput style="height: 0px" />
                          <mat-table [dataSource]="vehiclesDataSource">
                            <ng-container matColumnDef="year">
                              <mat-header-cell *matHeaderCellDef> Year </mat-header-cell>
                              <mat-cell *matCellDef="let item; let vIndex = index">
                                <input [id]="'year' + vIndex" matInput placeholder="None" [value]="item.year"
                                  readonly />
                              </mat-cell>
                            </ng-container>
                            <ng-container matColumnDef="color">
                              <mat-header-cell *matHeaderCellDef> Color </mat-header-cell>
                              <mat-cell *matCellDef="let item; let vIndex = index">
                                <input [id]="'color' + vIndex" matInput placeholder="None" [value]="item.color"
                                  readonly />
                              </mat-cell>
                            </ng-container>
                            <ng-container matColumnDef="makeModel">
                              <mat-header-cell *matHeaderCellDef> Make/Model </mat-header-cell>
                              <mat-cell *matCellDef="let item; let vIndex = index">
                                <input [id]="'makeModel' + vIndex" matInput placeholder="None" [value]="item.makeModel"
                                  readonly />
                              </mat-cell>
                            </ng-container>
                            <ng-container matColumnDef="license">
                              <mat-header-cell *matHeaderCellDef> License </mat-header-cell>
                              <mat-cell matInput *matCellDef="let item; let vIndex = index">
                                <input [id]="'license' + vIndex" matInput placeholder="None" [value]="item.licensePlate"
                                  readonly />
                              </mat-cell>
                            </ng-container>
                            <mat-header-row *matHeaderRowDef="vehicleDisplayedColumns"></mat-header-row>
                            <mat-row *matRowDef="let row; columns: vehicleDisplayedColumns"></mat-row>
                          </mat-table>
                        </mat-form-field>
                      </div>
                      <div *ngIf="renderAsMobile">
                        <mat-form-field appearance="outline" floatLabel="always">
                          <mat-label>Vehicles</mat-label>
                          <mat-list>
                            <mat-divider></mat-divider>
                            <ng-container *ngFor="let vehicleItem of tlm.vehicles">
                              <textarea matInput rows="5">{{
                                '\r\n' +
                                  'Year: ' +
                                  vehicleItem.year +
                                  '\r\n' +
                                  'Color: ' +
                                  vehicleItem.color +
                                  '\r\n' +
                                  'Make/Model: ' +
                                  vehicleItem.makeModel +
                                  '\r\n' +
                                  'License: ' +
                                  vehicleItem.licensePlate
                              }}</textarea>
                              <mat-divider></mat-divider>
                            </ng-container>
                          </mat-list>
                        </mat-form-field>
                      </div>
                    </div>
                  </div>
                </div>
              </mat-card>
            </div>
          </div>
          <br />
          <mat-divider></mat-divider>
          <br />
          <div class="row" id="identificationsTitle">
            <div class="col-12">
              <h4><span>Identifications</span></h4>
            </div>
          </div>
          <div class="row">
            <div class="col-12 tlm-profile-section" id="identificationsSection" (mouseenter)="mouseEnter('identifications')"
              (mouseleave)="mouseLeave('identifications')" [class.mat-elevation-z2]="!isActive.identifications"
              [class.mat-elevation-z20]="isActive.identifications">
              <mat-card id="identifications">
                <div *ngIf="tlm.identifications.length === 0">
                  <mat-form-field appearance="outline" floatLabel="always">
                    <mat-label>Identifications</mat-label>
                    <input id="identifications" matInput placeholder="None" readonly />
                  </mat-form-field>
                </div>
                <div *ngIf="tlm.identifications.length > 0">
                  <div *ngIf="!renderAsMobile" id="idList">
                    <div class="row">
                      <div class="col-12">
                        <mat-form-field appearance="outline">
                          <input id="hiddenId" matInput style="height: 0px" />
                          <mat-table [dataSource]="identificationsDataSource">
                            <ng-container matColumnDef="idType">
                              <mat-header-cell *matHeaderCellDef> Id Type </mat-header-cell>
                              <mat-cell *matCellDef="let idItem; let iIndex = index">
                                <input [id]="'idType' + iIndex" matInput placeholder="None" [value]="idItem?.type"
                                  readonly />
                              </mat-cell>
                            </ng-container>
                            <ng-container matColumnDef="idNumber">
                              <mat-header-cell *matHeaderCellDef> Id Number </mat-header-cell>
                              <mat-cell *matCellDef="let idItem; let iIndex = index">
                                <input [id]="'idNumber' + iIndex" matInput placeholder="None" [value]="idItem?.value"
                                  readonly />
                              </mat-cell>
                            </ng-container>
                            <ng-container matColumnDef="state">
                              <mat-header-cell *matHeaderCellDef> State </mat-header-cell>
                              <mat-cell *matCellDef="let idItem; let iIndex = index">
                                <input [id]="'state' + iIndex" matInput placeholder="None" [value]="idItem?.state?.code"
                                  readonly />
                              </mat-cell>
                            </ng-container>
                            <ng-container matColumnDef="expirationDate">
                              <mat-header-cell *matHeaderCellDef> Expiration Date </mat-header-cell>
                              <mat-cell *matCellDef="let idItem; let iIndex = index">
                                <input [id]="'expirationDate' + iIndex" matInput placeholder="None"
                                  [value]="parseISOString(idItem.expirationDate)" readonly />
                              </mat-cell>
                            </ng-container>
                            <mat-header-row *matHeaderRowDef="identificationDisplayedColumns"></mat-header-row>
                            <mat-row *matRowDef="let row; columns: identificationDisplayedColumns"></mat-row>
                          </mat-table>
                        </mat-form-field>
                      </div>
                    </div>
                  </div>
                  <div *ngIf="renderAsMobile" id="idListMobile">
                    <mat-form-field appearance="outline" floatLabel="always">
                      <mat-list>
                        <mat-divider></mat-divider>
                        <ng-container *ngFor="let idItem of tlm.identifications">
                          <textarea id="idTextArea" matInput rows="6">{{
                            '\r\n' +
                              'Identification Type: ' +
                              idItem.type +
                              '\r\n' +
                              'Id Number: ' +
                              idItem.value +
                              '\r\n' +
                              'State: ' +
                              idItem.state.code +
                              '\r\n' +
                              'Expiration Date: ' +
                              parseISOString(idItem.expirationDate)
                          }}</textarea>
                          <mat-divider></mat-divider>
                        </ng-container>
                      </mat-list>
                    </mat-form-field>
                  </div>
                </div>
              </mat-card>
            </div>
          </div>
          <br />
          <mat-divider></mat-divider>
          <br />
          <div class="row" id="backgroundTitle">
            <div class="col-12">
              <h4>
                <span class="d-none d-lg-inline">Background Information</span>
                <span class="d-inline d-lg-none">Background Info</span>
              </h4>
            </div>
          </div>
          <div class="row">
            <div class="col-12 tlm-profile-section" id="backgroundSection" (mouseenter)="mouseEnter('backgroundInfo')"
              (mouseleave)="mouseLeave('backgroundInfo')" [class.mat-elevation-z2]="!isActive.backgroundInfo"
              [class.mat-elevation-z20]="isActive.backgroundInfo">
              <div>
                <mat-card id="backgroundInfo">
                  <div *ngIf="tlm.description">
                    <div class="row">
                      <div class="col-12">
                        <mat-form-field appearance="outline">
                          <mat-label>Physical Description</mat-label>
                          <input id="hiddenDesc" value="N/A" matInput style="height: 0px" />
                          <div id="descriptionField" [innerHTML]="tlm.description"></div>
                        </mat-form-field>
                      </div>
                    </div>
                  </div>
                  <div *ngIf="!tlm.description">
                    <div class="row">
                      <div class="col-12">
                        <mat-form-field appearance="outline" floatLabel="always">
                          <mat-label>Physical Description</mat-label>
                          <input id="Description" matInput value="None" readonly />
                        </mat-form-field>
                      </div>
                    </div>
                  </div>
                  <div *ngIf="tlm.backgroundInfo">
                    <div class="row">
                      <div class="col-12">
                        <mat-form-field appearance="outline">
                          <mat-label>Background</mat-label>
                          <input id="hiddenBackground" value="N/A" matInput style="height: 0px" />
                          <div id="backgroundField" [innerHTML]="tlm.backgroundInfo"></div>
                        </mat-form-field>
                      </div>
                    </div>
                  </div>
                  <div *ngIf="!tlm.backgroundInfo">
                    <div class="row">
                      <div class="col-12">
                        <mat-form-field appearance="outline" floatLabel="always">
                          <mat-label>Background</mat-label>
                          <input id="Background" matInput value="None" readonly />
                        </mat-form-field>
                      </div>
                    </div>
                  </div>
                  <div *ngIf="tlm.criminalHistory">
                    <div class="row">
                      <div class="col-12">
                        <mat-form-field appearance="outline">
                          <mat-label>Criminal History</mat-label>
                          <input id="hiddenCriminal" value="N/A" matInput style="height: 0px" />
                          <div id="criminalHistoryField" [innerHTML]="tlm.criminalHistory"></div>
                        </mat-form-field>
                      </div>
                    </div>
                  </div>
                  <div *ngIf="!tlm.criminalHistory">
                    <div class="row">
                      <div class="col-12">
                        <mat-form-field appearance="outline" floatLabel="always">
                          <mat-label>Criminal History</mat-label>
                          <input id="CriminalHistory" matInput value="None" readonly />
                        </mat-form-field>
                      </div>
                    </div>
                  </div>
                  <div *ngIf="tlm.gracieNumbers.length > 0">
                    <div class="row">
                      <div class="col-12">
                        <mat-form-field appearance="outline" floatLabel="always">
                          <mat-label>Gracie Numbers</mat-label>
                          <mat-list>
                            <ng-container
                              *ngFor="let gracieNumber of tlm.gracieNumbers; last as lastGracie; let i = index">
                              <mat-list-item>
                                <input [id]="'gracieNumber' + i.toString()" matInput [value]="gracieNumber.value"
                                  readonly />
                                <mat-divider *ngIf="!lastGracie"></mat-divider>
                              </mat-list-item>
                            </ng-container>
                          </mat-list>
                        </mat-form-field>
                      </div>
                    </div>
                  </div>
                </mat-card>
              </div>
            </div>
          </div>
          <br />
          <mat-divider></mat-divider>
          <br />
          <div class="row" id="boloTitle">
            <div class="col-12">
              <h4>
                <span class="d-none d-lg-inline">BOLO </span>
                <span class="d-inline d-lg-none">BOLO</span>
                <span *ngIf="tlm.isActiveBolo">&nbsp;(ACTIVE)</span>
              </h4>
            </div>
          </div>
          <div class="row">
            <div class="col-12 tlm-profile-section" id="boloSection" (mouseenter)="mouseEnter('boloInfo')"
              (mouseleave)="mouseLeave('boloInfo')" [class.mat-elevation-z2]="!isActive.boloInfo"
              [class.mat-elevation-z20]="isActive.boloInfo">
              <mat-card id="boloInfo">
                <div *ngIf="tlm.bolos.length > 0">
                  <div class="row">
                    <div class="col-12 col-md-6">
                      <mat-form-field appearance="outline">
                        <mat-label>From Date</mat-label>
                        <input id="boloFromDate" matInput [value]="getFirstSortedBolo().startDateForDisplay" readonly />
                      </mat-form-field>
                    </div>
                    <div class="col-12 col-md-6">
                      <mat-form-field appearance="outline">
                        <mat-label>To Date</mat-label>
                        <input id="boloToDate" matInput [value]="getFirstSortedBolo().endDateForDisplay" readonly />
                      </mat-form-field>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-12">
                      <mat-form-field appearance="outline">
                        <mat-label>Reason</mat-label>
                        <textarea matInput cdkTextareaAutosize #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="5"
                          cdkAutosizeMaxRows="25" [value]="getFirstSortedBolo().reasonForDisplay" readonly></textarea>
                      </mat-form-field>
                    </div>
                  </div>
                </div>
                <span id="noBoloHistory" *ngIf="tlm.bolos.length === 0">No Bolo History</span>
                <div class="row">
                  <mat-accordion class="col-12" *ngIf="getBolosWithoutFirstBolo().length > 0">
                    <mat-expansion-panel>
                      <mat-expansion-panel-header>
                        <mat-panel-description> Expand to See Bolo History </mat-panel-description>
                      </mat-expansion-panel-header>
                      <div id="BolosListExpanded"
                        *ngFor="let boloItem of getBolosWithoutFirstBolo(); let bIndex = index">
                        <mat-form-field>
                          <div class="row">
                            <div class="col-12 col-md-6">
                              <mat-form-field appearance="outline">
                                <mat-label>Date</mat-label>
                                <input [id]="'boloFromDate' + bIndex" matInput [value]="boloItem.startDateForDisplay"
                                  readonly />
                              </mat-form-field>
                            </div>
                            <div class="col-12 col-md-6">
                              <mat-form-field appearance="outline">
                                <mat-label>Time</mat-label>
                                <input [id]="'boloToDate' + bIndex" matInput [value]="boloItem.endDateForDisplay"
                                  readonly />
                              </mat-form-field>
                            </div>
                          </div>
                          <div class="row">
                            <div class="col-12">
                              <mat-form-field appearance="outline">
                                <mat-label>Reason</mat-label>
                                <textarea matInput cdkTextareaAutosize #autosize="cdkTextareaAutosize"
                                  cdkAutosizeMinRows="5" cdkAutosizeMaxRows="25" [value]="boloItem.reasonForDisplay"
                                  readonly></textarea>
                              </mat-form-field>
                            </div>
                          </div>
                        </mat-form-field>
                      </div>
                    </mat-expansion-panel>
                  </mat-accordion>
                </div>
              </mat-card>
            </div>
          </div>
          <br />
          <mat-divider></mat-divider>
          <br />
          <div class="row" id="contactHistoryTitle">
            <div class="col-12">
              <h4>Contact History</h4>
            </div>
          </div>
          <div class="row">
            <div class="col-12 tlm-profile-section" id="contactHistorySection" (mouseenter)="mouseEnter('contactHistory')"
              (mouseleave)="mouseLeave('contactHistory')" [class.mat-elevation-z2]="!isActive.contactHistory"
              [class.mat-elevation-z20]="isActive.contactHistory">
              <mat-card id="contactHistory">
                <div *ngIf="getContactHistory().length > 0">
                  <div class="row">
                    <div class="col-12 col-md-6">
                      <mat-form-field appearance="outline">
                        <mat-label>Date</mat-label>
                        <input id="contactHistoryDate" matInput
                          [value]="tlm.getContactDateForDisplay(getFirstContact())" readonly />
                      </mat-form-field>
                    </div>
                    <div class="col-12 col-md-6">
                      <mat-form-field appearance="outline">
                        <mat-label>Time</mat-label>
                        <input id="contactHistoryTime" matInput
                          [value]="tlm.getContactTimeForDisplay(getFirstContact())" readonly />
                      </mat-form-field>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-12">
                      <mat-form-field appearance="outline">
                        <mat-label>Location</mat-label>
                        <span [matTooltip]="getFirstContact().location.address">
                          <input id="contactHistoryLocation" matInput
                            [value]="getFirstContact().location.locationForDisplay" readonly />
                        </span>
                      </mat-form-field>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-12">
                      <mat-form-field appearance="outline">
                        <mat-label>Notes</mat-label>
                        <textarea matInput cdkTextareaAutosize #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="5"
                          cdkAutosizeMaxRows="25" [value]="getFirstContact().notes" readonly></textarea>
                      </mat-form-field>
                    </div>
                  </div>
                </div>
                <span id="noContactHistory" *ngIf="getContactHistory().length === 0">No Contact History</span>
                <div class="row">
                  <mat-accordion class="col-12" *ngIf="getRestOfContactHistory().length > 0">
                    <mat-expansion-panel>
                      <mat-expansion-panel-header>
                        <mat-panel-description> Expand to See Contact History </mat-panel-description>
                      </mat-expansion-panel-header>
                      <div id="contactHistoryListExpanded"
                        *ngFor="let contactItem of getRestOfContactHistory(); let ciIndex = index">
                        <mat-form-field>
                          <div class="row">
                            <div class="col-12 col-md-6">
                              <mat-form-field appearance="outline">
                                <mat-label>Date</mat-label>
                                <input [id]="'contactHistoryDate' + ciIndex" matInput
                                  [value]="tlm.getContactDateForDisplay(contactItem)" readonly />
                              </mat-form-field>
                            </div>
                            <div class="col-12 col-md-6">
                              <mat-form-field appearance="outline">
                                <mat-label>Time</mat-label>
                                <input [id]="'contactHistoryTime' + ciIndex" matInput
                                  [value]="tlm.getContactTimeForDisplay(contactItem)" readonly />
                              </mat-form-field>
                            </div>
                          </div>
                          <div class="row">
                            <div class="col-12">
                              <mat-form-field appearance="outline">
                                <mat-label>Location</mat-label>
                                <input [id]="'contactHistoryLocation' + ciIndex" matInput
                                  [value]="contactItem.location.locationForDisplay"
                                  [matTooltip]="contactItem.location.address" readonly />
                              </mat-form-field>
                            </div>
                          </div>
                          <div class="row">
                            <div class="col-12">
                              <mat-form-field appearance="outline">
                                <mat-label>Notes</mat-label>
                                <textarea matInput cdkTextareaAutosize #autosize="cdkTextareaAutosize"
                                  cdkAutosizeMinRows="5" cdkAutosizeMaxRows="25" [value]="contactItem.notes"
                                  readonly></textarea>
                              </mat-form-field>
                            </div>
                          </div>
                        </mat-form-field>
                      </div>
                    </mat-expansion-panel>
                  </mat-accordion>
                </div>
              </mat-card>
            </div>
          </div>
          <br />
          <mat-divider></mat-divider>
          <br />
        </div>
      </div>
    </div>
  </div>
</div>
<div>
  <div *ngFor="let item of menuList" class="text-left">
    <button
      *ngIf="item.name === activeMenu"
      [id]="item.id + 'Menu'"
      [routerLink]="item.route"
      queryParamsHandling="merge"
      mat-raised-button
      color="primary"
      style="width: 100%"
      class="text-left"
    >
      {{ item.name }}
    </button>
    <button
      *ngIf="item.name !== activeMenu"
      [id]="item.id + 'Menu'"
      [routerLink]="item.route"
      queryParamsHandling="merge"
      mat-button
      color="primary"
      style="width: 100%"
      class="text-left"
    >
      {{ item.name }}
    </button>
  </div>
</div>

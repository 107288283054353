import { Component, Input } from '@angular/core';
import { UntypedFormArray, UntypedFormGroup } from '@angular/forms';
import * as sjv from 'simple-js-validator';
import { StateModel } from '../../../../shared';
import { EditThreatUtilities, MyErrorStateMatcher, EditThreatUtilitiesIdentificationsForm, editThreatFormNames } from '../../utilities';

@Component({
  moduleId: module.id,
  selector: 'app-tlm-edit-threat-identifications',
  templateUrl: './identifications.component.html',
  styleUrls: ['./identifications.component.scss']
})
export class EditThreatIdentificationsComponent {
  @Input() states: StateModel[];
  @Input() identificationTypes: string[];
  @Input() identificationsFormArray: UntypedFormArray = new UntypedFormArray([]);
  @Input() matAppearance: string;
  @Input() errorMatcher: MyErrorStateMatcher;
  boundRemoveItem: (n: number) => void;
  boundIsPopulated: () => boolean;
  boundMove: (n1: number, n2: number) => void;

  constructor(private editThreatUtils: EditThreatUtilities, private editThreatUtilsIdentificationsForm: EditThreatUtilitiesIdentificationsForm) {
    this.boundRemoveItem = this.removeItemFromList.bind(this);
    this.boundIsPopulated = this.isPopulated.bind(this);
    this.boundMove = this.move.bind(this);
  }

  isPopulated(identificationFG: UntypedFormGroup): boolean {
    if (
      identificationFG &&
      ((identificationFG.get(editThreatFormNames.identificationType) &&
        sjv.isNotEmpty(identificationFG.get(editThreatFormNames.identificationType).value)) ||
        (identificationFG.get(editThreatFormNames.identificationState) &&
          sjv.isNotEmpty(identificationFG.get(editThreatFormNames.identificationState).value)) ||
        (identificationFG.get(editThreatFormNames.identificationNumber) &&
          sjv.isNotEmpty(identificationFG.get(editThreatFormNames.identificationNumber).value)) ||
        (identificationFG.get(editThreatFormNames.identificationExpirationDate) &&
          sjv.isNotEmpty(identificationFG.get(editThreatFormNames.identificationExpirationDate).value)))
    ) {
      return true;
    }
    return false;
  }

  createAndAddItemToListIfPopulated(): void {
    return this.editThreatUtils.createAndAddItemToListIfPopulated(
      this.identificationsFormArray,
      this.editThreatUtilsIdentificationsForm.createEmptyItemFormGroup.bind(this.editThreatUtilsIdentificationsForm),
      this.isPopulated,
      'identifications'
    );
  }

  removeItemFromList(index: number): void {
    let reset = false;
    if (this.identificationsFormArray.length === 1) {
      reset = true;
    }
    return this.editThreatUtils.removeItemFromList(
      this.identificationsFormArray,
      this.editThreatUtilsIdentificationsForm.createEmptyItemFormGroup.bind(this.editThreatUtilsIdentificationsForm),
      index,
      reset
    );
  }

  move(oldIndex, newIndex) {
    return this.editThreatUtils.moveItemInList(this.identificationsFormArray, oldIndex, newIndex);
  }
}

import { ModuleWithProviders, NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { SharedModule } from '../../shared/shared.module';
import { EditGuard } from '../../auth';
import { NgxGalleryModule } from 'ngx-gallery-9';
import { NgxSpinnerModule } from 'ngx-spinner';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgxTrimDirectiveModule } from 'ngx-trim-directive';

import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatSelectModule } from '@angular/material/select';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTooltipModule } from '@angular/material/tooltip';

import { EditThreatAddressesFormComponent, EditThreatAddressesComponent, EditThreatAddressComponent } from './addresses-form';
import { EditThreatBackgroundInfoComponent } from './background-info/background-info.component';
import { EditThreatBasicInfoComponent } from './basic-info/basic-info.component';
import { EditThreatAliasesComponent, EditThreatAliasComponent } from './basic-info/aliases';
import { EditThreatEmailsComponent, EditThreatEmailComponent } from './basic-info/emails';
import { EditThreatGracieNumbersComponent, EditThreatGracieNumberComponent } from './background-info/gracie-numbers';
import { EditThreatPhonesComponent, EditThreatPhoneComponent } from './basic-info/phones';
import { EditThreatVehiclesComponent, EditThreatVehicleComponent } from './basic-info/vehicles';
import { EditThreatEmploymentHistoryComponent } from './employment-history';
import { EditThreatErrorModalComponent } from './error-modal';
import { EditThreatIdentificationsFormComponent, EditThreatIdentificationsComponent, EditThreatIdentificationComponent } from './identification-form';
import { EditThreatContactHistoryComponent, EditThreatContactComponent, EditThreatContactHistoryFormComponent } from './contact-history-form';
import { EditThreatBolosFormComponent, EditThreatBolosComponent, EditThreatBoloComponent } from './bolos-form';
import { EditThreatPhotosComponent } from './photos';
import { EditThreatVideosComponent, EditThreatVideoComponent, EditThreatVideosFormComponent } from './videos-form';
import { EditThreatSocialMediasComponent, EditThreatSocialMediaComponent, EditThreatSocialFormComponent } from './social-media-form';
import {
  EditThreatUtilities,
  EditThreatUtilitiesAddressesForm,
  EditThreatUtilitiesBackgroundInfoForm,
  EditThreatUtilitiesBasicInfoForm,
  EditThreatUtilitiesIdentificationsForm,
  EditThreatUtilitiesBolosForm,
  EditThreatUtilitiesPhotosForm,
  EditThreatUtilitiesContactHistoryForm,
  EditThreatUtilitiesProfileType,
  EditThreatUtilitiesSocialForm,
  EditThreatUtilitiesVideosForm
} from './utilities';

import { CoreModule, ProfileTypeApiResolver, ContactLocationServiceResolver, BoloEntryComponent } from '../../core';
import { EditThreatComponent } from './edit-threat.component';

import { QuillModule } from 'ngx-quill';
import { EditThreatResolver } from './edit-threat.resolver';

const routing: ModuleWithProviders<unknown> = RouterModule.forChild([
  {
    path: 'profile/:tlmId/edit',
    component: EditThreatComponent,
    canActivate: [EditGuard],
    resolve: {
      resolved: EditThreatResolver,
      profileTypes: ProfileTypeApiResolver,
      locations: ContactLocationServiceResolver
    },
    children: [
      { path: '', redirectTo: 'basic', pathMatch: 'full' },
      { path: 'addresses', component: EditThreatAddressesFormComponent },
      { path: 'contact', component: EditThreatContactHistoryFormComponent },
      { path: 'bolos', component: EditThreatBolosFormComponent },
      { path: 'background', component: EditThreatBackgroundInfoComponent },
      { path: 'basic', component: EditThreatBasicInfoComponent },
      { path: 'employment', component: EditThreatEmploymentHistoryComponent },
      {
        path: 'identifications',
        component: EditThreatIdentificationsFormComponent
      },
      { path: 'photos', component: EditThreatPhotosComponent },
      { path: 'videos', component: EditThreatVideosComponent },
      { path: 'social', component: EditThreatSocialFormComponent }
    ]
  }
]);

@NgModule({
    declarations: [
        EditThreatErrorModalComponent,
        EditThreatComponent,
        EditThreatAddressesFormComponent,
        EditThreatAddressesComponent,
        EditThreatAddressComponent,
        EditThreatContactHistoryComponent,
        EditThreatContactComponent,
        EditThreatContactHistoryFormComponent,
        EditThreatBolosFormComponent,
        EditThreatBolosComponent,
        EditThreatBoloComponent,
        EditThreatBackgroundInfoComponent,
        EditThreatBasicInfoComponent,
        EditThreatAliasesComponent,
        EditThreatAliasComponent,
        EditThreatEmailsComponent,
        EditThreatEmailComponent,
        EditThreatGracieNumberComponent,
        EditThreatGracieNumbersComponent,
        EditThreatPhonesComponent,
        EditThreatPhoneComponent,
        EditThreatSocialMediaComponent,
        EditThreatSocialMediasComponent,
        EditThreatVehiclesComponent,
        EditThreatVehicleComponent,
        EditThreatEmploymentHistoryComponent,
        EditThreatIdentificationsFormComponent,
        EditThreatIdentificationsComponent,
        EditThreatIdentificationComponent,
        EditThreatPhotosComponent,
        EditThreatSocialFormComponent,
        EditThreatSocialMediaComponent,
        EditThreatSocialMediasComponent,
        EditThreatVideosComponent,
        EditThreatVideoComponent,
        EditThreatVideosFormComponent
    ],
    imports: [
        routing,
        NgxGalleryModule,
        SharedModule,
        QuillModule.forRoot(),
        CoreModule,
        NgxSpinnerModule,
        BrowserAnimationsModule,
        MatFormFieldModule,
        MatInputModule,
        MatCardModule,
        MatDividerModule,
        MatTabsModule,
        MatButtonModule,
        MatButtonToggleModule,
        MatSelectModule,
        MatDatepickerModule,
        MatListModule,
        MatTableModule,
        MatNativeDateModule,
        MatDialogModule,
        MatCheckboxModule,
        MatIconModule,
        MatTooltipModule,
        MatAutocompleteModule,
        NgxTrimDirectiveModule
    ],
    exports: [
        EditThreatComponent,
        EditThreatAddressesFormComponent,
        EditThreatContactHistoryFormComponent,
        EditThreatBolosFormComponent,
        EditThreatBackgroundInfoComponent,
        EditThreatBasicInfoComponent,
        EditThreatEmploymentHistoryComponent,
        EditThreatIdentificationsFormComponent,
        EditThreatPhotosComponent,
        EditThreatSocialFormComponent,
        EditThreatSocialMediaComponent,
        EditThreatSocialMediasComponent,
        EditThreatVideosComponent,
        EditThreatVideoComponent,
        EditThreatVideosFormComponent
    ],
    providers: [
        ContactLocationServiceResolver,
        ProfileTypeApiResolver,
        EditThreatResolver,
        EditThreatUtilities,
        EditThreatUtilitiesAddressesForm,
        EditThreatUtilitiesBackgroundInfoForm,
        EditThreatUtilitiesBasicInfoForm,
        EditThreatUtilitiesIdentificationsForm,
        EditThreatUtilitiesBolosForm,
        EditThreatUtilitiesPhotosForm,
        EditThreatUtilitiesContactHistoryForm,
        EditThreatUtilitiesProfileType,
        EditThreatUtilitiesSocialForm,
        EditThreatUtilitiesVideosForm
    ]
})
export class EditThreatModule {}

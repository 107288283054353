import { Injectable, Inject } from '@angular/core';
import { Observable, of } from 'rxjs';
import { IRaceApiService } from './race-api.interface';
import { ILogger } from '../logger';

@Injectable()
export class RaceApiService implements IRaceApiService {
  data: string[];

  constructor(@Inject('LoggerInjected') private logger: ILogger) {
    this.data = [
      'American Indian/Alaska Native',
      'Asian Indian',
      'Black/African American',
      'Chinese',
      'Filipino',
      'Guamanian/Chamorro',
      'Japanese',
      'Korean',
      'Mexican/Hispanic/Latin American',
      'Native Hawaiian',
      'Samoan',
      'Vietnamese',
      'White/Caucasian',
      'Other Pacific Islander',
      'Other Asian',
      'Other'
    ];
  }

  list(): Observable<string[]> {
    this.logger.debug('RaceApiService.List', this.data);
    return of(this.data);
  }
}

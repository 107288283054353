<label [for]="componentId" class="tlm-label">{{ label }}</label>
<div class="input-group row">
  <quill-editor
    [id]="componentId"
    class="rich-text-editor col-12"
    [ngClass]="{ 'rich-text-editor-error': !fc.valid }"
    [formControl]="fc"
    [modules]="getToolbar()"
    trim="blur"
  >
  </quill-editor>
</div>
<br />
<br />
<div class="row">
  <div class="col-12">
    <mat-error *ngFor="let item of fc.errors | keyvalue">
      {{ item.value }}
    </mat-error>
  </div>
</div>

import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ProfileTypeModel } from '../../../shared';
import { Resolve } from '@angular/router';
import { ProfileTypeApiService } from './profile-type-api.service';

@Injectable()
export class ProfileTypeApiResolver implements Resolve<ProfileTypeModel[]> {
  constructor(private service: ProfileTypeApiService) {}

  resolve(): Observable<ProfileTypeModel[]> | Promise<ProfileTypeModel[]> | ProfileTypeModel[] {
    return this.service.list();
  }
}

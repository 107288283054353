import { Injectable, Inject } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { IAuthService } from './auth-service.interface';

@Injectable()
export class EditGuard implements CanActivate {
  constructor(@Inject('TlmAuthService') private authService: IAuthService, private router: Router) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    this.authService.setCacheRedirect(state.url);
    if (this.authService.isLoggedIn() && this.authService.hasEditAccess()) {
      return true;
    } else {
      this.router.navigate(['/incorrect-access']);
      return false;
    }
  }
}

import { Injectable, Inject } from '@angular/core';
import * as sjv from 'simple-js-validator';
import { PhotoModel, TlmModel } from '../../../shared';
import { UntypedFormControl, UntypedFormBuilder, UntypedFormGroup, UntypedFormArray } from '@angular/forms';
import { editThreatFormNames } from './edit-threat-form-names';
import { EditThreatUtilities } from './edit-threat-utilities.service';
import { IAuthService } from '../../../auth';
import * as _ from 'lodash';

@Injectable()
export class EditThreatUtilitiesPhotosForm {
  constructor(private fb: UntypedFormBuilder, private editThreatUtils: EditThreatUtilities, @Inject('TlmAuthService') private authService: IAuthService) {}

  createEmptyItemFormGroup(): UntypedFormGroup {
    return this.fb.group({
      [editThreatFormNames.photo]: []
    });
  }

  initializeForm(profileTypeFC: UntypedFormControl): UntypedFormGroup {
    return this.fb.group(
      {
        [editThreatFormNames.photoList]: new UntypedFormArray([])
      },
      {
        validator: this.photoListRequiredIfBoloAndNonAdmin.bind(this, profileTypeFC)
      }
    );
  }

  mapFromTlm(tlm: TlmModel, profileTypeFC: UntypedFormControl): UntypedFormGroup {
    const photosFA = this.fb.array([]);

    if (tlm && tlm.photos && tlm.photos.length > 0) {
      tlm.photos.forEach((photo) => {
        photosFA.push(
          this.fb.group({
            [editThreatFormNames.photo]: [photo.key]
          })
        );
      });
    }

    return this.fb.group(
      {
        [editThreatFormNames.photoList]: photosFA
      },
      {
        validator: this.photoListRequiredIfBoloAndNonAdmin.bind(this, profileTypeFC)
      }
    );
  }

  mapToTlmPhotos(fg: UntypedFormGroup): PhotoModel[] {
    const mapped = new Array<PhotoModel>();
    let order = 0;
    const photosFA = fg.get(editThreatFormNames.photoList) as UntypedFormArray;

    photosFA.controls.forEach((photoFG) => {
      const photoKey = this.editThreatUtils.getValueOrSetAsUndefined(photoFG.get(editThreatFormNames.photo));
      if (sjv.isNotEmpty(photoKey)) {
        mapped.push(new PhotoModel(order, photoKey));
        order++;
      }
    });
    return mapped;
  }

  photoRequiredIfBoloAndNonAdmin(profileTypeFC: UntypedFormControl, photoFC: UntypedFormControl) {
    const errorCode = 'photoRequired';
    const errMsg = 'Photo: [Photo] is required';

    // scenario 1: if bolo and non-admin and required not touched/submitted; then dont display any error (wait until touched/submitted)
    // scenario 2: if bolo and non-admin and required touched/submitted and required empty; then display error
    // scenario 3: if bolo and non-admin and required touched/submitted and required populated; then no error to display
    // scenario 4: if bolo and admin; then no error to display
    // scenario 5: if not bolo; then no error to display

    if (_.get(profileTypeFC, 'value.type', '') === 'bolo' && !this.authService.hasAdminAccess()) {
      if (photoFC.touched && sjv.isEmpty(photoFC.value)) {
        // scenario 2: if bolo and non-admin and required touched/submitted and required empty; then display error
        this.editThreatUtils.addErrorToFormControl(photoFC, errorCode, errMsg);
        return { [errorCode]: errMsg };
      } else {
        // scenario 1: if bolo and non-admin and required not touched/submitted; then dont display any error (wait until touched/submitted)
        // scenario 3: if bolo and non-admin and required touched/submitted and required populated; then no error to display
        this.editThreatUtils.removeErrorFromFormControl(photoFC, errorCode);
        return null;
      }
    } else {
      // scenario 4: if bolo and admin; then no error to display
      // scenario 5: if not bolo; then no error to display
      this.editThreatUtils.removeErrorFromFormControl(photoFC, errorCode);
      return null;
    }
  }

  photoListRequiredIfBoloAndNonAdmin(profileTypeFC: UntypedFormControl, photoFG: UntypedFormGroup) {
    const photosFA = photoFG.get(editThreatFormNames.photoList) as UntypedFormArray;
    let photo0FC = new UntypedFormControl();
    if (photosFA && photosFA.controls.length > 0) {
      photo0FC = photosFA.controls[0].get(editThreatFormNames.photo) as UntypedFormControl;
    } else {
      if (photoFG.touched) {
        photo0FC.markAsTouched(); // if the form is touched (which would happen during save) mark this as touched so validation logic executes
      }
    }

    return this.photoRequiredIfBoloAndNonAdmin(profileTypeFC, photo0FC);
  }
}

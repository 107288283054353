<div id="BolosForm">
  <div class="row">
    <div class="col-12 add-bolo-container">
      <button type="button" id="addBolo" class="btn btn-link tlm-link" (click)="createAndAddItemToListIfPopulated()">+ Add Bolo</button>
    </div>
  </div>
  <mat-divider></mat-divider>
  <br />
  <div>
    <div *ngIf="bolosFormArray.hasError('boloDatesOverlap')" class="alert alert-danger" role="alert">
      {{ bolosFormArray.errors['boloDatesOverlap'] }}
    </div>
    <div
      *ngFor="let boloFormGroup of bolosFormArray.controls; let i = index"
      [ngClass]="{ 'alert alert-danger': bolosFormArray.hasError('boloDatesOverlap') }"
    >
      <div class="row">
        <div class="col-12">
          <app-tlm-edit-threat-bolo
            [profileTypeFormControl]="profileTypeFormControl"
            [boloFormGroup]="boloFormGroup"
            [index]="i"
            [length]="bolosFormArray.controls.length"
            [removeItem]="boundRemoveItem"
            [isPopulated]="boundIsPopulated"
            [move]="boundMove"
            [matAppearance]="matAppearance"
            [errorMatcher]="errorMatcher"
          >
          </app-tlm-edit-threat-bolo>
          <br />
          <mat-divider></mat-divider>
          <br />
        </div>
      </div>
    </div>
  </div>
</div>

import { Component, Input } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { MyErrorStateMatcher, editThreatFormNames } from '../../../pages/edit-threat/utilities';

@Component({
  moduleId: module.id,
  selector: 'app-tlm-bolo-entry',
  templateUrl: './bolo-entry.component.html'
})
export class BoloEntryComponent {
  @Input() boloFormGroup: UntypedFormGroup;
  @Input() matAppearance: string;
  @Input() errorMatcher: MyErrorStateMatcher;

  maxDate = new Date();
  minDate = new Date(2019, 0, 1);
  formNames: any;

  constructor() {
    this.formNames = editThreatFormNames;
  }

  displayFieldError(key, value): string {
    let display = value;
    if (key === 'matDatepickerParse') {
      display = 'Please enter a valid date MM/DD/YYYY';
    } else if (key === 'matDatepickerMin') {
      display = 'Please enter a date after 1/1/2019';
    }
    return display;
  }
}

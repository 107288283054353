import { Component, Input } from '@angular/core';
import { UntypedFormArray, UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import * as sjv from 'simple-js-validator';
import * as _ from 'lodash';
import { EditThreatUtilities, editThreatFormNames, MyErrorStateMatcher, EditThreatUtilitiesBolosForm } from '../../utilities';

@Component({
  moduleId: module.id,
  selector: 'app-tlm-edit-threat-bolos',
  templateUrl: './bolos.component.html',
  styleUrls: ['./bolos.component.scss']
})
export class EditThreatBolosComponent {
  @Input() profileTypeFormControl: UntypedFormControl;
  @Input() bolosFormArray: UntypedFormArray = new UntypedFormArray([]);
  @Input() matAppearance: string;
  @Input() errorMatcher: MyErrorStateMatcher;
  boundRemoveItem: (n: number) => void;
  boundIsPopulated: () => boolean;
  boundMove: (n1: number, n2: number) => void;
  formNames: any;

  constructor(private editThreatUtils: EditThreatUtilities, private editThreatUtilsBolo: EditThreatUtilitiesBolosForm) {
    this.formNames = editThreatFormNames;
    this.boundRemoveItem = this.removeItemFromList.bind(this);
    this.boundIsPopulated = this.isPopulated.bind(this, this.formNames);
    this.boundMove = this.move.bind(this);
  }

  isPopulated(formNames: any, fg: UntypedFormGroup): boolean {
    const startDateFC = fg.get(formNames.boloStartDate) as UntypedFormControl;
    const endDateFC = fg.get(formNames.boloEndDate) as UntypedFormControl;
    const reasonFC = fg.get(formNames.boloReason) as UntypedFormControl;

    if (
      (startDateFC && sjv.isNotEmpty(startDateFC.value)) ||
      (endDateFC && sjv.isNotEmpty(endDateFC.value)) ||
      (reasonFC && sjv.isNotEmpty(reasonFC.value))
    ) {
      return true;
    }
    return false;
  }

  createAndAddItemToListIfPopulated(): void {
    return this.editThreatUtils.createAndAddItemToListIfPopulated(
      this.bolosFormArray,
      this.editThreatUtilsBolo.createEmptyItemFormGroup.bind(this.editThreatUtilsBolo, this.profileTypeFormControl),
      this.isPopulated.bind(this, this.formNames),
      'bolos'
    );
  }

  removeItemFromList(index: number): void {
    this.editThreatUtils.removeItemFromList(
      this.bolosFormArray,
      this.editThreatUtilsBolo.createEmptyItemFormGroup.bind(this.editThreatUtilsBolo, this.profileTypeFormControl),
      index
    );

    this.bolosFormArray.updateValueAndValidity();
  }

  move(oldIndex, newIndex) {
    return this.editThreatUtils.moveItemInList(this.bolosFormArray, oldIndex, newIndex);
  }
}

import { ModuleWithProviders, NgModule, Optional, SkipSelf } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { SharedModule } from '../shared';
import {
  AddPersonSubMenuComponent,
  BoloEntryComponent,
  ContactEntryComponent,
  FormControlInputComponent,
  FormControlInputErrorFuncComponent,
  FormControlInputNoErrorComponent,
  FormControlRichTextEditorComponent,
  FormControlTextAreaComponent
} from './components';
import { HeaderComponent, HeaderEditPersonComponent } from './layout';
import {
  AddPersonService,
  ServiceUtilities,
  ImageUtilitiesService,
  FileService,
  PhoneTypeApiService,
  SocialMediaTypeApiService,
  RaceApiService,
  GenderApiService,
  StateApiService,
  IdentificationTypeApiService,
  ProfileTypeApiService
} from './services';
import { LoggerModule } from 'ngx-logger';
import { environment } from '../../environments/environment';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatSelectModule } from '@angular/material/select';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTooltipModule } from '@angular/material/tooltip';
import { AddThreatModalComponent } from '../core/layout/header/add-threat-modal/add-threat-modal.component';
import { AddBoloModalComponent } from '../core/layout/header/add-bolo-modal/add-bolo-modal.component';
import { AddProfileSelectionModalComponent } from '../core/layout/header/add-profile-selection-modal/add-profile-selection-modal.component';
import { NgbCollapseModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxTrimDirectiveModule } from 'ngx-trim-directive';
import { QuillModule } from 'ngx-quill';

@NgModule({
    imports: [
        SharedModule,
        HttpClientModule,
        LoggerModule,
        MatAutocompleteModule,
        MatButtonModule,
        MatDatepickerModule,
        MatListModule,
        MatFormFieldModule,
        MatIconModule,
        MatInputModule,
        MatDialogModule,
        MatDividerModule,
        MatSelectModule,
        MatStepperModule,
        MatTooltipModule,
        NgbCollapseModule,
        NgxTrimDirectiveModule,
        QuillModule.forRoot()
    ],
    declarations: [
        HeaderComponent,
        HeaderEditPersonComponent,
        AddPersonSubMenuComponent,
        AddThreatModalComponent,
        AddBoloModalComponent,
        AddProfileSelectionModalComponent,
        BoloEntryComponent,
        ContactEntryComponent,
        FormControlInputComponent,
        FormControlInputErrorFuncComponent,
        FormControlInputNoErrorComponent,
        FormControlRichTextEditorComponent,
        FormControlTextAreaComponent
    ],
    exports: [
        HeaderComponent,
        HeaderEditPersonComponent,
        AddPersonSubMenuComponent,
        BoloEntryComponent,
        ContactEntryComponent,
        FormControlInputComponent,
        FormControlInputErrorFuncComponent,
        FormControlInputNoErrorComponent,
        FormControlRichTextEditorComponent,
        FormControlTextAreaComponent
    ],
    providers: [
        AddPersonService,
        ServiceUtilities,
        ImageUtilitiesService,
        FileService,
        PhoneTypeApiService,
        ProfileTypeApiService,
        SocialMediaTypeApiService,
        RaceApiService,
        GenderApiService,
        StateApiService,
        IdentificationTypeApiService,
        { provide: 'LoggerInjected', useClass: environment.loggerType },
        { provide: 'TlmAuthService', useClass: environment.authServiceType },
        {
            provide: 'TlmApiServiceInjected',
            useClass: environment.tlmApiServiceType
        },
        {
            provide: 'TlmSearchServiceInjected',
            useClass: environment.tlmSearchServiceType
        },
        { provide: 'ImageServiceInjected', useClass: environment.imageServiceType },
        {
            provide: 'ContactLocationServiceInjected',
            useClass: environment.contactLocationServiceType
        }
    ]
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    if (parentModule) {
      throw new Error('CoreModule is already loaded. Import it in the AppModule only');
    }
  }

  static forRoot(): ModuleWithProviders<CoreModule> {
    return {
      ngModule: CoreModule,
      providers: []
    };
  }
}

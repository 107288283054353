import { UntypedFormControl, FormGroupDirective, NgForm } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import * as sjv from 'simple-js-validator';

export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: UntypedFormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    let inErrorState = false;

    if (control && sjv.isNotEmpty(control.errors) && (form.dirty || form.touched)) {
      inErrorState = true;
    }

    return inErrorState;
  }
}

import { Injectable, Inject } from '@angular/core';
import { Observable, of } from 'rxjs';
import * as _ from 'lodash';
import { TlmModel, TlmModelFactory } from '../../../shared';
import { ITlmSearchService } from './tlm-search-service.interface';
import { ILogger } from '../logger';
import { SearchModel } from '../../../shared/models/search.model';
const tlms = require('../tlm-api-service/mock/tlms.json');

@Injectable()
export class TlmSearchServiceMockService implements ITlmSearchService {
  data: TlmModel[] = new Array<TlmModel>();

  constructor(@Inject('LoggerInjected') private logger: ILogger) {
    this.data = tlms.map((item) => new TlmModelFactory().create(item));
  }

  search(searchModel: SearchModel): Observable<TlmModel[] | any[]> {
    this.logger.debug('TlmSearchServiceMockService.Search', this.data);
    const data = _.filter(this.data, (item) => {
      item['highlight'] = {};
      if (searchModel.searchTerm !== '') {
        let aliasTemp;
        let phoneTemp;
        let emailTemp;

        if (item.person && item.person.firstName && item.person.firstName.toLowerCase().indexOf(searchModel.searchTerm) > -1) {
          item['highlight']['person.firstName'] = item.person.firstName;
        } else if (item.person && item.person.lastName && item.person.lastName.toLowerCase().indexOf(searchModel.searchTerm) > -1) {
          item['highlight']['person.lastName'] = item.person.lastName;
        } else if (item.rockSecurityId && item.rockSecurityId.indexOf(searchModel.searchTerm) > -1) {
          item['highlight']['rockSecurityId'] = item.rockSecurityId;
        } else if (
          _.find(item.aliases, (alias) => {
            aliasTemp = alias;
            return alias.value.toLowerCase().indexOf(searchModel.searchTerm) > -1;
          })
        ) {
          item['highlight']['aliases.value'] = aliasTemp.value;
        } else if (
          _.find(item.phones, (phone) => {
            phoneTemp = phone;
            return phone.value.indexOf(searchModel.searchTerm) > -1;
          })
        ) {
          item['highlight']['phones.value'] = phoneTemp.value;
        } else if (
          _.find(item.emails, (email) => {
            emailTemp = email;
            return email.value.toLowerCase().indexOf(searchModel.searchTerm) > -1;
          })
        ) {
          item['highlight']['emails.value'] = emailTemp.value;
        }
      }
      if (searchModel && searchModel.location && searchModel.location !== '') {
        let historyTemp;
        if (
          _.find(item.contactHistory, (contactHistory) => {
            historyTemp = contactHistory;
            return contactHistory.location.address.toLowerCase().indexOf(searchModel.location.toLowerCase()) > -1;
          })
        ) {
          item['highlight']['contactHistory.location.address'] = historyTemp.location.address;
        }
      }

      if (searchModel && searchModel.gracieNumber && searchModel.gracieNumber !== '') {
        let gracieTemp;
        if (
          _.find(item.gracieNumbers, (gracieNumber) => {
            gracieTemp = gracieNumber;
            return gracieNumber.value.toLowerCase().indexOf(searchModel.gracieNumber) > -1;
          })
        ) {
          item['highlight']['gracieNumbers.value'] = gracieTemp.value;
        }
      }

      let itemHighlightIsEmpty = true;
      for (const prop in item['highlight']) {
        if (item['highlight'].hasOwnProperty(prop)) {
          itemHighlightIsEmpty = false;
        }
      }

      if (
        !itemHighlightIsEmpty &&
        (searchModel.contactHistoryEndDate ||
          searchModel.contactHistoryStartDate ||
          searchModel.gracieNumber ||
          searchModel.location ||
          searchModel.searchTerm)
      ) {
        return item;
      } else {
        return null;
      }
    });

    return of(data);
  }
}

<div class="container-fullwidth">
  <app-tlm-layout-header></app-tlm-layout-header>
</div>
<br />
<div class="container">
  <div class="row">
    <div class="col-lg-6 col-md-12">
      <div class="col-12" style="display: flex; justify-content: space-between">
        <label class="tlm-label">Filter Results:</label>
        <a class="tlm-label" href="javascript:void(0)" id="searchTipsToggler" (click)="showTips = !showTips"> No results? Click here for help</a>
      </div>
      <input
        type="text"
        (keyup)="[assignSearchBar($event.target.value), searchTerm$.next($event.target.value)]"
        placeholder="Search by Name, Email, Alias, RSID, or Phone Number"
        class="form-control"
        id="searchBar"
      />
    </div>
    <div class="col-lg-6 col-md-12">
      <div id="searchTips" *ngIf="showTips">
        Results will only appear if a search has been initiated and results match the search criteria, search * to show up to 100 threats. Once a
        search has begun and results are shown, you may click the row of a result to see more detailed information or click the 'View Profile' link to
        open the profile.
      </div>
    </div>
  </div>
  <br />
  <div class="row">
    <div class="col-10 col-md-3" style="padding-right: 0%">
      <input type="text" [readonly]="true" (click)="openDateRangeModal()" [placeholder]="dateRange" class="form-control" id="dateRange" />
    </div>
    <button mat-icon-button color="warn" id="cancel" (click)="clearDateRange()">
      <mat-icon class="fa fa-close fa-lg"></mat-icon>
    </button>

    <div class="col-12 col-md-3">
      <input type="text" (keyup)="[assignLocation($event.target.value)]" placeholder="Search by Location" class="form-control" id="locationSearch" />
    </div>
    <div class="col-12 col-md-3">
      <input
        type="text"
        (keyup)="[assignGracieNumber($event.target.value), searchTerm$.next($event.target.value)]"
        placeholder="Search by GRACIE Number"
        class="form-control"
        id="gracieSearch"
      />
    </div>
  </div>

  <div *ngIf="renderAsMobile">
    <hr />
    <div *ngFor="let item of mobileTlmDataSource; let i = index">
      <div class="container" (click)="routeToProfile(item.tlmId)">
        <div class="row">
          <h4 class="searchResultName" id="displayName">
            {{ item.displayName }}
          </h4>
        </div>
        <div class="row">
          <h6>Last Contact:</h6>
        </div>
        <div class="row">
          <div *ngIf="noData === false && item.hideDate === false">
            <span style="font-weight: bold">When: </span>
            {{ item.lastContact.displayDate }}
            <span style="font-weight: bold">Where: </span>{{ item.lastContact.location.locationForDisplay }}
          </div>
        </div>
        <br />
        <div class="row">
          <h6>Matched On:</h6>
        </div>
        <div class="row">
          <br />
          <div [id]="'searchMatches' + i.toLocaleString()" [style.color]="item.matchColor" [style.font-weight]="item.matchFontWeight">
            {{ item.matches }}
          </div>
        </div>
      </div>
      <hr />
    </div>
  </div>

  <div *ngIf="!renderAsMobile">
    <table
      class="mat-table-full-width"
      mat-table
      [dataSource]="tlmsDataSource"
      multiTemplateDataRows
      matSort
      matSortActive="displayName"
      matSortDirection="asc"
    >
      <ng-container matColumnDef="tlmId">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Profile Link</th>
        <td mat-cell *matCellDef="let item">
          <div *ngIf="noData === false">
            <a routerLink="/profile/{{ item.tlmId }}">View Profile</a>
          </div>
        </td>
      </ng-container>
      <ng-container matColumnDef="profileStatus">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Status</th>
        <td *matCellDef="let item" id="threatType" class="">
          <mat-icon
            matTooltip="Threat"
            *ngIf="item.profileStatus == 'threat'"
            id="threatIcon"
            class="fa fa-user-secret fa-2x tlm-profile-status-threat-foreground"
          ></mat-icon>
          <mat-icon
            matTooltip="Active BOLO"
            style="font-size: 1.5em"
            *ngIf="item.profileStatus == 'boloActive'"
            id="activeBoloIcon"
            class="fa fa-binoculars tlm-profile-status-bolo-active-foreground"
          ></mat-icon>
          <mat-icon
            matTooltip="Inactive BOLO"
            style="font-size: 1.5em"
            *ngIf="item.profileStatus == 'boloInactive'"
            id="inactiveBoloIcon"
            class="fa fa-binoculars tlm-profile-status-bolo-inactive-foreground"
          ></mat-icon>
        </td>
      </ng-container>
      <ng-container matColumnDef="displayName">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Display Name</th>
        <td mat-cell *matCellDef="let item" id="displayName" class="searchResultName">
          {{ item.displayName }}
        </td>
      </ng-container>
      <ng-container matColumnDef="contactDate">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Last Contact</th>
        <td mat-cell *matCellDef="let item">
          <div *ngIf="noData === false && item.hideDate === false">
            <span style="font-weight: bold">When: </span>
            {{ item.lastContact.displayDate }}
            <span style="font-weight: bold">Where: </span
            ><span [matTooltip]="item.lastContact.location.address">{{
              formatLocationForDisplay(item.lastContact.location.locationForDisplay)
            }}</span>
          </div>
        </td>
      </ng-container>
      <ng-container matColumnDef="matches">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Matches</th>
        <td mat-cell *matCellDef="let item" [style.color]="item.matchColor" [style.font-weight]="item.matchFontWeight">
          {{ item.matches }}
        </td>
      </ng-container>

      <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
      <ng-container matColumnDef="expandedDetail">
        <td mat-cell *matCellDef="let element" [attr.colspan]="columnsToDisplay.length">
          <div *ngIf="noData === false">
            <div class="example-element-detail" [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
              <div>
                <div><strong>Matching Score:</strong> {{ element.score }}</div>
                <div>Profile Type: {{ element.profileType }}</div>
                <div>Profile Status: {{ element.profileStatus }}</div>
                <div>First Name: {{ element.firstName }}</div>
                <div>Last Name: {{ element.lastName }}</div>
                <div>Alias(es): {{ element.aliases }}</div>
                <div>Email(s): {{ element.emails }}</div>
                <div>Phone(s): {{ element.phones }}</div>
                <div>Location(s): {{ element.location }}</div>
                <div>Create Date: {{ element.createDisplayDate }}</div>
                <div *ngIf="debug">
                  Raw:
                  <pre>{{ element | json }}</pre>
                </div>
              </div>
            </div>
          </div>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
      <tr
        mat-row
        *matRowDef="let element; columns: columnsToDisplay"
        class="example-element-row"
        [class.example-expanded-row]="expandedElement === element"
        (click)="expandedElement = expandedElement === element ? null : element"
      ></tr>
      <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row"></tr>
    </table>
  </div>
</div>

import { Injectable, Inject } from '@angular/core';
import { ImageUtilitiesService } from '../image-utilities-service';
import { FileService } from '../file-service/file.service';
import { ILogger } from '../logger';
import { IImageService } from './image.interface';

@Injectable()
export class ImageService implements IImageService {
  constructor(
    @Inject('LoggerInjected') private logger: ILogger,
    private imageUtilitiesService: ImageUtilitiesService,
    private fileService: FileService
  ) {}

  uploadImage(tlmId: string, imageFile: any): Promise<any> {
    return new Promise((resolve, reject) => {
      const extension = this.imageUtilitiesService.determineExtension(imageFile.name);

      const key = this.imageUtilitiesService.generateKey(tlmId, extension);

      const contentType = imageFile.type;

      this.imageUtilitiesService.signedPutUrl(key, extension, contentType).subscribe(
        (data) => {
          this.imageUtilitiesService.uploadImageBlobToSignedPutUrl(data.url, contentType, imageFile).subscribe(
            (data2) => {
              this.fileService.readAsDataURLFileReader(imageFile).then(
                (fileAsDataUrl) =>
                  resolve({
                    tlmId,
                    extension,
                    contentType,
                    key,
                    base64Image: fileAsDataUrl
                  }),
                (err3) => {
                  this.logger.error('uploadImage - ReadAsDataUrl - err: ', err3);
                  return reject(err3);
                }
              );
            },
            (err2) => {
              this.logger.error('uploadImage - BlobToUrl - err: ', err2);
              return reject(err2);
            }
          );
        },
        (err) => {
          this.logger.error('uploadImage - signedPutUrl - err: ', err);
          return reject(err);
        }
      );
    });
  }

  getImage(key: string): Promise<any> {
    return new Promise((resolve, reject) => {
      const promises = [];
      promises.push(this.getImageSmall(key));
      promises.push(this.getImageMedium(key));
      promises.push(this.getImageBig(key));

      Promise.all(promises)
        .then((result) =>
          resolve({
            small: result[0],
            medium: result[1],
            big: result[2]
          })
        )
        .catch((err) => {
          this.logger.error('There was an issue processing the image(s).  Please try again.', err);
          return reject(err);
        });
    });
  }

  getImageSized(key: string, size?: string): Promise<any> {
    const processInput = size ? key + size : key;
    return new Promise((resolve, reject) => {
      this.imageUtilitiesService.process(processInput).subscribe(
        (data) => resolve(data.url),
        (err) => {
          this.logger.error('getImage - err: ', err);
          return reject(err);
        }
      );
    });
  }

  getImageBig(key: string): Promise<any> {
    return this.getImageSized(key);
  }

  getImageMedium(key: string): Promise<any> {
    return this.getImageSized(key, '?w=400');
  }

  getImageSmall(key: string): Promise<any> {
    return this.getImageSized(key, '?w=150');
  }
}

import { Auth0AuthService } from '../app/auth/auth0.auth-service';
import { TlmApiService } from '../app/core/services/tlm-api-service/tlm-api.service';
import { TlmSearchService } from '../app/core/services/tlm-search-service/tlm-search-service.service';
import { Logger } from '../app/core/services/logger/logger.service';
import { ImageService } from '../app/core/services/image-service/image.service';
import { ContactLocationService } from '../app/core/services/contact-location-service/contact-location-service.service';

export const environment = {
  production: false,
  version: require('../../package.json').version,
  loggerType: Logger,
  authServiceType: Auth0AuthService,
  tlmApiServiceType: TlmApiService,
  imageServiceType: ImageService,
  tlmSearchServiceType: TlmSearchService,
  contactLocationServiceType: ContactLocationService
};

import { Component, OnInit, OnDestroy, Inject, Input } from '@angular/core';
import { TlmModel } from '../../../shared';
import { NgxGalleryOptions, NgxGalleryImage } from 'ngx-gallery-9';
import { Router, ActivatedRoute } from '@angular/router';
import { formatDate } from '@angular/common';
import { IImageService, MediaService } from '../../../core';
import { Subscription } from 'rxjs';
import * as _ from 'lodash';
import { IAuthService } from '../../../auth';

@Component({
  selector: 'app-tlm-bolo-profile',
  templateUrl: './bolo-profile.component.html',
  styleUrls: ['bolo-profile.component.scss']
})
export class BoloProfileComponent implements OnInit, OnDestroy {
  @Input() tlm: TlmModel;
  @Input() photo: NgxGalleryImage[];
  @Input() rowIndex: number;
  @Input() colIndex: number;

  dateToDisplay: string;
  boloNameForDisplay: string;
  isActive: any;
  ngxGalleryOptionsProfilePhoto: NgxGalleryOptions[];

  renderAsMobile$: Subscription;
  renderAsMobile: boolean;

  debug = false;

  constructor(
    private router: Router,
    @Inject('ImageServiceInjected') private imageService: IImageService,
    private mediaService: MediaService,
    private route: ActivatedRoute,
    @Inject('TlmAuthService') private authService: IAuthService
  ) {
    this.ngxGalleryOptionsProfilePhoto = [
      {
        width: '100%',
        height: '100%',
        thumbnails: false,
        imageSize: 'contain',
        imageArrows: false,
        thumbnailsArrows: false
      }
    ];

    this.isActive = {};
  }

  ngOnInit(): void {
    const boloToDisplay = this.getBoloToDisplay(this.tlm);
    this.dateToDisplay = this.formatBoloDate(boloToDisplay.endDate);
    this.boloNameForDisplay = this.abbreviateString(this.tlm.nameForDisplay, 18);

    if (this.tlm.photos && this.tlm.photos.length > 0) {
      this.imageService.getImage(this.tlm.photos[0].key).then((getImageResponse) => {
        this.photo = [getImageResponse];
      });
    }

    this.renderAsMobile$ = this.mediaService.getRenderAsMobileStatus().subscribe((event) => {
      this.renderAsMobile = event;
    });

    this.route.queryParams.subscribe((params) => {
      this.debug = false;
      if (params.debug && this.authService.hasAdminAccess()) {
        this.debug = params.debug === 'true';
      }
    });
  }

  ngOnDestroy() {
    if (this.renderAsMobile$) {
      this.renderAsMobile$.unsubscribe();
    }
  }

  formatBoloDate(date: string): string {
    const format = 'MM/dd/yyyy';
    const locale = 'en-US';
    let formattedDate;

    if (date) {
      formattedDate = formatDate(date, format, locale);
    }

    return formattedDate;
  }

  abbreviateString(string: string, cutOffLength: number): string {
    if (string) {
      if (string.length >= cutOffLength) {
        string = string.slice(0, cutOffLength) + '...';
      }
    }
    return string;
  }

  getBoloToDisplay(tlm): any {
    let result = {};

    if (tlm) {
      result = _.sortBy(tlm.bolos, ['startDate']).reverse()[0];
    }

    return result;
  }

  mouseEnter(id: string): void {
    this.isActive[id] = true;
  }

  mouseLeave(id: string): void {
    this.isActive[id] = false;
  }

  routeToProfile(): void {
    this.router.navigate(['/profile/' + this.tlm.tlmId]);
  }
}

export interface SubMenuConfig {
  id: string;
  name: string;
  route: string;
}

export interface AddPersonSubMenuConfig {
  addPersonBasicInfoForm: SubMenuConfig;
  addPersonIdentificationsForm: SubMenuConfig;
  addPersonBackgroundForm: SubMenuConfig;
  addPersonAddressForm: SubMenuConfig;
  // addPersonEmploymentForm: SubMenuConfig;
  addPersonSocialForm: SubMenuConfig;
  addPersonPhotosForm: SubMenuConfig;
  addPersonVideosForm: SubMenuConfig;
  addPersonContactHistoryForm: SubMenuConfig;
  addPersonBoloForm: SubMenuConfig;
}
// eslint-disable-next-line @typescript-eslint/naming-convention
export const ConfigAddPersonSubMenu: AddPersonSubMenuConfig = {
  addPersonBasicInfoForm: {
    id: 'addPersonBasicInfoForm',
    name: 'Basic Information',
    route: 'basic'
  },
  addPersonIdentificationsForm: {
    id: 'addPersonIdentificationsForm',
    name: 'Identifications',
    route: 'identifications'
  },
  addPersonBackgroundForm: {
    id: 'addPersonBackgroundForm',
    name: 'Background Info',
    route: 'background'
  },
  addPersonAddressForm: {
    id: 'addPersonAddressForm',
    name: 'Addresses',
    route: 'addresses'
  },
  // addPersonEmploymentForm: {
  //   id: 'addPersonEmploymentForm',
  //   name: 'Employment History',
  //   route: 'employment'
  // },
  addPersonSocialForm: {
    id: 'addPersonSocialForm',
    name: 'Social Media',
    route: 'social'
  },
  addPersonPhotosForm: {
    id: 'addPersonPhotosForm',
    name: 'Photos',
    route: 'photos'
  },
  addPersonVideosForm: {
    id: 'addPersonVideosForm',
    name: 'Videos',
    route: 'videos'
  },
  addPersonContactHistoryForm: {
    id: 'addPersonContactHistoryForm',
    name: 'Contact History',
    route: 'contact'
  },
  addPersonBoloForm: {
    id: 'addPersonBoloForm',
    name: 'BOLO',
    route: 'bolos'
  }
};

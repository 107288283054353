import { Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { IContactLocationService } from '../../core';

@Component({
  selector: 'app-tlm-admin',
  templateUrl: './admin.component.html'
})
export class AdminComponent implements OnInit, OnDestroy {
  @ViewChild(MatSort) sort: MatSort;
  locationsDataSource = new MatTableDataSource();
  columnsToDisplay = ['locationId', 'title', 'address', 'groupId'];
  locations$: Subscription;

  constructor(
    private titleService: Title,
    public route: Router,
    @Inject('ContactLocationServiceInjected')
    private contactLocationService: IContactLocationService
  ) {}

  ngOnInit(): void {
    this.titleService.setTitle('TLM - Admin');
    this.locationsDataSource.sort = this.sort;
    this.locationsDataSource.data = [];
    this.showLocationsOnPageLoad();
  }

  ngOnDestroy() {
    this.locations$.unsubscribe();
  }

  resetLocationList() {
    this.locationsDataSource.data = [];
    this.showLocationsOnPageLoad();
  }

  showLocationsOnPageLoad() {
    this.locations$ = this.contactLocationService.list().subscribe((data) => {
      this.locationsDataSource.data = data;

      if (this.locationsDataSource.data.length === 0) {
        this.locationsDataSource.data = [
          // eslint-disable-next-line @typescript-eslint/naming-convention
          { Title: 'No Locations Have Been Created' }
        ];
      }

      this.locationsDataSource.sortingDataAccessor = (dataToSort, sortHeaderId) => {
        if (typeof dataToSort[sortHeaderId] === 'string') {
          return dataToSort[sortHeaderId].toLocaleLowerCase();
        } else {
          return dataToSort[sortHeaderId];
        }
      };
    });
  }
}

import { Component, Input } from '@angular/core';
import * as _ from 'lodash';
import * as sjv from 'simple-js-validator';
import { UntypedFormArray, UntypedFormGroup } from '@angular/forms';
import { EditThreatUtilities } from '../../utilities';
import { MyErrorStateMatcher } from '../../utilities/edit-threat.error-matcher';
import { EditThreatUtilitiesBackgroundInfoForm } from '../../utilities/edit-threat-utilities-background-info-form.service';

@Component({
  moduleId: module.id,
  selector: 'app-tlm-edit-threat-gracie-numbers',
  templateUrl: './gracie-numbers.component.html'
})
export class EditThreatGracieNumbersComponent {
  @Input() gracieNumbersFormArray: UntypedFormArray = new UntypedFormArray([]);
  @Input() matAppearance: string;
  @Input() errorMatcher: MyErrorStateMatcher;
  boundRemoveItem: (n: number) => void;
  boundIsPopulated: () => boolean;

  constructor(private editThreatUtils: EditThreatUtilities, private editThreatUtilsBackgroundInfoForm: EditThreatUtilitiesBackgroundInfoForm) {
    this.boundRemoveItem = this.removeItemFromList.bind(this);
    this.boundIsPopulated = this.isPopulated.bind(this);
  }

  isPopulated(gracieNumberFG: UntypedFormGroup): boolean {
    return gracieNumberFG && gracieNumberFG.get('gracieNumber') && sjv.isNotEmpty(gracieNumberFG.get('gracieNumber').value);
  }

  createAndAddItemToListIfPopulated(): void {
    return this.editThreatUtils.createAndAddItemToListIfPopulated(
      this.gracieNumbersFormArray,
      this.editThreatUtilsBackgroundInfoForm.createEmptyGracieNumberFormGroup.bind(this.editThreatUtilsBackgroundInfoForm),
      this.isPopulated,
      'Gracie Numbers'
    );
  }

  removeItemFromList(index: number): void {
    let reset = false;
    if (this.gracieNumbersFormArray.length === 1) {
      reset = true;
    }
    return this.editThreatUtils.removeItemFromList(
      this.gracieNumbersFormArray,
      this.editThreatUtilsBackgroundInfoForm.createEmptyGracieNumberFormGroup.bind(this.editThreatUtilsBackgroundInfoForm),
      index,
      reset
    );
  }
}

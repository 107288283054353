<button class="button-cancel" matTooltip="Cancel" mat-dialog-close mat-button color="warn" id="cancel">
  <mat-icon class="fa fa-close fa-2x"></mat-icon>
</button>
<div class="tlm-add-profile-modal-header">
  <span>Add BOLO</span>
</div>
<mat-dialog-content>
  <mat-horizontal-stepper labelPosition="bottom" [linear]="true" #stepper>
    <mat-step label="Contact" [stepControl]="contactForm">
      <form id="contactFormStep" [formGroup]="contactForm">
        <app-tlm-contact-entry
          [contactHistoryFormGroup]="contactForm"
          index="0"
          [locations]="locations"
          matAppearance="outline"
          [errorMatcher]="errorMatcher"
        ></app-tlm-contact-entry>
        <div>
          <button
            mat-raised-button
            color="primary"
            style="float: right"
            type="button"
            (click)="onClickNext(stepper, contactForm)"
            [disabled]="contactForm.dirty && contactForm.invalid"
          >
            Next
          </button>
        </div>
      </form>
    </mat-step>
    <mat-step label="Info" [stepControl]="infoForm">
      <form id="infoFormStep" [formGroup]="infoForm">
        <div class="row">
          <div class="col-12">
            <app-tlm-form-control-input
              [fc]="infoForm.get(formNames.rockSecurityId)"
              label="Rock Security ID"
              componentId="rockSecurityId"
              matAppearance="outline"
              errorMatcher=""
            ></app-tlm-form-control-input>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <app-tlm-form-control-input-no-error
              [fc]="infoForm.get(formNames.firstName)"
              label="First Name"
              componentId="firstName"
              matAppearance="outline"
            ></app-tlm-form-control-input-no-error>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <app-tlm-form-control-input-no-error
              [fc]="infoForm.get(formNames.lastName)"
              label="Last Name"
              componentId="lastName"
              matAppearance="outline"
            ></app-tlm-form-control-input-no-error>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <app-tlm-form-control-input-no-error
              [fc]="infoForm.get(formNames.alias)"
              label="Alias"
              componentId="alias"
              matAppearance="outline"
            ></app-tlm-form-control-input-no-error>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <app-tlm-form-control-text-area
              [fc]="infoForm.get(formNames.physicalDescription)"
              label="Physical Description"
              componentId="physicalDescription"
              matAppearance="outline"
              minRows="3"
              maxRows="10"
            ></app-tlm-form-control-text-area>
          </div>
        </div>
        <div>
          <button mat-raised-button color="primary" matStepperPrevious style="float: left" type="button">Back</button>
          <button
            mat-raised-button
            color="primary"
            style="float: right"
            type="button"
            (click)="onClickNext(stepper, infoForm)"
            [disabled]="infoForm.dirty && infoForm.invalid"
          >
            Next
          </button>
        </div>
      </form>
    </mat-step>
    <mat-step label="Photo" [stepControl]="photoForm">
      <form id="photoFormStep" [formGroup]="photoForm">
        <div class="row">
          <div class="col-12">
            <mat-label>Photo</mat-label>
            <div style="float: right">
              <button mat-flat-button color="accent" (click)="onAddPhoto($event)" *ngIf="imageUrl === null">
                <span class="fa fa-plus"></span> Upload
              </button>
              <button mat-flat-button color="warn" (click)="onRemovePhoto($event)" *ngIf="imageUrl !== null">
                <span class="fa fa-minus"></span> Remove
              </button>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <input
              type="file"
              #addImage
              style="display: none"
              (change)="onInputPhoto(addImage.files, photoForm.get(formNames.photo))"
              accept="image/jpeg, image/png, image/tiff, image/bmp, image/gif"
            />
            <img [src]="imageUrl" height="200" *ngIf="imageUrl" alt="photo to add" />
            <mat-error *ngFor="let item of photoForm.get(formNames.photo).errors | keyvalue">
              {{ item.value }}
            </mat-error>
          </div>
        </div>
        <br />
        <div>
          <button mat-stroked-button matStepperPrevious style="float: left" type="button">Back</button>
          <button
            mat-raised-button
            color="primary"
            style="float: right"
            type="button"
            (click)="onClickNext(stepper, photoForm)"
            [disabled]="photoForm.dirty && photoForm.invalid"
          >
            Next
          </button>
        </div>
      </form>
    </mat-step>
    <mat-step label="BOLO" [stepControl]="boloForm">
      <form id="boloFormStep" [formGroup]="boloForm">
        <app-tlm-bolo-entry [boloFormGroup]="boloForm" matAppearance="outline" [errorMatcher]="errorMatcher"></app-tlm-bolo-entry>
        <div>
          <button mat-stroked-button matStepperPrevious style="float: left" type="button">Back</button>
          <button
            id="saveButton"
            mat-raised-button
            color="primary"
            type="submit"
            style="float: right"
            (click)="onClickSubmit(boloForm)"
            [disabled]="photoForm.dirty && photoForm.invalid"
          >
            Submit
          </button>
        </div>
      </form>
    </mat-step>
    <div></div>
  </mat-horizontal-stepper>
</mat-dialog-content>

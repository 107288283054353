import { Component, Input } from '@angular/core';
import * as sjv from 'simple-js-validator';
import { UntypedFormArray, UntypedFormGroup } from '@angular/forms';
import { StateModel } from '../../../../shared';
import { EditThreatUtilities, MyErrorStateMatcher, EditThreatUtilitiesBasicInfoForm, editThreatFormNames } from '../../utilities';

@Component({
  moduleId: module.id,
  selector: 'app-tlm-edit-threat-vehicles',
  templateUrl: './vehicles.component.html'
})
export class EditThreatVehiclesComponent {
  @Input() vehiclesFormArray: UntypedFormArray = new UntypedFormArray([]);
  @Input() states: StateModel[];
  @Input() matAppearance: string;
  @Input() errorMatcher: MyErrorStateMatcher;
  boundRemoveItem: (n: number) => void;
  boundIsPopulated: () => boolean;
  boundMove: (n1: number, n2: number) => void;

  constructor(private editThreatUtils: EditThreatUtilities, private editThreatUtilsBasicInfoForm: EditThreatUtilitiesBasicInfoForm) {
    this.boundRemoveItem = this.removeItemFromList.bind(this);
    this.boundIsPopulated = this.isPopulated.bind(this);
    this.boundMove = this.move.bind(this);
  }

  isPopulated(vehicleFG: UntypedFormGroup): boolean {
    if (
      vehicleFG &&
      ((vehicleFG.get(editThreatFormNames.vehicleYear) && sjv.isNotEmpty(vehicleFG.get(editThreatFormNames.vehicleYear).value)) ||
        (vehicleFG.get(editThreatFormNames.vehicleMakeModel) && sjv.isNotEmpty(vehicleFG.get(editThreatFormNames.vehicleMakeModel).value)) ||
        (vehicleFG.get(editThreatFormNames.vehicleLicensePlate) && sjv.isNotEmpty(vehicleFG.get(editThreatFormNames.vehicleLicensePlate).value)) ||
        (vehicleFG.get(editThreatFormNames.vehicleColor) && sjv.isNotEmpty(vehicleFG.get(editThreatFormNames.vehicleColor).value)) ||
        (vehicleFG.get(editThreatFormNames.vehicleState) && sjv.isNotEmpty(vehicleFG.get(editThreatFormNames.vehicleState).value)))
    ) {
      return true;
    }
    return false;
  }

  createAndAddItemToListIfPopulated(): void {
    return this.editThreatUtils.createAndAddItemToListIfPopulated(
      this.vehiclesFormArray,
      this.editThreatUtilsBasicInfoForm.createEmptyVehicleItemFormGroup.bind(this.editThreatUtilsBasicInfoForm),
      this.isPopulated,
      'vehicles'
    );
  }

  removeItemFromList(index: number): void {
    let reset = false;
    if (this.vehiclesFormArray.length === 1) {
      reset = true;
    }
    return this.editThreatUtils.removeItemFromList(
      this.vehiclesFormArray,
      this.editThreatUtilsBasicInfoForm.createEmptyVehicleItemFormGroup.bind(this.editThreatUtilsBasicInfoForm),
      index,
      reset
    );
  }

  move(oldIndex, newIndex) {
    return this.editThreatUtils.moveItemInList(this.vehiclesFormArray, oldIndex, newIndex);
  }
}

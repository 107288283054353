import { Component, Input } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { MyErrorStateMatcher, editThreatFormNames } from '../utilities/';
import { VideoModel, EditThreatFormNameModel } from '../../../shared';

@Component({
  selector: 'app-tlm-edit-threat-videos-form',
  templateUrl: './videos-form.component.html'
})
export class EditThreatVideosFormComponent {
  @Input() videosFormFormGroup: UntypedFormGroup = new UntypedFormGroup({});
  @Input() matAppearance: string;
  @Input() errorMatcher: MyErrorStateMatcher;
  @Input() videos: VideoModel[];
  formNames: EditThreatFormNameModel = editThreatFormNames;
}

import { ModuleWithProviders, NgModule } from '@angular/core';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { RouterModule } from '@angular/router';
import { NgxSpinnerModule } from 'ngx-spinner';
import { AdminGuard } from '../../auth';
import { CoreModule } from '../../core';
import { SharedModule } from '../../shared';
import { AdminComponent } from './admin.component';

const routing: ModuleWithProviders<unknown> = RouterModule.forChild([
  {
    path: 'admin',
    component: AdminComponent,
    canActivate: [AdminGuard]
  }
]);

@NgModule({
  declarations: [AdminComponent],
  imports: [routing, MatTableModule, MatSortModule, SharedModule, CoreModule, NgxSpinnerModule]
})
export class AdminModule {}

<div [formGroup]="vehicleFormGroup">
  <div class="row">
    <div class="col-10 col-md-11">
      <div class="row">
        <div class="col-12 col-md-5">
          <mat-form-field [appearance]="matAppearance">
            <mat-label>Vehicle Year</mat-label>
            <input [id]="'vehicleYear' + index" matInput [formControlName]="formNames.vehicleYear" [errorStateMatcher]="errorMatcher" />
          </mat-form-field>
        </div>
        <div class="col-12 col-md-7">
          <mat-form-field [appearance]="matAppearance">
            <mat-label>Make/Model</mat-label>
            <input [id]="'vehicleMakeModel' + index" matInput [formControlName]="formNames.vehicleMakeModel" [errorStateMatcher]="errorMatcher" />
          </mat-form-field>
        </div>
      </div>
      <div class="row">
        <div class="col-12 col-md-4">
          <mat-form-field [appearance]="matAppearance">
            <mat-label>Color</mat-label>
            <input [id]="'vehicleColor' + index" matInput [formControlName]="formNames.vehicleColor" [errorStateMatcher]="errorMatcher" />
          </mat-form-field>
        </div>
        <div class="col-12 col-md-5">
          <mat-form-field [appearance]="matAppearance">
            <mat-label>License Plate</mat-label>
            <input
              [id]="'vehicleLicensePlate' + index"
              matInput
              [formControlName]="formNames.vehicleLicensePlate"
              [errorStateMatcher]="errorMatcher"
            />
          </mat-form-field>
        </div>
        <div class="col-12 col-md-3">
          <mat-form-field [appearance]="matAppearance">
            <mat-label>State</mat-label>
            <mat-select [formControlName]="formNames.vehicleState" [id]="'vehicleState' + index" [errorStateMatcher]="errorMatcher">
              <mat-option>Select One</mat-option>
              <mat-option *ngFor="let state of states" [value]="state.name">
                {{ state.name }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
    </div>
    <div class="col-1 my-auto">
      <div class="row">
        <div class="col-12">
          <button mat-icon-button color="warn" [id]="'removeVehicle' + index" (click)="removeOnClick(index)" [disabled]="!removeActionEnabled()">
            <mat-icon class="fa fa-close fa-lg"></mat-icon>
          </button>
        </div>
      </div>
      <br />
      <div class="row">
        <div class="col-12">
          <button mat-icon-button color="accent" [id]="'moveVehicleUp' + index" (click)="moveUpOnClick(index)" [disabled]="!moveUpActionEnabled()">
            <mat-icon class="fa fa-chevron-up fa-sm"></mat-icon>
          </button>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <button
            mat-icon-button
            color="accent"
            [id]="'moveVehicleDown' + index"
            (click)="moveDownOnClick(index)"
            [disabled]="!moveDownActionEnabled()"
          >
            <mat-icon class="fa fa-chevron-down"></mat-icon>
          </button>
        </div>
      </div>
    </div>
  </div>
</div>

import { Component, Input } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { EditThreatUtilities, MyErrorStateMatcher } from '../../../utilities';
import { ContactLocationModel } from '../../../../../shared';

@Component({
  moduleId: module.id,
  selector: 'app-tlm-edit-threat-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class EditThreatContactComponent {
  @Input() contactHistoryFormGroup: UntypedFormGroup;
  @Input() matAppearance: string;
  @Input() errorMatcher: MyErrorStateMatcher;
  @Input() index: number;
  @Input() length: number;
  @Input() removeItem: (n: number) => void;
  @Input() isPopulated: () => boolean;
  @Input() locations: ContactLocationModel[];

  constructor(private editThreatUtils: EditThreatUtilities) {}

  removeActionEnabled(): boolean {
    return this.editThreatUtils.removeActionEnabled(this.length, this.isPopulated, this.contactHistoryFormGroup);
  }

  removeOnClick(index: number) {
    if (this.removeActionEnabled()) {
      this.removeItem(index);
    }
  }
}

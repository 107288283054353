<div class="row" id="summarySection">
  <div class="col-12">
    <mat-card
      (mouseenter)="mouseEnter('header')"
      (mouseleave)="mouseLeave('header')"
      [class.mat-elevation-z2]="!isActive.header"
      [class.mat-elevation-z20]="isActive.header"
      [id]="'boloCard-r' + rowIndex + '-c' + colIndex"
    >
      <div class="row">
        <div class="col-md-7 col-sm-12 photo-container">
          <ngx-gallery
            *ngIf="photo"
            [id]="'profilePhotoGallery-r' + rowIndex + '-c' + colIndex"
            #ngxGalleryProfilePhoto
            [options]="ngxGalleryOptionsProfilePhoto"
            [images]="photo"
          ></ngx-gallery>
        </div>
        <div
          class="col-md-5 col-sm-12 tlm-top-buffer"
          [ngClass]="{ 'bolo-profile-click': !renderAsMobile, 'bolo-profile-click-mobile': !renderAsMobile }"
          (click)="routeToProfile()"
        >
          <div class="row">
            <div class="col-12">
              <mat-toolbar color="primary">
                <span [id]="'boloCardDisplayName-r' + rowIndex + '-c' + colIndex" *ngIf="tlm.nameForDisplay.length < 17">{{
                  boloNameForDisplay
                }}</span>
                <span [id]="'boloCardDisplayName-r' + rowIndex + '-c' + colIndex" class="reduce-font" *ngIf="tlm.nameForDisplay.length >= 17">{{
                  boloNameForDisplay
                }}</span>
              </mat-toolbar>
            </div>
          </div>
          <br />
          <div class="row">
            <div class="col-md-12 col-sm-12">
              <strong>Expires: </strong><span [id]="'expiration-r' + rowIndex + '-c' + colIndex">{{ dateToDisplay }}</span>
            </div>
          </div>
          <br />
          <div class="row">
            <div class="col-12">
              <mat-form-field appearance="outline">
                <mat-label>Reason</mat-label>
                <textarea
                  [id]="'profileHeaderAliases-r' + rowIndex + '-c' + colIndex"
                  matInput
                  [value]="getBoloToDisplay(tlm)?.reason"
                  readonly
                ></textarea>
              </mat-form-field>
            </div>
          </div>
        </div>
      </div>
    </mat-card>
    <div *ngIf="debug">
      <pre>Starts: {{ getBoloToDisplay(tlm).startDate }}</pre>
    </div>
  </div>
</div>

import { Injectable, Inject } from '@angular/core';
import { Observable, of } from 'rxjs';
import { IProfileStatusApiService } from './profile-status-api.interface';
import { ILogger } from '../logger';
import { ProfileStatusModel } from '../../../shared';

@Injectable()
export class ProfileStatusApiService implements IProfileStatusApiService {
  data: ProfileStatusModel[];

  constructor(@Inject('LoggerInjected') private logger: ILogger) {
    this.data = [
      {
        display: 'Active BOLO',
        status: 'boloActive',
        class: 'tlm-profile-status-bolo-active',
        classForegroundOnly: 'tlm-profile-status-bolo-active-foreground',
        classBackgroundOnly: 'tlm-profile-status-bolo-active-background',
        icon: 'fa-binoculars'
      },
      {
        display: 'Inactive BOLO',
        status: 'boloInactive',
        class: 'tlm-profile-status-bolo-inactive',
        classForegroundOnly: 'tlm-profile-status-bolo-inactive-foreground',
        classBackgroundOnly: 'tlm-profile-status-bolo-inactive-background',
        icon: 'fa-binoculars'
      },
      {
        display: 'Threat',
        status: 'threat',
        class: 'tlm-profile-status-threat',
        classForegroundOnly: 'tlm-profile-status-threat-foreground',
        classBackgroundOnly: 'tlm-profile-status-threat-background',
        icon: 'fa-user-secret'
      }
    ];
  }

  list(): Observable<ProfileStatusModel[]> {
    this.logger.debug('ProfileStatusApiService.List', this.data);
    return of(this.data);
  }
}

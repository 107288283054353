<div class="container-fullwidth">
  <app-tlm-layout-header></app-tlm-layout-header>
</div>
<br />

<div class="container">
  <br />
  <br />
  <table
    class="mat-table-full-width"
    mat-table
    [dataSource]="locationsDataSource"
    multiTemplateDataRows
    matSort
    matSortActive="address"
    matSortDirection="asc"
  >
    <caption>Contact Locations</caption>
    <ng-container matColumnDef="locationId">
      <th mat-header-cell *matHeaderCellDef mat-sort-header id="locHeader">Location ID</th>
      <td mat-cell *matCellDef="let item" id="locationId">
        {{ item.locationId }}
      </td>
    </ng-container>
    <ng-container matColumnDef="title">
      <th mat-header-cell *matHeaderCellDef mat-sort-header id="titleHeader">Title</th>
      <td mat-cell *matCellDef="let item" id="title">{{ item.title }}</td>
    </ng-container>
    <ng-container matColumnDef="address">
      <th mat-header-cell *matHeaderCellDef mat-sort-header id="addHeader">Address</th>
      <td mat-cell *matCellDef="let item" id="address">{{ item.address }}</td>
    </ng-container>
    <ng-container matColumnDef="groupId">
      <th mat-header-cell *matHeaderCellDef mat-sort-header id="grpHeader">Group ID</th>
      <td mat-cell *matCellDef="let item" id="groupId">{{ item.groupId }}</td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
    <tr mat-row *matRowDef="let row; columns: columnsToDisplay"></tr>
  </table>
</div>

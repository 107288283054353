                                      import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { AddPersonService, ConfigAddPersonSubMenu } from './core';
import { Router, NavigationEnd, NavigationStart, NavigationCancel, NavigationError, RouterEvent } from '@angular/router';
import { filter } from 'rxjs/operators';
import * as sjv from 'simple-js-validator';
import * as _ from 'lodash';
import { NgxSpinnerService } from 'ngx-spinner';

const sjv = require('simple-js-validator');

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'  
})
export class AppComponent implements OnInit, OnDestroy {
  spinnerType: string;

  spinnerTypes = [
    'ball-8bits',
    'ball-atom',
    'ball-beat',
    'ball-circus',
    'ball-climbing-dot',
    'ball-clip-rotate-multiple',
    'ball-fussion',
    'ball-grid-pulse',
    'ball-newton-cradle',
    'ball-pulse',
    'ball-scale-multiple',
    'ball-spin-fade-rotating',
    'ball-square-clockwise-spin', 
    'cog',    
    'fire',
    'line-scale',
    'line-spin-fade-rotating',    
    'pacman',
    'square-jelly-box',
    'square-loader',    
    'timer'    
  ];

  activeMenu$: Subscription;
  isLoading$: Subscription;
  isLoading = true;

  constructor(private addPersonService: AddPersonService, public route: Router, private spinner: NgxSpinnerService) {}

  ngOnInit(): void {
    this.activeMenu$ = this.route.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe((event: NavigationEnd) => {
      const url = this.removeQueryStringParamsFromUrl(event.url);

      const subUrl = url.substring(url.lastIndexOf('/') + 1);
      const key = _.findKey(ConfigAddPersonSubMenu, { route: subUrl });

      if (sjv.isNotEmpty(key)) {
        this.addPersonService.setActiveMenu(ConfigAddPersonSubMenu[key]);
      }

      window.scrollTo(0, 0); // temp fix until update to ng6.1 and then can use https://medium.com/@sarunint/scroll-position-restoration-in-angular-5a09df8bf626
    });

    this.isLoading$ = this.route.events.subscribe((routerEvent: RouterEvent) => {
      if (routerEvent instanceof NavigationStart) {
        this.spinnerType = this.spinnerTypes[Math.floor(Math.random() * this.spinnerTypes.length)];
        this.spinner.show();
      } else if (routerEvent instanceof NavigationEnd || routerEvent instanceof NavigationCancel || routerEvent instanceof NavigationError) {
        this.spinner.hide();
      }
    });
  }

  ngOnDestroy() {
    this.activeMenu$.unsubscribe();
    this.isLoading$.unsubscribe();
  }

  removeQueryStringParamsFromUrl(url: string): string {
    const qsIndex = url.indexOf('?');

    if (qsIndex > -1) {
      url = url.slice(0, qsIndex);
    }

    return url;
  }
}

import { Component, Input, OnChanges } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { StateModel, EditThreatFormNameModel } from '../../../../../shared';
import * as _ from 'lodash';
import * as sjv from 'simple-js-validator';
import { MyErrorStateMatcher, EditThreatUtilities, editThreatFormNames } from '../../../utilities';

@Component({
  moduleId: module.id,
  selector: 'app-tlm-edit-threat-address',
  templateUrl: './address.component.html'
})
// eslint-disable-next-line max-len
export class EditThreatAddressComponent implements OnChanges {
  @Input() addressFormGroup: UntypedFormGroup;
  @Input() index: number;
  @Input() length: number;
  @Input() removeItem: (n: number) => void;
  @Input() isPopulated: () => boolean;
  @Input() move: (n1: number, n2: number) => void;
  @Input() states: StateModel[];
  @Input() matAppearance: string;
  @Input() errorMatcher: MyErrorStateMatcher;

  maxDate = new Date();
  minDate = new Date(1900, 0, 1);
  formNames: EditThreatFormNameModel;

  constructor(private editThreatUtils: EditThreatUtilities) {
    this.formNames = editThreatFormNames;
  }

  ngOnChanges() {
    this.onChangeCurrentAddress();
  }

  removeActionEnabled(): boolean {
    return this.editThreatUtils.removeActionEnabled(this.length, this.isPopulated, this.addressFormGroup);
  }

  removeOnClick(index: number): void {
    if (this.removeActionEnabled()) {
      this.removeItem(index);
    }
  }

  moveUpActionEnabled(): boolean {
    return this.editThreatUtils.moveUpActionEnabled(this.index);
  }

  moveUpOnClick(index): void {
    if (this.moveUpActionEnabled()) {
      this.move(index, index - 1);
    }
  }

  moveDownActionEnabled() {
    return this.editThreatUtils.moveDownActionEnabled(this.length, this.index);
  }

  moveDownOnClick(index) {
    if (this.moveDownActionEnabled()) {
      this.move(index, index + 1);
    }
  }

  onChangeCurrentAddress() {
    if (
      this.addressFormGroup.get(editThreatFormNames.addressCurrent) &&
      sjv.isTrue(this.addressFormGroup.get(editThreatFormNames.addressCurrent).value)
    ) {
      this.addressFormGroup.get(editThreatFormNames.addressTo).setValue(null);
      this.addressFormGroup.get(editThreatFormNames.addressTo).disable();
    } else {
      this.addressFormGroup.get(editThreatFormNames.addressTo).enable();
    }
  }
}

<div>
  <div class="row">
    <div class="col-12">
      <span class="tlm-header">Videos</span>
    </div>
  </div>
  <div class="row no-gutters">
    <div class="col-12">
      <form class="needs-validation" novalidate [formGroup]="videosFormFormGroup">
        <div class="row">
          <div class="col-12">
            <app-tlm-edit-threat-videos
              [videoFormArray]="videosFormFormGroup.get(formNames.videoList)"
              [matAppearance]="matAppearance"
              [errorMatcher]="errorMatcher"
            >
            </app-tlm-edit-threat-videos>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>

import { Injectable } from '@angular/core';
import { IAuthService } from './auth-service.interface';
import { HttpHeaders } from '@angular/common/http';

@Injectable()
export class NoAuthService implements IAuthService {
  adminAccess = true;
  readAccess = true;
  createAccess = true;
  editAccess = true;
  boloAccess = true;
  searchAccess = true;
  redirect: string;

  constructor() {}

  public isLoggedIn(): boolean {
    return true;
  }

  public hasAdminAccess(): boolean {
    return this.adminAccess;
  }

  public hasReadAccess(): boolean {
    return this.readAccess;
  }

  public hasCreateAccess(): boolean {
    return this.createAccess;
  }

  public hasEditAccess(): boolean {
    return this.editAccess;
  }

  public hasBoloAccess(): boolean {
    return this.boloAccess;
  }

  public hasSearchAccess(): boolean {
    return this.searchAccess;
  }

  public setAdminAccess(access: boolean) {
    return (this.adminAccess = access);
  }

  public setReadAccess(access: boolean) {
    return (this.readAccess = access);
  }

  public setCreateAccess(access: boolean) {
    return (this.createAccess = access);
  }

  public setEditAccess(access: boolean) {
    return (this.editAccess = access);
  }

  public setBoloAccess(access: boolean) {
    return (this.boloAccess = access);
  }

  public setSearchAccess(access: boolean) {
    return (this.searchAccess = access);
  }

  public login(redirect?: string) {
    // do nothing
  }

  public logout() {
    // do nothing
  }

  public buildAuthHeaders(addtlHttpHeaders?: any): HttpHeaders {
    return new HttpHeaders(addtlHttpHeaders);
  }

  public getCacheCommonId(): string {
    return '-9999';
  }

  public setCacheRedirect(redirect: string) {
    this.redirect = redirect;
  }
}

<div [formGroup]="phoneFormGroup">
  <div class="row">
    <div class="col-5 col-md-6">
      <mat-form-field [appearance]="matAppearance">
        <mat-label>Phone Number</mat-label>
        <input
          [id]="'phoneNumber' + index"
          matInput
          [formControlName]="formNames.phoneNumber"
          type="tel"
          mask="(000) 000-0000"
          [errorStateMatcher]="errorMatcher"
        />
        <mat-error *ngFor="let item of phoneFormGroup.get(formNames.phoneNumber).errors | keyvalue">
          {{ item.key === 'mask' ? 'Phone format incorrect: ' + item.value.requiredMask : item.value }}
        </mat-error>
      </mat-form-field>
    </div>
    <div class="col-5 col-md-4">
      <mat-form-field [appearance]="matAppearance">
        <mat-label>Phone Type</mat-label>
        <mat-select [formControlName]="formNames.phoneType" [id]="'phoneType' + index" [errorStateMatcher]="errorMatcher">
          <mat-option>Select One</mat-option>
          <mat-option *ngFor="let phoneType of phoneTypes" [value]="phoneType">
            {{ phoneType }}
          </mat-option>
        </mat-select>
        <mat-error *ngFor="let item of phoneFormGroup.get(formNames.phoneType).errors | keyvalue">
          {{ item.value }}
        </mat-error>
      </mat-form-field>
    </div>
    <div class="col-1 my-auto">
      <button mat-icon-button color="warn" [id]="'removePhone' + index" (click)="removeOnClick(index)" [disabled]="!removeActionEnabled()">
        <mat-icon class="fa fa-close fa-lg"></mat-icon>
      </button>
    </div>
  </div>
</div>

import { Component, Inject, OnInit } from '@angular/core';
import { get as _get } from 'lodash';
import { IAuthService } from '../../auth';

@Component({
  selector: 'app-tlm-auth-callback',
  templateUrl: './auth-callback.component.html'
})
export class AuthCallbackComponent implements OnInit {
  constructor(@Inject('TlmAuthService') private authService: IAuthService) {}

  ngOnInit() {}

  login() {
    this.authService.login('/add/basic');
  }

  logout() {
    this.authService.logout();
  }

  // getName(nameKey) {
  //   return _get(this.userProfile, nameKey, null);
  // }

  // get firstName() {
  //   return _get(this.userProfile, "first_name", null);
  // }

  // get lastName() {
  //   return _get(this.userProfile, "last_name", null);
  // }

  // get userProfile() {
  //   const claims = this.authService.getIdentityClaims();
  //   if (!claims) {
  //     return null;
  //   }
  //   return claims;
  // }

  // get requestAccessToken() {
  //   return this.authService.getRequestAccessToken();
  // }

  // get id_token() {
  //   return this.authService.getIdToken();
  // }

  // get access_token() {
  //   return this.authService.getAccessToken();
  // }

  // get id_token_expiration() {
  //   return this.authService.getIdTokenExpiration();
  // }

  // get access_token_expiration() {
  //   return this.authService.getAccessTokenExpiration();
  // }

  // public testSilentRefresh() {
  //   this.authService.testSilentRefresh();
  // }
}

import { Injectable } from '@angular/core';
import { UntypedFormGroup, UntypedFormArray, UntypedFormBuilder, UntypedFormControl } from '@angular/forms';
import * as _ from 'lodash';
import * as sjv from 'simple-js-validator';
import { TlmModel, BoloModel } from '../../../shared';
import { EditThreatUtilities } from './edit-threat-utilities.service';
import { editThreatFormNames } from './edit-threat-form-names';
import { DateTime } from 'luxon';

@Injectable()
export class EditThreatUtilitiesBolosForm {
  constructor(private fb: UntypedFormBuilder, private editThreatUtils: EditThreatUtilities) {}

  convertStringToDate(dateString: string): Date {
    const dateWithoutTime = dateString.split('T')[0];
    return DateTime.fromFormat(dateWithoutTime, 'yyyy-MM-dd').toJSDate();
  }

  formatDate(date: Date): string {
    return DateTime.fromJSDate(date).toFormat('yyyy-MM-dd');
  }

  createEmptyItemFormGroup(profileTypeFC: UntypedFormControl): UntypedFormGroup {
    return this.fb.group(
      {
        [editThreatFormNames.boloStartDate]: [null, [this.startDateCannotBeGreaterThanTodayIfStartDatePopulated.bind(this)]],
        [editThreatFormNames.boloEndDate]: [null],
        [editThreatFormNames.boloReason]: []
      },
      {
        validator: [
          this.endDateCannotBeLessThanStartDateIfDateFieldsPopulated.bind(this),
          this.startDateRequiredIfBoloOrBoloFieldsPopulated.bind(this, profileTypeFC),
          this.endDateRequiredIfBoloOrBoloFieldsPopulated.bind(this, profileTypeFC)
        ]
      }
    );
  }

  initializeForm(): UntypedFormGroup {
    return new UntypedFormGroup({
      [editThreatFormNames.boloList]: new UntypedFormArray([], [this.boloArrayValidation.bind(this)])
    });
  }

  mapFromTlm(tlm: TlmModel, profileTypeFC: UntypedFormControl): UntypedFormGroup {
    const bolosFA = new UntypedFormArray([], [this.boloArrayValidation.bind(this)]);

    if (tlm.bolos && tlm.bolos.length > 0) {
      tlm.bolos.forEach((bolo) => {
        bolosFA.push(
          this.fb.group(
            {
              [editThreatFormNames.boloStartDate]: [
                this.convertStringToDate(bolo.startDate),
                [this.startDateCannotBeGreaterThanTodayIfStartDatePopulated.bind(this)]
              ],
              [editThreatFormNames.boloEndDate]: [this.convertStringToDate(bolo.endDate)],
              [editThreatFormNames.boloReason]: [bolo.reason]
            },
            {
              validator: [
                this.endDateCannotBeLessThanStartDateIfDateFieldsPopulated.bind(this),
                this.startDateRequiredIfBoloOrBoloFieldsPopulated.bind(this, profileTypeFC),
                this.endDateRequiredIfBoloOrBoloFieldsPopulated.bind(this, profileTypeFC)
              ]
            }
          )
        );
      });
    } else {
      bolosFA.push(this.createEmptyItemFormGroup(profileTypeFC));
    }

    return new UntypedFormGroup({ [editThreatFormNames.boloList]: bolosFA });
  }

  mapToTlmBoloEntry(fg: UntypedFormGroup): BoloModel {
    let mapped: BoloModel = null;
    const startDate: Date = this.editThreatUtils.getValueOrSetAsUndefined(fg.get(editThreatFormNames.boloStartDate));
    const endDate: Date = this.editThreatUtils.getValueOrSetAsUndefined(fg.get(editThreatFormNames.boloEndDate));
    const reason: string = this.editThreatUtils.getValueOrSetAsUndefined(fg.get(editThreatFormNames.boloReason));

    if (sjv.isNotEmpty(startDate) || sjv.isNotEmpty(endDate) || sjv.isNotEmpty(reason)) {
      mapped = new BoloModel(this.formatDate(startDate), this.formatDate(endDate), reason);
    }

    return mapped;
  }

  mapToTlm(fg: UntypedFormGroup): BoloModel[] {
    const mapped = new Array<BoloModel>();
    const bolosFA = fg.get(editThreatFormNames.boloList) as UntypedFormArray;

    bolosFA.controls.forEach((boloFG) => {
      const entry = this.mapToTlmBoloEntry(boloFG as UntypedFormGroup);
      if (entry) {
        mapped.push(entry);
      }
    });

    return mapped;
  }

  boloArrayValidation(fa: UntypedFormArray) {
    const errors = {};

    const bolos = [];
    fa.controls.forEach((fg) => {
      const boloStartDateFC = fg.get(editThreatFormNames.boloStartDate) as UntypedFormControl;
      const boloEndDateFC = fg.get(editThreatFormNames.boloEndDate) as UntypedFormControl;
      const boloReasonFC = fg.get(editThreatFormNames.boloReason) as UntypedFormControl;
      bolos.push({ boloStartDateFC, boloEndDateFC, boloReasonFC });
    });

    // check that dates are not in the range of another bolo
    // https://derickbailey.com/2015/09/07/check-for-date-range-overlap-with-javascript-arrays-sorting-and-reducing/

    // using old date format from datepicker directly
    // bolos.sort((a,b) => +new Date(a.boloStartDateFC.value) - +new Date(b.boloStartDateFC.value));

    bolos.sort((a, b) => a.boloStartDateFC.value - b.boloStartDateFC.value);

    const finalResult = bolos.reduce(
      (result, current, idx, arr) => {
        if (idx === 0) {
          return result;
        }
        const previous = arr[idx - 1];

        // check for any overlap

        // using old date format from datepicker directly
        // const previousEnd = new Date(previous.boloEndDateFC.value);
        // const currentStart = new Date(current.boloStartDateFC.value);

        const previousEnd = previous.boloEndDateFC.value;
        const currentStart = current.boloStartDateFC.value;
        const overlap = previousEnd >= currentStart;

        // store the result
        if (overlap) {
          // yes, there is overlap
          result.overlap = true;
          // store the specific ranges that overlap
          result.ranges.push({
            previous,
            current
          });
        }

        return result;

        // seed the reduce
      },
      { overlap: false, ranges: [] }
    );

    if (finalResult.overlap) {
      const errMsg = 'Bolo date ranges cannot overlap';
      errors['boloDatesOverlap'] = errMsg;
      fa.setErrors(errors);
    } else {
      if (fa.hasError('boloDatesOverlap')) {
        delete fa.errors['boloDatesOverlap'];
      }
    }

    if (sjv.isEmpty(errors)) {
      return null;
    } else {
      return errors;
    }
  }

  fieldRequiredIfBoloOrBoloFieldsPopulated(profileTypeFC: UntypedFormControl, fg: UntypedFormGroup, requiredFC: UntypedFormControl, errorCode: string, errMsg: string) {
    const boloStartDateFC = fg.get(editThreatFormNames.boloStartDate) as UntypedFormControl;
    const boloEndDateFC = fg.get(editThreatFormNames.boloEndDate) as UntypedFormControl;
    const boloReasonFC = fg.get(editThreatFormNames.boloReason) as UntypedFormControl;

    // scenario 1: if bolo and required not touched/submitted; then dont display any error (wait until touched/submitted)
    // scenario 2: if bolo and required touched/submitted and required empty; then display error
    // scenario 3: if bolo and required touched/submitted and required populated; then no error to display
    // scenario 4: if not bolo and bolo fields not touched/submitted; then no error to display
    // scenario 5: if not bolo and bolo fields touched/submitted and bolo fields empty (thus required empty); then no error to display
    // scenario 6: if not bolo and bolo fields touched/submitted and bolo fields populated and required empty; then display error
    // scenario 7: if not bolo and bolo fields touched/submitted and bolo fields populated and required populated; then no error to display

    if (_.get(profileTypeFC, 'value.type', '') === 'bolo') {
      if (requiredFC.touched && sjv.isEmpty(requiredFC.value)) {
        // scenario 2: if bolo and touched/submitted and empty; then display error
        this.editThreatUtils.addErrorToFormControl(requiredFC, errorCode, errMsg);
        return { [errorCode]: errMsg };
      } else {
        // scenario 1: if bolo and not touched/submitted; then dont display any error (wait until touched/submitted)
        // scenario 3: if bolo and touched/submitted and populated; then no error to display
        this.editThreatUtils.removeErrorFromFormControl(requiredFC, errorCode);
        return null;
      }
    } else {
      if (
        (boloStartDateFC.touched || boloEndDateFC.touched || boloReasonFC.touched) &&
        (sjv.isNotEmpty(boloStartDateFC.value) || sjv.isNotEmpty(boloEndDateFC.value) || sjv.isNotEmpty(boloReasonFC.value)) &&
        sjv.isEmpty(requiredFC.value)
      ) {
        // scenario 6: if not bolo and bolo fields touched/submitted and bolo fields populated and start date empty; then display error
        this.editThreatUtils.addErrorToFormControl(requiredFC, errorCode, errMsg);
        return { [errorCode]: errMsg };
      } else {
        // scenario 4: if not bolo and bolo fields not touched/submitted; then no error to display
        // scenario 5: if not bolo and bolo fields touched/submitted and bolo fields empty (thus start date empty); then no error to display
        // scenario 7: if not bolo and bolo fields touched/submitted and bolo fields populated and start date populated; then no error to display
        this.editThreatUtils.removeErrorFromFormControl(requiredFC, errorCode);
        return null;
      }
    }
  }

  startDateRequiredIfBoloOrBoloFieldsPopulated(profileTypeFC: UntypedFormControl, fg: UntypedFormGroup) {
    const boloStartDateFC = fg.get(editThreatFormNames.boloStartDate) as UntypedFormControl;
    const errorCode = 'missingStartDate';
    const errMsg = 'Bolo: [Start Date] required';
    return this.fieldRequiredIfBoloOrBoloFieldsPopulated(profileTypeFC, fg, boloStartDateFC, errorCode, errMsg);
  }

  endDateRequiredIfBoloOrBoloFieldsPopulated(profileTypeFC: UntypedFormControl, fg: UntypedFormGroup) {
    const boloEndDateFC = fg.get(editThreatFormNames.boloEndDate) as UntypedFormControl;
    const errorCode = 'missingEndDate';
    const errMsg = 'Bolo: [End Date] required';
    return this.fieldRequiredIfBoloOrBoloFieldsPopulated(profileTypeFC, fg, boloEndDateFC, errorCode, errMsg);
  }

  endDateCannotBeLessThanStartDateIfDateFieldsPopulated(fg: UntypedFormGroup) {
    const boloStartDateFC = fg.get(editThreatFormNames.boloStartDate) as UntypedFormControl;
    const boloEndDateFC = fg.get(editThreatFormNames.boloEndDate) as UntypedFormControl;

    if (
      sjv.isNotEmpty(boloStartDateFC.value) &&
      sjv.isNotEmpty(boloEndDateFC.value) &&
      new Date(boloStartDateFC.value) > new Date(boloEndDateFC.value)
    ) {
      const errMsg = 'Bolo: [End Date] should be greater than or equal to [Start Date]';
      this.editThreatUtils.addErrorToFormControl(boloEndDateFC, 'endDateLessThanStartDate', errMsg);
      return { endDateLessThanStartDate: errMsg };
    } else {
      this.editThreatUtils.removeErrorFromFormControl(boloEndDateFC, 'endDateLessThanStartDate');
      return null;
    }
  }

  startDateCannotBeGreaterThanTodayIfStartDatePopulated(boloStartDateFC: UntypedFormControl) {
    if (sjv.isNotEmpty(boloStartDateFC.value) && new Date(boloStartDateFC.value) > new Date(Date.now())) {
      const errMsg = 'Bolo: [Start Date] cannot be greater than today';
      this.editThreatUtils.addErrorToFormControl(boloStartDateFC, 'startDateGreaterThanToday', errMsg);
      return { startDateGreaterThanToday: errMsg };
    } else {
      this.editThreatUtils.removeErrorFromFormControl(boloStartDateFC, 'startDateGreaterThanToday');
      return null;
    }
  }
}

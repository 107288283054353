import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { OAuthModule } from 'angular-oauth2-oidc';
import { AdminGuard } from './admin.guard';
import { AuthGuard } from './auth.guard';
import { Auth0AuthService } from './auth0.auth-service';
import { BoloGuard } from './bolo.guard';
import { CreateGuard } from './create.guard';
import { EditGuard } from './edit.guard';
import { NoAuthService } from './no.auth-service';
import { ReadGuard } from './read.guard';
import { SearchGuard } from './search.guard';

@NgModule({
  imports: [CommonModule, OAuthModule.forRoot()],
  declarations: []
})
export class AuthModule {
  static forRoot(): ModuleWithProviders<AuthModule> {
    return {
      ngModule: AuthModule,
      providers: [AdminGuard, AuthGuard, BoloGuard, ReadGuard, EditGuard, CreateGuard, SearchGuard, NoAuthService, Auth0AuthService]
    };
  }
}
